/**
 * Настройки для инструментов web аналитики.
 *
 */

export const YM_GOAL_PLAY_VIA_SEARCH = "PLAY_VIA_SEARCH",
  YM_GOAL_PLAY_VIA_TILE = "PLAY_VIA_TILE",
  YM_GOAL_PLAY_VIA_MAP = "PLAY_VIA_MAP",
  YM_GOAL_PLAY_LIVE = "PLAY_LIVE",
  YM_GOAL_PLAY_ARCHIVE = "PLAY_ARCHIVE",
  YM_GOAL_MULTI_ARCHIVE = "MULTI_ARCHIVE";
