/**
 * Отдельный vuex модуль для взаимодействия по cars-API
 */
import {
  DEFAULT_PAGE_SIZE_FOR_SELECT,
  DEFAULT_PAGE_SIZE_FOR_TABLE,
  getExtraApi,
  makeFilterApi,
  reformatErrorsForm,
  SPECIAL_TYPES_DATA_IN_CELLS
} from "@/store/pacs/helpers.js";
import {ACTION_LOAD_ENTITIES_FOR_MULTI_EDIT} from "@/store/pacs/actions.js";

// actions для car. Вызов действия начинать с "pacs/cars/"
export const ACTION_LOAD_CARS = "LOAD_CARS";
export const ACTION_LOAD_CARS_FOR_TABLE = "LOAD_CARS_FOR_TABLE";
export const ACTION_LOAD_CARS_FOR_SELECT = "LOAD_CARS_FOR_SELECT";
export const ACTION_LOAD_CAR_FOR_EDIT = "LOAD_CAR_FOR_EDIT";
export const ACTION_LOAD_CARS_FOR_MULTI_EDIT = "LOAD_CARS_FOR_MULTI_EDIT";
export const ACTION_CREATE_CAR = "CREATE_CAR";
export const ACTION_EDIT_CAR = "EDIT_CAR";
export const ACTION_DELETE_CARS = "DELETE_CARS";

/**
 * Структура для общения с API по конкретной сущности.
 */
export const CAR_STRUCT = Object.freeze({
  key: "id", // Ключевое поле в API, по которому осуществляется идентификация.
  // Список полей сущности.
  fields: {
    id: {
      name: "id", // Название поля в API.
      title: "ID", // Название поля для человека.
      type: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE, // Тип данных для отображения в ячейке таблицы (По умолчанию SPECIAL_TYPES_DATA_IN_CELLS.TEXT).
      sort: true, // Разрешение сортировать по полю.
    },
    employee_id: {
      name: "employee_id",
      title: "Сотрудник",
      type: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
      extra: "employee", // Названия связанных сущностей (конкретное поле либо массив полей) (По умолчанию нет).
    },
    number: {
      name: "number",
      title: "Номер",
      type: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
    },
  },
  // Список сущностей связанных с текущей.
  extras: {
    employee: "employee",
  },
  // Список используемых в API фильтров.
  filters: {
    employee_id: "employee_id"
  },
});

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * Загрузка списка автомобилей.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_CARS](context, {page, pageSize, orderBy, filters, search}) {
      const fields = Object.keys(CAR_STRUCT.fields),
        response = await this.getters.ajaxClient.post("/v0/cars/", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          extra: getExtraApi(CAR_STRUCT, fields),
          filters,
          search
        });
      return response.data;
    },
    /**
     * Загрузка списка автомобилей для отображения в таблице.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_CARS_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy = [], filters = [], search = ""}) {
      return dispatch(ACTION_LOAD_CARS, {page, pageSize, orderBy, filters, search});
    },
    /**
     * Загрузка списка автомобилей для отображения в селекте.
     *
     * @param {Function} dispatch
     * @param {Number} pageSize
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_CARS_FOR_SELECT]({dispatch}, {pageSize = DEFAULT_PAGE_SIZE_FOR_SELECT, search}) {
      const responseData = await dispatch(ACTION_LOAD_CARS, {pageSize, search});
      return responseData.results;
    },
    /**
     * Загрузка одного автомобиля для редактирования.
     *
     * @param {Function} dispatch
     * @param {Number} carId
     * @return {Promise}
     */
    async [ACTION_LOAD_CAR_FOR_EDIT]({dispatch}, carId) {
      const responseData = await dispatch(ACTION_LOAD_CARS, {page: 1, pageSize: 1, filters: [makeFilterApi(CAR_STRUCT.key, "=", carId)]});
      return {entityInfo: responseData.results[0], extraInfo: responseData.extra};
    },
    /**
     * Загрузка ключевых данных автомобилей для множественного редактирования.
     *
     * @param {Function} dispatch
     * @param {Array} filters
     * @param {String} search
     * @return {Promise.<Array.<Number>>}
     */
    async [ACTION_LOAD_CARS_FOR_MULTI_EDIT]({dispatch}, {filters, search}) {
      return dispatch(`pacs/${ACTION_LOAD_ENTITIES_FOR_MULTI_EDIT}`, {
        apiUrl: "/v0/cars/",
        keyField: CAR_STRUCT.key,
        dataForApi: {filters, search},
      }, {root: true});
    },
    /**
     * Создание автомобиля.
     *
     * @param {Object} context
     * @param {String} carNumber
     * @param {Number} employeeId
     * @return {Promise}
     */
    async [ACTION_CREATE_CAR](context, {carNumber, employeeId}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/cars/create/", {
          number: carNumber.toLocaleUpperCase(),
          employee_id: employeeId,
        });
        return response.data;
      } catch (error) {
        const apiDataErrors = reformatErrorsForm(error.response.data.fields),
          dataErrors = {
            carNumber: apiDataErrors["number"],
            employeeId: apiDataErrors["employee_id"],
          };
        throw [dataErrors, error.response.data];
      }
    },
    /**
     * Редактирование автомобиля.
     *
     * @param {Object} context
     * @param {Number} carId
     * @param {String} carNumber
     * @param {Number} employeeId
     * @return {Promise}
     */
    async [ACTION_EDIT_CAR](context, {carId, carNumber, employeeId}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/cars/edit/", {
          id: carId,
          number: carNumber.toLocaleUpperCase(),
          employeeId: employeeId,
        });
        return response.data;
      } catch (error) {
        const apiDataErrors = reformatErrorsForm(error.response.data.fields),
          dataErrors = {
            carNumber: apiDataErrors["number"],
            employeeId: apiDataErrors["employee_id"],
          };
        throw [dataErrors, error.response.data];
      }
    },
    /**
     * Удаление автомобилей.
     *
     * @param {Object} context
     * @param {Array.<Number>} carIds
     * @return {Promise}
     */
    async [ACTION_DELETE_CARS](context, carIds) {
      return this.getters.ajaxClient.post("/v0/cars/delete/", {ids: carIds});
    },
  },
};
