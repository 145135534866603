/**
 * Вспомогательные функции.
 */

/**
 * Класс с согласованной структурой параметров для таблиц сущностей.
 *
 * Параметры необходимы для корректной выдачи информации в таблице.
 * Класс необходим для преобразования объекта параметров в строку и обратно, чтобы иметь в одном месте механизм сериализации
 * параметров, результат которой будет подставляться в адресную строку.
 *
 * Если для какого-либо параметра информация не задана - она исключается из конечного результата сериализации.
 * Для такого случая в конечном счете значения принимаются по умолчанию, которые задаются для каждой сущности в отдельности.
 */
export class TableQueryParams {
  constructor({currentPage = null, order = null, filters = null, searchText = null}) {
    this.currentPage = currentPage;
    this.order = order;
    this.filters = filters;
    this.searchText = searchText;
  }

  /**
   * Преобразует объект в строку, пригодную для вставки в качестве значения query параметра в URL.
   *
   * @return {String}
   */
  stringify() {
    return stringifyQueryParams({
      p: this.currentPage,
      o: this.order,
      f: this.filters,
      s: this.searchText,
    });
  }

  /**
   * Создаст из переданной строки объект {@link TableQueryParams}.
   *
   * @param {String|Null} rawQueryParams
   * @return {TableQueryParams}
   */
  static parse(rawQueryParams) {
    const parsedParams = parseQueryParams(rawQueryParams);
    return new TableQueryParams({
      currentPage: parsedParams.p ? parseInt(parsedParams.p) : null,
      order: parsedParams.o || null,
      filters: parsedParams.f || null,
      searchText: parsedParams.s || null,
    });
  }
}

/**
 * Преобразует объект в строку, пригодную для вставки в качестве значения query параметра в URL.
 *
 * @param {Object} queryParams
 * @return {String}
 */
export function stringifyQueryParams(queryParams) {
  return encodeURI(JSON.stringify(_.omitBy(queryParams, _.isNil)));
}

/**
 * Преобразует строку, взятую из части query в URL, и преобразует ее в объект.
 *
 * @param {String|Null} rawQueryParams
 * @return {String}
 */
export function parseQueryParams(rawQueryParams) {
  return JSON.parse(decodeURI(rawQueryParams || encodeURI("{}")));
}
