<template>
  <div class="main__content content">
    <div class="pacs-settings-table">
      <div class="pacs-settings-table__group-setting">
        <SmartInputDate
          v-model="archiveFrom"
          :caption="$t('startDate')"
          class="pacs-settings-table__settings-control pacs-settings-table__settings-control_small"
          size="l"
          @input="applyTableSettings()"
        />
        <SmartInputDate
          v-model="archiveTo"
          :caption="$t('endDate')"
          class="pacs-settings-table__settings-control pacs-settings-table__settings-control_small"
          size="l"
          @input="applyTableSettings()"
        />
        <CamsButton
          class="pacs-settings-table__settings-control"
          priority="primary"
          size="l"
          type="button"
          @click="downloadTableAsCSV()"
        >
          {{ $t('downloadReport') }}
        </CamsButton>
      </div>
    </div>

    <SpinnerLoading v-if="isLoading" class="loader_center" color="blue" />

    <template v-else>
      <p v-show="dataRows.length === 1000" class="pacs-settings-table__warn">
        {{ $t('tooManyRows') }}
      </p>
      <PacsTable
        :column-captions="{
          id: 'eventID',
          cameraNumber: 'cameraNumber',
          date: 'eventDate',
          duration: 'duration',
        }"
        :column-names="columnNames"
        :column-widths="columnWidths"
        :data-rows="dataRows"
        :page-count="1"
        :total-count="dataRows.length"
        :view-only-mode="true"
      >
        <template #customThCells />
        <template #customTdCells="{dataRow}">
          <CamsButton icon-type="only" :title="$t('eventVideo')" type="button" @click="playEventStartForEventInTable(dataRow['_analyticMessage_'])">
            <svg>
              <use xlink:href="#icon-video-preview" />
            </svg>
          </CamsButton>
          <CamsButton icon-type="only" :title="$t('downloadEventVideo')" type="button" @click="downloadEventVideoInTable(dataRow['_analyticMessage_'])">
            <svg>
              <use xlink:href="../../../assets/img/icons.svg#cloud-archive" />
            </svg>
          </CamsButton>
        </template>
      </PacsTable>
    </template>

    <!--iframe для скачивания-->
    <iframe :src="downloadUrl" frameborder="0" height="1" width="1" />
  </div>
</template>

<script>
import {ACTION_LOAD_PACS_MOTION_ALARM_REPORT} from "@/store/pacs/analytics/index.js";
import PacsTable from "@/components/pacs/PacsTable.vue";
import {SPECIAL_TYPES_DATA_IN_CELLS} from "@/store/pacs/helpers.js";
import {eventTableMixin} from "@/components/pacs/analytics/mixins.js";
import {downloadCSV} from "@/utils/helpers.js";

/**
 * Компонент отображения таблицы и фильтров с информацией по событиям распознавания движения в кадре.
 */
export default {
  name: "TableMotionAlarm",
  mixins: [
    eventTableMixin,
  ],
  components: {
    PacsTable,
  },
  data() {
    return {
      columnNames: ["id", "cameraNumber", "date", "duration"],
      // Настройки для фиксации ширины определенных колонок.
      columnWidths: {
        id: "130px",
      },
      archiveFrom: null,
      archiveTo: null,
    };
  },
  computed: {
    /**
     * @return {Date} Корректное представление archiveFrom в сравнении с archiveTo.
     */
    clearArchiveFrom() {
      return this.archiveFrom && this.archiveTo ? new Date(Math.min(this.archiveFrom.getTime(), this.archiveTo.getTime())) : null;
    },
    /**
     * @return {Date} Корректное представление archiveTo в сравнении с archiveFrom.
     */
    clearArchiveTo() {
      return this.archiveFrom && this.archiveTo ? new Date(Math.max(this.archiveFrom.getTime(), this.archiveTo.getTime())) : null;
    },
  },
  methods: {
    /**
     * @see eventTableMixin.methods.applyTableSettings
     */
    applyTableSettings() {
      this.stringifyQueryParams({
        f: +this.clearArchiveFrom,
        t: +this.clearArchiveTo,
      });
    },
    /**
     * @see eventTableMixin.methods.assignQueryParams
     */
    assignQueryParams(parsedQueryParams) {
      if (_.isEmpty(parsedQueryParams)) {
        this.resetFilters();
      } else {
        this.archiveFrom = new Date(parsedQueryParams.f);
        this.archiveTo = new Date(parsedQueryParams.t);
      }
    },
    /**
     * @see eventTableMixin.methods.loadPage.
     */
    async loadPage() {
      if (!this.clearArchiveFrom || !this.clearArchiveTo) {
        return;
      }

      this.isLoading = true;
      let responseData;
      try {
        responseData = await this.$store.dispatch(`pacs/analytics/${ACTION_LOAD_PACS_MOTION_ALARM_REPORT}`, {
          archiveFrom: this.clearArchiveFrom,
          archiveTo: this.clearArchiveTo,
        });
      } catch (error) {
        if (!error) {
          return; // Для прерванных ранее отправленных запросов.
        }
        this.$camsdals.alert(this.$t('loadingError'));
      }
      responseData = responseData ?? [];
      this.dataRows = responseData.map((message) => {
        return {
          "id": {
            value: message.id,
          },
          "cameraNumber": {
            value: message.cameraNumber,
          },
          "date": {
            type: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
            value: message.date,
          },
          "duration": {
            value: message.duration,
          },
          "_analyticMessage_": message, // Объект сообщения для передачи в события клика в кнопках на строчках в таблице. См. в этом шаблоне.
        };
      });
      this.isLoading = false;
    },
    /**
     * Сброс фильтров в начальное состояние.
     * Фильтры по умолчанию по датам за последний час.
     */
    resetFilters() {
      const archiveTo = this.maxArchiveTo || new Date(),
            archiveFrom = new Date(archiveTo.getTime());
      archiveFrom.setHours(archiveTo.getHours() - 1);
      this.archiveFrom = archiveFrom;
      this.archiveTo = archiveTo;
    },
    /**
     * Выгрузка данных таблицы в виде CSV.
     */
    downloadTableAsCSV() {
      let csvText = "";

      const filename = this.$t('motionReportFilename'),
            headers = [this.columnCaptions.duration, this.columnCaptions.cameraNumber, this.columnCaptions.date];
      csvText = `${headers.join(',')}\r\n`;
      this.dataRows.forEach((message) => {
        csvText += `${message.duration.value},${message.cameraNumber.value},${this.$options.filters.localDateTime(message.date.value)}\r\n`;
      });
      downloadCSV(filename, csvText);
    },
  },
};
</script>
