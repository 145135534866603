<template>
  <div class="one-screen">
    <div class="one-screen__nav">
      <button
        :class="{'btn__tab--active': selectedTab === oneScreenTabs.SMART_PLAYER}"
        class="nav__item btn__tab"
        type="button"
        @click="selectedTab = oneScreenTabs.SMART_PLAYER"
      >
        {{ $t('player') }}
      </button>
      <button
        v-if="cameraInfo.isEmbed"
        :class="{'btn__tab--active': selectedTab === oneScreenTabs.EMBED}"
        class="nav__item btn__tab"
        type="button"
        @click="selectedTab = oneScreenTabs.EMBED"
      >
        {{ $t('embed') }}
      </button>
      <button
        v-if="cameraInfo.hasAnalyticThermalVision"
        :class="{'btn__tab--active': selectedTab === oneScreenTabs.THERMAL_VISION_REPORT}"
        class="nav__item btn__tab"
        type="button"
        @click="selectedTab = oneScreenTabs.THERMAL_VISION_REPORT"
      >
        {{ $t('thermalVisionReport') }}
      </button>
      <button
        v-if="cameraInfo.hasAnalyticCarNumber"
        :class="{'btn__tab--active': selectedTab === oneScreenTabs.CAR_NUMBER_REPORT}"
        class="nav__item btn__tab"
        type="button"
        @click="selectedTab = oneScreenTabs.CAR_NUMBER_REPORT"
      >
        {{ $t('carNumberReport') }}
      </button>
      <button
        v-if="cameraInfo.hasAnalyticFaceRecognition"
        :class="{'btn__tab--active': selectedTab === oneScreenTabs.FACE_RECOGNITION_REPORT}"
        class="nav__item btn__tab"
        type="button"
        @click="selectedTab = oneScreenTabs.FACE_RECOGNITION_REPORT"
      >
        {{ $t('faceRecognitionReport') }}
      </button>
      <button
        v-if="cameraInfo.hasAnalyticHelmet"
        :class="{'btn__tab--active': selectedTab === oneScreenTabs.HELMET_REPORT}"
        class="nav__item btn__tab"
        type="button"
        @click="selectedTab = oneScreenTabs.HELMET_REPORT"
      >
        {{ $t('helmetReport') }}
      </button>
      <button
        v-if="cameraInfo.hasAnalyticMask"
        :class="{'btn__tab--active': selectedTab === oneScreenTabs.MASK_REPORT}"
        class="nav__item btn__tab"
        type="button"
        @click="selectedTab = oneScreenTabs.MASK_REPORT"
      >
        {{ $t('maskReport') }}
      </button>
      <button
        v-if="cameraInfo.hasAnalyticPerimeter"
        :class="{'btn__tab--active': selectedTab === oneScreenTabs.PERIMETER_REPORT}"
        class="nav__item btn__tab"
        type="button"
        @click="selectedTab = oneScreenTabs.PERIMETER_REPORT"
      >
        {{ $t('perimeterReport') }}
      </button>
      <button
        v-if="cameraInfo.hasAnalyticMotionAlarm"
        :class="{'btn__tab--active': selectedTab === oneScreenTabs.MOTION_ALARM_REPORT}"
        class="nav__item btn__tab"
        type="button"
        @click="selectedTab = oneScreenTabs.MOTION_ALARM_REPORT"
      >
        {{ $t('motionAlarmReport') }}
      </button>

      <button
        v-if="cameraInfo.hasAnalyticCrowd"
        :class="{'btn__tab--active': selectedTab === oneScreenTabs.CROWD_REPORT}"
        class="nav__item btn__tab"
        type="button"
        @click="selectedTab = oneScreenTabs.CROWD_REPORT"
      >
        {{ $t('crowdReport') }}
      </button>
      <button
        v-if="cameraInfo.hasAnalyticPeopleCount"
        :class="{'btn__tab--active': selectedTab === oneScreenTabs.PEOPLE_REPORT}"
        class="nav__item btn__tab"
        type="button"
        @click="selectedTab = oneScreenTabs.PEOPLE_REPORT"
      >
        {{ $t('peopleCountReport') }}
      </button>
      <button
        :class="{'btn-favorites--active': cameraInfo.inFavorites}"
        class="nav__item btn-favorites"
        type="button"
        @click="toggleFavorite()"
      >
        <svg class="icon icon-heart">
          <use xlink:href="#icon-heart" />
        </svg>
      </button>
      <button
        :class="[cameraInfo.hasCoordinates ? 'btn-blue' : 'btn-gray']"
        :disabled="!cameraInfo.hasCoordinates"
        class="nav__item btn-map"
        type="button"
        @click="goToMap()"
      >
        {{ $t('onMap') }}
      </button>
    </div>

    <SmartPlayerScreen
      v-if="selectedTab === oneScreenTabs.SMART_PLAYER"
      :initial-camera-info="cameraInfo"
      :initial-time-shift="localInitialTimeShift"
      class="one-screen__main"
    />
    <EmbedScreen
      v-if="selectedTab === oneScreenTabs.EMBED"
      :initial-camera-info="cameraInfo"
      class="one-screen__main"
    />
    <ThermalVisionReportScreen
      v-if="selectedTab === oneScreenTabs.THERMAL_VISION_REPORT"
      :initial-camera-info="cameraInfo"
      class="one-screen__main"
    />
    <CarNumberReportScreen
      v-if="selectedTab === oneScreenTabs.CAR_NUMBER_REPORT"
      :initial-camera-info="cameraInfo"
      class="one-screen__main"
    />
    <FaceRecognitionReportScreen
      v-if="selectedTab === oneScreenTabs.FACE_RECOGNITION_REPORT"
      :initial-camera-info="cameraInfo"
      class="one-screen__main"
    />
    <HelmetReportScreen
      v-if="selectedTab === oneScreenTabs.HELMET_REPORT"
      :initial-camera-info="cameraInfo"
      class="one-screen__main"
    />
    <MaskReportScreen
      v-if="selectedTab === oneScreenTabs.MASK_REPORT"
      :initial-camera-info="cameraInfo"
      class="one-screen__main"
    />
    <PerimeterReportScreen
      v-if="selectedTab === oneScreenTabs.PERIMETER_REPORT"
      :initial-camera-info="cameraInfo"
      class="one-screen__main"
    />
    <CrowdReportScreen
      v-if="selectedTab === oneScreenTabs.CROWD_REPORT"
      :initial-camera-info="cameraInfo"
      class="one-screen__main"
    />
    <MotionAlarmReportScreen
      v-if="selectedTab === oneScreenTabs.MOTION_ALARM_REPORT"
      :initial-camera-info="cameraInfo"
      class="one-screen__main"
    />
    <PeopleReportScreen
      v-if="selectedTab === oneScreenTabs.PEOPLE_REPORT"
      :initial-camera-info="cameraInfo"
      class="one-screen__main"
    />
  </div>
</template>

<script>
import {CameraInfo} from "@/store/cameras/index.js";
import {ONE_SCREEN_TABS} from "@/utils/consts.js";
import ThermalVisionReportScreen from "@/components/oneScreen/reports/ThermalVisionReportScreen.vue";
import {ROUTE_CAMS_FAV, ROUTE_CAMS_SEARCH, ROUTE_MAP_FAV, ROUTE_MAP_MY, ROUTE_MAP_PUBLIC} from "@/router/names.js";
import CarNumberReportScreen from "@/components/oneScreen/reports/CarNumberReportScreen.vue";
import FaceRecognitionReportScreen from "@/components/oneScreen/reports/FaceRecognitionReportScreen.vue";
import HelmetReportScreen from "@/components/oneScreen/reports/HelmetReportScreen.vue";
import MotionAlarmReportScreen from "@/components/oneScreen/reports/MotionAlarmReportScreen.vue";
import MaskReportScreen from "@/components/oneScreen/reports/MaskReportScreen.vue";
import CrowdReportScreen from "@/components/oneScreen/reports/CrowdReportScreen.vue";
import SmartPlayerScreen from "@/components/oneScreen/SmartPlayerScreen.vue";
import EmbedScreen from "@/components/oneScreen/EmbedScreen.vue";
import PerimeterReportScreen from "@/components/oneScreen/reports/PerimeterReportScreen.vue";
import PeopleReportScreen from "@/components/oneScreen/reports/PeopleReportScreen.vue";
import {mapState} from "vuex";
import {
  QUERY_KEY_MAP_CAMERA_NUMBER,
  QUERY_KEY_ONE_SCREEN_TIME_SHIFT
} from "@/router/queryKeys.js";



/**
 * Основной компонент для предоставления различных действий и опций по одной конкретной камере.
 *
 * Непосредственно сам компонент не имеет какой-то серьезной функциональной части, но имеет собственное меню для навигации
 * между функциональными частями: плеер, отчеты и т.д.
 *
 * todo внести сюда общие части между скринами
 */
export default {
  name: "OneScreenDialog",
  components: {
    SmartPlayerScreen,
    EmbedScreen,
    ThermalVisionReportScreen,
    CarNumberReportScreen,
    FaceRecognitionReportScreen,
    HelmetReportScreen,
    MotionAlarmReportScreen,
    CrowdReportScreen,
    MaskReportScreen,
    PerimeterReportScreen,
    PeopleReportScreen,
  },
  props: {
    /**
     * Экземпляр {@link CameraInfo} по которому отображается базовая информация в компоненте.
     * Необходимо убедится что переданная информация по камере обладает требуемой информацией без необходимости ее повторного запроса.
     * todo но возможно стоит переделать это, когда вынесуться общие части между экранами сюда
     * тогда и отследить где вызывается модалка и убрать лишнее получение доп информации
     */
    cameraInfo: {
      type: CameraInfo,
      required: true
    },
    /**
     * Начало для воспроизведения архивного видео при открытии плеера.
     */
    initialTimeShift: {
      type: Date,
      default: null,
    },
    /**
     * Запрошенный таб при открытии диалогового окна.
     */
    initialSelectedTab: {
      type: String,
      default: ONE_SCREEN_TABS.SMART_PLAYER
    }
  },
  data() {
    let selectedTab = ONE_SCREEN_TABS[this.initialSelectedTab] || ONE_SCREEN_TABS.SMART_PLAYER;
    // todo Корректировка на случай обращения к вкладке для камеры у которой нет соответствующей функции.
    // if (selectedTab === ONE_SCREEN_TABS.THERMAL_VISION_REPORT && !this.cameraInfo.hasAnalytics) {
    //   selectedTab = ONE_SCREEN_TABS.LIVE;
    // }

    return {
      showButton: true,
      oneScreenTabs: ONE_SCREEN_TABS,
      selectedTab: selectedTab,
      localInitialTimeShift: this.initialTimeShift
    };
  },
  computed: {
    ...mapState(["sharedTimeShift"]),

  }
  ,
  watch: {
    value(val) {
      this.newValue = val;
      this.$emit("change", val);
    },
    /**
     * При переключении с любой другой вкладки на вкладку с плеером
     * необходимо отобразить метку времени в том же месте,
     * на котором она и была до перехода со вкладки плеера.
     */
    selectedTab() {
      if (this.selectedTab === this.oneScreenTabs.SMART_PLAYER && this.$route.query[QUERY_KEY_ONE_SCREEN_TIME_SHIFT]) {
        this.localInitialTimeShift = this.sharedTimeShift;
      }
    }
  },
  methods: {
    /**
     * Добавление/удаление камеры в списке избранных.
     *
     * По умолчанию переключаем маркера сразу чтобы не вызывать задержек в интерфейсе,
     * а если произошла ошибка, то возвращаем старое значение.
     */
    toggleFavorite() {
      const inFavorites = this.cameraInfo.inFavorites;
      this.$store.dispatch("apiFav", {cameraNumber: this.cameraInfo.number, isFav: inFavorites})
        .catch(() => {
          // eslint-disable-next-line vue/no-mutating-props
          this.cameraInfo.inFavorites = inFavorites;
        });
      // eslint-disable-next-line vue/no-mutating-props
      this.cameraInfo.inFavorites = !inFavorites;
    },
    /**
     * Закрытие окна и переход к картам с позиционированием на камере.
     */
    goToMap() {
      if (!this.cameraInfo.hasCoordinates) {
        return;
      }
      let routeName = ROUTE_MAP_MY;

      // проверяем на какой странице было вызвано модельное окно и переадресуем на соответствующую вкладку в картах
      if (
        // && this.cameraInfo.inFavorites - нужно для случая если пользователь открыл модальное окно,
        // убрал камеру из избранных и потом перешел на карты
        (this.$route.name === ROUTE_CAMS_FAV && this.cameraInfo.inFavorites) ||
        (this.$route.name === ROUTE_MAP_FAV && this.cameraInfo.inFavorites) ||
        (this.$route.name === ROUTE_CAMS_SEARCH && this.cameraInfo.inFavorites)
      ) {
        routeName = ROUTE_MAP_FAV;
      } else if (
        this.$route.name === ROUTE_MAP_PUBLIC ||
        (this.$route.name === ROUTE_CAMS_FAV && this.cameraInfo.isPublic) ||
        (this.$route.name === ROUTE_CAMS_SEARCH && this.cameraInfo.isPublic)
      ) {
        routeName = ROUTE_MAP_PUBLIC;
      }

      this.$vuedals.close();
      this.$router.push({
        name: routeName,
        query: {[QUERY_KEY_MAP_CAMERA_NUMBER]: this.cameraInfo.number}
      });
    },
  }
};
</script>

<style lang="scss">
@import "./scss/index.scss";
</style>
