<template>
  <div class="pacs-container-entity">
    <SpinnerLoadingModal v-if="isLoading" />
    <p v-else-if="errorLoadEntity">
      {{ $t('errorLoadEntity') }}
    </p>
    <template v-else>
      <form class="pacs-entity-form-primary" @keyup.enter="saveDataWithPhotosAndReload()" @submit.prevent="saveDataWithPhotosAndReload()">
        <SmartInputText
          v-model="dataForm.title"
          :error="dataErrors.title"
          :caption="$t('employeeName')"
          maxlength="255"
          class="input-row"
        />

        <SmartVSelect
          v-model="dataForm.deviceAccessGroupIds"
          :initial-options="initialOptionsDeviceAccessGroups"
          :multiple="true"
          :settings-remote-search="settingsSelectDeviceAccessGroup"
          :caption="$t('deviceAccessGroups')"
          class="input-row"
        />

        <!-- todo до переделки апи фото
        <div class="input-row">
          <CamsButton priority="primary" type="button" @click="$refs.fileInput.click()">
            {{ $t('addPhoto') }}
          </CamsButton>
          <input
            ref="fileInput"
            accept="image/*"
            multiple
            style="display:none"
            type="file"
            @change="selectPhoto"
          >

          <div v-if="photos.length" class="pacs-entity-photos">
            <div
              v-for="(photo, index) in photos"
              :key="index"
              class="employee-photo-preview"
            >
              <img
                :src="photo.id ? photo.image : photo"
                class="employee-photo-preview__img"
              >
              <CamsButton
                class="employee-photo-preview__delete-button"
                icon-type="only"
                size="s"
                :title="$t('deletePhoto')"
                type="button"
                @click="photo.id ? deleteExistPhoto(photo.id) : deleteNewPhoto(photo)"
              >
                <img alt="{{ $t('deletePhoto') }}" src="@/assets/img/icons/close.svg">
              </CamsButton>
            </div>
          </div>
          <p v-else style="margin-top: 7px">
            {{ $t('noPhotos') }}
          </p>
        </div>
        -->

        <div class="input-row">
          <SmartInputArray
            v-model="dataForm.cars"
            :error="dataErrors.cars"
            :caption="$t('cars')"
            class="input-row"
          />
          <SmartInputArray
            v-model="dataForm.attachedUsernames"
            :error="dataErrors.attachedUsernames"
            :caption="$t('attachedUsernames')"
            class="input-row"
          />
          <p>
            {{ $t('employeeCarsCount') }} {{ carsCount }}
            <router-link v-show="dataForm.cars.length > 0" :to="routeEmployeeCars">
              {{ $t('viewAll') }}
            </router-link>
          </p>
        </div>

        <SmartSwitch
          v-model="dataForm.isActive"
          :error="dataErrors.isActive"
          :caption="$t('isActive')"
          class="input-row"
        />

        <div class="buttons-group input-row">
          <CamsButton priority="primary" type="submit">
            {{ $t('save') }}
          </CamsButton>
          <CamsButton type="button" @click="deleteEmployee()">
            {{ $t('delete') }}
          </CamsButton>
        </div>
      </form>
      <div class="pacs-entity-form-secondary">
        <SetPermissionsForEmployees
          :employee-id="entityId"
          class="input-row"
        >
          <template #legendCaption>
            {{ $t('accessSettings') }}
          </template>
        </SetPermissionsForEmployees>
        <div v-show="initialOptionsDeviceAccessGroups.length">
          <p><strong>{{ $t('accessGroupList') }}</strong></p>
          <p v-for="(deviceAccessGroupTitle, deviceAccessGroupId) in deviceAccessGroupLinksInfo" :key="`deviceAccessGroup-${deviceAccessGroupId}`">
            <router-link :to="{name: routes.ROUTE_PACS_EDIT_DEVICE_ACCESS_GROUP, params: {deviceAccessGroupId}}">
              {{ $t('viewGroup') }} <strong>{{ deviceAccessGroupTitle }}</strong>
            </router-link>
          </p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import SetPermissionsForEmployees from "@/components/pacs/pacsPermissions/SetPermissionsForEmployees.vue";
import {ACTION_DELETE_EMPLOYEES, ACTION_EDIT_EMPLOYEE, ACTION_LOAD_EMPLOYEE_FOR_EDIT, EMPLOYEE_STRUCT} from "@/store/pacs/employees/index.js";
import {ACTION_DELETE_PHOTO, ACTION_LOAD_PHOTOS_FOR_EMPLOYEE} from "@/store/pacs/employeesPhoto/index.js";
import {editEntityMixin} from "@/components/pacs/mixins.js";
import {actionsWithPhotosMixin} from "@/components/pacs/employess/mixins.js";
import {ACTION_LOAD_DEVICE_ACCESS_GROUPS_FOR_SELECT, DEVICE_ACCESS_GROUP_STRUCT} from "@/store/pacs/deviceAccessGroups/index.js";
import {makeFilterApi} from "@/store/pacs/helpers.js";
import {CAR_STRUCT} from "@/store/pacs/cars/index.js";
import {ROUTE_PACS_CARS, ROUTE_PACS_EDIT_DEVICE_ACCESS_GROUP, ROUTE_PACS_EMPLOYEES} from "@/router/names.js";
import {TableQueryParams} from "@/components/pacs/helpers.js";
import {QUERY_KEY_TABLE_PARAMS} from "@/router/queryKeys.js";

/**
 * Компонент страницы редактирования сотрудника.
 */
export default {
  components: {SetPermissionsForEmployees},
  mixins: [editEntityMixin, actionsWithPhotosMixin],
  data() {
    const employeeId = Number(this.$route.params.employeeId),
          paramsRouteEmployeeCars = new TableQueryParams({
            filters: [makeFilterApi(CAR_STRUCT.filters.employee_id, "=", employeeId)],
          }),
          routeEmployeeCars = {name: ROUTE_PACS_CARS, query: {[QUERY_KEY_TABLE_PARAMS]: paramsRouteEmployeeCars.stringify()}};

    return {
      entityId: employeeId,
      actionForSave: `pacs/employees/${ACTION_EDIT_EMPLOYEE}`,
      actionForLoadEntity: `pacs/employees/${ACTION_LOAD_EMPLOYEE_FOR_EDIT}`,
      dataForm: {
        employeeId,
        title: "",
        isActive: true,
        deviceAccessGroupIds: [],
        attachedUsernames: [],
        cars: [],
      },
      // Настройки выпадающих списков.
      initialOptionsDeviceAccessGroups: [],
      settingsSelectDeviceAccessGroup: {
        action: `pacs/deviceAccessGroups/${ACTION_LOAD_DEVICE_ACCESS_GROUPS_FOR_SELECT}`,
        valueField: DEVICE_ACCESS_GROUP_STRUCT.fields.id.name,
        labelField: DEVICE_ACCESS_GROUP_STRUCT.fields.title.name,
      },
      carsCount: 0,
      routeEmployeeCars: routeEmployeeCars,
      routes: {ROUTE_PACS_EDIT_DEVICE_ACCESS_GROUP},
      deviceAccessGroupLinksInfo: {}
    };
  },
  methods: {
    /**
     * @link editEntityMixin.methods.afterLoadSourceData
     */
    async afterLoadSourceData() {
      // Общее преобразование полей из API получения данных.
      this.dataForm = {
        ...this.dataForm,
        title: this.sourceData.entityInfo[EMPLOYEE_STRUCT.fields.title.name],
        isActive: this.sourceData.entityInfo[EMPLOYEE_STRUCT.fields.is_active.name],
        deviceAccessGroupIds: this.sourceData.entityInfo[EMPLOYEE_STRUCT.fields.device_access_group_ids.name],
        attachedUsernames: this.sourceData.entityInfo[EMPLOYEE_STRUCT.fields.attached_usernames.name],
        cars: this.sourceData.extraInfo[EMPLOYEE_STRUCT.extras.car].map(car => car.number),
      };
      this.initialOptionsDeviceAccessGroups = this.sourceData.extraInfo[EMPLOYEE_STRUCT.extras.device_access_group];
      this.carsCount = this.dataForm.cars.length;

      // Сборка данных для ссылок на группы доступа.
      this.deviceAccessGroupLinksInfo = {};
      for (const deviceAccessGroupInfo of this.sourceData.extraInfo[EMPLOYEE_STRUCT.extras.device_access_group]) {
        this.deviceAccessGroupLinksInfo[deviceAccessGroupInfo[DEVICE_ACCESS_GROUP_STRUCT.key]] = deviceAccessGroupInfo[DEVICE_ACCESS_GROUP_STRUCT.fields.title.name];
      }
      // Сборка данных по фотографиям.
      await this.loadPhotos();
    },
    /**
     * Отправка запроса на удаление ранее загруженной фотографии.
     *
     * @param {Number} photoId
     */
    deleteExistPhoto(photoId) {
      this.$camsdals.confirm(this.$t('confirmDeletePhoto'), async () => {
        this.isLoading = true;
        try {
          await this.$store.dispatch(`pacs/employeesPhoto/${ACTION_DELETE_PHOTO}`, [photoId]);
        } catch {
          this.$camsdals.alert(this.$t('errorDeletePhoto'));
        }
        await this.loadPhotos();
        this.isLoading = false;
      });
    },
    /**
     * Загрузка фотографий по сотруднику.
     */
    async loadPhotos() {
      const responseData = await this.$store.dispatch(`pacs/employeesPhoto/${ACTION_LOAD_PHOTOS_FOR_EMPLOYEE}`, {employeeId: this.entityId});
      this.photos = responseData.results;
    },
    /**
     * Сохранение данных и продолжение редактирования.
     * После успешного сохранения данных происходит перезагрузка исходных данных.
     */
    async saveDataWithPhotosAndReload() {
      try {
        await this.saveData();
        await this.saveAllPhotosToEmployee(this.entityId);
        await this.loadSourceData();
        this.$notify({
          group: "main",
          text: this.$t('successSave'),
          duration: 5000,
          type: "default"
        });
      } catch {
        // Перехват пробрасываемого исключения с ошибками валидации.
      }
    },
    /**
     * Отправка запроса на удаление текущего сотрудника.
     */
    deleteEmployee() {
      this.$camsdals.confirm(this.$t('confirmDeleteEmployee'), async () => {
        this.isLoading = true;
        try {
          await this.$store.dispatch(`pacs/employees/${ACTION_DELETE_EMPLOYEES}`, [this.entityId]);
          await this.$router.push({name: ROUTE_PACS_EMPLOYEES});
          this.$camsdals.alert(this.$t('employeeDeleted'));
        } catch {
          this.$camsdals.alert(this.$t('errorDeleteEmployee'));
        }
        this.isLoading = false;
      });
    },
  },
};
</script>
