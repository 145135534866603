<template>
  <div>
    <div class="cams-table-wrapper">
      <div v-if="tableCaption" class="cams-table-caption">
        {{ tableCaption }}
      </div>
      <table v-if="dataRows.length > 0" class="cams-table">
        <tr>
          <th v-if="!viewOnlyMode" class="cams-table__cell cams-table__cell_fixed-width">
            <div v-if="!offCheckBox" class="checkbox">
              <input
                v-model="allEntitiesForMultiEdit"
                class="checkbox__input"
                type="checkbox"
                @change="selectedEntitiesForMultiEdit = allEntitiesForMultiEdit ? true : []"
              >
              <span class="checkbox__img" />
            </div>
          </th>

          <th
            v-for="columnName in columnNames"
            :key="columnName"
            :class="{'cams-table__cell_sortable': columnName in detailSortInfo, 'cams-table__cell_sort-asc': hasSortAsc(columnName), 'cams-table__cell_sort-desc': hasSortDesc(columnName)}"
            class="cams-table__cell"
            @click="!viewOnlyMode && changeSort(columnName)"
            v-html="`<div class='cams-table__cell-wrapper'>${$t(columnCaptions[columnName])} <span>${getSortIndex(columnName)}</span></div>`"
          />
          <th v-if="$scopedSlots.customThCells" class="cams-table__cell">
            <div class="cams-table__cell-wrapper">
              <!-- @slot Слот для размещения заголовка для дополнительной колонки с компонентами. -->
              <slot name="customThCells" />
            </div>
          </th>
        </tr>

        <tr v-if="allEntitiesForMultiEdit || selectedEntitiesForMultiEdit.length > 0" class="cams-table__row-count">
          <td :colspan="columnNames.length + (($scopedSlots.customThCells || $scopedSlots.customTdCells) ? 2 : 1)" class="cams-table__cell">
            <template v-if="allEntitiesForMultiEdit">
              {{ $t('allRowsSelected') }} ({{ totalCount }}).
            </template>
            <template v-else>
              {{ $t('selectedRows') }} {{ selectedEntitiesForMultiEdit.length }}.
              <a href="#" @click="allEntitiesForMultiEdit = selectedEntitiesForMultiEdit = true">
                {{ $t('selectAll') }} {{ totalCount }}.
              </a>
            </template>
          </td>
        </tr>

        <tr v-for="(dataRow, rowIndex) in dataRows" :key="`cell-${rowIndex}`">
          <td v-if="!viewOnlyMode" class="cams-table__cell cams-table__cell_fixed-width">
            <div v-show="!offCheckBox" class="checkbox">
              <input
                ref="rowCheckboxes"
                v-model="selectedEntitiesForMultiEdit"
                :value="dataRow['_key_']"
                class="checkbox__input"
                type="checkbox"
                @change="allEntitiesForMultiEdit && flushSelectedEntitiesForMultiEdit()"
                @click.exact="lastClickedCheckbox = rowIndex"
                @click.shift.exact="shiftSelectEntitiesForMultiEdit(rowIndex)"
              >
              <span class="checkbox__img" />
            </div>
          </td>
          <PacsTableCell
            v-for="(columnName, columnIndex) in columnNames"
            :key="`cell-${rowIndex}-${columnIndex}`"
            :data-cell="dataRow[columnName]"
            :style="{width: columnWidths[columnName] || 'auto'}"
          />
          <td v-if="$scopedSlots.customTdCells" class="cams-table__cell" style="width: 40px">
            <div class="buttons-group">
              <!-- @slot Слот для размещения компонентов в рамках каждой ячейки. -->
              <slot name="customTdCells" :dataRow="dataRow" />
            </div>
          </td>
        </tr>
      </table>
      <div v-else>
        {{ $t('noData') }}
      </div>

      <div class="pacs-pages">
        <div v-show="totalCount" class="pacs-pages__side-info">
          {{ $t('totalCount') }}: {{ totalCount }}
        </div>
      </div>
      <paginate
        v-if="pageCount > 1"
        :click-handler="selectPage"
        :hide-prev-next="true"
        :page-count="pageCount"
        :page-range="5"
        :value="currentPage"
        active-class="smart-pagination__page_active"
        break-view-class="smart-pagination__page_collapse"
        container-class="smart-pagination"
        next-class="smart-pagination__page smart-pagination__next-prev"
        next-link-class="smart-pagination__page-link"
        next-text="<svg class='icon icon-arrow-left'><use xlink:href='#icon-arrow-left'></use></svg>"
        page-class="smart-pagination__page"
        page-link-class="smart-pagination__page-link"
        prev-class="smart-pagination__page smart-pagination__next-prev"
        prev-link-class="smart-pagination__page-link"
        prev-text="<svg class='icon icon-arrow-right'><use xlink:href='#icon-arrow-right'></use></svg>"
      />
      <div class="pacs-pages__side-info" />
    </div>
  </div>
</template>

<script>
import PacsTableCell from "@/components/pacs/PacsTableCell.vue";
import {SORT_DIRECTIONS} from "@/store/pacs/helpers.js";

export default {
  components: {
    PacsTableCell
  },
  props: {
    /**
     * Заголовок для всей таблицы.
     */
    tableCaption: {
      type: String,
      default: null,
    },
    /**
     * Список названий колонок для таблицы.
     */
    columnNames: {
      type: Array,
      required: true,
    },
    /**
     * Объект со всеми заголовками полей для таблицы.
     */
    columnCaptions: {
      type: Object,
      required: true,
    },
    /**
     * Ключевое поле для таблицы, которое используется в ряде функциональных штук:
     * - на него опирается работа для чекбоксов для множественного редактирования.
     */
    keyField: {
      type: String,
      default: "id",
    },
    /**
     * Перечень структурированных объектов для компонента PacsTableCell для отображения данных в ячейке.
     * ```
     * {
     *   _key_: Number|String, // Специальное поле для хранения ключевого значения. Используется для значений в чекбоксах для групповых действий.
     *   columnName: { // Все остальные поля для передачи в PacsTableCell.
     *     type: null
     *     value: ""
     *     params: {}
     *   },
     *   ...
     * }
     * ```
     */
    dataRows: {
      type: Array,
      required: true,
    },
    /**
     * Информация об используемых и доступных сортировках.
     * Массив пар, в каждой из которых на 0 позиции наименование колонки доступной для сортировки,
     * а на 1 позиции направление сортировки или null если сортировка по данному полю не включена.
     * ```
     * [
     *   [columnName, direction|null],
     *   ...
     * ]
     * ```
     */
    sortInfo: {
      type: Array,
      default: () => ([]),
    },
    /**
     * Количество страниц в таблице.
     */
    pageCount: {
      type: Number,
      required: true,
    },
    /**
     * Текущая страница в таблице.
     */
    currentPage: {
      type: Number,
      default: 1,
    },
    /**
     * Общее количество сущностей.
     */
    totalCount: {
      type: Number,
      default: 0,
    },
    /**
     * Режим только для просмотра. В нем не доступны сортировки по столбцам, пагинация и выбор ячеек.
     */
    viewOnlyMode: {
      type: Boolean,
      default: false,
    },
    offCheckBox: {
      type: Boolean,
      default: false,
    },
    /**
     * Объект со установленными значениями ширины для определенных колонок.
     */
    columnWidths: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      selectedEntitiesForMultiEdit: [], // Список с ключами сущностей для групповой операции.
      allEntitiesForMultiEdit: false, // Флаг для выбора всех сущностей для групповой операции.
      lastClickedCheckbox: 0, // Индекс строки чекбокса для выбора нескольких по зажатому shift.
    };
  },
  computed: {
    /**
     * @return {Object} Детальная информация о настройках сортировки.
     */
    detailSortInfo() {
      const detailSortInfo = {};
      let nextUsedSortIndex = 1;
      for (const sortInfoValue of this.sortInfo) {
        const currentSortIndex = sortInfoValue[1] ? nextUsedSortIndex++ : 0;
        detailSortInfo[sortInfoValue[0]] = [
          currentSortIndex, // Порядок применения сортировки.
          sortInfoValue[1], // Параметр сортировки.
        ];
      }
      return detailSortInfo;
    }
  },
  watch: {
    /**
     * Уведомление родительского компонента об изменении списка строк, предназначенных для массового редактирования.
     */
    selectedEntitiesForMultiEdit() {
      /**
       * Возвращает список значений ключевого поля или true для всех значений, по которым нужно произвести групповые действия.
       * @event new-entities-for-multi-edit
       */
      this.$emit("new-entities-for-multi-edit", this.selectedEntitiesForMultiEdit);
    },
  },
  methods: {
    /**
     * Отправка сообщения в родительский компонент о переходе к новой странице.
     *
     * @param {Number} selectedPage
     */
    selectPage(selectedPage) {
      /**
       * Срабатывает при выборе новой страницы в таблице и отдает номер этой страницы.
       * @event new-page
       */
      this.$emit("new-page", selectedPage);
    },
    /**
     * Имеется ли у поля сортировка по возрастанию.
     *
     * @param {String} columnName
     * @return {Boolean}
     */
    hasSortAsc(columnName) {
      return (columnName in this.detailSortInfo) && this.detailSortInfo[columnName][1] === SORT_DIRECTIONS.ASC;
    },
    /**
     * Имеется ли у поля сортировка по убыванию.
     *
     * @param {String} columnName
     * @return {Boolean}
     */
    hasSortDesc(columnName) {
      return (columnName in this.detailSortInfo) && this.detailSortInfo[columnName][1] === SORT_DIRECTIONS.DESC;
    },
    /**
     * Вернет порядок сортировки для поля (если он есть) в строковом виде для вставки в шаблон.
     *
     * @param {String} columnName
     * @return {String}
     */
    getSortIndex(columnName) {
      return (columnName in this.detailSortInfo && this.detailSortInfo[columnName][0]) ? this.detailSortInfo[columnName][0] : "";
    },
    /**
     * Изменение настройки сортировки у таблицы.
     * Сначала поле сортируется по возрастанию, потом по убыванию, потом сортировка у поля сбрасывается.
     * Сообщает в родительский компонент о новых настройках сортировки и тот принимает решение о приятии настроек.
     *
     * @param {String} columnName
     */
    changeSort(columnName) {
      if (!(columnName in this.detailSortInfo)) {
        return;
      }
      const newSortDirection = {
        [SORT_DIRECTIONS.ASC]: SORT_DIRECTIONS.DESC,
        [SORT_DIRECTIONS.DESC]: null
      }[this.detailSortInfo[columnName][1]];
      /**
       * Срабатывает при выборе новой колонки для сортировки. Отдается настройка для сортировки по выбранному полю.
       * @event new-sort
       */
      this.$emit("new-sort", [columnName, newSortDirection !== undefined ? newSortDirection : SORT_DIRECTIONS.ASC]);
    },
    /**
     * Выбор чекбоксов в таблице при зажатой клавише `shift`.
     * Позволяет отметить (снять отметку) у группы чекбоксов на основе последнего выбранного и текущего.
     *
     * На основе последнего нажатого чекбокса и текущего кликнутого с шифтом,
     * определяется диапазон интересующих ключевых значений из чекбоксов.
     *
     * На основе кликнутого с шифтом чекбоксе определяется - необходимо либо всех попадающие в диапазон значения учесть,
     * при массовом редактировании, либо все вычесть из него.
     *
     * В зависимости от полученных настроек интересующие значения в итоговый массив будут попадать либо удаляться,
     * с учетом его текущего состояния.
     *
     * @param {Number} newIndexDataTable
     */
    shiftSelectEntitiesForMultiEdit(newIndexDataTable) {
      if (this.allEntitiesForMultiEdit) {
        this.flushSelectedEntitiesForMultiEdit();
        return;
      }

      const startIndex = Math.min(newIndexDataTable, this.lastClickedCheckbox),
            endIndex = Math.max(newIndexDataTable, this.lastClickedCheckbox),
            allInterestingValues = _.map(this.$refs.rowCheckboxes.slice(startIndex, endIndex + 1), "value"),
            needAllCheckOrElse = !this.selectedEntitiesForMultiEdit.includes(this.$refs.rowCheckboxes[newIndexDataTable].value);

      allInterestingValues.forEach((interestingValue) => {
        if (needAllCheckOrElse && !this.selectedEntitiesForMultiEdit.includes(interestingValue)) {
          this.selectedEntitiesForMultiEdit.push(interestingValue);
        } else if (!needAllCheckOrElse && this.selectedEntitiesForMultiEdit.includes(interestingValue)) {
          this.selectedEntitiesForMultiEdit.splice(this.selectedEntitiesForMultiEdit.indexOf(interestingValue), 1);
        }
      });

      this.lastClickedCheckbox = newIndexDataTable;
    },
    /**
     * Метод для клика по чекбоксу при выбранных всех элементах. Сбрасывает состояние выбранных чекбоксов.
     */
    flushSelectedEntitiesForMultiEdit() {
      this.allEntitiesForMultiEdit = false;
      this.selectedEntitiesForMultiEdit = [];
    },
  }
};
</script>
