/**
 * Отдельный vuex модуль для взаимодействия по pacsPermissions-API
 */
import {getExtraApi, makeFilterApi, SORT_DIRECTIONS, SPECIAL_TYPES_DATA_IN_CELLS} from "@/store/pacs/helpers.js";

// actions для pacs_permissions. Вызов действия начинать с "pacs/pacsPermissions/"
export const ACTION_LOAD_DEVICE_ACCESS_GROUPS_PERMISSIONS = "LOAD_DEVICE_ACCESS_GROUPS_PERMISSIONS";
export const ACTION_LOAD_DEVICE_ACCESS_GROUP_PERMISSIONS_FOR_EDIT = "LOAD_DEVICE_ACCESS_GROUP_PERMISSIONS_FOR_EDIT";
export const ACTION_ADD_DEVICE_ACCESS_GROUPS_PERMISSIONS_BY_FORM = "ADD_DEVICE_ACCESS_GROUPS_PERMISSIONS_BY_FORM";
export const ACTION_DELETE_DEVICE_ACCESS_GROUPS_PERMISSIONS = "DELETE_DEVICE_ACCESS_GROUPS_PERMISSIONS";

export const ACTION_LOAD_EMPLOYEES_PERMISSIONS = "LOAD_EMPLOYEES_PERMISSIONS";
export const ACTION_LOAD_EMPLOYEE_PERMISSIONS_FOR_EDIT = "LOAD_EMPLOYEE_PERMISSIONS_FOR_EDIT";
export const ACTION_ADD_EMPLOYEES_PERMISSIONS_BY_FORM = "ADD_EMPLOYEES_PERMISSIONS";
export const ACTION_DELETE_EMPLOYEES_PERMISSIONS = "DELETE_EMPLOYEES_PERMISSIONS";

/**
 * Структура для общения с API по конкретной сущности.
 */
export const DEVICE_ACCESS_GROUP_PERMISSION_STRUCT = Object.freeze({
  key: "device_access_group_id",
  fields: {
    device_access_group_id: {
      name: "device_access_group_id",
      title: "ID группы доступа",
      type: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
      extra: "device_access_group",
    },
    access_type: {
      name: "access_type",
      title: "Уровень прав доступа к СКУД",
    },
    device_id: {
      name: "device_id",
      title: "ID СКУД-устройства",
      type: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
      extra: "device",
    },
    is_auto: {
      name: "is_auto",
      title: "Авто?",
      type: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
    },
  },
  extras: {
    device: "device",
    device_access_group: "device_access_group",
  },
  filters: {
    device_id: "device_id",
    device_access_group_id: "device_access_group_id",
  },
});

export const EMPLOYEE_PERMISSION_STRUCT = Object.freeze({
  key: "employee_id",
  fields: {
    employee_id: {
      name: "employee_id",
      title: "ID сотрудника",
      type: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
      extra: "employee",
    },
    access_type: {
      name: "access_type",
      title: "Уровень прав доступа к СКУД",
    },
    device_id: {
      name: "device_id",
      title: "ID СКУД-устройства",
      type: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
      extra: "device",
    },
    is_auto: {
      name: "is_auto",
      title: "Авто?",
      type: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
    },
  },
  extras: {
    device: "device",
    employee: "employee",
  },
});

/**
 * Перечисление прав на сотрудников и группы доступа к устройствам (группы сотрудников), для назначения их устройствам.
 */
export const DEVICES_PERMISSIONS = Object.freeze({
  ACCESS: 0,
  ENTRANCE: 1,
  EXIT: 2,
});

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    // -------------------------------------------------------------------------------------------------------------------------------------
    // DeviceAccessGroupsPermission
    /**
     * Загрузка списка прав.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_DEVICE_ACCESS_GROUPS_PERMISSIONS](context, {page, pageSize, orderBy, filters, search}) {
      const fields = Object.keys(DEVICE_ACCESS_GROUP_PERMISSION_STRUCT.fields),
        response = await this.getters.ajaxClient.post("/v0/pacs_permissions/device_access_groups/", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          extra: getExtraApi(DEVICE_ACCESS_GROUP_PERMISSION_STRUCT, fields),
          search
        });
      return response.data;
    },
    /**
     * Загрузка списка прав для редактирования.
     *
     * @param {Function} dispatch
     * @param {Number} deviceId
     * @param {Number} deviceAccessGroupId
     * @param {String} search
     * @param {Number} page
     * @return {Promise}
     */
    async [ACTION_LOAD_DEVICE_ACCESS_GROUP_PERMISSIONS_FOR_EDIT]({dispatch}, {deviceId = null, deviceAccessGroupId = null, search = "", page = 1}) {
      let filter = makeFilterApi(DEVICE_ACCESS_GROUP_PERMISSION_STRUCT.filters.device_id, "=", deviceId),
        orderBy = {field: DEVICE_ACCESS_GROUP_PERMISSION_STRUCT.fields.device_access_group_id.name, direction: SORT_DIRECTIONS.ASC};
      if (deviceAccessGroupId) {
        filter = makeFilterApi(DEVICE_ACCESS_GROUP_PERMISSION_STRUCT.filters.device_access_group_id, "=", deviceAccessGroupId);
        orderBy = {field: DEVICE_ACCESS_GROUP_PERMISSION_STRUCT.fields.device_id.name, direction: SORT_DIRECTIONS.ASC};
      }

      return dispatch(ACTION_LOAD_DEVICE_ACCESS_GROUPS_PERMISSIONS, {
        page,
        pageSize: 10,
        orderBy: [orderBy],
        filters: [filter],
        search
      });
    },
    /**
     * Выдача новых прав.
     *
     * @param {Object} context
     * @param {Number} deviceAccessGroupId
     * @param {Boolean} isAuto
     * @param {Array} rawPermissionsInfo [[deviceId, permission], ...]
     * @param {Boolean} mergePermissions
     * @return {Promise}
     */
    async [ACTION_ADD_DEVICE_ACCESS_GROUPS_PERMISSIONS_BY_FORM](context, {deviceAccessGroupId = null, isAuto = false, rawPermissionsInfo, mergePermissions = true}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/pacs_permissions/device_access_groups/add/", {
          device_access_group_id: deviceAccessGroupId,
          is_auto: isAuto,
          access_type: rawPermissionsInfo.map((rawPermissionInfo) => {
            return {device_id: rawPermissionInfo[0], permission: rawPermissionInfo[1]};
          }),
          merge_permissions: mergePermissions,
        });
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Удаление прав.
     *
     * @param {Object} context
     * @param {Number} deviceAccessGroupId
     * @param {Boolean} isAuto
     * @param {Array} deviceIds
     * @return {Promise}
     */
    async [ACTION_DELETE_DEVICE_ACCESS_GROUPS_PERMISSIONS](context, {deviceAccessGroupId, isAuto, deviceIds}) {
      return this.getters.ajaxClient.post("/v0/pacs_permissions/device_access_groups/delete/", {
        device_access_group_id: deviceAccessGroupId,
        is_auto: isAuto,
        device_ids: deviceIds,
      });
    },
    // -------------------------------------------------------------------------------------------------------------------------------------
    // DeviceAccessPermission
    /**
     * Загрузка списка прав
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_EMPLOYEES_PERMISSIONS](context, {page, pageSize, orderBy, filters, search}) {
      const fields = Object.keys(EMPLOYEE_PERMISSION_STRUCT.fields),
        response = await this.getters.ajaxClient.post("/v0/pacs_permissions/devices/", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          extra: getExtraApi(EMPLOYEE_PERMISSION_STRUCT, fields),
          search
        });
      return response.data;
    },
    /**
     * Загрузка списка прав для редактирования.
     *
     * @param {Function} dispatch
     * @param {Number} deviceId
     * @param {String}  employeeId
     * @param {String} search
     * @param {Number} page
     * @return {Promise}
     */
    async [ACTION_LOAD_EMPLOYEE_PERMISSIONS_FOR_EDIT]({dispatch}, {deviceId = null, employeeId = null, search = "", page = 1}) {
      let filter = makeFilterApi(EMPLOYEE_PERMISSION_STRUCT.fields.device_id.name, "=", deviceId),
        orderBy = {field: EMPLOYEE_PERMISSION_STRUCT.fields.employee_id.name, direction: SORT_DIRECTIONS.ASC};
      if (employeeId) {
        filter = makeFilterApi(EMPLOYEE_PERMISSION_STRUCT.fields.employee_id.name, "=", employeeId);
        orderBy = {field: EMPLOYEE_PERMISSION_STRUCT.fields.device_id.name, direction: SORT_DIRECTIONS.ASC};
      }

      return dispatch(ACTION_LOAD_EMPLOYEES_PERMISSIONS, {
        page,
        pageSize: 10,
        orderBy: [orderBy],
        filters: [filter],
        search
      });
    },
    /**
     * Выдача новых прав.
     *
     * @param {Object} context
     * @param {Number} employeeId
     * @param {Boolean} isAuto
     * @param {Array} rawPermissionsInfo [[deviceId, permission], ...]
     * @param {Boolean} mergePermissions
     * @return {Promise}
     */
    async [ACTION_ADD_EMPLOYEES_PERMISSIONS_BY_FORM](context, {employeeId = null, isAuto = false, rawPermissionsInfo, mergePermissions = true}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/pacs_permissions/devices/add/", {
          employee_id: employeeId,
          is_auto: isAuto,
          access_type: rawPermissionsInfo.map((rawPermissionInfo) => {
            return {device_id: rawPermissionInfo[0], permission: rawPermissionInfo[1]};
          }),
          merge_permissions: mergePermissions,
        });
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Удаление прав.
     *
     * @param {Object} context
     * @param {Number} employeeId
     * @param {Boolean} isAuto
     * @param {Array} deviceIds
     * @return {Promise}
     */
    async [ACTION_DELETE_EMPLOYEES_PERMISSIONS](context, {employeeId, isAuto, deviceIds}) {
      return this.getters.ajaxClient.post("/v0/pacs_permissions/devices/delete/", {
        employee_id: employeeId,
        is_auto: isAuto,
        device_ids: deviceIds,
      });
    },
  },
};
