<template>
  <li>
    <div class="folder" :class="{ 'active': isActive }" @click="toggleFolder">
      <div class="folder-name">
        <svg v-if="!isExpanded" class="arrow-icon"><use xlink:href="../../assets/img/icons.svg#icon-arrow-folder-right" /></svg>
        <svg v-if="isExpanded" class="arrow-icon"><use xlink:href="../../assets/img/icons.svg#icon-arrow-down" /></svg>
        {{ folder.name }}
        <button class="add-button" @click.stop="editFolder">
          <svg class="edit-button"><use xlink:href="../../assets/img/icons.svg#icon-edit-folder" /></svg>
        </button>
      </div>
    </div>
    <!-- Список камер -->
    <ul v-if="isExpanded && folder.cameras && folder.cameras.length > 0" class="cameras">
      <li
        v-for="camera in folder.cameras"
        :key="camera.number"
        class="camera-item"
        :class="{ 'active-camera': activeCameraNumber === camera.number }"
        @mouseenter="highlightCamera(camera.number)"
        @mouseleave="removeHighlightCamera"
      >
        <!-- Кнопка для проигрывания видео -->
        <button class="play-button" @click.stop="openOneScreenDialog(camera.number,camera.title)">
          <svg class="icon play-icon">
            <use xlink:href="#icon-video-preview" />
          </svg>
        </button>
        <!-- Название камеры -->
        <span class="camera-name">{{ camera.title }}</span>
        <!-- Кнопка удаления камеры -->
        <button class="add-button" @click.stop="deleteCamera(camera)">
          <svg class="icon delete-button right-edge">
            <use xlink:href="../../assets/img/icons.svg#icon-delete-camera" />
          </svg>
        </button>
      </li>
    </ul>
    <!-- Дочерние папки -->
    <ul v-if="isExpanded && folder.children && folder.children.length > 0" class="children">
      <li v-for="childFolder in folder.children" :key="childFolder.id" class="child-folder">
        <div class="folder-border">
          <TreeNode
            :folder="childFolder"
            @folder-click="handleChildFolderClick"
          />
        </div>
      </li>
    </ul>
  </li>
</template>
<script>
import {ACTION_LOAD_FOLDER, ACTION_REMOVE_CAMERAS_FROM_FOLDER} from "@/store/folders/index.js";
import MeshFolderEditFrameDialog from "@/components/meshCameras/MeshFolderEditFrameDialog.vue";
import {ACTION_LOAD_CAMERA_FOR_PLAYER} from "@/store/cameras/index.js";
import {QUERY_KEY_ONE_SCREEN_TAB, QUERY_KEY_ONE_SCREEN_TIME_SHIFT} from "@/router/queryKeys.js";
import {TOKEN_TTL} from "@/utils/consts.js";
import OneScreenDialog from "@/components/oneScreen/OneScreenDialog.vue";
import MeshCreateFolderFrameDialog from "@/components/meshCameras/MeshCreateFolderFrameDialog.vue";

export default {
  name: "TreeNode",
  // eslint-disable-next-line vue/require-prop-types
  props: ['folder'],
  data() {
    return {
      isExpanded: false,
      activeCameraNumber: null,
      activeFolderId: null,
    };
  },
  computed: {
    isActive() {
      return this.activeFolderId === this.folder.id;
    }
  },
  mounted() {
    this.$root.$on('reset-active-state', () => {
      this.activeFolderId = null;
    });
  },
  methods: {
    toggleFolder() {
      this.$root.$emit('folder-load', this.folder);
      this.$root.$emit('reset-active-state');
      this.isExpanded = !this.isExpanded;
      if (!this.folder.children || this.folder.children.length === 0) {
        this.$emit('folder-click', this.folder.id);
      }
      this.activeFolderId = this.isActive ? null : this.folder.id;
    },
    /**
     * Открытие диалогового окна для проигрывания видео с выбранной камеры.
     */
    async openOneScreenDialog(cameraNumber,cameraTitle) {
      const cameraInfo = await this.$store.cache.dispatch(`cameras/${ACTION_LOAD_CAMERA_FOR_PLAYER}`, {
        number: cameraNumber,
        tokenLiveTTL: TOKEN_TTL.PLAYER,
        tokenDVRTTL: TOKEN_TTL.PLAYER,
      });

      if (!cameraInfo) {
        this.$camsdals.alert(this.$t('insufficientRights'));
        return;
      }

      const oneScreenDialogProps = {cameraInfo};
      if (this.$route.query[QUERY_KEY_ONE_SCREEN_TIME_SHIFT]) {
        oneScreenDialogProps.initialTimeShift = new Date(+this.$route.query[QUERY_KEY_ONE_SCREEN_TIME_SHIFT]);
      }
      if (this.$route.query[QUERY_KEY_ONE_SCREEN_TAB]) {
        oneScreenDialogProps.initialSelectedTab = this.$route.query[QUERY_KEY_ONE_SCREEN_TAB];
      }
      this.$camsdals.open(
        OneScreenDialog,
        oneScreenDialogProps,
        {dialogTitle: cameraTitle},
        {
          size: "vuedal-auto-width",
          name: "js-click-outside",
        },
      );
    },
    async openSelectFolderCamerasDialog() {
      this.$camsdals.open(
        MeshCreateFolderFrameDialog,
        {initialSelectedCameraNumbers: [], parentId: this.folder.id},
        {dialogTitle: this.$t('createSubfolder', {folderName: this.folder.name})},
        {
          size: "vuedal-auto-width vuedal-all-height",
          name: "js-click-outside"
        },
      );
    },
    /**
     * Редактирование папки.
     */
    async editFolder() {
      const folderData = await this.$store.dispatch(
        `folders/${ACTION_LOAD_FOLDER}`,
        this.folder.id
      );
      const cameraNumbers = folderData[0].cameras.map(camera => camera.number);
      this.$camsdals.open(
        MeshFolderEditFrameDialog,
        {
          initialSelectedCameraNumbers: cameraNumbers,
          folderInfo: folderData[0]
        },
        {
          dialogTitle: this.$t('editFolder', {folderName: this.folder.name})
        },
        {
          size: "vuedal-auto-width vuedal-all-height",
          name: "js-click-outside",
        }
      );
    },
    deleteCamera(camera) {
      this.$camsdals.confirm(this.$t('deleteCameraConfirmation', {cameraTitle: camera.title, folderName: this.folder.name}), async () => {
        this.isLoading = true;
        try {
          await this.$store.dispatch(`folders/${ACTION_REMOVE_CAMERAS_FROM_FOLDER}`, {
            folderId: this.folder.id,
            cameraNumbers: camera.number
          });
          this.$notify({
            group: "main",
            text: this.$t('cameraDeletedSuccess', {cameraTitle: camera.title, folderName: this.folder.name}),
            duration: 5000,
            type: "success",
          });
        } catch {
          this.$camsdals.alert(this.$t('errorDeletingCamera'));
        }
        this.$root.$emit('folder-created', this.folder.id);
        this.$root.$emit('load-cameras');
        this.isLoading = false;
      });
    },
    handleChildFolderClick(folderId) {
      this.$emit('folder-click', folderId);
    },
    highlightCamera(cameraNumber) {
      this.activeCameraNumber = cameraNumber;
    },
    removeHighlightCamera() {
      this.activeCameraNumber = null;
    },
  },
};
</script>

<style scoped>
/* Общие стили для иконки стрелки */
.arrow-icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  fill: #2F84F8;
}
.play-icon {
  display: flex;
  fill: #2F84F8;
  margin-right: 5px;
}
/* Стили для родительской папки */
.folder {
  display: flex;
  align-items: center;
  padding-left: 10px;
  cursor: pointer;
  font-size: 16px;
  margin-right: -1px;

}
.folder-name {
  display: flex;
  height: 28px;
  width: 100%;
  align-items: center;
}

.folder-name > :nth-last-child(1){
  margin-left: auto;
}

/* Стили для кнопки редактирования */
.edit-button {
  visibility: hidden; /* Скрыть кнопку по умолчанию */
  width: 24px;
  height: 24px;
  border: none;
  cursor: pointer;
  background: none;
  margin-right: 10px;
}

/* Стили для элемента папки, отображение кнопки редактирования при наведении */
.folder-name:hover .edit-button {
  visibility: visible; /* Отображать кнопку при наведении курсора на название папки */
}

/* Оптимизация для того, чтобы кнопка не пропадала при наведении непосредственно на кнопку */
.edit-button:hover {
  visibility: visible;
}
.delete-button {
  visibility: hidden;
  width: 12px;
  height: 12px;
  border: none;
  cursor: pointer;
  background: none;
}
.camera-item:hover .delete-button {
  visibility: visible;
}
.add-button {
  width: 30px;
  height: 24px;
  cursor: pointer;
  background: none;
}

/* Стили для камер */
.cameras {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 5px;
}

.camera-item {
  display: flex;
  align-items: center;
  background-color: #fff;
  margin-left: 10px;
  width: 90%;
}


/* Стили для дочерних папок */
.children {
  padding-left: 20px;
}

/* Стили для подсветки активной папки */
.active .folder-name {
  background-color: #2f84f81f;
}

/* Стили для кнопки воспроизведения */
.play-button {
  background: none;
  border: none;
  cursor: pointer;
  width: 24px;
  height: 20px;
}
.camera-name {
  flex-grow: 1; /* Растягиваем элемент на всю доступную ширину */
}
.right-edge {
}

/* Стили для подсветки активной камеры */
.active-camera {
  background-color: #f0f0f0; /* Цвет подсветки активной камеры */
}
</style>
