import {ACTION_LOAD_CAMERA_FOR_MULTI} from "@/store/cameras/index.js";

/**
 * Примесь для методов загрузки необходимой информации по камере для мультиэкрана.
 */
export const loadCameraInfoForMultiMixin = {
  methods: {
    /**
     * Загрузка информации по указанной камере.
     * Обрати внимание на наличие кеша и актуальный срок жизни токена - чтобы не пересекались!
     *
     * @param {String} cameraNumber
     * @return {Promise.<CameraInfo>|Null}
     */
    async loadCameraInfo(cameraNumber) {
      if (!cameraNumber) {
        return;
      }
      return this.$store.cache.dispatch(`cameras/${ACTION_LOAD_CAMERA_FOR_MULTI}`, cameraNumber);
    },
  }
};
