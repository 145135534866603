<template>
  <div class="main__content content">
    <div class="pacs-settings-table">
      <div class="pacs-settings-table__group-setting">
        <SmartInputText
          v-model="searchText"
          class="pacs-settings-table__settings-control pacs-settings-table__settings-control_big"
          :placeholder="$t('searchPlaceholder')"
          size="l"
          @keyup.native.enter="applyTableSettings()"
        >
          <CamsButton
            position="r"
            size="l"
            type="button"
            icon-type="only"
            @click="applyTableSettings()"
          >
            <svg class="icon-search">
              <use xlink:href="#icon-search" />
            </svg>
          </CamsButton>
        </SmartInputText>

        <div v-show="showGroupOperations" class="pacs-settings-table__multi-edit">
          <CamsButton icon-type="fixed" size="l" type="button" @click="deleteDeviceAccessGroups()">
            {{ $t('deleteGroups') }}
            <svg viewBox="-5 -5 30 30">
              <use xlink:href="#icon-delete" />
            </svg>
          </CamsButton>
        </div>

        <CamsButton priority="primary" size="l" type="button" @click="openDialogCreateAccessGroup()">
          {{ $t('addGroup') }}
        </CamsButton>
      </div>
    </div>

    <SpinnerLoadingModal v-if="isLoading" />
    <SpinnerLoading v-if="isLoadingTable" class="loader_center" color="blue" />

    <PacsTable
      v-else
      :column-captions="{
        id: 'tableHeaders.id',
        title: 'tableHeaders.title',
        about: 'tableHeaders.about',
        employee_count: 'tableHeaders.employee_count'
      }"
      :column-names="columnNames"
      :column-widths="columnWidths"
      :current-page="currentPage"
      :data-rows="dataRows"
      :key-field="keyField"
      :page-count="pageCount"
      :sort-info="sortInfo"
      :total-count="totalCount"
      @new-page="selectPage"
      @new-sort="changeSort"
      @new-entities-for-multi-edit="selectedEntitiesForMultiEdit = $event"
    >
      <template #customThCells />
      <template #customTdCells="{dataRow}">
        <CamsButton
          icon-type="only"
          :title="$t('delete')"
          type="button"
          @click="deleteDeviceAccessGroup(dataRow['_key_'])"
        >
          <svg>
            <use xlink:href="#icon-delete" />
          </svg>
        </CamsButton>
      </template>
    </PacsTable>
  </div>
</template>

<script>
import {
  ACTION_DELETE_DEVICE_ACCESS_GROUPS,
  ACTION_LOAD_DEVICE_ACCESS_GROUPS_FOR_MULTI_EDIT,
  ACTION_LOAD_DEVICE_ACCESS_GROUPS_FOR_TABLE,
  DEVICE_ACCESS_GROUP_STRUCT
} from "@/store/pacs/deviceAccessGroups/index.js";
import {ROUTE_PACS_EDIT_DEVICE_ACCESS_GROUP, ROUTE_PACS_EMPLOYEES} from "@/router/names.js";
import PacsTable from "@/components/pacs/PacsTable.vue";
import {entityTableMixin} from "@/components/pacs/mixins.js";
import CreateAccessGroupDialog from "@/components/pacs/deviceAccessGroups/CreateDeviceAccessGroupDialog.vue";
import {makeFilterApi, SPECIAL_TYPES_DATA_IN_CELLS} from "@/store/pacs/helpers.js";
import {TableQueryParams} from "@/components/pacs/helpers.js";
import {EMPLOYEE_STRUCT} from "@/store/pacs/employees/index.js";
import {QUERY_KEY_TABLE_PARAMS} from "@/router/queryKeys.js";

/**
 * Компонент отображения таблицы и фильтров с информацией по устройствам.
 */
export default {
  mixins: [
    entityTableMixin
  ],
  components: {
    PacsTable,
  },
  data() {
    return {
      entityStruct: DEVICE_ACCESS_GROUP_STRUCT,
      entityFields: [
        DEVICE_ACCESS_GROUP_STRUCT.fields.id,
        DEVICE_ACCESS_GROUP_STRUCT.fields.title,
        DEVICE_ACCESS_GROUP_STRUCT.fields.about,
        DEVICE_ACCESS_GROUP_STRUCT.fields.employee_count,
      ],
      actionLoadDataForMultiEdit: `pacs/deviceAccessGroups/${ACTION_LOAD_DEVICE_ACCESS_GROUPS_FOR_MULTI_EDIT}`,
      // Настройки для фиксации ширины определенных колонок.
      columnWidths: {
        [DEVICE_ACCESS_GROUP_STRUCT.fields.id.name]: "100px",
        [DEVICE_ACCESS_GROUP_STRUCT.fields.employee_count.name]: "220px",
      },
    };
  },
  methods: {
    /**
     * @link entityTableMixin.methods.loadPage
     */
    async loadPage() {
      this.isLoadingTable = true;
      try {
        const responseData = await this.$store.dispatch(`pacs/deviceAccessGroups/${ACTION_LOAD_DEVICE_ACCESS_GROUPS_FOR_TABLE}`, {
          page: this.currentPage,
          search: this.searchText,
          orderBy: this.sortInfoForApi,
        });

        this.pageCount = responseData.page.all;
        this.totalCount = responseData.count;
        this.dataRows = responseData.results.map((rawDeviceAccessGroupInfo) => {
          const deviceAccessGroupId = Number(rawDeviceAccessGroupInfo[DEVICE_ACCESS_GROUP_STRUCT.key]),
                paramsRouteEmployees = new TableQueryParams({
                  filters: [makeFilterApi(EMPLOYEE_STRUCT.filters.device_access_group_id, "=", deviceAccessGroupId)],
                }),
                paramsRouteEditDeviceAccessGroup = {name: ROUTE_PACS_EDIT_DEVICE_ACCESS_GROUP, params: {deviceAccessGroupId}};

          return {
            ...this.calcDefaultDataRow(rawDeviceAccessGroupInfo),
            [DEVICE_ACCESS_GROUP_STRUCT.fields.id.name]: {
              type: DEVICE_ACCESS_GROUP_STRUCT.fields.id.type,
              value: rawDeviceAccessGroupInfo[DEVICE_ACCESS_GROUP_STRUCT.fields.id.name],
              params: paramsRouteEditDeviceAccessGroup,
            },
            [DEVICE_ACCESS_GROUP_STRUCT.fields.title.name]: {
              type: DEVICE_ACCESS_GROUP_STRUCT.fields.title.type,
              value: rawDeviceAccessGroupInfo[DEVICE_ACCESS_GROUP_STRUCT.fields.title.name],
              params: paramsRouteEditDeviceAccessGroup,
            },
            [DEVICE_ACCESS_GROUP_STRUCT.fields.employee_count.name]: {
              type: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
              value: rawDeviceAccessGroupInfo.employee_count ? `${this.$t('viewAll')} (${rawDeviceAccessGroupInfo[DEVICE_ACCESS_GROUP_STRUCT.fields.employee_count.name]})` : null,
              params: {
                name: ROUTE_PACS_EMPLOYEES,
                query: {[QUERY_KEY_TABLE_PARAMS]: paramsRouteEmployees.stringify()}
              },
            },
          };
        });
      } finally {
        this.isLoadingTable = false;
      }
    },
    /**
     * Открытие диалогового окна для создания группы доступа.
     */
    openDialogCreateAccessGroup() {
      this.$camsdals.open(CreateAccessGroupDialog, {}, {dialogTitle: this.$t('createAccessGroup')});
    },
    /**
     * Удаление группы доступа.
     *
     * @param {Number} deviceAccessGroupId
     */
    deleteDeviceAccessGroup(deviceAccessGroupId) {
      this.$camsdals.confirm(this.$t('confirmDeleteGroup'), async () => {
        this.isLoading = true;
        try {
          await this.$store.dispatch(`pacs/deviceAccessGroups/${ACTION_DELETE_DEVICE_ACCESS_GROUPS}`, [deviceAccessGroupId]);
          await this.loadPage();
          this.$camsdals.alert(this.$t('groupDeleted'));
        } catch {
          this.$camsdals.alert(this.$t('errorDeletingGroup'));
        }
        this.isLoading = false;
      });
    },
    async deleteDeviceAccessGroups() {
      const deviceAccessGroupIds = await this.getSelectedEntitiesForMultiEdit();
      if (deviceAccessGroupIds.length) {
        this.$camsdals.confirm(this.$t('confirmDeleteGroups', {count: deviceAccessGroupIds.length}), async () => {
          this.isLoading = true;
          try {
            await this.$store.dispatch(`pacs/deviceAccessGroups/${ACTION_DELETE_DEVICE_ACCESS_GROUPS}`, deviceAccessGroupIds);
            await this.loadPage();
            this.$camsdals.alert(this.$t('groupsDeleted'));
          } catch {
            this.$camsdals.alert(this.$t('errorDeletingGroups'));
          }
          this.isLoading = false;
        });
      }
    },
  },
};
</script>
