import {ACTION_CREATE_PHOTO} from "@/store/pacs/employeesPhoto/index.js";

/**
 * Примесь для работы с сущностью сотрудника.
 */
export const actionsWithPhotosMixin = {
  data() {
    return {
      // Перечень фото сотрудника.
      photos: [],
    };
  },
  methods: {
    /**
     * Выбор файла с фотографией и его преобразование в base64 формат.
     *
     * @param {Object} event
     */
    selectPhoto(event) {
      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i],
          reader = new FileReader();
        reader.onloadend = () => {
          if (this.$_actionsWithPhotosMixin_validatePhoto(reader.result)) {
            this.photos.push(reader.result);
          } else {
            this.$camsdals.alert("Ошибка при выборе фото. Попробуйте выбрать другую фотографию или уменьшите эту.");
          }
        };
        reader.readAsDataURL(file);
      }
    },
    /**
     * Проверка фотографии.
     * Ограничение на количество символов фотографии определено в API.
     *
     * @param {String} photo
     */
    $_actionsWithPhotosMixin_validatePhoto(photo) {
      return photo.startsWith("data:image/") && photo.length <= 512000;
    },
    /**
     * Удаление вновь добавленной фотографии.
     *
     * @param {String} photo фото в формате base64
     */
    deleteNewPhoto(photo) {
      const files = [...this.photos];
      files.splice(files.indexOf(photo), 1);
      this.photos = files;
    },
    /**
     * Создание фотографии сотрудника.
     *
     * @param {Number} employeeId
     * @param {String} photo
     */
    async savePhotoToEmployee(employeeId, photo) {
      try {
        return this.$store.dispatch(`pacs/employeesPhoto/${ACTION_CREATE_PHOTO}`, {employeeId, photo});
      } catch {
        this.$camsdals.alert("Ошибка при загрузке фото");
      }
    },
    /**
     * Сохранение всех фотографий сотрудника.
     *
     * @param {Number} employeeId
     */
    async saveAllPhotosToEmployee(employeeId) {
      const newPhotos = this.photos.filter(photo => !photo.id);
      for (const photo of newPhotos) {
        await this.savePhotoToEmployee(employeeId, photo);
      }
    }
  }
};