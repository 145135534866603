import makeMessage from "@/store/analytics/analyticMessage.js";

/**
 * Служебный класс для работы с одним экземпляром SSE.
 * Устанавливает соединение, регистрирует и удаляет обработчики сообщений, закрывает соединение.
 */
export default class SSEWorker {
  /**
   * @param {String} sseUrl URL до SSE канала сообщений
   * @param {Function} onNewMessage Функция срабатывает после получения нового сообщения, вызывается и в аргументе принимает это сообщение.
   */
  constructor(sseUrl, onNewMessage) {
    this.eventSource = new EventSource(sseUrl);
    this.registeredHandlers = {};

    const defaultHandlers = {
      // "open": () => {},
      /**
       * Обработчик для SSE события "message".
       *
       * @param {MessageEvent} event
       */
      "message": (event) => {
        _.isFunction(onNewMessage) && onNewMessage(makeMessage(event.data));
      },
      // "error": () => {},
    };
    for (const [eventType, handler] of Object.entries(defaultHandlers)) {
      if (!(eventType in this.registeredHandlers)) {
        this.eventSource.addEventListener(eventType, handler);
        this.registeredHandlers[eventType] = handler;
      }
    }
  }

  /**
   * Удаляет всех слушателей событий для текущего экземляра EventSource.
   */
  close() {
    for (const [eventType, handler] of Object.entries(this.registeredHandlers)) {
      this.eventSource.removeEventListener(eventType, handler);
    }
    this.registeredHandlers = {};
    this.eventSource.close();
  }
}