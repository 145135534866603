<template>
  <div class="language-selector">
    <ul class="language-list">
      <SpinnerLoadingModal v-if="isLoading" />
      <li v-for="(lang, index) in languages" :key="index" class="language-item" @click="selectLanguage(lang.value)">
        <span>{{ lang.label }}</span>
        <!-- Отображаем иконку только напротив выбранного языка -->
        <svg v-if="selectedLanguage === lang.value" class="icon">
          <use xlink:href="#icon-circle-confirm" />
        </svg>
      </li>
    </ul>
    <div class="buttons">
      <CamsButton class="cancel-btn" @click="cancel">
        {{ $t('cancel') }}
      </CamsButton>
      <CamsButton class="save-btn" priority="primary" @click="saveLanguage">
        {{ $t('save') }}
      </CamsButton>
    </div>
  </div>
</template>

<script>
import {methodsForDialogMixin} from "@/components/pacs/mixins.js";
import {ROUTE_ROOT} from "@/router/names.js";

export default {
  mixins: [methodsForDialogMixin],
  data() {
    return {
      isLoading: false,
      selectedLanguage: localStorage.getItem('locale') || 'ru', // Получаем язык из localStorage или устанавливаем по умолчанию 'ru'
      languages: [
        {value: 'ru', label: 'Русский'},
        {value: 'en', label: 'English'},
        {value: 'tr', label: 'Türkçe'}
      ]
    };
  },
  methods: {
    // Этот метод будет вызван при выборе языка
    selectLanguage(language) {
      this.selectedLanguage = language; // Устанавливаем выбранный язык
    },
    // Этот метод изменяет язык и сохраняет его в localStorage
    changeLanguage(locale) {
      this.$i18n. locale = locale;
      localStorage.setItem('locale', locale); // Сохраняем выбранную локаль в LocalStorage

    },
    // Метод сброса выбранного языка
    cancel() {
      this.selectedLanguage = localStorage.getItem('locale');
      this.closeDialog();
    },
    // Метод сохранения выбранного языка
    async saveLanguage() {
      this.isLoading=true;
      await this.changeLanguage(this.selectedLanguage); // Сохраняем выбранный язык через метод
      this.$router.go(ROUTE_ROOT); // Обновить текущий маршрут без перезагрузки страницы
      this.closeDialog();
      this.isLoading=false;
    }
  },
  mounted() {
    // При монтировании компонента проверяем localStorage и устанавливаем язык
    this.selectedLanguage = localStorage.getItem('locale') || 'ru'; // Устанавливаем язык при загрузке компонента
  }
};
</script>
<style scoped lang="scss">
.language-selector {
}

.language-list {
  list-style: none;
  text-align: left;
}

.language-item {
  display: flex;
  justify-content: space-between; /* Размещаем текст слева, иконку справа */
  align-items: center;
  margin: 12px 0;
  cursor: pointer;
  font-size: 16px;
}

.language-item:hover {
  background-color: #f0f0f0; /* Добавляем эффект наведения */
  border-radius: 5px;
}

.icon {
  margin-top: 1px;
  width: 22px;
  height: 22px;
}

.buttons {
  display: flex;
  justify-content: flex-start;
}

.cancel-btn {
  padding: 10px 30px;
  border-radius: 30px;

}

.save-btn {
  padding: 10px 30px;
  margin-left: 10px;
  border-radius: 30px;
}
.cams-dialog {
.title {
font-size: 10px !important;
}
}
</style>
