<template>
  <div class="settings-access">
    <div v-if="accountsInfo.length > 0" class="settings-access__content">
      <div class="settings-access__title">
        {{ $t('linkedAccounts') }} {{ linkCount }}
      </div>
      <div
        v-for="(accountInfo, rowIndex) in accountsInfo"
        :key="`row-${rowIndex}`"
        class="access-container__list-item"
      >
        {{ accountInfo.username }}
        <CamsButton
          class="settings-access__button-delete-field"
          icon-type="only"
          type="button"
          @click="deleteChildLink(accountInfo.childId)"
        >
          <svg class="icon-close">
            <use xlink:href="#icon-delete" />
          </svg>
        </CamsButton>
      </div>
    </div>
    <SpinnerLoadingModal v-if="isLoading" />
  </div>
</template>

<script>

import {
  ACTION_DELETE_CHILD_LINKS,
  ACTION_LOAD_CHILD_LINKS,
  FIELDS_USER_LINK
} from "@/store/users/index.js";
import {editEntityMixin} from "@/components/pacs/mixins.js";

/**
 * Компонент установки дочерних связей между учетными записями.
 */

export default {
  mixins: [editEntityMixin],
  data() {
    return {
      showInput: false,
      accountsInfo: [],
      linkCount: null,
    };
  },
  created() {
    this.loadChildLinks();
  },
  methods: {
    /**
     * Загрузка списка дочерних связей.
     */
    async loadChildLinks() {
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch(`users/${ACTION_LOAD_CHILD_LINKS}`);
        this.linkCount = response.count;
        this.accountsInfo = response.results.map((rawChildLinkInfo) => {
          return {
            username: rawChildLinkInfo[FIELDS_USER_LINK.username],
            date: rawChildLinkInfo[FIELDS_USER_LINK.date],
            childId: rawChildLinkInfo[FIELDS_USER_LINK.user_id],
          };
        });
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * Отправка запроса на удаление дочерней связи между учетными записями.
     */
    async deleteChildLink(childId) {
      this.$camsdals.confirm(this.$t('deleteConfirmation'), async () => {
        this.isLoading = true;
        try {
          await this.$store.dispatch(`users/${ACTION_DELETE_CHILD_LINKS}`, childId);
        } catch (error) {
          this.$camsdals.alert(error);
        }
        await this.loadChildLinks();
        this.$root.$emit('load-cameras');
        this.isLoading = false;
      });
    },
  }
};


</script>
