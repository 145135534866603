/**
 * Отдельный vuex модуль для сохранения общего состояния основных компонентов из раздела meshCameras,
 * с целью его восстановления между переходами к/из компонентов.
 */

import {PAGE_SIZES, PARAMS_SORT_CAMERAS, TYPES_VIEWS} from "@/utils/consts.js";

// Перечень мутаций. Вызов начинать с "meshCameras/"
export const MUTATION_SET_STATE_FRAME = "SET_STATE_FRAME";
export const MUTATION_SET_PAGE_SIZE = "SET_PAGE_SIZE";

/**
 * Зафиксированные условные обозначения главных компонентов meshCameras,
 * у каждого из которых имеется собственное локальное состояние.
 *
 * @type {Readonly}
 */
export const FRAMES = Object.freeze({
  DIALOG: "DIALOG",
  PAGE: "PAGE",
});

/**
 * Зафиксированные табы, существующие в рамках главных компонентов meshCameras ({@link FRAMES}),
 * у каждого из которых имеется собственное локальное состояние.
 *
 * @type {Readonly}
 */
export const TABS = Object.freeze({
  MY: "MY",
  FAV: "FAV",
  SEARCH: "SEARCH",
  FOLDERS: "FOLDERS",
});

/**
 * Функция вернет начальное состояние для всех заявленных случаев использования компонентов meshCameras.
 *
 * @return {Object}
 */
function initialState() {
  return {
    pageSize: PAGE_SIZES.DEFAULT,
    frames: {
      [FRAMES.DIALOG]: {
        tabs: {
          [TABS.MY]: {sort: PARAMS_SORT_CAMERAS.addr_asc, page: 1},
          [TABS.FAV]: {sort: PARAMS_SORT_CAMERAS.user, page: 1},
          [TABS.SEARCH]: {sort: PARAMS_SORT_CAMERAS.addr_asc, page: 1},
        },
        activeTab: TABS.MY,
        typeView: TYPES_VIEWS.TILE,
        querySearchCameras: "",
        queryTabSearch: "",
      },
      [FRAMES.PAGE]: {
        tabs: {
          [TABS.MY]: {sort: PARAMS_SORT_CAMERAS.addr_asc, page: 1},
          [TABS.FAV]: {sort: PARAMS_SORT_CAMERAS.user, page: 1},
          [TABS.SEARCH]: {sort: PARAMS_SORT_CAMERAS.addr_asc, page: 1},
          [TABS.FOLDERS]: {sort: PARAMS_SORT_CAMERAS.addr_asc, page: 1},
        },
        activeTab: TABS.MY,
        typeView: TYPES_VIEWS.TILE,
        querySearchCameras: "",
        queryTabSearch: "",
      },
    },
  };
}

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    /**
     * Сохранение ключевых параметров при использовании главных компонентов meshCameras
     * для их последующего восстановления в компоненте.
     *
     * @param {Object} state
     * @param {String} frame
     * @param {String} tab
     * @param {String} typeView
     * @param {String} querySearchCameras
     * @param {String} queryTabSearch
     * @param {String} sort
     * @param {Number} page
     */
    [MUTATION_SET_STATE_FRAME](state, {frame, tab, typeView = null, querySearchCameras = null, queryTabSearch = null, sort = null, page = null, folders = null}) {
      state.frames[frame].activeTab = tab;
      state.frames[frame].typeView = _.isNull(typeView) ? state.frames[frame].typeView : typeView;
      state.frames[frame].querySearchCameras = _.isNull(querySearchCameras) ? state.frames[frame].querySearchCameras : querySearchCameras;
      state.frames[frame].queryTabSearch = _.isNull(queryTabSearch) ? state.frames[frame].queryTabSearch : queryTabSearch;
      state.frames[frame].tabs[tab].sort = _.isNull(sort) ? state.frames[frame].tabs[tab].sort : sort;
      state.frames[frame].tabs[tab].page = _.isNull(page) ? state.frames[frame].tabs[tab].page : page;
      state.frames[frame].tabs[tab].folders = _.isNull(folders) ? state.frames[frame].tabs[tab].folders : folders;
    },
    /**
     * Изменение количества загружаемых элементов.
     *
     * {@see PAGE_SIZES}
     * @param {Object} state
     * @param {Number} pageSize
     */
    [MUTATION_SET_PAGE_SIZE](state, pageSize) {
      state.pageSize = pageSize;
    },
  },
};
