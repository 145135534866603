// Перенос из sucams частей для работы с API для представления данных в таблицах.
// todo не все из этого нужно будет - со временем стоит удалить

/**
 * Тип данных для ячеек в таблице, чтобы применять для нужных полей сущностей определенный формат их представления.
 */
export const SPECIAL_TYPES_DATA_IN_CELLS = Object.freeze({
  BOOLEAN: "BOOLEAN",
  TEXT: "TEXT",
  DATE_TIME: "DATE_TIME",
  DATE: "DATE",
  ROUTE: "ROUTE",
  ARRAY: "ARRAY",
});

/**
 * Направления сортировок.
 */
export const SORT_DIRECTIONS = Object.freeze({
  ASC: "asc",
  DESC: "desc"
});

/**
 * Стандартный размер списка загружаемых элементов для таблиц.
 */
export const DEFAULT_PAGE_SIZE_FOR_TABLE = 20;

/**
 * Стандартный размер списка загружаемых элементов для элементов выбора.
 */
export const DEFAULT_PAGE_SIZE_FOR_SELECT = 5;

/**
 * Общая функция сборки одного комплекта информации о фильтре, который можно передавать в API для фильтрации сущностей.
 *
 * @param {String} name
 * @param {String} lookup
 * @param {*} value
 * @param {Boolean} isExclude
 * @return {{lookup: String, argument: String, field: *, exclude: Boolean}}
 */
export function makeFilterApi(name, lookup, value, isExclude = false) {
  return {field: name, lookup, argument: value, exclude: isExclude};
}

/**
 * Зная структуру объекта фильтров для API, вернет значение фильтра по указанному полю.
 * todo Используется исключительно в ucams из-за узкости табличных фильтров,
 * в будущем имеет смысл переделать API чтобы сразу имелся нужный фильтр.
 *
 * @param {Array} filters
 * @param {String} name
 * @return {*}
 */
export function getFilterApiValue(filters, name) {
  const neededFilterIndex = filters.findIndex((filter) => filter["field"] === name);
  return neededFilterIndex >= 0 ? filters[neededFilterIndex]["argument"] : null;
}

/**
 * Вернет список extra данных для API по конкретной структуре для указанного списка полей.
 *
 * @param {Object} entityStruct
 * @param {Array} selectedEntityFields
 * @return {Array}
 */
export function getExtraApi(entityStruct, selectedEntityFields) {
  return selectedEntityFields.reduce((accumulator, currentValue) => {
    const extra = entityStruct.fields[currentValue].extra;
    if (!extra) {
      return accumulator;
    }
    return [...accumulator, ...(Array.isArray(extra) ? extra : [extra])];
  }, []);
}

/**
 * Преобразует перечень ошибок полей форм в формат пригодный для их отображения.
 *
 * При передаче форм создания/редактирования объектов могут возникать ошибки.
 * Формат коллекции ошибок определенный сервером не подходит для использования их в чистом виде,
 * поэтому он преобразуется в вид {fieldName: "All errors by field", fieldNameArray: {errorIndex: "Error in value by index"}}.
 *
 * @param {Object} errorsFields
 * @return {Object}
 */
export function reformatErrorsForm(errorsFields) {
  return _.reduce(errorsFields, (result, value, key) => {
    const [errorField, errorFieldIndex] = key.split(".");
    if (errorFieldIndex) {
      (result[errorField] || (result[errorField] = []))[errorFieldIndex] = value.join(" ");
    } else {
      result[errorField] = value.join(" ");
    }
    return result;
  }, {});
}
