export const ruMessagesCams = {
  linkedAccounts: "Привязанные аккаунты",
  accountAccess: "Доступ к аккаунту",
  exit: "Выход",
  accountRelationsSettings: "Настройка связей учетной записи",
  sortBy: "Сортировать по:",
  language: "Язык",
  languageSelection: "Выбор языка",
  view: "Вид:",
  byAddress: "По адресу",
  byHeading: "По заголовку",
  byDateAdded: "По дате добавления",
  user: "Пользователь",
  tile: "Плитка",
  list: "Список",
  cancel: "Отмена",
  save: "Сохранить",
  myCameras: "Мои камеры",
  favorites: "Избранное",
  search: "Поиск",
  showAll: "Показать всё",
  found: "Найдено",
  addresses: "Адреса",
  titles: "Названия",
  noResults: "Нет результатов поиска",
  createFolder: "Создать папку",
  editFolder: "Редактировать папку",
  noCamerasFound: "Камеры не найдены",
  saveSelectedCameras: "Сохранить выбранные камеры",
  enterFolderName: "Введите имя папки",
  folderCreate: "Создание папки",
  saveChanges: "Сохранить изменения",
  createSubfolder: "Создать подпапку",
  deleteFolder: "Удалить папку",
  deleteFolderConfirmation: "Внимание! При удалении папки удалятся все вложенные папки и камеры.",
  folderDeletedSuccess: "Папка {folderName} успешно удалена",
  changesSaved: "Изменения сохранены",
  errorAddingCameras: "Ошибка при добавлении камер",
  errorDeletingFolder: "Ошибка при удалении папки",
  createSubfolderFor: "Создать подпапку для {folderName}",
  cameraDisabled: "Камера отключена",
  cameraNotFound: "Камера не обнаружена",
  embedCamera: "Встроить на сайт",
  cameraSelectedForMonitor: "Камера выбрана для тревожного монитора",
  cameraSelected: "Камера выбрана",
  viewOnline: "Просмотр онлайн",
  viewArchive: "Просмотр архива",
  infoOfCamera: "Информация по камере",
  thermalImager: "Тепловизор",
  carNumberRecognition: "Распознавание номеров",
  faceRecognition: "Распознавание лиц",
  helmetRecognition: "Распознавание касок",
  maskDetector: "Детектор масок",
  perimeterDetector: "Детектор пересечения зоны",
  crowdDetector: "Детектор скопления людей",
  peopleCounter: "Подсчет посетителей",
  motionAlarm: "Движение в кадре",
  player: "Плеер",
  embed: "Встраивание",
  thermalVisionReport: "Отчет по тепловизору",
  carNumberReport: "Отчет по автомобильным номерам",
  faceRecognitionReport: "Отчет по распознаванию лиц",
  helmetReport: "Отчет по каскам",
  maskReport: "Отчет по маскам",
  perimeterReport: "Отчет по вторжению в зону",
  motionAlarmReport: "Отчет по движению",
  crowdReport: "Отчет по скоплению людей",
  peopleCountReport: "Отчет по подсчету посетителей",
  onMap: "На карте",
  width: "Ширина",
  height: "Высота",
  fullscreen: "Разворачивать в полный экран",
  autoplay: "Автоматический запуск",
  https: "HTTPS",
  lowLatency: "Прямой эфир без задержки",
  standardPlayback: "Стандартный способ воспроизведения. Видео отдаётся с небольшой задержкой.",
  lowLatencyPlayback: "Видео транслируется без задержек. Не поддерживается в некоторых браузерах.",
  sound: "Звук",
  hoursWithoutReload: "Часов трансляции без перезагрузки:",
  copyCode: "Скопировать код",
  streamError: "Ошибка: не удалось запустить трансляцию",
  copiedToClipboard: "Скопировано в буфер обмена",
  events: "События",
  info: "Информация",
  cameraNumber: "Камера №",
  cameraAddress: "Адрес камеры",
  timezoneOffset: "Смещение часового пояса",
  invalidDownloadRange: "Невозможно скачать. Выбран некорректный диапазон времени, попробуйте изменить границы скачивания.",
  downloadFailed: "Невозможно скачать. Попробуйте позднее.",
  addCamera: "Добавить камеру",
  alarmCell: "Тревожная ячейка",
  streamUnavailable: "Трансляция недоступна. Необходимо изменить камеру.",
  alarm: "Тревога",
  deleteCamera: "Удалить камеру из ячейки",
  changeCamera: "Изменить камеру в ячейке",
  moveToAnotherCell: "Переместить в другую ячейку",
  selectCameraForCell: "Выберите камеру для ячейки",
  newMosaic: "Новая мозаика",
  enterTitle: "Укажите название",
  addMosaic: "Добавить мозаику",
  hideArchiveTools: "Скрыть инструменты работы с архивом",
  showArchiveTools: "Показать инструменты работы с архивом",
  archive: "Архив",
  cancelChanges: "Отменить все изменения и скрыть настройки",
  showSettings: "Показать настройки текущей мозаики",
  setLowLatency: "Установить для всех камер режим работы без задержки",
  selectAlarmCameras: "Выбрать тревожные камеры для этой мозаики",
  oneCamera: "1 камера",
  fourCamerasGrid: "Сетка на 4 камеры",
  grid5Title: "Сетка 5",
  grid6Title: "Сетка 6",
  grid8Title: "Сетка 8",
  grid9Title: "Сетка 9",
  grid10Title: "Сетка 10",
  grid16Title: "Сетка 16",
  deleteMosaic: "Удалить текущую мозаику",
  delete: "Удалить",
  alarmCameras: "Тревожные камеры",
  public: "Публичные",
  confirmDeleteMosaic: "Хотите удалить эту мозаику?",
  deleteMosaicError: "Ошибка при удалении мозаики",
  deleteMosaicSuccess: "Мозаика удалена",
  saveChangesError: "При сохранении произошла ошибка. Попробуйте перезагрузить страницу и повторите сохранение.",
  saveMosaicError: "При сохранении мозаики произошла ошибка. Попробуйте создать мозаику заново.",
  saveChangesSuccess: "Мозаика сохранена",
  newMosaicAdded: "Добавлена новая мозаика",
  defaultMosaicRedirect: "Мозаика сохранена",
  chooseCamerasForAlarm: "Выберите камеры для получения событий на мозаике",
  setAlarmCameras: "Нажмите 'Сохранить', чтобы активировать выбранные камеры как тревожные",
  setFullscreen: "Установить полноэкранный режим",
  archiveSettings: "Настройки архива",
  hideEventsCount: "Скрыто событий: ",
  hiddenThermalVisionEvents: "Скрыто событий по тепловизору: ",
  hiddenCarNumberEvents: "Скрыто событий по автомобильным номерам: ",
  hiddenFaceRecognitionEvents: "Скрыто событий по распознаванию лиц: ",
  hiddenHelmetEvents: "Скрыто событий по каскам: ",
  hiddenMotionAlarmEvents: "Скрыто событий по движению: ",
  hiddenMaskEvents: "Скрыто событий по маскам: ",
  hiddenCrowdEvents: "Скрыто событий по скоплению: ",
  hiddenPerimeterEvents: "Скрыто событий по периметру: ",
  hiddenPeopleCounterEvents: "Скрыто событий по подсчету людей: ",
  editTitleTooltip: "Редактировать",
  titleSaveSuccess: "Название камеры успешно изменено",
  titleSaveError: "Ошибка: ",
  titleWarning: "Длина названия не может превышать 64 символа и не может быть пустым",
  noEvents: "События на данном промежутке времени отсутствуют.",
  deleteConfirmation: "Удалить связь с учетной записью?",
  accessHeader: "Доступ к аккаунту",
  nothingHere: "Тут ничего нет",
  createTokenMessage: "Создайте новый токен, для интеграции сторонних систем с нашим сервисом по API",
  createToken: "Создать токен",
  username: "Логин",
  link: "Ссылка",
  token: "Токен",
  copy: "Скопировать",
  deleteToken: "Удалить токен",
  copySuccess: "Скопировано в буфер",
  addLinkPrompt: "Чтобы привязать камеры другой учетной записи нажмите добавить",
  add: "Добавить",
  enterUsername: "Введите логин",
  password: "Введите пароль",
  accountLinkedSuccess: "Учетная запись привязана",
  inputUsernamePassword: "Укажите логин и пароль:",
  employees: "Сотрудники",
  accessDevices: "Устройства доступа",
  accessGroups: "Группы доступа",
  cars: "Автомобили",
  searchPlaceholder: "Поиск",
  filterByGroupsPlaceholder: "Фильтр по группам доступа",
  addToGroup: "Добавить в группу",
  removeFromGroup: "Убрать из группы",
  deleteEmployees: "Удалить сотрудников",
  addEmployee: "Добавить",
  addEmployeeFromFile: "Добавить из файла",
  downloadReport: "Скачать отчет",
  deleteEmployeeConfirm: "Хотите удалить этого сотрудника?",
  deleteEmployeesConfirm: "Хотите удалить выбранных сотрудников?",
  employeesDeleted: "Сотрудники удалены",
  employeeDeleted: "Сотрудник удален",
  errorDeletingEmployee: "Ошибка при удалении сотрудника",
  viewAll: "Посмотреть все",
  deleteSelectedEmployeesConfirm: "Хотите удалить выбранных сотрудников? Выбрано",
  removeEmployeesFromGroup: "Удалить сотрудников из группы",
  addEmployeesToGroup: "Добавить сотрудников в группу",
  csvFileName: "Выгрузка по сотрудникам.csv",
  totalEmployees: "Всего сотрудников:",
  tableHeaders: {
    id: "ID",
    name: "Имя",
    cars: "Автомобили",
    deviceAccessGroups: "Группы доступа",
    isActive: "Активен",
    identifier: "Идентификатор",
    emergencyVehicleAccess: "Доступ спецтранспорта",
    cameraCount: "Кол-во камер",
    cameras: "Камеры",
    title: "Название",
    about: "Дополнительное описание",
    employee_count: "Количество сотрудников",
    employee_ids: "Сотрудники группы",
  },
  entry: "записей",
  employeeName: "Имя сотрудника",
  enterEmployeeName: "Укажите ФИО сотрудника",
  selectAccessGroups: "Укажите группы доступа и сотрудник получит доступ к устройствам",
  carNumbers: "Автомобильные номера",
  accountNumber: "Учетная запись (номер договора)",
  isActive: "Активен?",
  saveAndAddNew: "Сохранить и добавить нового",
  successEmployeeCreated: "Сотрудник успешно создан",
  uploadFile: "Загрузить файл",
  getTemplate: "Получить шаблон",
  successFileUploaded: "Файл успешно загружен",
  errorFileUpload: "Ошибка: ",
  errorLoadEntity: "Ошибка: невозможно отобразить информацию.",
  deviceAccessGroups: "Группы доступа",
  addPhoto: "Добавить фото",
  deletePhoto: "Удалить фото",
  noPhotos: "Фотографий нет",
  attachedUsernames: "Учетная запись для доступа к шлагбауму (номер договора)",
  employeeCarsCount: "Количество автомобилей у сотрудника: ",
  accessSettings: "Настройки доступа",
  accessGroupList: "Список групп доступа:",
  viewGroup: "Смотреть группу: ",
  confirmDeletePhoto: "Хотите удалить фотографию?",
  errorDeletePhoto: "Ошибка при удалении фото",
  successSave: "Сохранено успешно",
  confirmDeleteEmployee: "Хотите удалить этого сотрудника?",
  errorDeleteEmployee: "Ошибка при удалении сотрудника",
  addDevice: "Добавить устройство",
  chooseDevice: "Выберите устройство",
  enterEmployee: "Введите сотрудника",
  more: "Ещё",
  deviceFilter: "Фильтр по устройствам",
  employeeFilter: "Фильтр по сотрудникам",
  device: "Устройство",
  employee: "Сотрудник",
  accessLevel: "Уровень доступа",
  notFound: "Прав не найдено.",
  foundCount: "Найдено: ",
  addPermissionError: "Ошибка при добавлении прав",
  setPermissionError: "Ошибка при установке прав",
  deletePermissionConfirm: "Хотите удалить эти права?",
  devicePermissionDeleted: "Права на устройство удалены",
  deletePermissionError: "Ошибка при удалении прав",
  fullAccess: "Полный доступ",
  entrance: "Вход",
  selectAccessGroupForDelete: "Выберите группу доступа для удаления из нее сотрудников ",
  selectAccessGroupForAdd: "Выберите группу доступа для добавления ее к сотрудникам ",
  enterAccessGroup: "Введите группу доступа",
  deleteFromGroup: "Удалить из этой группы",
  errorDeleteEmployeesFromGroup: "Ошибка при удалении сотрудников из группы. ",
  errorAddEmployeesToGroup: "Ошибка при добавлении сотрудников к группе. ",
  noData: "Нет данных",
  totalCount: "Количество",
  selectDevicePlaceholder: "Выберите устройство",
  selectGroupPlaceholder: "Введите название группы доступа",
  addGroup: "Добавить группу сотрудников",
  groupFilter: "Фильтр по группам доступа",
  deviceHeader: "Устройство",
  groupHeader: "Группа доступа",
  noPermissions: "Прав не найдено",
  errorAddingPermissions: "Ошибка при добавлении прав",
  errorUpdatingPermissions: "Ошибка при обновлении прав",
  confirmDeletePermission: "Хотите удалить эти права?",
  permissionDeleted: "Права на устройство удалены",
  errorDeletingPermissions: "Ошибка при удалении прав",
  allRowsSelected: "Выбраны все строки",
  selectedRows: "Выбрано строк",
  selectAll: "Выбрать все",
  deviceName: "Название устройства",
  deviceState: "Состояние устройства",
  active: "Активен",
  inactive: "Неактивен",
  emergencyVehicleAccess: "Доступ спецтранспорта",
  available: "Есть",
  notAvailable: "Нет",
  cameraCountForDevice: "Количество привязанных камер к устройству",
  availableCamerasForUser: "Количество доступных камер для текущего пользователя",
  noAvailableCamerasForUser: "У текущего пользователя нет доступных камер.",
  noCamerasAttachedToDevice: "К устройству доступа не привязана ни одна камера.",
  editPermissionsForEmployees: "Изменить права для сотрудников",
  editPermissionsForDeviceAccessGroups: "Изменить права для групп доступа",
  editAccessSettings: "Настройки доступа",
  deleteGroups: "Удалить группы",
  createAccessGroup: "Создание группы доступа",
  confirmDeleteGroup: "Хотите удалить эту группу доступа?",
  groupDeleted: "Группа доступа удалена",
  errorDeletingGroup: "Ошибка при удалении группы доступа",
  confirmDeleteGroups: "Хотите удалить выбранные группы доступа? Выбрано {count}",
  groupsDeleted: "Группы доступа удалены",
  errorDeletingGroups: "Ошибка при удалении групп доступа",
  groupTitle: "Название группы доступа",
  groupAbout: "Дополнительное описание",
  employeesCount: "Количество сотрудников в группе: ",
  viewAllEmployees: "Посмотреть всех сотрудников",
  addEmployeeToGroup: "Добавить сотрудника в группу",
  saveSuccess: "Сохранено успешно",
  deleteGroupConfirm: "Хотите удалить эту группу доступа?",
  employeeAddedToGroup: "Сотрудник добавлен в группу",
  errorAddingEmployees: "Ошибка при добавлении сотрудников к группе.",
  groupName: "Название группы",
  additionalDescription: "Дополнительное описание",
  filterByEmployee: "Фильтр по сотрудникам",
  deleteCars: "Удалить автомобили",
  addCar: "Добавить автомобиль",
  createCarDialogTitle: "Создание автомобиля",
  confirmDeleteCar: "Хотите удалить этот автомобиль?",
  carDeleted: "Автомобиль удален",
  errorDeleteCar: "Ошибка при удалении автомобиля",
  confirmDeleteSelectedCars: "Хотите удалить выбранные автомобили? Выбрано: {count}",
  carsDeleted: "Автомобили удалены",
  errorDeleteCars: "Ошибка при удалении автомобилей",
  carNumber: "Номер автомобиля",
  carOwner: "Владелец автомобиля",
  existingEmployeeCars: "Существующие автомобили сотрудника",
  shownCars: "Показано автомобилей сотрудника",
  totalCars: "Всего у него",
  accountLinkedTo: "Учетная запись привязана к",
  deleteAccountLinkConfirmation: "Удалить связь с учетной записью?",
  accessGranted: "Предоставлен доступ",
  name: "Имя",
  number: "Номер",
  access: "Доступ",
  date: "Дата",
  yes: "Да",
  no: "Нет",
  unknown: "Неизвестный",
  startDate: "Дата начала",
  endDate: "Дата конца",
  reset: "Сбросить",
  itemsPerPage: "Элементов на странице",
  screenshot: "Скриншот",
  video: "Видео",
  downloadVideo: "Скачать видео",
  text: "Текст",
  archiveError: "Ошибка: невозможно получить доступ к архиву",
  crowdDetection: "Обнаружение скопления людей",
  screenshotEvent: "Скриншот события",
  eventVideo: "Видео события",
  downloadEventVideo: "Скачать видео события",
  report: "Отчет",
  noHelmet: "Отсутствие каски",
  noMask: "Отсутствие маски",
  duration: "Длительность (в сек.)",
  reportType: "Тип отчета",
  inCount: "Вошло посетителей",
  outCount: "Вышло посетителей",
  defaultReport: "Общий отчет",
  hourly: "Почасовой",
  daily: "По дням",
  weekly: "По неделям",
  monthly: "По месяцам",
  perimeterCrossing: "Пересечение периметра",
  minTemperature: "Наименьшая температура",
  maxTemperature: "Наибольшая температура",
  temperature: "Температура, ℃",
  exceededTemperature: "Температура превышена?",
  maxRowsWarning:
    "Отображается максимально допустимое количество 1000 строк, остальные записи могли быть скрыты. Чтобы увидеть их, уточните фильтры.",
  thermalCameras: "Тепловизоры",
  motions: "Движения",
  helmets: "Каски",
  masks: "Маски",
  crowdGathering: "Скопление людей",
  zoneCrossing: "Пересечение зоны",
  tooManyRows: "Отображается максимально допустимое количество 1000 строк, остальные записи могли быть скрыты. Чтобы увидеть их, уточните фильтры.",
  eventText: "Событие",
  eventDate: "Дата",
  crowdReportFilename: "Отчет по скоплению",
  defaultCrowdEvent: "Скопление людей",
  helmetReportFilename: "Отчет по каскам",
  helmetMissing: "Отсутствие каски",
  maskReportFilename: "Отчет по маскам",
  perimeterSecurityReportFilename: "Отчет по пересечению периметра",
  unknownEvent: "Неизвестно",
  loadingError: "Произошла ошибка при загрузке данных. Пожалуйста, уточните фильтры.",
  motionReportFilename: "Отчет по движениям",
  thermalReportFilename: "Отчет по тепловизорам",
  title: "Имя",
  tariff: "Тариф",
  address: "Адрес",
  firmwareUpdateMessage: "Прошивка обновляется… не закрывайте и не обновляйте страницу.",
  cameraModel: "Модель камеры",
  macAddress: "MAC-адрес",
  firmwareVersion: "Версия ПО",
  firmwareUpdateAvailable: "Доступно обновление",
  enableVideoStream: "Включить видеопоток",
  enableAudio: "Звук",
  ntpServer: "NTP сервер",
  videoResolution: "Разрешение",
  videoBitrate: "Битрейт",
  nightModeEnable: "ИК подсветка",
  timeZone: "Часовой пояс",
  rebootCamera: "Перезагрузить камеру",
  confirmReboot: "Хотите перезагрузить камеру?",
  cameraRebootIn15Sec: "Камера будет перезагружена в течение 15 секунд",
  configurationError: "Ошибка при конфигурации",
  confirmUpgradeFirmware: "Будет установлена прошивка версии: {version}. Не отключайте камеру от питания и интернета. Это может привести к повреждению.",
  upgradeSuccess: "Прошивка обновится в течение 45 секунд",
  upgradeError: "Произошла ошибка, попробуйте еще раз",
  screenshotAlt: "Скриншот события",
  screenshotProcessing: "Скриншот в обработке.",
  Movement: "Движение",
  continues: "продолжается",
  sec: "сек.",
  deviceId: "Устройство",
  employeeId: "Сотрудник",
  specialTransport: "Спецтранспорт",
  eventView: 'Просмотр события',
  downloadError: 'Невозможно скачать. Попробуйте позднее.',
  screenshotView: 'Просмотр скриншота события',
  participants: '&copy; Участники {link}',
  insufficientRights: "Недостаточно прав.",
  liveStreamTokenError: "Не удалось получить токен для доступа к трансляции.",
  archiveTokenError: "Не удалось получить токен для доступа к архиву.",
  multiscreen: "Мультиэкран",
  map: "Карта",
  scud: "СКУД",
  setupCameras: "Настройка камер",
  reloadToUpdate: "Хотите перезагрузить страницу, чтобы обновиться?",
  reloadNow: "Перезагрузить сейчас",
  postpone: "Отложить",
  newVersion: "Вышла новая версия приложения",
  errorWhileLoading: "Ой, при загрузке страницы что-то пошло не так. Давайте попробуем перезагрузить страницу.",
  reload: "Перезагрузить",
  errorOccurred: "Произошла ошибка",
  on: "Включена",
  off: "Отключена",
  auto: "Автоматический режим",
};
