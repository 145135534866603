<template>
  <div class="cams-container__camera cams-container__camera--tile">
    <div v-if="grabbableMode" class="grabbable">
      <svg class="icon icon-grabbable">
        <use xlink:href="#icon-grabbable" />
      </svg>
    </div>
    <div class="cams-container__camera-address">
      {{ cameraInfo.address }}
    </div>

    <div
      v-if="cameraInfo.isInactive()"
      class="cams-container__camera-thumb cams-container__camera-thumb--inactive"
      @click="folderMode ? clickOnScreen() : null"
    >
      <div class="cams-container__camera-meta camera-meta">
        <div class="camera-meta__status">
          {{ cameraInfo.inactivityPeriod.reason }}
        </div>
        <div class="camera-meta__todo">
          {{ cameraInfo.inactivityPeriod.todo }}
        </div>
        <div class="camera-meta__number">
          № {{ cameraInfo.number }}
        </div>
      </div>
      <div class="cams-container__camera-fogging" />
    </div>

    <div
      v-else-if="cameraInfo.isDisabled()"
      class="cams-container__camera-thumb cams-container__camera-thumb--inactive"
      @click="folderMode ? clickOnScreen() : null"
    >
      <div class="cams-container__camera-meta camera-meta">
        <div class="camera-meta__status">
          {{ $t('cameraDisabled') }}
        </div>
        <div class="camera-meta__todo">
          {{ cameraInfo.recordDisablePeriod.start | localDateTime }}
        </div>
        <div class="camera-meta__number">
          № {{ cameraInfo.number }}
        </div>
      </div>
      <div class="cams-container__camera-fogging" />
    </div>

    <div
      v-else
      v-observe-visibility="visibilityChanged"
      :class="{'cams-container__camera-thumb--focus': showInfo, 'cams-container__camera-thumb--disabled': isDisabled}"
      class="cams-container__camera-thumb"
      @click="folderMode ? clickOnScreen() : null"
    >
      <template v-if="isVisible && !isDisabled">
        <img
          v-if="cameraInfo.server.screenshot_domain"
          :alt="cameraInfo.title"
          :src="cameraInfo.screenshotUrl(cameraScreenshotSizes.s400, screenshotSign)"
          class="cams-container__video-thumb"
          @error="isDisabled = true"
        >
        <video
          v-else
          :src="cameraInfo.screenshotUrl(cameraScreenshotSizes.s400, screenshotSign)"
          autoplay
          class="cams-container__video-thumb"
          @error="isDisabled = true"
        />
      </template>

      <template v-else>
        <div class="cams-container__camera-meta cams-container__camera-meta--error camera-meta" @click="folderMode ? clickOnScreen() : null">
          <img alt="" src="@/assets/img/icons/video-camera.svg">
          <div class="camera-meta__status">
            {{ $t('cameraNotFound') }}
          </div>
        </div>
      </template>

      <div class="cams-container__camera-meta camera-meta">
        <div>
          <div class="camera-meta__tariff">
            {{ cameraInfo.tariffName() }}
          </div>
          <div class="camera-meta__number">
            № {{ cameraInfo.number }}
          </div>
        </div>
        <button v-if="!selectableOnlyMode && cameraInfo.isEmbed" type="button" @click="showEmbed()">
          {{ $t('embedCamera') }}
          <svg class="icon icon-integration">
            <use xlink:href="#icon-integration" />
          </svg>
        </button>
      </div>

      <div v-show="isSelectedCamera" class="cams-container__selected-camera">
        <div class="cams-container__selected-camera-message">
          <svg class="icon ">
            <use xlink:href="#icon-warning-alarm-screen" />
          </svg>
          <div>{{ $t('cameraSelectedForMonitor') }}</div>
        </div>
      </div>
      <div class="cams-container__camera-fogging" @click="clickOnScreen()" />
    </div>
    <div v-show="isSelectedCameraForFolder" class="cams-container__selected-camera">
      <div class="cams-container__selected-camera-message">
        <svg class="icon">
          <use xlink:href="#icon-select-circle" />
        </svg>
        <div>{{ $t('cameraSelected') }}</div>
      </div>
    </div>
    <div class="cams-container__camera-fogging" @click="clickOnScreen()" />
    <button
      v-if="!selectableOnlyMode && !cameraInfo.isInactive()"
      class="cams-container__camera-controls__btn cams-container__camera-controls__btn--online"
      :title="$t('viewOnline')"
      type="button"
      @click="play()"
    >
      <svg class="icon icon-play">
        <use xlink:href="#icon-play2" />
      </svg>
    </button>
    <button
      v-if="!selectableOnlyMode && !cameraInfo.isInactive() && cameraInfo.isAvailableArchive()"
      class="cams-container__camera-controls__btn cams-container__camera-controls__btn--archive"
      :title="$t('viewArchive')"
      type="button"
      @click="playArchive()"
    >
      <svg class="icon icon-download">
        <use xlink:href="#icon-download" />
      </svg>
    </button>
    <button
      v-if="!cameraInfo.isInactive()"
      class="cams-container__camera-controls__btn cams-container__camera-controls__btn--info"
      :title="$t('infoOfCamera')"
      type="button"
      @click="showInfo = !showInfo"
    >
      <svg class="icon icon-info">
        <use xlink:href="#icon-info2" />
        <title>{{ $t('infoOfCamera') }}</title>
      </svg>
    </button>

    <div class="cams-container__camera-desc">
      <button
        v-if="cameraInfo.hasAnalyticThermalVision"
        class="cams-container__camera-controls__btn cams-container__camera-controls__btn--analytics-imager"
        :title="$t('thermalImager')"
        type="button"
      >
        <svg class="icon icon-analytics">
          <use xlink:href="#icon-temperature" />
        </svg>
      </button>

      <button
        v-else-if="cameraInfo.hasAnalyticCarNumber"
        class="cams-container__camera-controls__btn cams-container__camera-controls__btn--analytics-imager"
        :title="$t('carNumberRecognition')"
        type="button"
      >
        <svg class="icon icon-analytics">
          <use xlink:href="#icon-car" />
        </svg>
      </button>
      <button
        v-else-if="cameraInfo.hasAnalyticFaceRecognition"
        class="cams-container__camera-controls__btn cams-container__camera-controls__btn--analytics-imager"
        :title="$t('faceRecognition')"
        type="button"
      >
        <svg class="icon icon-analytics">
          <use xlink:href="#icon-face" />
        </svg>
      </button>
      <button
        v-else-if="cameraInfo.hasAnalyticHelmet"
        class="cams-container__camera-controls__btn cams-container__camera-controls__btn--analytics-imager"
        :title="$t('helmetRecognition')"
        type="button"
      >
        <svg class="icon icon-analytics">
          <use xlink:href="#icon-helmet" />
        </svg>
      </button>

      <button
        v-else-if="cameraInfo.hasAnalyticMask"
        class="cams-container__camera-controls__btn cams-container__camera-controls__btn--analytics-imager"
        :title="$t('maskDetector')"
        type="button"
      >
        <svg class="icon icon-analytics">
          <use xlink:href="#icon-mask" />
        </svg>
      </button>

      <button
        v-else-if="cameraInfo.hasAnalyticPerimeter"
        class="cams-container__camera-controls__btn cams-container__camera-controls__btn--analytics-imager"
        :title="$t('perimeterDetector')"
        type="button"
      >
        <svg class="icon icon-analytics">
          <use xlink:href="#icon-perimeter" />
        </svg>
      </button>

      <button
        v-else-if="cameraInfo.hasAnalyticCrowd"
        class="cams-container__camera-controls__btn cams-container__camera-controls__btn--analytics-imager"
        :title="$t('crowdDetector')"
        type="button"
      >
        <svg class="icon icon-analytics">
          <use xlink:href="#icon-crowd" />
        </svg>
      </button>
      <button
        v-else-if="cameraInfo.hasAnalyticPeopleCount"
        class="cams-container__camera-controls__btn cams-container__camera-controls__btn--analytics-imager"
        :title="$t('peopleCounter')"
        type="button"
      >
        <svg class="icon icon-analytics">
          <use xlink:href="#icon-people-counter" />
        </svg>
      </button>
      <button
        v-else-if="cameraInfo.hasAnalyticMotionAlarm"
        class="cams-container__camera-controls__btn cams-container__camera-controls__btn--analytics-imager"
        :title="$t('motionAlarm')"
        type="button"
      >
        <svg class="icon icon-analytics">
          <use xlink:href="#icon-motion" />
        </svg>
      </button>
      <EditorCameraTitle
        :camera-info="cameraInfo"
        :is-editable="!selectableOnlyMode"
        class="cams-container__title"
        spinner-color="white"
      />
    </div>
  </div>
</template>

<script>
import {CAMERA_SCREENSHOT_SIZES} from "@/store/cameras/index.js";
import {viewTypeMixin} from "@/components/meshCameras/mixins.js";
import {YM_GOAL_PLAY_VIA_TILE} from "@/utils/tracking.js";

/**
 * Компонент отображает превью информацию по камере в виде плитки.
 *
 * todo разобрать маркеры видимости/отключения
 */
export default {
  name: "ViewTile",
  mixins: [viewTypeMixin],
  data() {
    return {
      showInfo: false,
      isVisible: false,
      isDisabled: false,
      cameraScreenshotSizes: CAMERA_SCREENSHOT_SIZES,
    };
  },
  methods: {
    /**
     * Клик по скриншоту вызывает действия в зависимости от режима работы компонента.
     */
    clickOnScreen() {
      this.selectCamera();
      !this.selectableOnlyMode && this.play(YM_GOAL_PLAY_VIA_TILE);
    },
    /**
     * todo
     *
     * @param {Boolean} isVisible
     */
    visibilityChanged(isVisible) {
      this.isVisible = this.isVisible || isVisible;
    },
  },
};
</script>

<style lang="scss" scoped>
.cams-container__selected-camera {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  opacity: 1;
  z-index: 0;
  cursor: pointer;
}

.cams-container__selected-camera-message {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #fff;

  & svg {
    width: 30px;
    height: 30px;
    fill: #fff;
  }

  div {
    text-align: center;
  }
}
</style>
