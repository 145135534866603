/**
 * Общие данные и методы для компонентов карты.
 */

import {TOKEN_TTL, VENDOR_IS_UFANET} from "@/utils/consts.js";
import {YM_GOAL_PLAY_VIA_MAP} from "@/utils/tracking.js";
import {ACTION_LOAD_CAMERA_FOR_PLAYER} from "@/store/cameras/index.js";
import {QUERY_KEY_ONE_SCREEN_CAMERA_NUMBER} from "@/router/queryKeys.js";

/**
 * Общие свойства и методы для запуска плеера из карты.
 */
export const runPlayerMixin = {
  methods: {
    /**
     * Воспроизведение прямой трансляции.
     *
     * @param {String} cameraNumber
     */
    async play(cameraNumber) {
      VENDOR_IS_UFANET && this.$metrika?.reachGoal(YM_GOAL_PLAY_VIA_MAP);
      const cameraInfo = await this.$store.cache.dispatch(`cameras/${ACTION_LOAD_CAMERA_FOR_PLAYER}`, {
        number: cameraNumber,
        tokenLiveTTL: TOKEN_TTL.PLAYER,
        tokenDVRTTL: TOKEN_TTL.PLAYER,
      });
      if (!cameraInfo) {
        this.$camsdals.alert("Невозможно открыть камеру");
      }
      this.$router.push({
        name: this.$route.name,
        query: {
          ...(this.$route.query || {}),
          [QUERY_KEY_ONE_SCREEN_CAMERA_NUMBER]: cameraInfo.number
        }
      });
    },
  },
};
