import map_mixin from "@/store/map/mapMixin.js";
import {MAP_TARGET} from "@/utils/consts.js";

let mapPub = Object.assign({}, map_mixin);
mapPub.state = {
  cameras: {},
  refreshDataTimerFlag: false,
  target: MAP_TARGET.pub,
};

export default mapPub;
