/**
 * Отдельный vuex модуль для взаимодействия по mosaics-API
 */

import {ACTION_LOAD_CAMERAS_MY, FIELDS_CAMERA} from "@/store/cameras/index.js";

// actions для mosaics. Вызов действия начинать с "mosaics/"
export const ACTION_LOAD_MOSAICS = "LOAD_MOSAICS";
export const ACTION_LOAD_MOSAIC = "LOAD_MOSAIC";
export const ACTION_SAVE_MOSAIC = "SAVE_MOSAIC";
export const ACTION_DELETE_MOSAIC = "DELETE_MOSAIC";
export const ACTION_GET_ANALYTIC_SSE_URL = "GET_ANALYTIC_SSE_URL";

/**
 * Перечисление констант с названиями полей, разрешенных к использованию через API.
 */
export const FIELDS_MOSAIC = Object.freeze({
  id: "id",
  title: "title",
  grid: "grid",
  slots: "slots",
  realtime: "realtime",
  alarm_cameras: "alarm_cameras",
});

/**
 * Класс для представления информации о мозаике.
 */
export class MosaicInfo {
  constructor(rawMosaicInfo) {
    this.id = rawMosaicInfo[FIELDS_MOSAIC.id];
    this.title = rawMosaicInfo[FIELDS_MOSAIC.title];
    this.grid = rawMosaicInfo[FIELDS_MOSAIC.grid];
    this.slots = rawMosaicInfo[FIELDS_MOSAIC.slots] || [];
    this.lowLatencyMode = Boolean(rawMosaicInfo[FIELDS_MOSAIC.realtime]);
    // Массив номеров камер для отображения в мозаике. Позиция в массиве определяет позицию в сетке.
    this.viewCameraNumbers = [];
    // Список с индексами ячеек в которых должен быть функционал тревожных ячеек.
    this.alarmSlots = [];
    this.slots.forEach(({number, alarm}, slotIndex) => {
      this.viewCameraNumbers.push(number);
      if (alarm) {
        this.alarmSlots.push(slotIndex);
      }
    });
    // Массив номеров камер по которым будут работать тревожные ячейки.
    this.alarmCameraNumbers = rawMosaicInfo[FIELDS_MOSAIC.alarm_cameras] || [];
  }
}

/**
 * Перечисление доступных сеток для мозаик.
 */
export const GRIDS_MOSAICS = Object.freeze({
  "GRID1": "GRID1",
  "GRID4": "GRID4",
  "GRID5": "GRID5",
  "GRID6": "GRID6",
  "GRID8": "GRID8",
  "GRID9": "GRID9",
  "GRID10": "GRID10",
  "GRID16": "GRID16",
});

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * Загрузка списка мозаик.
     *
     * @param {Object} context
     * @param {Array} mosaicIds
     * @return {Promise.<Array.<MosaicInfo>>}
     */
    async [ACTION_LOAD_MOSAICS](context, {mosaicIds = null}) {
      const response = await this.getters.ajaxClient.post("/v0/mosaics/", {ids: mosaicIds});
      if (!response.data.count) {
        return [];
      }
      return response.data.results.map(rawMosaicInfo => new MosaicInfo(rawMosaicInfo));
    },
    /**
     * Загрузка одной мозаики.
     *
     * @param {Function} dispatch
     * @param {Number} mosaicId
     * @return {Promise.<MosaicInfo>|null}
     */
    async [ACTION_LOAD_MOSAIC]({dispatch}, mosaicId) {
      const listMosaicsInfo = await dispatch(ACTION_LOAD_MOSAICS, {mosaicIds: [mosaicId]});
      return _.head(listMosaicsInfo) || null;
    },
    /**
     * Отправка данных для создания новой мозаики.
     *
     * @param {Object} context
     * @param {Number|null} mosaicId
     * @param {String} mosaicTitle
     * @param {String} mosaicGrid
     * @param {Array.<String>} viewCameraNumbers
     * @param {Boolean} lowLatencyMode
     * @param {Array.<String>} alarmCameraNumbers
     * @param {Array.<Number>} alarmSlots
     * @return {Promise}
     */
    async [ACTION_SAVE_MOSAIC](context, {mosaicId, mosaicTitle, mosaicGrid, viewCameraNumbers, lowLatencyMode, alarmCameraNumbers, alarmSlots}) {
      let slots = [{"number": null}];
      if (!_.isEmpty(viewCameraNumbers)) {
        slots = [];
        for (let slotIndex = 0; slotIndex < viewCameraNumbers.length; slotIndex++) {
          slots.push({number: viewCameraNumbers[slotIndex], alarm: alarmSlots.includes(slotIndex)});
        }
      }

      try {
        const response = await this.getters.ajaxClient.post(mosaicId ? "/v0/mosaics/edit/" : "/v0/mosaics/create/", {
          ...(mosaicId ? {[FIELDS_MOSAIC.id]: mosaicId} : {}),
          [FIELDS_MOSAIC.title]: mosaicTitle,
          [FIELDS_MOSAIC.grid]: mosaicGrid,
          [FIELDS_MOSAIC.slots]: slots,
          [FIELDS_MOSAIC.realtime]: lowLatencyMode,
          [FIELDS_MOSAIC.alarm_cameras]: alarmCameraNumbers,
        });
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Удаление существующей мозаики.
     *
     * @param {Object} context
     * @param {Number} mosaicId
     * @return {Promise.<Boolean>}
     */
    async [ACTION_DELETE_MOSAIC](context, {mosaicId}) {
      try {
        await this.getters.ajaxClient.post("/v0/mosaics/delete/", {id: mosaicId});
        return true;
      } catch {
        throw false;
      }
    },
    /**
     * Вернет URL для использования Server Sent Events через механизм EventSource.
     * Для URL так же необходим токен доступа к прямой трансляции который берется с любых камер пользователя.
     *
     * По идее достаточно запросить токен у 1 камеры из принадлежащих пользователю, однако
     * существует опасность что такая камера может быть отключена и токен по ней не создастся.
     * Вариант запросить много камер и взять первый настоящий токен в возвращаемом наборе камер.
     * Хотя и в таком случае может не оказаться живой камеры, но мы уже этот случай не рассматриваем,
     * поскольку сама идея с токеном для SSE мозаики не продумана - нет специального токена для нее, а вместо него используется токен камеры.
     *
     * @param {Function} dispatch
     * @param {Number} mosaicId
     * @return {Promise.<String>}
     */
    async [ACTION_GET_ANALYTIC_SSE_URL]({dispatch}, mosaicId) {
      if (!mosaicId) {
        return null;
      }

      let tokenLive;
      try {
        /** @type CameraInfo */
        const [listCamerasInfo, ,] = await dispatch(`cameras/${ACTION_LOAD_CAMERAS_MY}`, {
            fields: [FIELDS_CAMERA.token_l],
            tokenLiveTTL: 600,
            page: 1,
            pageSize: 120
          }, {root: true}),
          cameraInfoWithTokenLive = listCamerasInfo.find(cameraInfo => cameraInfo.tokenLive);
        tokenLive = cameraInfoWithTokenLive.tokenLive;
      } catch {
        return null;
      }

      return `//${this.state.context.defaultPushDomain}/api/v0/analytics/push/mosaics/${mosaicId}/?token=${tokenLive}`;
    },
  },
};
