/**
 * Модуль vuex для объединения группы функций взаимодействующих с API аналитических настроек.
 */

import thermalVisions, {ACTION_LOAD_THERMAL_VISION_EVENT} from "@/store/analytics/thermalVisions/index.js";
import carNumbers, {ACTION_LOAD_CAR_NUMBER_EVENT} from "@/store/analytics/carNumbers/index.js";
import faceRecognitions, {ACTION_LOAD_FACE_RECOGNITION_EVENT} from "@/store/analytics/faceRecognitions/index.js";
import helmets, {ACTION_LOAD_HELMET_EVENT} from "@/store/analytics/helmets/index.js";
import masks, {ACTION_LOAD_MASK_EVENT} from "@/store/analytics/masks/index.js";
import crowds, {ACTION_LOAD_CROWD_EVENT} from "@/store/analytics/crowds/index.js";
import perimeterSecuritys, {ACTION_LOAD_PERIMETER_SECURITY_EVENT} from "@/store/analytics/perimeterSecurity/index.js";
import motionAlarms from "@/store/analytics/motionAlarms/index.js";
import peopleCount,{ACTION_LOAD_PEOPLE_COUNT_EVENT} from "@/store/analytics/peopleCount/index.js";
import makeMessage, {
  AnalyticMessagesChunk,
  CarNumberMessage,
  FaceRecognitionMessage,
  ThermalVisionMessage,
  HelmetMessage,
  MaskMessage,
  CrowdMessage,
  PerimeterSecurityMessage,
  PeopleCountMessage,
} from "@/store/analytics/analyticMessage.js";
import {DEFAULT_PAGE_SIZE_FOR_TABLE, makeFilterApi, SPECIAL_TYPES_DATA_IN_CELLS} from "@/store/pacs/helpers.js";
// actions для общих запросов данных по аналитикам. Вызов действия начинать с "analytics/"
export const ACTION_LOAD_ARCHIVE_EVENTS_BY_CAMERA = "LOAD_ARCHIVE_EVENTS_BY_CAMERA";
export const ACTION_LOAD_ARCHIVE_EVENTS_BY_CAMERAS = "LOAD_ARCHIVE_EVENTS_BY_CAMERAS";
export const ACTION_LOAD_ARCHIVE_EVENTS_CHUNKS = "LOAD_ARCHIVE_EVENTS_CHUNKS";
export const ACTION_LOAD_ARCHIVE_EVENTS_CHUNKS_FOR_MULTI = "LOAD_ARCHIVE_EVENTS_CHUNKS_FOR_MULTI";
export const ACTION_LOAD_FULL_SCREENSHOT_EVENT = "LOAD_FULL_SCREENSHOT_EVENT";
export const ACTION_EDIT_CAR_NUMBER_ANALYTICS = "EDIT_CAR_NUMBER_ANALYTICS";
export const ACTION_LOAD_CAR_NUMBER_ANALYTICS = "LOAD_CAR_NUMBER_ANALYTICS";
export const ACTION_LOAD_CAR_NUMBER_ANALYTICS_FOR_TABLE = "LOAD_CAR_NUMBER_ANALYTICS_FOR_TABLE";
export const ACTION_LOAD_CAR_NUMBER_ANALYTICS_FOR_EDIT = "LOAD_CAR_NUMBER_ANALYTICS_FOR_EDIT";
export const ACTION_EDIT_HELMET_ANALYTICS = "EDIT_HELMET_ANALYTICS";
export const ACTION_LOAD_HELMET_ANALYTICS = "LOAD_HELMET_ANALYTICS";
export const ACTION_LOAD_HELMET_ANALYTICS_FOR_TABLE = "LOAD_HELMET_ANALYTICS_FOR_TABLE";
export const ACTION_LOAD_HELMET_ANALYTICS_FOR_EDIT = "LOAD_HELMET_ANALYTICS_FOR_EDIT";
export const ACTION_EDIT_MASK_ANALYTICS = "EDIT_MASK_ANALYTICS";
export const ACTION_LOAD_MASK_ANALYTICS = "LOAD_MASK_ANALYTICS";
export const ACTION_LOAD_MASK_ANALYTICS_FOR_TABLE = "LOAD_MASK_ANALYTICS_FOR_TABLE";
export const ACTION_LOAD_MASK_ANALYTICS_FOR_EDIT = "LOAD_MASK_ANALYTICS_FOR_EDIT";
export const ACTION_EDIT_CROWD_ANALYTICS = "EDIT_CROWD_ANALYTICS";
export const ACTION_LOAD_CROWD_ANALYTICS = "LOAD_CROWD_ANALYTICS";
export const ACTION_LOAD_CROWD_ANALYTICS_FOR_TABLE = "LOAD_CROWD_ANALYTICS_FOR_TABLE";
export const ACTION_LOAD_CROWD_ANALYTICS_FOR_EDIT = "LOAD_CROWD_ANALYTICS_FOR_EDIT";
export const ACTION_EDIT_PERIMETER_SECURITY_ANALYTICS = "EDIT_PERIMETER_SECURITY_ANALYTICS";
export const ACTION_LOAD_PERIMETER_SECURITY_ANALYTICS = "LOAD_PERIMETER_SECURITY_ANALYTICS";
export const ACTION_LOAD_PERIMETER_SECURITY_ANALYTICS_FOR_TABLE = "LOAD_PERIMETER_SECURITY_ANALYTICS_FOR_TABLE";
export const ACTION_LOAD_PERIMETER_SECURITY_ANALYTICS_FOR_EDIT = "LOAD_PERIMETER_SECURITY_ANALYTICS_FOR_EDIT";
export const ACTION_EDIT_PEOPLE_COUNT_ANALYTICS = "EDIT_PEOPLE_COUNT_ANALYTICS";
export const ACTION_LOAD_PEOPLE_COUNT_ANALYTICS = "LOAD_PEOPLE_COUNT_ANALYTICS";
export const ACTION_LOAD_PEOPLE_COUNT_ANALYTICS_FOR_TABLE = "LOAD_PEOPLE_COUNT_ANALYTICS_FOR_TABLE";
export const ACTION_LOAD_PEOPLE_COUNT_ANALYTICS_FOR_EDIT = "LOAD_PEOPLE_COUNT_ANALYTICS_FOR_EDIT";
export const CAMERA_ANALYTICS_STRUCT = Object.freeze({
  key: "camera_number",
  fields: {
    camera_number: {
      name: "camera_number",
      title: "Номер камеры",
      type: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
      sort: true,
    },
    min_confidence: {
      name: "min_confidence",
      title: "Порог распознавания",

    },
    image_width: {
      name: "image_width",
      title: "Ширина изображения",
    },
    image_height: {
      name: "image_height",
      title: "Высота изображения",
    },
    zones: {
      name: "zones",
      title: "Зоны",
    },
  },
});

export const CAMERA_PERIMETER_ANALYTICS_STRUCT = Object.freeze({
  key: "camera_number",
  fields: {
    camera_number: {
      name: "camera_number",
      title: "Номер камеры",
      type: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
      sort: true,
    },
    min_object_height: {
      name: "min_object_height",
      title: "Минимальная высота объекта",

    },
    min_object_width: {
      name: "min_object_width",
      title: "Минимальная ширина объекта",

    },
    image_width: {
      name: "image_width",
      title: "Ширина изображения",
    },
    image_height: {
      name: "image_height",
      title: "Высота изображения",
    },
    zones: {
      name: "zones",
      title: "Зоны",
    },
  },
});

export const CAMERA_ANALYTICS_STRUCT_CROWD = Object.freeze({
  key: "camera_number",
  fields: {
    camera_number: {
      name: "camera_number",
      title: "Номер камеры",
      type: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
      sort: true,
    },
    min_confidence: {
      name: "min_confidence",
      title: "Порог распознавания",

    },
    image_width: {
      name: "image_width",
      title: "Ширина изображения",
    },
    image_height: {
      name: "image_height",
      title: "Высота изображения",
    },
    zones: {
      name: "zones",
      title: "Зоны",
    },
    max_people_count: {
      name: "max_people_count",
      title: "Максимальное количество людей в толпе",
    },
  },
});
export const CAMERA_ANALYTICS_STRUCT_PEOPLE_COUNT = Object.freeze({
  key: "camera_number",
  fields: {
    camera_number: {
      name: "camera_number",
      title: "Номер камеры",
      type: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
      sort: true,
    },
    image_width: {
      name: "image_width",
      title: "Ширина изображения",
    },
    image_height: {
      name: "image_height",
      title: "Высота изображения",
    },
    counter_line: {
      name: "counter_line",
      title: "Линия",
    },
    enter_point: {
      name: "enter_point",
      title: "Точка входа",
    },
  },
});

export default {
  namespaced: true,
  modules: {
    thermalVisions,
    carNumbers,
    faceRecognitions,
    helmets,
    motionAlarms,
    masks,
    crowds,
    perimeterSecuritys,
    peopleCount
  },
  actions: {
    /**
     * Загрузка списка событий произошедших в определенный период по одной камере.
     * Определение типа событий происходит по ключу `type` в каждом экземпляре события.
     *
     * @param {Object} context
     * @param {String} cameraNumber
     * @param {Date} archiveFrom
     * @param {Date} archiveTo
     * @param {Number} limit
     * @param {String} orderByDate asc|desc
     * @return {Promise.<Array.<AnalyticMessage>>}
     */
    async [ACTION_LOAD_ARCHIVE_EVENTS_BY_CAMERA](context, {cameraNumber, archiveFrom, archiveTo, limit = 1000, orderByDate = "desc"}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/analytics/archive_events/", {
          camera_number: cameraNumber,
          start: archiveFrom.toISOString(),
          end: archiveTo.toISOString(),
          limit,
          order_by_date: orderByDate,
        });
        return response.data.results.map(rawEventData => makeMessage(rawEventData, cameraNumber));
      } catch {
        return [];
      }
    },
    /**
     * Загрузка списка событий произошедших в определенный период по набору камер.
     * Определение типа событий происходит по ключу `type` в каждом экземпляре события.
     *
     * @param {Object} context
     * @param {Array.<String>} cameraNumbers
     * @param {Date} archiveFrom
     * @param {Date} archiveTo
     * @param {Number} limit
     * @param {String} orderByDate asc|desc
     * @return {Promise.<Array.<AnalyticMessage>>}
     */
    async [ACTION_LOAD_ARCHIVE_EVENTS_BY_CAMERAS](context, {cameraNumbers, archiveFrom, archiveTo, limit = 1000, orderByDate = "desc"}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/analytics/archive_events_many/", {
          camera_numbers: cameraNumbers,
          start: archiveFrom.toISOString(),
          end: archiveTo.toISOString(),
          limit,
          order_by_date: orderByDate,
        });
        return response.data.results.map(rawEventData => makeMessage(rawEventData));
      } catch {
        return [];
      }
    },
    /**
     * Загрузка сгруппированных событий произошедших в определенный период.
     *
     * @param {Object} context
     * @param {String} cameraNumber
     * @param {Date} archiveFrom
     * @param {Date} archiveTo
     * @param {String} orderByDate asc|desc
     * @return {Promise.<Array.<AnalyticMessagesChunk>>}
     */
    async [ACTION_LOAD_ARCHIVE_EVENTS_CHUNKS](context, {cameraNumber, archiveFrom, archiveTo, orderByDate = "desc"}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/analytics/archive_event_chunks/", {
          camera_number: cameraNumber,
          start: archiveFrom.toISOString(),
          end: archiveTo.toISOString(),
          order_by_date: orderByDate,
        });
        return response.data.results.map(rawAnalyticMessagesChunk => new AnalyticMessagesChunk(rawAnalyticMessagesChunk));
      } catch {
        return [];
      }
    },
    /**
     * Загрузка сгруппированных событий произошедших в определенный период по нескольким камерам.
     *
     * @param {Object} context
     * @param {Array.<String>} cameraNumbers
     * @param {Date} archiveFrom
     * @param {Date} archiveTo
     * @param {String} orderByDate asc|desc
     * @return {Promise.<Array.<AnalyticMessagesChunk>>}
     */
    async [ACTION_LOAD_ARCHIVE_EVENTS_CHUNKS_FOR_MULTI](context, {cameraNumbers, archiveFrom, archiveTo, orderByDate = "desc"}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/analytics/archive_event_chunks_many/", {
          camera_numbers: cameraNumbers,
          start: archiveFrom.toISOString(),
          end: archiveTo.toISOString(),
          order_by_date: orderByDate,
        });
        return response.data.results.map(rawAnalyticMessagesChunk => new AnalyticMessagesChunk(rawAnalyticMessagesChunk));
      } catch {
        return [];
      }
    },
    /**
     * Получение скриншота из сообщения события по камере.
     * Для каждого типа аналитики используется свой action для загрузки полной информации о событии.
     * Если аналитика не предусматривает наличие скриншота - вернется пустая строка.
     *
     * @param {Function} dispatch
     * @param {AnalyticMessage} analyticMessage
     * @param {String} screenshotToken
     * @return {Promise.<String>}
     */
    async [ACTION_LOAD_FULL_SCREENSHOT_EVENT]({dispatch}, {analyticMessage, screenshotToken}) {
      let fullScreenshot = "",
        actionLoadEventWithScreenshot;

      if (analyticMessage instanceof CarNumberMessage) {
        fullScreenshot = analyticMessage.getFullScreenshotUrl(screenshotToken);
        actionLoadEventWithScreenshot = `carNumbers/${ACTION_LOAD_CAR_NUMBER_EVENT}`;
      }
      if (analyticMessage instanceof ThermalVisionMessage) {
        fullScreenshot = analyticMessage.getFullScreenshotUrl(screenshotToken);
        actionLoadEventWithScreenshot = `thermalVisions/${ACTION_LOAD_THERMAL_VISION_EVENT}`;
      }
      if (analyticMessage instanceof FaceRecognitionMessage) {
        fullScreenshot = analyticMessage.getFullScreenshotUrl(screenshotToken);
        actionLoadEventWithScreenshot = `faceRecognitions/${ACTION_LOAD_FACE_RECOGNITION_EVENT}`;
      }
      if (analyticMessage instanceof HelmetMessage) {
        fullScreenshot = analyticMessage.getFullScreenshotUrl(screenshotToken);
        actionLoadEventWithScreenshot = `helmets/${ACTION_LOAD_HELMET_EVENT}`;
      }
      if (analyticMessage instanceof MaskMessage) {
        fullScreenshot = analyticMessage.getFullScreenshotUrl(screenshotToken);
        actionLoadEventWithScreenshot = `masks/${ACTION_LOAD_MASK_EVENT}`;
      }
      if (analyticMessage instanceof PerimeterSecurityMessage) {
        fullScreenshot = analyticMessage.getFullScreenshotUrl(screenshotToken);
        actionLoadEventWithScreenshot = `perimeterSecuritys/${ACTION_LOAD_PERIMETER_SECURITY_EVENT}`;
      }
      if (analyticMessage instanceof CrowdMessage) {
        fullScreenshot = analyticMessage.getFullScreenshotUrl(screenshotToken);
        actionLoadEventWithScreenshot = `crowds/${ACTION_LOAD_CROWD_EVENT}`;
      }
      if (analyticMessage instanceof PeopleCountMessage) {
        fullScreenshot = analyticMessage.getFullScreenshotUrl(screenshotToken);
        actionLoadEventWithScreenshot = `peopleCount/${ACTION_LOAD_PEOPLE_COUNT_EVENT}`;
      }
      if (fullScreenshot || !actionLoadEventWithScreenshot) {
        return fullScreenshot;
      }

      const analyticMessageWithScreenshot = await dispatch(actionLoadEventWithScreenshot, {
        cameraNumber: analyticMessage.cameraNumber,
        eventId: analyticMessage.id
      });
      if (analyticMessageWithScreenshot) {
        return analyticMessageWithScreenshot.getFullScreenshotUrl(screenshotToken);
      }
      return "";
    },
    //CAR_NUMBER -------------------------------------------------------------------------------------------------
    /**
     * Загрузка списка камер с аналитикой распознавания номеров.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_CAR_NUMBER_ANALYTICS](context, {page, pageSize, orderBy, filters, search}) {
      const fields = Object.keys(CAMERA_ANALYTICS_STRUCT.fields),
        response = await this.getters.ajaxClient.post("/v0/analytics/car_number/", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          search,
        });
      return response.data;

    },
    /**
     * Загрузка списка камер с распознаванием номеров для отображения в таблице.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_CAR_NUMBER_ANALYTICS_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy = [], filters = [], search = ""}) {
      return dispatch(ACTION_LOAD_CAR_NUMBER_ANALYTICS, {page, pageSize, orderBy, filters, search});
    },

    /**
     * Редактирование существующей настройки аналитики распознавания номеров.
     *
     * @param {Object} context
     * @param {String} cameraNumber
     * @param {Number} minConfidence
     * @return {Promise}
     */
    async [ACTION_EDIT_CAR_NUMBER_ANALYTICS](context, {cameraNumber,minConfidence,imageWidth, imageHeight, zones}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/analytics/car_number/edit/",
          {
            camera_number: cameraNumber,
            min_confidence: minConfidence,
            image_width: imageWidth,
            image_height: imageHeight,
            zones:zones,
          } );
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Загрузка экземпляра для редактирования.
     *
     * @param {Function} dispatch
     * @param {String} cameraNumber
     * @return {Promise}
     */
    async [ACTION_LOAD_CAR_NUMBER_ANALYTICS_FOR_EDIT]({dispatch}, cameraNumber) {
      const responseData = await dispatch(ACTION_LOAD_CAR_NUMBER_ANALYTICS, {
        page: 1,
        pageSize: 1,
        filters: [makeFilterApi(CAMERA_ANALYTICS_STRUCT.key, "=", cameraNumber)]
      });
      return {entityInfo: responseData.results[0]};
    },
    //HELMET -------------------------------------------------------------------------------------------------
    /**
     * Загрузка списка камер с аналитикой распознавания касок.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_HELMET_ANALYTICS](context, {page, pageSize, orderBy, filters, search}) {
      const fields = Object.keys(CAMERA_ANALYTICS_STRUCT.fields),
        response = await this.getters.ajaxClient.post("/v0/analytics/helmet/", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          search,
        });
      return response.data;

    },
    /**
     * Загрузка списка камер с касками для отображения в таблице.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_HELMET_ANALYTICS_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy = [], filters = [], search = ""}) {
      return dispatch(ACTION_LOAD_HELMET_ANALYTICS, {page, pageSize, orderBy, filters, search});
    },

    /**
     * Редактирование существующей настройки аналитики по каскам.
     *
     * @param {Object} context
     * @param {String} cameraNumber
     * @param {Number} minConfidence
     * @return {Promise}
     */
    async [ACTION_EDIT_HELMET_ANALYTICS](context, {cameraNumber,minConfidence,imageWidth, imageHeight, zones}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/analytics/helmet/edit/",
          {
            camera_number: cameraNumber,
            min_confidence: minConfidence,
            image_width: imageWidth,
            image_height: imageHeight,
            zones:zones,
          } );
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Загрузка экземпляра для редактирования.
     *
     * @param {Function} dispatch
     * @param {String} cameraNumber
     * @return {Promise}
     */
    async [ACTION_LOAD_HELMET_ANALYTICS_FOR_EDIT]({dispatch}, cameraNumber) {
      const responseData = await dispatch(ACTION_LOAD_HELMET_ANALYTICS, {
        page: 1,
        pageSize: 1,
        filters: [makeFilterApi(CAMERA_ANALYTICS_STRUCT.key, "=", cameraNumber)]
      });
      return {entityInfo: responseData.results[0]};
    },
    //MASK -------------------------------------------------------------------------------------------------
    /**
     * Загрузка списка камер с аналитикой распознавания масок.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_MASK_ANALYTICS](context, {page, pageSize, orderBy, filters, search}) {
      const fields = Object.keys(CAMERA_ANALYTICS_STRUCT.fields),
        response = await this.getters.ajaxClient.post("/v0/analytics/mask/", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          search,
        });
      return response.data;

    },
    /**
     * Загрузка списка камер с масками для отображения в таблице.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_MASK_ANALYTICS_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy = [], filters = [], search = ""}) {
      return dispatch(ACTION_LOAD_MASK_ANALYTICS, {page, pageSize, orderBy, filters, search});
    },

    /**
     * Редактирование существующей настройки аналитики по маскам.
     *
     * @param {Object} context
     * @param {String} cameraNumber
     * @param {Number} minConfidence
     * @return {Promise}
     */
    async [ACTION_EDIT_MASK_ANALYTICS](context, {cameraNumber,minConfidence,imageWidth, imageHeight, zones}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/analytics/mask/edit/",
          {
            camera_number: cameraNumber,
            min_confidence: minConfidence,
            image_width: imageWidth,
            image_height: imageHeight,
            zones:zones,
          } );
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Загрузка экземпляра для редактирования.
     *
     * @param {Function} dispatch
     * @param {String} cameraNumber
     * @return {Promise}
     */
    async [ACTION_LOAD_MASK_ANALYTICS_FOR_EDIT]({dispatch}, cameraNumber) {
      const responseData = await dispatch(ACTION_LOAD_MASK_ANALYTICS, {
        page: 1,
        pageSize: 1,
        filters: [makeFilterApi(CAMERA_ANALYTICS_STRUCT.key, "=", cameraNumber)]
      });
      return {entityInfo: responseData.results[0]};
    },
    //CROWD -------------------------------------------------------------------------------------------------
    /**
     * Загрузка списка камер с аналитикой распознавания толпы.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_CROWD_ANALYTICS](context, {page, pageSize, orderBy, filters, search}) {
      const fields = Object.keys(CAMERA_ANALYTICS_STRUCT_CROWD.fields),
        response = await this.getters.ajaxClient.post("/v0/analytics/crowd/", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          search,
        });
      return response.data;

    },
    /**
     * Загрузка списка камер с толпой для отображения в таблице.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_CROWD_ANALYTICS_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy = [], filters = [], search = ""}) {
      return dispatch(ACTION_LOAD_CROWD_ANALYTICS, {page, pageSize, orderBy, filters, search});
    },

    /**
     * Редактирование существующей настройки аналитики по толпе.
     *
     * @param {Object} context
     * @param {String} cameraNumber
     * @param {Number} minConfidence
     * @return {Promise}
     */
    async [ACTION_EDIT_CROWD_ANALYTICS](context, {cameraNumber, minConfidence, imageWidth, imageHeight, zones}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/analytics/crowd/edit/",
          {
            camera_number: cameraNumber,
            min_confidence: minConfidence,
            image_width: imageWidth,
            image_height: imageHeight,
            zones:zones,
          } );
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Загрузка экземпляра для редактирования.
     *
     * @param {Function} dispatch
     * @param {String} cameraNumber
     * @return {Promise}
     */
    async [ACTION_LOAD_CROWD_ANALYTICS_FOR_EDIT]({dispatch}, cameraNumber) {
      const responseData = await dispatch(ACTION_LOAD_CROWD_ANALYTICS, {
        page: 1,
        pageSize: 1,
        filters: [makeFilterApi(CAMERA_ANALYTICS_STRUCT.key, "=", cameraNumber)]
      });
      return {entityInfo: responseData.results[0]};
    },
    //PERIMETER_SECURITY -------------------------------------------------------------------------------------------------
    /**
     * Загрузка списка камер с аналитикой вторжения в зону.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_PERIMETER_SECURITY_ANALYTICS](context, {page, pageSize, orderBy, filters, search}) {
      const fields = Object.keys(CAMERA_PERIMETER_ANALYTICS_STRUCT.fields),
        response = await this.getters.ajaxClient.post("/v0/analytics/perimeter_security/", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          search,
        });
      return response.data;

    },
    /**
     * Загрузка списка камер с периметром для отображения в таблице.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_PERIMETER_SECURITY_ANALYTICS_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy = [], filters = [], search = ""}) {
      return dispatch(ACTION_LOAD_PERIMETER_SECURITY_ANALYTICS, {page, pageSize, orderBy, filters, search});
    },

    /**
     * Редактирование существующей настройки аналитики по вторжению в периметер.
     *
     * @param {Object} context
     * @param {String} cameraNumber
     * @param {Number} minConfidence
     * @return {Promise}
     */
    async [ACTION_EDIT_PERIMETER_SECURITY_ANALYTICS](context, {cameraNumber,minHeight,minWidth,imageWidth,imageHeight, zones}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/analytics/perimeter_security/edit/",
          {
            camera_number: cameraNumber,
            min_object_height: minHeight,
            min_object_width: minWidth,
            image_width: imageWidth,
            image_height: imageHeight,
            zones:zones,
          } );
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Загрузка экземпляра для редактирования.
     *
     * @param {Function} dispatch
     * @param {String} cameraNumber
     * @return {Promise}
     */
    async [ACTION_LOAD_PERIMETER_SECURITY_ANALYTICS_FOR_EDIT]({dispatch}, cameraNumber) {
      const responseData = await dispatch(ACTION_LOAD_PERIMETER_SECURITY_ANALYTICS, {
        page: 1,
        pageSize: 1,
        filters: [makeFilterApi(CAMERA_PERIMETER_ANALYTICS_STRUCT.key, "=", cameraNumber)]
      });
      return {entityInfo: responseData.results[0]};
    },
    //PEOPLE_COUNT -------------------------------------------------------------------------------------------------
    /**
     * Загрузка списка камер с аналитикой подсчета посетителей.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_PEOPLE_COUNT_ANALYTICS](context, {page, pageSize, orderBy, filters, search}) {
      const fields = Object.keys(CAMERA_ANALYTICS_STRUCT_PEOPLE_COUNT.fields),
        response = await this.getters.ajaxClient.post("/v0/analytics/people_counter/", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          search,
        });
      return response.data;

    },
    /**
     * Загрузка списка камер с подсчетом посетителей для отображения в таблице.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_PEOPLE_COUNT_ANALYTICS_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy = [], filters = [], search = ""}) {
      return dispatch(ACTION_LOAD_PEOPLE_COUNT_ANALYTICS, {page, pageSize, orderBy, filters, search});
    },

    /**
     * Редактирование существующей настройки аналитики по подсчету посетителей.
     *
     * @param {Object} context
     * @param {String} cameraNumber
     * @param {Number} minConfidence
     * @return {Promise}
     */
    async [ACTION_EDIT_PEOPLE_COUNT_ANALYTICS](context, {cameraNumber,imageWidth,imageHeight, counterLine, enterPoint}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/analytics/people_counter/edit/",
          {
            camera_number: cameraNumber,
            image_width: imageWidth,
            image_height: imageHeight,
            counter_line: counterLine,
            enter_point: enterPoint
          } );
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Загрузка экземпляра для редактирования.
     *
     * @param {Function} dispatch
     * @param {String} cameraNumber
     * @return {Promise}
     */
    async [ACTION_LOAD_PEOPLE_COUNT_ANALYTICS_FOR_EDIT]({dispatch}, cameraNumber) {
      const responseData = await dispatch(ACTION_LOAD_PEOPLE_COUNT_ANALYTICS, {
        page: 1,
        pageSize: 1,
        filters: [makeFilterApi(CAMERA_ANALYTICS_STRUCT_PEOPLE_COUNT.key, "=", cameraNumber)]
      });
      return {entityInfo: responseData.results[0]};
    },
  },
};
