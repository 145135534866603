<template>
  <div class="multi-live-events">
    <component
      :is="analyticMessageViewComponents[message.analyticType]"
      v-for="(message, messageIndex) in messages"
      :key="messageIndex"
      :analytic-message="message"
      :show-camera-info="true"
      class="multi-live-events__analytic-message"
    />
  </div>
</template>

<script>
import {analyticMessageViewMixin, messageSoundNotificationMixin,} from "@/components/events/mixins.js";
import SSEWorker from "@/store/analytics/sse.js";
import {DEFAULT_SHOW_EVENT_TTL} from "@/utils/consts.js";
import {loadCameraInfoForMultiMixin} from "@/components/multiScreen/mixins.js";

/**
 * Компонент для отображения списка событий (сообщений), получаемых через Server Sent Events (SSE).
 * Применяется для отображения событий аналитики на мультиэкране.
 */
export default {
  name: "MultiLiveEvents",
  mixins: [
    messageSoundNotificationMixin,
    loadCameraInfoForMultiMixin,
    analyticMessageViewMixin,
  ],
  props: {
    /**
     * Экземпляр {@link CameraInfo} по которому отображается информация в компоненте.
     */
    sseUrl: {
      type: String,
      required: true
    },
    /**
     * Ограничение для количества отображаемых сообщений. Для избежания перегрузки DOM бесполезной информацией.
     *
     */
    messagesLimit: {
      type: Number,
      default: 1000,
    },
  },
  data() {
    return {
      registeredWorker: null,
      messages: [],
    };
  },
  watch: {
    /**
     * Наблюдение за URL для периодического обновления связи с каналом событий.
     */
    sseUrl() {
      this.registeredWorker.close();
      this.registeredWorker = new SSEWorker(this.sseUrl, this.onNewMessage);
    },
  },
  /**
   * Создание соединения с сервером и регистрация обработчиков событий.
   */
  mounted() {
    this.registeredWorker = new SSEWorker(this.sseUrl, this.onNewMessage);
  },
  /**
   * Удаление обработчиков событий и закрытие соединений.
   */
  beforeDestroy() {
    this.registeredWorker.close();
  },
  methods: {
    /**
     * Срабатывает в момент получения нового сообщения аналитики.
     * Регистрирует новые сообщения, удаляет старые, вышедшее за рамки установленного лимита.
     * Извещает об этом родителя по событию `@new-message`.
     * Устанавливает таймер на автоматическое удаление сообщений по прошествии времени = их длительности.
     *
     * @param {AnalyticMessage} analyticMessage
     */
    async onNewMessage(analyticMessage) {
      analyticMessage.cameraInfo = await this.loadCameraInfo(analyticMessage.cameraNumber);
      this.messages.unshift(analyticMessage);
      this.$emit("new-message", analyticMessage);

      this.soundNotification();

      if (this.messages.length > this.messagesLimit) {
        this.messages.splice(this.messagesLimit + 1);
      } else {
        if (analyticMessage) {
          setTimeout(() => {
            this.deleteMessage(analyticMessage);
          }, DEFAULT_SHOW_EVENT_TTL);
        }
      }
    },
    /**
     * Удаление сообщения из списка.
     *
     * @param {AnalyticMessage} analyticMessage
     */
    deleteMessage(analyticMessage) {
      this.messages.splice(this.messages.indexOf(analyticMessage), 1);
    },
  },
};
</script>

<style lang="scss">
@import "camsng-frontend-shared/styles/vars.scss";

.multi-live-events {
  overflow-y: hidden;
  overflow-x: hidden;
  width: 343px;

  position: fixed;
  bottom: 10px;
  right: 10px;
  margin: 5px;

  display: flex;
  justify-content: flex-start;
  flex-direction: column-reverse;
  gap: 4px;

  &__analytic-message {
    border: 1px solid $cams-color-alto;
    cursor: pointer;

    //&:hover {
    //  background: url(../../../assets/img/icons/close.svg) no-repeat center, linear-gradient(0deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1));
    //
    //  &:after {
    //    content: '';
    //    position: absolute;
    //    top: 0;
    //    left: 0;
    //    right: 0;
    //    bottom: 0;
    //    width: 100%;
    //    height: 100%;
    //  }
    //}
  }
}
</style>