/**
 * Отдельный vuex модуль для взаимодействия по devices-API
 */
import {
  DEFAULT_PAGE_SIZE_FOR_SELECT,
  DEFAULT_PAGE_SIZE_FOR_TABLE,
  getExtraApi,
  makeFilterApi,
  SPECIAL_TYPES_DATA_IN_CELLS
} from "@/store/pacs/helpers.js";

// actions для devices. Вызов действия начинать с "pacs/devices/"
export const ACTION_LOAD_DEVICES = "LOAD_DEVICES";
export const ACTION_LOAD_DEVICES_FOR_TABLE = "LOAD_DEVICES_FOR_TABLE";
export const ACTION_LOAD_DEVICES_FOR_SELECT = "LOAD_DEVICES_FOR_SELECT";
export const ACTION_LOAD_DEVICE_FOR_EDIT = "LOAD_DEVICE_FOR_EDIT";
export const ACTION_LOAD_DEVICES_TO_CAMERAS = "LOAD_DEVICES_TO_CAMERAS";

/**
 * Структура для общения с API по конкретной сущности.
 */
export const DEVICE_STRUCT = Object.freeze({
  key: "id",
  fields: {
    id: {
      name: "id",
      title: "ID",
      type: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
      sort: true,
    },
    ident: {
      name: "ident",
      title: "Идентификатор",
      sort: true,
    },
    title: {
      name: "title",
      title: "Название",
      type: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
      sort: true,
    },
    emergency_vehicle_access: {
      name: "emergency_vehicle_access",
      title: "Доступ спецтранспорта",
      type: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
      sort: true,
    },
    camera_count: {
      name: "camera_count",
      title: "Кол-во камер",
      extra: "camera",
    },
    camera_numbers: {
      name: "camera_numbers",
      title: "Камеры",
      type: SPECIAL_TYPES_DATA_IN_CELLS.ARRAY,
      extra: "camera",
    },
    is_active: {
      name: "is_active",
      title: "Активна?",
      type: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
      sort: true,
    },
  },
  extras: {
    camera: "camera",
  },
});

export const DEVICES_TO_CAMERAS_STRUCT = Object.freeze({
  key: "device_id",
  fields: {
    device_id: {
      name: "device_id",
      title: "Устройство",
      type: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
      extra: "device",
    },
    camera_number: {
      name: "camera_number",
      title: "Камера",
      type: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
      extra: "camera",
    },
    camera_direction: {
      name: "camera_direction",
      title: "Направление камеры",
    },
  },
  extras: {
    device: "device",
    camera: "camera",
  },
});

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * Загрузка списка устройств.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_DEVICES](context, {page, pageSize, orderBy, filters, search}) {
      const fields = Object.keys(DEVICE_STRUCT.fields),
        response = await this.getters.ajaxClient.post("/v0/devices/", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          search,
          extra: getExtraApi(DEVICE_STRUCT, fields)
        });
      return response.data;
    },
    /**
     * Загрузка списка устройств для отображения в таблице.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_DEVICES_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy = [], search = ""}) {
      return dispatch(ACTION_LOAD_DEVICES, {page, pageSize, orderBy, search});
    },
    /**
     * Загрузка списка устройств для отображения в селекте.
     *
     * @param {Function} dispatch
     * @param {Number} pageSize
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_DEVICES_FOR_SELECT]({dispatch}, {pageSize = DEFAULT_PAGE_SIZE_FOR_SELECT, search}) {
      const responseData = await dispatch(ACTION_LOAD_DEVICES, {pageSize, search});
      return responseData.results;
    },
    /**
     * Загрузка одного устройства для редактирования.
     *
     * @param {Function} dispatch
     * @param {Number} deviceId
     * @return {Promise}
     */
    async [ACTION_LOAD_DEVICE_FOR_EDIT]({dispatch}, deviceId) {
      const responseData = await dispatch(ACTION_LOAD_DEVICES, {page: 1, pageSize: 1, filters: [makeFilterApi(DEVICE_STRUCT.key, "=", deviceId)]});
      return {entityInfo: responseData.results[0], extraInfo: responseData.extra};
    },
    // -----------------------------------------------------------------------------------------------------------------
    // DEVICE TO CAMERA ADD ACTIONS
    /**
     * Загрузка списка камер прикрепленных к устройствам СКУД.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_DEVICES_TO_CAMERAS](context, {page, pageSize, orderBy, filters, search}) {
      const fields = Object.keys(DEVICES_TO_CAMERAS_STRUCT.fields),
        response = await this.getters.ajaxClient.post("/v0/devices/cameras/", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          extra: getExtraApi(DEVICES_TO_CAMERAS_STRUCT, fields),
          search
        });
      return response.data;
    },

  },
};
