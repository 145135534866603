<template>
  <div>
    <div class="main__header">
      <div class="tabs-container" style="padding-top: 30px">
        <router-link :to="{ name: routes.ROUTE_PACS_CAR_NUMBER_ANALYTICS }" class="tabs-container__tab-item">
          Автомобили
        </router-link>
        <router-link :to="{ name: routes.ROUTE_PACS_HELMET_ANALYTICS }" class="tabs-container__tab-item">
          Каски
        </router-link>
        <router-link :to="{ name: routes.ROUTE_PACS_MASK_ANALYTICS }" class="tabs-container__tab-item">
          Маски
        </router-link>
        <router-link :to="{ name: routes.ROUTE_PACS_CROWD_ANALYTICS }" class="tabs-container__tab-item">
          Скопление людей
        </router-link>
        <router-link :to="{ name: routes.ROUTE_PACS_PERIMETER_SECURITY_ANALYTICS }" class="tabs-container__tab-item">
          Пересечение зоны
        </router-link>
      </div>
    </div>

    <router-view />
  </div>
</template>

<script>
import {
  ROUTE_PACS_CAR_NUMBER_ANALYTICS,
  ROUTE_PACS_HELMET_ANALYTICS,
  ROUTE_PACS_MASK_ANALYTICS,
  ROUTE_PACS_CROWD_ANALYTICS, ROUTE_PACS_PERIMETER_SECURITY_ANALYTICS,
} from "@/router/names.js";

export default {
  name: "PacsAnalyticsSettingsFrame",
  data() {
    return {
      routes: {
        ROUTE_PACS_CAR_NUMBER_ANALYTICS,
        ROUTE_PACS_HELMET_ANALYTICS,
        ROUTE_PACS_MASK_ANALYTICS,
        ROUTE_PACS_CROWD_ANALYTICS,
        ROUTE_PACS_PERIMETER_SECURITY_ANALYTICS
      },
    };
  },
};
</script>
