<template>
  <div class="settings-access">
    <div v-if="$slots.legendCaption" class="settings-access__title">
      <slot name="legendCaption" />
    </div>
    <div :class="{'settings-access__content': $slots.legendCaption}">
      <template v-if="modeAddPermission">
        <div v-for="(itemField, indexField) in formAddPermission" :key="`fieldAddPermission${indexField}`" class="input-row settings-access__input-row">
          <SmartVSelect
            v-if="modeForEmployee"
            v-model="itemField.entityId"
            :settings-remote-search="settingsSelectDevice"
            class="settings-access__select"
            :placeholder="$t('chooseDevice')"
          />
          <SmartVSelect
            v-else
            v-model="itemField.entityId"
            :settings-remote-search="settingsSelectEmployee"
            class="settings-access__select"
            :placeholder="$t('enterEmployee')"
          />

          <div class="buttons-group">
            <SmartSelect
              v-model="itemField.permission"
              :options="permissionsForSelect"
              size="m"
            />
            <CamsButton
              v-if="lengthFormAddPermission > 1"
              class="settings-access__button-delete-field"
              icon-type="only"
              type="button"
              @click="deleteFieldFormAddPermission(indexField)"
            >
              <svg class="icon-close">
                <use xlink:href="#close" />
              </svg>
            </CamsButton>
          </div>
        </div>

        <div class="buttons-group input-row">
          <CamsButton
            v-if="!modeForEmployee"
            :disabled="lengthFormAddPermission >= 3"
            priority="primary"
            @click="addNewFieldFormAddPermission()"
          >
            {{ $t('more') }}
          </CamsButton>
          <CamsButton priority="primary" type="button" @click="addPermissions()">
            {{ $t('save') }}
          </CamsButton>
          <CamsButton type="button" @click="switchOffModeAddPermission()">
            {{ $t('cancel') }}
          </CamsButton>
        </div>
      </template>

      <template v-if="!modeAddPermission">
        <CamsButton
          class="input-row"
          priority="primary"
          type="button"
          @click="switchOnModeAddPermission()"
        >
          {{ modeForEmployee ? $t('addDevice') : $t('addEmployee') }}
        </CamsButton>

        <div class="input-row">
          <SmartInputText
            v-model="search"
            :placeholder="modeForEmployee ? $t('deviceFilter') : $t('employeeFilter')"
            type="text"
            @input="debouncedLoadPermissions()"
          />
        </div>
      </template>

      <div class="input-row">
        <SpinnerLoading v-if="isLoading" size="s" />

        <div v-else-if="isAvailablePermissions" class="access-container">
          <div class="access-container__header">
            <div>{{ modeForEmployee ? $t('device') : $t('employee') }}</div>
            <div>{{ $t('accessLevel') }}</div>
          </div>

          <div class="access-container__content">
            <div v-for="(permissionInfo, permissionInfoIndex) in permissionsInfo" :key="permissionInfoIndex" class="access-container__list-item">
              <div>{{ permissionInfo.title }}</div>

              <div class="buttons-group">
                <SmartSelect
                  v-model="permissionInfo.permission"
                  :options="permissionsForSelect"
                  size="s"
                  style="margin-bottom: 0"
                  @input="updatePermission(permissionInfo.deviceId, permissionInfo.employeeId, permissionInfo.isAuto, $event)"
                />
                <CamsButton
                  class="settings-access__button-delete-field"
                  icon-type="only"
                  type="button"
                  @click="deletePermission(permissionInfo.deviceId, permissionInfo.employeeId, permissionInfo.isAuto)"
                >
                  <svg class="icon-close">
                    <use xlink:href="#close" />
                  </svg>
                </CamsButton>
              </div>
            </div>
          </div>

          <div>
            <nav v-if="pageInfo.numPages > 1" class="pagination-small">
              <paginate
                v-model="currentPage"
                :active-class="'pagination-small__list-item--active'"
                :break-view-class="'pagination-small__list-item--collapse'"
                :click-handler="loadPermissions"
                :container-class="'pagination-small__list'"
                :hide-prev-next="true"
                :page-class="'pagination-small__list-item'"
                :page-count="pageInfo.numPages"
                :page-range="5"
                next-text=""
                prev-text=""
              />
            </nav>

            <div class="pagination-small__count">
              {{ $t('foundCount') }} {{ pageInfo.count }}
            </div>
          </div>
        </div>

        <div v-else>
          <p>{{ $t('notFound') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {setPermissionsMixin} from "@/components/pacs/pacsPermissions/mixin.js";
import {ACTION_LOAD_DEVICES_FOR_SELECT, DEVICE_STRUCT} from "@/store/pacs/devices/index.js";
import {ACTION_LOAD_EMPLOYEES_FOR_SELECT, EMPLOYEE_STRUCT} from "@/store/pacs/employees/index.js";
import {
  ACTION_ADD_EMPLOYEES_PERMISSIONS_BY_FORM,
  ACTION_DELETE_EMPLOYEES_PERMISSIONS,
  ACTION_LOAD_EMPLOYEE_PERMISSIONS_FOR_EDIT, DEVICES_PERMISSIONS,
  EMPLOYEE_PERMISSION_STRUCT,
} from "@/store/pacs/pacsPermissions/index.js";

/**
 * Компонент диалога установки прав сотрудников для устройств.
 *
 * Предусматривает два режима:
 *  - для управления правами многих устройств для указанного сотрудника;
 *  - для управления правами многих сотрудников для указанного устройства;
 *
 * Режим зависит от переданного идентификатора в конкретный параметр (ID устройства или сотрудника - приоритет для сотрудника).
 * Возможность множественного добавления прав предназначена для одного устройства.
 */
export default {
  mixins: [setPermissionsMixin],
  props: {
    /**
     * Идентификатор сотрудника - позволяет управлять правами разных пользователей для этого устройства.
     */
    employeeId: {
      type: Number,
      default: null
    },
    /**
     * Идентификатор устройства - позволяет управлять правами разных сотрудников для этого устройства.
     */
    deviceId: {
      type: Number,
      default: null
    },
  },
  data() {
    return {
      settingsSelectDevice: {
        action: `pacs/devices/${ACTION_LOAD_DEVICES_FOR_SELECT}`,
        valueField: DEVICE_STRUCT.fields.id.name,
        labelField: DEVICE_STRUCT.fields.title.name
      },
      settingsSelectEmployee: {
        action: `pacs/employees/${ACTION_LOAD_EMPLOYEES_FOR_SELECT}`,
        valueField: EMPLOYEE_STRUCT.fields.id.name,
        labelField: EMPLOYEE_STRUCT.fields.title.name
      },
    };
  },
  computed: {
    /**
     * Вернет true для режима, в котором права устанавливаются для конкретного сотрудника, а настраиваются устройства.
     *
     * @return {Boolean}
     */
    modeForEmployee() {
      return this.employeeId !== null;
    },
  },
  created() {
    this.debouncedLoadPermissions = _.debounce(this.loadPermissions, 350);
    this.loadPermissions();
    this.getPermissionsForSelect();
  },
  methods: {
    getPermissionsForSelect() {
      return this.permissionsForSelect ={
        [DEVICES_PERMISSIONS.ACCESS]: this.$t('fullAccess'),
        [DEVICES_PERMISSIONS.ENTRANCE]: this.$t('entrance'),
        [DEVICES_PERMISSIONS.EXIT]: this.$t('exit'),
      };
    },
    /**
     * Загрузит информацию по правам для редактирования.
     *
     * @param {Number} currentPage
     */
    async loadPermissions(currentPage = 1) {
      this.currentPage = this.currentPage === currentPage ? this.currentPage : currentPage;
      // Основной фильтр зависит от режима работы компонента.
      const mainFilter = this.modeForEmployee ? {employeeId: this.employeeId} : {deviceId: this.deviceId};
      this.isLoading = true;
      try {
        const responseData = await this.$store.dispatch(`pacs/pacsPermissions/${ACTION_LOAD_EMPLOYEE_PERMISSIONS_FOR_EDIT}`, {
                ...mainFilter,
                search: this.search || "",
                page: currentPage
              }),

              titlesForPermissions = this.modeForEmployee
                ? _.chain(responseData.extra[EMPLOYEE_PERMISSION_STRUCT.extras.device])
                  .keyBy(DEVICE_STRUCT.fields.id.name)
                  .mapValues(DEVICE_STRUCT.fields.title.name)
                  .value()
                : _.chain(responseData.extra[EMPLOYEE_PERMISSION_STRUCT.extras.employee])
                  .keyBy(EMPLOYEE_STRUCT.fields.id.name)
                  .mapValues(EMPLOYEE_STRUCT.fields.title.name)
                  .value();

        this.pageInfo.count = responseData.count;
        this.pageInfo.numPages = responseData.page.all;
        this.permissionsInfo = responseData.results.map((rawPermissionInfo) => {
          return {
            employeeId: rawPermissionInfo[EMPLOYEE_PERMISSION_STRUCT.fields.employee_id.name],
            permission: rawPermissionInfo[EMPLOYEE_PERMISSION_STRUCT.fields.access_type.name],
            deviceId: rawPermissionInfo[EMPLOYEE_PERMISSION_STRUCT.fields.device_id.name],
            isAuto: rawPermissionInfo[EMPLOYEE_PERMISSION_STRUCT.fields.is_auto.name],
            title: titlesForPermissions[rawPermissionInfo[this.modeForEmployee ? EMPLOYEE_PERMISSION_STRUCT.fields.device_id.name : EMPLOYEE_PERMISSION_STRUCT.fields.employee_id.name]],
          };
        });

      } finally {
        this.isLoading = false;

      }
    },
    /**
     * Обработка формы для добавления прав и оформление запроса по заполненным данным.
     */
    async addPermissions() {
      const dataForSave = {},
            valuesFormAddPermission = _.values(this.formAddPermission);

      if (this.modeForEmployee) {
        // Если задан сотрудник, то в списке прав заданы устройства (1) и их права.
        dataForSave.employeeId = this.employeeId;
        dataForSave.rawPermissionsInfo = valuesFormAddPermission.map((rawPermissionInfo) => {
          return (rawPermissionInfo.entityId && rawPermissionInfo.permission) ? [rawPermissionInfo.entityId, rawPermissionInfo.permission] : null;
        });
        dataForSave.rawPermissionsInfo = _.filter(dataForSave.rawPermissionsInfo);

        if (_.isEmpty(dataForSave.rawPermissionsInfo)) {
          this.$camsdals.alert(this.$t('addPermissionError'));
          return;
        }
      } else {
        // Если задано устройство, то в списке прав заданы сотрудники и их права.
        const rawPermissionInfo = _.head(valuesFormAddPermission);
        if (!rawPermissionInfo.entityId) {
          this.$camsdals.alert(this.$t('addPermissionError'));
          return;
        }

        dataForSave.employeeId = rawPermissionInfo.entityId;
        dataForSave.rawPermissionsInfo = [
          [this.deviceId, rawPermissionInfo.permission]
        ];
      }

      this.isLoading = true;
      try {
        await this.$store.dispatch(`pacs/pacsPermissions/${ACTION_ADD_EMPLOYEES_PERMISSIONS_BY_FORM}`, dataForSave);
        this.switchOffModeAddPermission();
      } catch {
        this.$camsdals.alert(this.$t('addPermissionError'));
      }
      this.isLoading = false;
    },
    /**
     * Редактирование (установка) прав для найденных.
     *
     * @param {Number} deviceId
     * @param {Number} employeeId
     * @param {Boolean} isAuto
     * @param {Number} permission
     */
    async updatePermission(deviceId, employeeId, isAuto, permission) {
      this.isLoading = true;
      try {
        await this.$store.dispatch(`pacs/pacsPermissions/${ACTION_ADD_EMPLOYEES_PERMISSIONS_BY_FORM}`, {
          employeeId,
          isAuto,
          rawPermissionsInfo: [[deviceId, permission]],
          mergePermissions: false,
        });
      } catch {
        this.$camsdals.alert(this.$t('setPermissionError'));
      }
      this.loadPermissions(this.currentPage);
      this.isLoading = false;
    },
    /**
     * Удаление конкретных прав из списка найденных.
     *
     * @param {Number} deviceId
     * @param {Number} employeeId
     * @param {Boolean} isAuto
     */
    deletePermission(deviceId, employeeId, isAuto) {
      this.$camsdals.confirm(this.$t('deletePermissionConfirm'), async () => {
        this.isLoading = true;
        try {
          await this.$store.dispatch(`pacs/pacsPermissions/${ACTION_DELETE_EMPLOYEES_PERMISSIONS}`, {employeeId, isAuto, deviceIds: [deviceId]});
          this.$notify({
            group: "main",
            text: this.$t('devicePermissionDeleted'),
            duration: 5000,
            type: "default"
          });
        } catch {
          this.$camsdals.alert(this.$t('deletePermissionError'));
        }
        this.loadPermissions();
        this.isLoading = false;
      });
    },
  },
};
</script>

<style lang="scss">
@import "./style.scss";
</style>
