/**
 * Отдельный vuex модуль для взаимодействия по analytics/mask-API
 */

import {ANALYTICS, AnalyticSettings} from "@/store/analytics/helpers.js";
import makeMessage from "@/store/analytics/analyticMessage.js";

// actions для masks. Вызов действия начинать с "analytics/masks/"
export const ACTION_LOAD_MASKS_SETTINGS = "LOAD_MASKS_SETTINGS";
export const ACTION_LOAD_MASK_SETTINGS = "LOAD_MASK_SETTINGS";
export const ACTION_LOAD_MASK_EVENTS = "LOAD_MASK_EVENTS";
export const ACTION_LOAD_MASK_EVENT = "LOAD_MASK_EVENT";
export const ACTION_LOAD_MASK_REPORT = "LOAD_MASK_REPORT";

/**
 * Класс для представления настроек аналитики определения масок.
 */
export class MaskSettings extends AnalyticSettings {
  get analyticType() {
    return ANALYTICS.mask;
  }
}

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * Загрузка списка настроек аналитик определения масок.
     *
     * @param {Object} context
     * @param {Array.<String>} camerasNumbers
     * @return {Promise.<Array.<MaskSettings>>}
     */
    async [ACTION_LOAD_MASKS_SETTINGS](context, {camerasNumbers}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/analytics/mask/settings/", {camera_numbers: camerasNumbers});
        return response.data.results.map(rawMaskSettings => new MaskSettings(rawMaskSettings));
      } catch {
        return [];
      }
    },
    /**
     * Загрузка настроек аналитики определения масок с конкретной камеры.
     *
     * @param {Function} dispatch
     * @param {String} cameraNumber
     * @return {Promise.<MaskSettings>}
     */
    async [ACTION_LOAD_MASK_SETTINGS]({dispatch}, {cameraNumber}) {
      const masksSettings = await dispatch(ACTION_LOAD_MASKS_SETTINGS, {camerasNumbers: [cameraNumber]});
      if (_.isEmpty(masksSettings)) {
        return null;
      }
      return masksSettings[0];
    },
    /**
     * Загрузка перечня событий по аналитике масок с камеры для формирования отчета.
     *
     * @param {Object} context
     * @param {String} cameraNumber
     * @param {Array.<Number>} eventIds
     * @return {Promise.<Array.<MaskMessage>>}
     */
    async [ACTION_LOAD_MASK_EVENTS](context, {cameraNumber, eventIds}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/analytics/mask/events/", {
          camera_number: cameraNumber,
          event_ids: eventIds,
        });
        return response.data.results.map(rawEventData => makeMessage(rawEventData, cameraNumber, ANALYTICS.mask));
      } catch {
        return [];
      }
    },
    /**
     * Загрузка перечня событий по аналитики автомобильных номеров с камеры для формирования отчета.
     *
     * @param {Function} dispatch
     * @param {String} cameraNumber
     * @param {Number} eventId
     * @return {Promise.<MaskMessage>}
     */
    async [ACTION_LOAD_MASK_EVENT]({dispatch}, {cameraNumber, eventId}) {
      const masksSettings = await dispatch(ACTION_LOAD_MASK_EVENTS, {cameraNumber, eventIds: [eventId]});
      if (_.isEmpty(masksSettings)) {
        return null;
      }
      return masksSettings[0];
    },
    /**
     * Загрузка перечня событий по определению масок с камеры для формирования отчета.
     *
     * @param {Object} context
     * @param {String} cameraNumber
     * @param {Date} archiveFrom
     * @param {Date} archiveTo
     * @param {String} query
     * @return {Promise.<Array.<MaskMessage>>}
     */
    async [ACTION_LOAD_MASK_REPORT](context, {cameraNumber, archiveFrom, archiveTo, query = null}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/analytics/mask/report/", {
          camera_number: cameraNumber,
          start: archiveFrom.toISOString(),
          end: archiveTo.toISOString(),
          query: query,
        });
        return response.data.results.map(rawEventData => makeMessage(rawEventData, cameraNumber, ANALYTICS.mask));
      } catch {
        return [];
      }
    },
  },
};
