<template>
  <div>
    <SpinnerLoading v-if="isLoading" class="loader_center" color="blue" />
    <ul v-else>
      <TreeNode
        v-for="folder in treeData"
        :key="folder.id"
        :folder="folder"
        @folder-click="handleFolderClick"
        @toggle-folder="handleToggleFolder"
        @toggle-camera="handleToggleCamera"
        @folder-created="handleFolderCreated"
      />
    </ul>
  </div>
</template>

<script>
import TreeNode from './TreeNode.vue';
import {ACTION_LOAD_FOLDER, ACTION_LOAD_FOLDERS_ROOT} from "@/store/folders/index.js";
/**
 * Компонент для представления списка папок.
 * Папки могут содержать камеры, и подпапки, до 8 уровня вложенности.
 */
export default {
  data() {
    return {
      treeData: [],
      isLoading: false,
    };
  },
  methods: {
    /**
     * Слушатель клика на папку. По клику передается folder_id в api,
     * для уточнения информации по каждой папке.
     */
    async handleFolderClick(folderId) {
      try {
        const folderData = await this.$store.dispatch(
          `folders/${ACTION_LOAD_FOLDER}`,
          folderId
        );
        this.updateTreeData(folderData, folderId);
        this.activeFolderId = folderId;
      } catch (error) {
        console.error('Error loading folder data', error);
      }

    },
    /**
     * Скрытие раскрытие папок.
     */
    handleToggleFolder(folderId) {
      const updatedTreeData = this.toggleFolderRecursive(this.treeData, folderId);
      this.treeData = [...updatedTreeData];
    },

    handleToggleCamera(cameraNumber) {
      const updatedTreeData = this.toggleCameraRecursive(this.treeData, cameraNumber);
      this.treeData = [...updatedTreeData];
    },

    toggleFolderRecursive(treeData, folderId) {
      return treeData.map((folder) => {
        if (folder.id === folderId) {
          return {...folder, collapsed: !folder.collapsed};
        } else if (folder.children && folder.children.length > 0) {
          return {
            ...folder,
            children: this.toggleFolderRecursive(folder.children, folderId),
          };
        }
        return folder;
      });
    },

    toggleCameraRecursive(treeData, cameraNumber) {
      return treeData.map((folder) => {
        if (folder.cameras && folder.cameras.length > 0) {
          const updatedCameras = folder.cameras.map((camera) => {
            if (camera.number === cameraNumber) {
              return {...camera, collapsed: !camera.collapsed};
            }
            return camera;
          });

          return {
            ...folder,
            cameras: updatedCameras,
          };
        } else if (folder.children && folder.children.length > 0) {
          return {
            ...folder,
            children: this.toggleCameraRecursive(folder.children, cameraNumber),
          };
        }
        return folder;
      });
    },

    updateTreeData(newData, parentFolderId) {
      const updatedTreeData = this.updateTreeDataRecursive(this.treeData, newData, parentFolderId);
      this.treeData = [...updatedTreeData];
    },

    updateTreeDataRecursive(treeData, newData, parentFolderId) {
      return treeData.map((folder) => {
        if (folder.id === parentFolderId) {
          return {
            ...folder,
            collapsed: false,
            children: newData[0].children || [],
            cameras: newData[0].cameras || [],
            name: newData[0].name || [],
          };
        } else if (folder.children && folder.children.length > 0) {
          return {
            ...folder,
            children: this.updateTreeDataRecursive(folder.children, newData, parentFolderId),
          };
        }
        return folder;
      });
    },

    async loadRootChildren() {
      this.isLoading = true;
      try {
        this.treeData = await this.$store.dispatch(
          `folders/${ACTION_LOAD_FOLDERS_ROOT}`,
          {}
        );
      } catch (error) {
        console.error('Error loading root folders', error);
      }
      this.isLoading = false;
    },
    async    handleFolderCreated(folder) {
      folder ? await this.handleFolderClick(folder) : await this.loadRootChildren();
    }
  },

  components: {
    TreeNode,
  },
  beforeDestroy() {
    this.$root.$off('folder-created', this.handleFolderCreated);
  },
  mounted() {
    this.$root.$on('folder-created', this.handleFolderCreated);
  },
  created() {
    this.loadRootChildren();
  },
};
</script>
