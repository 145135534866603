<template>
  <div
    v-click-outside="switchOffWorkMode"
    :class="{'cameras-finder_active': workMode}"
    class="cameras-finder"
    @focus.capture="switchOnWorkMode()"
  >
    <div class="search-form-input">
      <svg class="icon">
        <use xlink:href="#icon-search" />
      </svg>
      <input
        :value="newValue"
        :class="{'search-form-input__input_highlighted': showResults}"
        autocomplete="off"
        class="search-form-input__input"
        :placeholder="$t('search')"
        tabindex="0"
        type="search"
        @focus="switchOnWorkMode()"
        @input="inputSearchText($event.target.value)"
        @keydown.down="down()"
        @keydown.enter.prevent="handleEnterKey()"
        @keydown.esc="handleEscapeKey()"
        @keydown.up="up()"
      >
      <button
        id="btnShowAllCams"
        :disabled="!showResults"
        class="search-form-input__show-all btn-blue"
        tabindex="-1"
        type="button"
        @click="showAllResults()"
      >
        <svg class="icon icon-arrow-enter">
          <use xlink:href="#icon-arrow-enter" />
        </svg>
        <span class="search-form-input__show-all-text">
          {{ $t('showAll') }} <small>{{ $t('found') }} <span id="searchResults">{{ totalCount }}</span></small>
        </span>
      </button>
    </div>

    <div v-show="showResults" class="cameras-finder-results">
      <template v-if="listCamerasInfo.length">
        <div class="cameras-finder-results__headers">
          <div>{{ $t('addresses') }}</div>
          <div>{{ $t('titles') }}</div>
        </div>

        <div
          v-for="(cameraInfo, indexCurrentItem) in listCamerasInfo"
          :key="cameraInfo.number"
          :class="{'active': indexActiveItem === indexCurrentItem}"
          class="cameras-finder-results__item"
          tabindex="0"
          @click="selectCamera(cameraInfo)"
          @mouseenter="indexActiveItem = indexCurrentItem"
          @mouseleave="indexActiveItem = -1"
          @mouseover="indexActiveItem = indexCurrentItem"
          @keydown.enter.prevent="selectCamera(cameraInfo)"
        >
          <div class="cameras-finder-results__text" v-html="$options.filters.highlightText(cameraInfo.address, newValue)" />
          <div class="cameras-finder-results__text" v-html="$options.filters.highlightText(cameraInfo.title, newValue)" />
          <button
            v-if="!selectableOnlyMode"
            :class="cameraInfo.isInactive() ? 'btn-gray' : 'btn-blue'"
            class="cameras-finder-results__play"
            tabindex="-1"
            type="button"
          >
            <svg class="icon icon-circle-play">
              <use xlink:href="#icon-circle-play" />
            </svg>
          </button>
        </div>
      </template>
      <p v-else class="cameras-finder-results__no-results">
        {{ $t('noResults') }}
      </p>
    </div>
  </div>
</template>

<script>
import {PAGE_SIZES, PARAMS_SORT_CAMERAS, VENDOR_IS_UFANET} from "@/utils/consts.js";
import {ACTION_SEARCH_CAMERAS, FIELDS_CAMERA} from "@/store/cameras/index.js";
import {YM_GOAL_PLAY_VIA_SEARCH} from "@/utils/tracking.js";
import {QUERY_KEY_ONE_SCREEN_CAMERA_NUMBER} from "@/router/queryKeys.js";

/**
 * Минимальная длинна поисковой строки для которой будет выполняться поиск.
 */
export const CAMS_SEARCH_MIN_LENGTH = 1;

/**
 * Компонент поиска камер.
 *
 * Можно передать начальный текст для поиска, это значение отслеживается в поле ввода.
 * Результаты поиска отображаются в выпадающем списке,
 * в котором имеются интерактивные элементы взаимодействия с каждым результатом.
 */
export default {
  name: "CamerasFinder",
  filters: {
    /**
     * Подсвечивание подстрок поиска в результатах.
     * Для подсветки текста совпадающего с поисковым запросом используется `<mark>`.
     * Обрати внимание на вызов фильтра в шаблоне - это из-за vue2 при работе фильтров внутри v-for.
     */
    highlightText(words, searchText) {
      let queryTokens = searchText.split(" ");
      queryTokens = queryTokens.filter(token => !!token);

      let markedText = words;
      for (let token of queryTokens) {
        let iQuery = new RegExp(token, "ig");
        markedText = markedText.toString().replace(iQuery, function (matchedTxt) {
          return `<mark>${matchedTxt}</mark>`;
        });
      }
      return markedText;
    },
  },
  props: {
    /**
     * Значение, которое надо передавать через v-model.
     */
    value: {
      type: String,
      default: "",
    },
    /**
     * Указывается режим в котором отключены интерактивные элементы управления,
     * вместо них компонент используется для выбора камеры через отправку события `select-camera`.
     */
    selectableOnlyMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      workMode: false,
      newValue: this.value,
      listCamerasInfo: [],
      totalCount: 0,
      showResults: false,
      indexActiveItem: -1,
    };
  },
  watch: {
    /**
     * Наблюдение за оригинальным значением для его синхронизации со внутренним значением компонента и отправкой события change.
     *
     * @param {String} newValue
     */
    value(newValue) {
      this.newValue = newValue;
      this.$emit("change", newValue);
    },
  },
  /**
   * Регистрируется вариант функции `loadCameras` с отложенным выполнением.
   */
  created() {
    this.debouncedLoadCameras = _.debounce(this.loadCameras, 500);
  },
  methods: {
    /**
     * Приводит компонент в рабочее состояние - загружает камеры в соответствии с поисковым запросом.
     */
    switchOnWorkMode() {
      if (!this.workMode) {
        // Если предыдущее состояние уже было активно, то повторный поиск не нужен.
        this.indexActiveItem = -1;
        this.loadCameras();
      }
      this.workMode = true;
    },
    /**
     * Выход из рабочего режима компонента - скрывается отображение результатов поиска.
     */
    switchOffWorkMode() {
      this.workMode = false;
      this.indexActiveItem = -1;
      this.showResults = false;
    },
    /**
     * Метод срабатывает при вводе текста в текстовое поле поиска по событию `input`.
     * Новое значение сохраняется в компоненте и передается по событию в родительский компонент в привязанную модель.
     *
     * @param {String} newValue
     */
    inputSearchText(newValue = "") {
      this.newValue = newValue;
      this.$emit("input", newValue);

      if (newValue === "") {
        this.indexActiveItem = -1;
        this.listCamerasInfo = [];
        this.showResults = false;
        return;
      }

      this.debouncedLoadCameras();
    },
    /**
     * Функция загрузки камер по заданным опциям поиска.
     */
    async loadCameras() {
      if (this.newValue.length < CAMS_SEARCH_MIN_LENGTH) {
        return;
      }
      const dataForLoadCameras = {
        pageSize: PAGE_SIZES.LIMITED,
        page: 1,
        fields: [
          FIELDS_CAMERA.number,
          FIELDS_CAMERA.address,
          FIELDS_CAMERA.title,
          FIELDS_CAMERA.longitude,
          FIELDS_CAMERA.latitude,
          // FIELDS_CAMERA.is_embed,
          FIELDS_CAMERA.analytics,
          FIELDS_CAMERA.is_fav,
          FIELDS_CAMERA.is_public,
          FIELDS_CAMERA.inactivity_period,
          // FIELDS_CAMERA.server,
          FIELDS_CAMERA.tariff,
          // FIELDS_CAMERA.token_l,
          FIELDS_CAMERA.permission,
        ],
        query: this.newValue,
        orderBy: PARAMS_SORT_CAMERAS.addr_asc,
      };

      const [listCamerasInfo, , totalCount] = await this.$store.dispatch(`cameras/${ACTION_SEARCH_CAMERAS}`, dataForLoadCameras);
      this.indexActiveItem = -1;
      this.totalCount = totalCount;
      this.listCamerasInfo = listCamerasInfo;
      this.showResults = true;
    },
    /**
     * Метод инициирует событие для родительского компонента `show-all-results`,
     * которое обозначает необходимость отображения полного результата поиска.
     */
    showAllResults() {
      this.$emit("show-all-results");
      this.showResults = false;
    },
    /**
     * Клик по текущей камере с целью выбора. Инициирует событие `select-camera` - передачи номера камеры в родительский компонент.
     * В зависимости от режима работы компонента может сразу запустить воспроизведение видео.
     *
     * @param {CameraInfo} cameraInfo
     */
    selectCamera(cameraInfo) {
      this.$emit("select-camera", cameraInfo.number);
      this.play(cameraInfo);
    },
    /**
     * Воспроизведение прямой трансляции.
     *
     * @param {CameraInfo} cameraInfo
     */
    play(cameraInfo) {
      if (this.selectableOnlyMode) {
        return;
      }
      VENDOR_IS_UFANET && this.$metrika?.reachGoal(YM_GOAL_PLAY_VIA_SEARCH);
      this.$router.push({
        name: this.$route.name,
        query: {
          ...(this.$route.query || {}),
          [QUERY_KEY_ONE_SCREEN_CAMERA_NUMBER]: cameraInfo.number
        }
      });
      this.switchOffWorkMode();
    },
    /**
     * Нажатие клавиши `enter` в поисковой строке.
     *
     * В случае наличия активной строки будет восприниматься как выбор камеры,
     * в противном случае необходимо показать все результаты поиска.
     */
    handleEnterKey() {
      // Проверяем, что длина введенного текста больше нуля
      if (this.newValue.length > 0) {
        // Если длина текста больше нуля, выполняем поиск или выбор камеры
        if (this.indexActiveItem === -1) {
          // Если нет активного элемента, показываем все результаты поиска
          this.showAllResults();
        } else {
          // Иначе выбираем активную камеру
          const cameraInfo = this.listCamerasInfo[this.indexActiveItem];
          if (cameraInfo) {
            this.selectCamera(cameraInfo);
          }
        }
      }
    },

    handleEscapeKey() {
      this.inputSearchText('');
    },
    /**
     * Нажатие клавиши `down` на поле ввода устанавливает следующий элемент результата как активный.
     * В случае нажатия в не пустой строке поиска, но без данных результатов, произойдет выполнение поискового запроса.
     */
    down() {
      if (!this.showResults) {
        this.loadCameras();
        return;
      }

      if (this.indexActiveItem < this.listCamerasInfo.length - 1) {
        this.indexActiveItem++;
      } else {
        this.indexActiveItem = -1;
      }
    },
    /**
     * Нажатие клавиши `up` на поле ввода устанавливает предыдущий элемент результата как активный.
     */
    up() {
      if (this.indexActiveItem > 0) {
        this.indexActiveItem--;
      } else if (this.indexActiveItem === -1) {
        this.indexActiveItem = this.listCamerasInfo.length - 1;
      } else {
        this.indexActiveItem = -1;
      }
    },
  },
};
</script>

<style lang="scss">
@import "./scss/cameras-finder.scss";
</style>
