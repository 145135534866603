<template>
  <v-map
    :key="`map_${target}`"
    ref="map"
    :bounds="bound"
    :center="defaultCenter"
    :min-zoom="minZoom"
    :options="mapOptions"
    :zoom="zoom"
  >
    <v-control-zoom zoom-in-title="" zoom-out-title="" position="verticalcenterright" />

    <v-tile-layer
      :attribution="attributionLayer"
      :url="mapTilesUrl"
    />
    <v-control-attribution position="bottomright" prefix="" />

    <v-marker-cluster v-if="camerasNumber > 1" :key="`cluster_${target}`" ref="clusterRef">
      <v-marker
        v-for="item in cameras"
        :key="target + item.number"
        :ref="item.number"
        :icon="item.marker_id ? getCustomCameraIcon(item.marker_id) : null"
        :lat-lng="[item.latitude, item.longitude]"
        @click="play(item.number)"
        @mouseout="onMarkerMouseOut"
        @mouseover="onMarkerMouseOver"
        @popupopen="popen(item.number)"
      >
        <v-popup>
          <MapPopup
            :number="item.number"
            :target="target"
            @popupContentToggle="onPopupContentToggle"
          />
        </v-popup>
      </v-marker>
    </v-marker-cluster>
    <template v-else>
      <v-marker
        v-for="item in cameras"
        :key="target + item.number"
        :ref="item.number"
        :lat-lng="[item.latitude, item.longitude]"
        @click="play(item.number)"
        @mouseout="onMarkerMouseOut"
        @mouseover="onMarkerMouseOver"
        @popupopen="popen(item.number)"
      >
        <v-popup>
          <MapPopup
            :number="item.number"
            :target="target"
            @popupContentToggle="onPopupContentToggle"
          />
        </v-popup>
      </v-marker>
    </template>
  </v-map>
</template>

<script>
import MapPopup from "@/components/map/MapPopup.vue";
import L from "leaflet";
import {mapState} from "vuex";
import {LControlAttribution, LControlZoom, LMap, LMarker, LPopup, LTileLayer} from "vue2-leaflet";
import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";
import {MAP_TARGET, MAP_TILES_URL} from "@/utils/consts.js";
import {QUERY_KEY_MAP_CAMERA_NUMBER} from "@/router/queryKeys.js";
import iconRetinaUrl from "@/assets/img/icons/map-pointer2х.png";
import markedIconRetinaUrl from "@/assets/img/icons/map-pointer2х_marked.png";
import iconUrl from "@/assets/img/icons/map-pointer1х.png";
import markedIconUrl from "@/assets/img/icons/map-pointer1х_marked.png";
import shadowUrl from "leaflet/dist/images/marker-shadow.png";
import {runPlayerMixin} from "@/components/map/mixins.js";

const defaultIcon = L.icon({
  iconRetinaUrl,
  iconUrl,
  iconSize: [37, 52],
  iconAnchor: [18, 51],
  popupAnchor: [0, -47],
  shadowAnchor: [12, 39],
  shadowUrl,
});

const markedIcon = L.icon({
  iconRetinaUrl: markedIconRetinaUrl,
  iconUrl: markedIconUrl,
  iconSize: [37, 52],
  iconAnchor: [18, 51],
  popupAnchor: [0, -47],
  shadowAnchor: [12, 39],
  shadowUrl,
});

export default {
  name: "MapTab",
  mixins: [runPlayerMixin],
  components: {
    MapPopup,
    "v-control-zoom": LControlZoom,
    "v-map": LMap,
    "v-tile-layer": LTileLayer,
    "v-marker": LMarker,
    "v-popup": LPopup,
    "v-marker-cluster": Vue2LeafletMarkerCluster,
    "v-control-attribution": LControlAttribution,
  },
  props: {
    target: {
      type: String,
      required: true,
      validator(value) {
        return value in MAP_TARGET;
      },
    },
  },
  data() {
    return {
      mapTilesUrl: MAP_TILES_URL,
      mapOptions: {zoomControl: false, attributionControl: false},
      bound: null,
      currentMarkerTarget: null,
      isOnMarkerMouseOver: false,
      popUpDisableClose: false,
      timerID: null,
    };
  },
  computed: {
    ...mapState({
      defaultCenter: state => state.map.defaultCenter,
      minZoom: state => state.map.minZoom,
      zoom: state => state.map.zoom,
    }),
    refreshDataTimerFlag() {
      return this.$store.state.map[this.target].refreshDataTimerFlag;
    },
    cameras() {
      return this.$store.state.map[this.target].cameras;
    },
    /**
     * Если на карте только один маркер, то он не отрисовывается в кластере (пока не смог выяснить почему, надо копаться в библиотеках leaflet),
     * поэтому в шаблоне проверяется количество камер на карте
     */
    //TODO выяснить почему массив из одного маркера не отображается на карте
    camerasNumber() {
      return Object.keys(this.cameras).length;
    },
    attributionLayer() {
      // Используем $t для получения перевода и подставляем ссылку в него
      return this.$t('participants', {link: '<a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'});
    }
  },

  watch: {
    /**
     * при переходе с fav наблюдаются проблемы если кластер остается раскрытым, поэтому мы закрываем его тут
     * beforeRouteLeave не работает https://forum.vuejs.org/t/in-component-guard-beforerouteleave-not-working-solved/4658
     */
    $route() {
      if (this.$refs.clusterRef && this.$refs.clusterRef.mapObject) {
        this.$refs.clusterRef.mapObject.unspiderfy();
      }
    },
  },
  mounted() {
    // Корректировка положения элементов управления зумом на карте (см. в шаблоне verticalcenterright).
    this.$refs.map.mapObject._controlCorners["verticalcenterright"] = L.DomUtil.create(
      "div",
      "leaflet-verticalcenter leaflet-right",
      this.$refs.map.mapObject._controlContainer
    );

    //TODO поместить область карты в store и когда надо изменять
    //TODO запоминать пользовательскую область при перезагрузке

    this.$store.dispatch(`map/${this.target}/apiCameras`, cameras => {
      /**
       * Устанавливаем видимую область карты исходя из камер которые должны быть отображены.
       * @return {*}
       */

      let bound;
      if (this.$route.query[QUERY_KEY_MAP_CAMERA_NUMBER]) {
        let zoomedToCamera = cameras.find(camera => {
          return camera.number === this.$route.query[QUERY_KEY_MAP_CAMERA_NUMBER];
        });
        bound = L.latLngBounds([zoomedToCamera.latitude, zoomedToCamera.longitude]);
      } else {
        bound = L.latLngBounds(Object.values(cameras).map(x => [x.latitude, x.longitude]));
      }

      if (bound.isValid()) {
        this.bound = bound.pad(0.05);
      }
    });
  },
  updated() {
    this.chooseMarker();
  },
  activated() {
    /**
     * при переходах по вкладкам камера ломается, решение с помощью генерации события resize найдено
     * тут https://github.com/KoRiGaN/Vue2Leaflet/issues/96 и тут https://github.com/KoRiGaN/Vue2Leaflet/issues/157
     */
    window.dispatchEvent(new Event("resize"));
    /**
     * текущий компонент keep-alive, поэтому мы стучимся за данными если выставлен соответствующий признак
     * и если это любимые камеры, так как необходимо оперативно реагировать на добавление и удаление камер в избранное
     */
    if (this.target === MAP_TARGET.fav || this.refreshDataTimerFlag) {
      this.$store.dispatch(`map/${this.target}/apiCameras`);
    } else {
      this.chooseMarker();
    }
  },
  methods: {
    /**
     * Здесь происходит зум и выделение выбранного маркера
     *
     * Приходится устанавливать интервал, в ожидании пока не появится нужный маркер и кластер, возникли сложности с поиском
     * нужного события. Нашел только совет с использованием интервалов, более того, в примере от разработчиков тоже
     * использовался интервал.
     */
    chooseMarker() {
      let timerIterationCounter = 0;
      if (this.$route.query[QUERY_KEY_MAP_CAMERA_NUMBER]) {
        let intId = setInterval(() => {
          if (++timerIterationCounter === 100) {
            // что-то нехорошее случилось, обекты на карте не появились
            clearInterval(intId);
          }
          /**
           * Здесь происходит проверка наличия кластера маркеров как vue объекта this.$refs.clusterRef,
           * но это не дает гарантий того, что объект карты существует, так как он создается в методе mounted vue объекта,
           * аналогично поступаем с проверкой маркера this.$refs[this.$route.query[QUERY_KEY_MAP_CAMERA_NUMBER]]
           */

          if (
            this.$refs.clusterRef &&
            this.$refs.clusterRef.mapObject &&
            this.$refs[this.$route.query[QUERY_KEY_MAP_CAMERA_NUMBER]] &&
            this.$refs[this.$route.query[QUERY_KEY_MAP_CAMERA_NUMBER]][0] &&
            this.$refs[this.$route.query[QUERY_KEY_MAP_CAMERA_NUMBER]][0].mapObject
          ) {
            if (this.choosenMarker) {
              // если при повторном выборе маркера существует текущий, то текущий делаем маркером по умолчанию
              this.choosenMarker.setIcon(defaultIcon);
            }
            this.choosenMarker = this.$refs[this.$route.query[QUERY_KEY_MAP_CAMERA_NUMBER]][0].mapObject;
            // устанавливаем иконку для вновь выбранного маркера
            this.choosenMarker.setIcon(markedIcon);
            // зум и раскрытие кластера
            // тут какая-то дичь с картами нашел, решение здесь https://github.com/KoRiGaN/Vue2Leaflet/issues/96
            setTimeout(() => {
              this.$refs.clusterRef.mapObject.zoomToShowLayer(this.$refs[this.$route.query[QUERY_KEY_MAP_CAMERA_NUMBER]][0].mapObject);
            }, 200);
            clearInterval(intId);
          }
        }, 100);
      } else {
        if (this.choosenMarker) {
          this.choosenMarker.setIcon(defaultIcon);
        }
      }
    },
    // вызов попапа на карте у камеры
    popen(number) {
      this.$store.dispatch(`map/${this.target}/apiPopupData`, number);
    },
    /**
     * вызывается при наведении курсора на маркер
     */
    onMarkerMouseOver(event) {
      event.target.openPopup();
      clearTimeout(this.timerID);
      this.isOnMarkerMouseOver = true;
      this.currentMarkerTarget = event.target;
    },
    /**
     * вызывается при отведении курсора с маркера
     */
    onMarkerMouseOut() {
      this.isOnMarkerMouseOver = false;
      this.setCloseTimerOfCurrentPopup();
    },
    /**
     * здесь устанавливается или сбрасывается таймер для отображения попапа пренадлежащего определенному маркеру
     * попап должен отображаться 2 сек, и закрываться, если на маркер или попап наводили мышкой, то таймер устанавливается снова
     */
    onPopupContentToggle(popUpDisableClose) {
      this.popUpDisableClose = popUpDisableClose;
      if (popUpDisableClose) {
        clearTimeout(this.timerID);
      }
      if (!(popUpDisableClose || this.isOnMarkerMouseOver)) {
        this.setCloseTimerOfCurrentPopup();
      }
    },
    /**
     * установка таймера для попапа
     */
    setCloseTimerOfCurrentPopup() {
      this.timerID = setTimeout(() => {
        if (this.currentMarkerTarget && !this.popUpDisableClose) {
          this.currentMarkerTarget.closePopup();
          this.timerID = null;
        }
      }, 2000);
    },
    /**
     * @returns {Object|*} Вернет иконку для камеры.
     */
    getCustomCameraIcon(markerId) {
      if (markerId != null) {
        return L.icon({
          iconRetinaUrl: `${window.location.origin}/api/v0/markers/${markerId}-retina.img`,
          iconUrl: `${window.location.origin}/api/v0/markers/${markerId}-main.img`,
          iconSize: [55, 57],
          iconAnchor: [29, 43],
          popupAnchor: [0, -47],
          shadowAnchor: [12, 39],
        });
      }
    },
  },
};
</script>
