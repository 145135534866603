<template>
  <div class="main__content content">
    <div class="pacs-settings-table">
      <div class="pacs-settings-table__group-setting">
        <SmartInputText
          v-model="searchText"
          caption="Поиск"
          class="pacs-settings-table__settings-control pacs-settings-table__settings-control_big"
          size="l"
          @keyup.native.enter="applyTableSettings()"
        >
          <CamsButton
            position="r"
            size="l"
            type="button"
            icon-type="only"
            @click="applyTableSettings()"
          >
            <svg class="icon-search">
              <use xlink:href="#icon-search" />
            </svg>
          </CamsButton>
        </SmartInputText>
      </div>
    </div>

    <SpinnerLoading v-if="isLoadingTable" class="loader_center" color="blue" />
    <PacsTable
      :column-captions="columnCaptions"
      :column-names="columnNames"
      :data-rows="dataRows"
      :page-count="1"
      :total-count="dataRows.length"
      :view-only-mode="true"
    />
  </div>
</template>

<script>
import PacsTable from "@/components/pacs/PacsTable.vue";
import {
  ROUTE_PACS_EDIT_CAR_NUMBER_ANALYTICS,
} from "@/router/names.js";
import {
  ACTION_LOAD_CAR_NUMBER_ANALYTICS_FOR_TABLE,
  CAMERA_ANALYTICS_STRUCT
} from "@/store/analytics/index.js";
import {entityTableMixin} from "@/components/pacs/mixins.js";

/**
 * Компонент отображения таблицы камер с аналитикой распознавания а/м.
 */
export default {
  name: "TableCarNumberAnalytics",
  mixins: [
    entityTableMixin,
  ],
  components: {
    PacsTable
  },
  data() {
    return {
      entityStruct: CAMERA_ANALYTICS_STRUCT,
      entityFields: [
        CAMERA_ANALYTICS_STRUCT.fields.camera_number,
        CAMERA_ANALYTICS_STRUCT.fields.min_confidence,
      ],

    };
  },
  methods: {
    /**
     * @see methods.loadPage.
     */
    async loadPage() {
      this.isLoadingTable = true;
      try {
        const responseData = await this.$store.dispatch(`analytics/${ACTION_LOAD_CAR_NUMBER_ANALYTICS_FOR_TABLE}`, {
          search: this.searchText,
        });
        this.dataRows = responseData.results.map((rawCarNumberInfo) => {
          const cameraNumber = rawCarNumberInfo[CAMERA_ANALYTICS_STRUCT.fields.camera_number.name],
                paramsRouteEditCarNumberAnalytics = {name: ROUTE_PACS_EDIT_CAR_NUMBER_ANALYTICS, params: {cameraNumber}};
          return {
            ...this.calcDefaultDataRow(rawCarNumberInfo),
            [CAMERA_ANALYTICS_STRUCT.fields.camera_number.name]: {
              type: CAMERA_ANALYTICS_STRUCT.fields.camera_number.type,
              value: cameraNumber,
              params: paramsRouteEditCarNumberAnalytics
            },
            [CAMERA_ANALYTICS_STRUCT.fields.min_confidence.name]: {
              value: rawCarNumberInfo[CAMERA_ANALYTICS_STRUCT.fields.min_confidence.name],
            },
          };
        });
      } finally {
        this.isLoadingTable = false;
      }
    },
  },
};
</script>
