/**
 * Примеси и функциональные компоненты для отображения сообщений событий по конкретным аналитикам.
 *
 * Имена функциональных компонентов связаны с классами сообщений конкретных аналитик, для простоты добавляется постфикс `View`.
 * Однако не нужно пытаться динамически определять компонент для отображения сообщения через интерполяцию строк с именем класса сообщения в :is,
 * поскольку при минификации js имена классов будут изменены. Надежнее явно прописать соответствия между классами сообщений и их отображениями.
 */

import {ANALYTICS} from "@/store/analytics/helpers.js";
import EventPlaybackDialog from "@/components/events/EventPlaybackDialog.vue";
import EventScreenshotDialog from "@/components/events/EventScreenshotDialog.vue";
import defaultCover from "@/assets/img/icons/default-cover.svg";
import {ACTION_GET_DOWNLOAD_TOKEN, CameraInfo} from "@/store/cameras/index.js";
import {AnalyticMessage} from "@/store/analytics/analyticMessage.js";
import bell from "@/assets/bell.wav";
import {QUERY_KEY_ONE_SCREEN_CAMERA_NUMBER, QUERY_KEY_ONE_SCREEN_TIME_SHIFT} from "@/router/queryKeys.js";
import {DOWNLOADS_TYPES} from "camsng-frontend-shared/lib/consts.js";
import {MAX_UNIX_DOWNLOAD_DELTA_FOR_PLAYER, MIN_UNIX_DOWNLOAD_DELTA_FOR_PLAYER} from "@/utils/consts.js";
import "./scss/analytic-message.scss";
import i18n from "@/../i18n/i18n.js";

/**
 * Общие части между компонентами для отображения сообщений.
 */
const analyticMessageView = {
  functional: true,
  props: {
    /**
     * Экземпляр {@link AnalyticMessage} по которому отображается информация в компоненте.
     */
    analyticMessage: {
      type: AnalyticMessage,
      required: true
    },
    /**
     * Флаг для показа в рамках сообщения информации по связанной камере.
     */
    showCameraInfo: {
      type: Boolean,
      default: false,
    }
  },
};

/**
 * Компонент отображения экземпляра сообщения по тепловизору.
 * Обработка событий `@play-event-start` для воспроизведения видео события по клику на описание события,
 * `@show-full-screenshot` для отображения большого скриншота при клике на маленький.
 *
 * @see ThermalVisionMessage
 */
export const ThermalVisionMessageView = {
  ...analyticMessageView,
  render(h, context) {
    const analyticMessage = context.props.analyticMessage,
      fio = analyticMessage.text || i18n.t('unknown'), // "Неизвестный"
      temperature = i18n.t('temperature') + ` ${parseFloat(analyticMessage.temperature)}℃`,
      date = moment.tz(analyticMessage.date, moment.tz.guess()).format("DD.MM.YYYY HH:mm:ss"),
      // Признак видимости кнопки для перехода к определенному событию.
      isVisibleOverlay = context.listeners["play-event-start"],
      screenshotToken = analyticMessage.cameraInfo.tokenLive || analyticMessage.cameraInfo.tokenDVR;

    let useFullScreenshot = false,
      handleClickScreenshot = {},
      faceScreenshot = analyticMessage.getFaceScreenshotUrl(screenshotToken);
    if (faceScreenshot) {
      if (context.listeners["show-full-screenshot"]) {
        useFullScreenshot = true;
        handleClickScreenshot = {click: () => context.listeners["show-full-screenshot"](analyticMessage)};
      }
    } else {
      faceScreenshot = defaultCover;
    }

    return h(
      "div", {class: ["analytic-message", context.data.class, context.data.staticClass], attrs: {title: fio}}, [
        h("div", {class: {"analytic-message__screenshot": true, "analytic-message__overlay": useFullScreenshot}, on: handleClickScreenshot}, [
          h("img", {attrs: {src: faceScreenshot, alt: fio}})
        ]),
        isVisibleOverlay
          ? h("router-link", {
            class: "analytic-message__description analytic-message__overlay",
            props: {
              to: {
                params: {isClickButtonToPlayEvent: true},
                query: {
                  [QUERY_KEY_ONE_SCREEN_CAMERA_NUMBER]: analyticMessage.cameraNumber,
                  [QUERY_KEY_ONE_SCREEN_TIME_SHIFT]: +analyticMessage.date
                }
              }
            }
          }, [
            h("p", {
              class: {"analytic-message__message-title": true, "analytic-message__message-title_warning": analyticMessage.alarm}
            }, temperature),
            h("p", {class: "analytic-message__message-text"}, fio),
            h("div", {class: "analytic-message__message-date"}, date)
          ])
          : h("div", {class: "analytic-message__description"}, [
            ...(context.props.showCameraInfo ? [
              h("div", {class: "analytic-message__camera-title"}, analyticMessage.cameraInfo.title),
              h("div", {class: "analytic-message__camera-address"}, analyticMessage.cameraInfo.address),
            ] : []),
            h("p", {
              class: {"analytic-message__message-title": true, "analytic-message__message-title_warning": analyticMessage.alarm}
            }, temperature),
            h("p", {class: "analytic-message__message-text"}, fio),
            h("div", {class: "analytic-message__message-date"}, date)
          ])
      ]);
  },
};

/**
 * Компонент отображения экземпляра сообщения по автомобильным номерам.
 * Обработка событий `@play-event-start` для воспроизведения видео события по клику на описание события,
 * `@show-full-screenshot` для отображения большого скриншота при клике на маленький.
 *
 * @see CarNumberMessage
 */
export const CarNumberMessageView = {
  ...analyticMessageView,
  render(h, context) {
    const analyticMessage = context.props.analyticMessage,
      text = analyticMessage.text || i18n.t('unknown'),
      date = moment.tz(analyticMessage.date, moment.tz.guess()).format("DD.MM.YYYY HH:mm:ss"),
      // Признак видимости кнопки для перехода к определенному событию.
      isVisibleOverlay = context.listeners["play-event-start"],
      screenshotToken = analyticMessage.cameraInfo.tokenLive || analyticMessage.cameraInfo.tokenDVR;

    let useFullScreenshot = false,
      handleClickScreenshot = {},
      plateScreenshot = analyticMessage.getPlateScreenshotUrl(screenshotToken);
    if (plateScreenshot) {
      if (context.listeners["show-full-screenshot"]) {
        useFullScreenshot = true;
        handleClickScreenshot = {click: () => context.listeners["show-full-screenshot"](analyticMessage)};
      }
    } else {
      plateScreenshot = defaultCover;
    }

    return h(
      "div", {class: ["analytic-message", context.data.class, context.data.staticClass], attrs: {title: text}}, [
        h("div", {class: {"analytic-message__screenshot": true, "analytic-message__overlay": useFullScreenshot}, on: handleClickScreenshot}, [
          h("img", {attrs: {src: plateScreenshot, alt: text}})
        ]),
        isVisibleOverlay
          ? h("router-link", {
            class: "analytic-message__description analytic-message__overlay",
            props: {
              to: {
                params: {isClickButtonToPlayEvent: true},
                query: {
                  [QUERY_KEY_ONE_SCREEN_CAMERA_NUMBER]: analyticMessage.cameraNumber,
                  [QUERY_KEY_ONE_SCREEN_TIME_SHIFT]: +analyticMessage.date
                }
              }
            }
          }, [
            h("div", {
              class: {"analytic-message__message-title": true, "analytic-message__message-title_warning": analyticMessage.access}
            }, `${analyticMessage.number} ${analyticMessage.titleIfEmergencyVehicle}`),
            h("p", {class: "analytic-message__message-text"}, text),
            h("div", {class: "analytic-message__message-date"}, date)
          ])
          : h("div", {class: "analytic-message__description"}, [
            ...(context.props.showCameraInfo ? [
              h("div", {class: "analytic-message__camera-title"}, analyticMessage.cameraInfo.title),
              h("div", {class: "analytic-message__camera-address"}, analyticMessage.cameraInfo.address),
            ] : []),
            h("div", {
              class: {"analytic-message__message-title": true, "analytic-message__message-title_warning": analyticMessage.access}
            }, `${analyticMessage.number} ${analyticMessage.titleIfEmergencyVehicle}`),
            h("p", {class: "analytic-message__message-text"}, text),
            h("div", {class: "analytic-message__message-date"}, date)
          ])
      ]);
  },
};

/**
 * Компонент отображения экземпляра сообщения по лицам.
 * Обработка событий `@play-event-start` для воспроизведения видео события по клику на описание события,
 * `@show-full-screenshot` для отображения большого скриншота при клике на маленький.
 *
 * @see FaceRecognitionMessage
 */
export const FaceRecognitionMessageView = {
  ...analyticMessageView,
  render(h, context) {
    const analyticMessage = context.props.analyticMessage,
      fio = analyticMessage.text || i18n.t('unknown'),
      date = moment.tz(analyticMessage.date, moment.tz.guess()).format("DD.MM.YYYY HH:mm:ss"),
      // Признак видимости кнопки для перехода к определенному событию.
      isVisibleOverlay = context.listeners["play-event-start"],
      screenshotToken = analyticMessage.cameraInfo.tokenLive || analyticMessage.cameraInfo.tokenDVR;

    let useFullScreenshot = false,
      handleClickScreenshot = {},
      faceScreenshot = analyticMessage.getFaceScreenshotUrl(screenshotToken);
    if (faceScreenshot) {
      if (context.listeners["show-full-screenshot"]) {
        useFullScreenshot = true;
        handleClickScreenshot = {click: () => context.listeners["show-full-screenshot"](analyticMessage)};
      }
    } else {
      faceScreenshot = defaultCover;
    }

    return h(
      "div", {class: ["analytic-message", context.data.class, context.data.staticClass], attrs: {title: fio}}, [
        h("div", {class: {"analytic-message__screenshot": true, "analytic-message__overlay": useFullScreenshot}, on: handleClickScreenshot}, [
          h("img", {attrs: {src: faceScreenshot, alt: fio}})
        ]),
        isVisibleOverlay
          ? h("router-link", {
            class: "analytic-message__description analytic-message__overlay",
            props: {
              to: {
                params: {isClickButtonToPlayEvent: true},
                query: {
                  [QUERY_KEY_ONE_SCREEN_CAMERA_NUMBER]: analyticMessage.cameraNumber,
                  [QUERY_KEY_ONE_SCREEN_TIME_SHIFT]: +analyticMessage.date
                }
              }
            }
          }, [
            h("div", {class: {"analytic-message__message-title": true, "analytic-message__message-title_warning": analyticMessage.access}}, fio),
            h("div", {class: "analytic-message__message-date"}, date)
          ])
          : h("div", {class: "analytic-message__description"}, [
            ...(context.props.showCameraInfo ? [
              h("div", {class: "analytic-message__camera-title"}, analyticMessage.cameraInfo.title),
              h("div", {class: "analytic-message__camera-address"}, analyticMessage.cameraInfo.address),
            ] : []),
            h("div", {class: {"analytic-message__message-title": true, "analytic-message__message-title_warning": analyticMessage.access}}, fio),
            h("div", {class: "analytic-message__message-date"}, date)
          ])
      ]);
  },
};

/**
 * Компонент отображения экземпляра сообщения по определению касок.
 * Обработка событий `@play-event-start` для воспроизведения видео события по клику на описание события.
 *
 * @see HelmetMessage
 */
export const HelmetMessageView = {
  ...analyticMessageView,
  render(h, context) {
    const analyticMessage = context.props.analyticMessage,
      text = analyticMessage.text,
      date = moment.tz(analyticMessage.date, moment.tz.guess()).format("DD.MM.YYYY HH:mm:ss"),
      // Признак видимости кнопки для перехода к определенному событию.
      isVisibleOverlay = context.listeners["play-event-start"],
      screenshotToken = analyticMessage.cameraInfo.tokenLive || analyticMessage.cameraInfo.tokenDVR;

    let useFullScreenshot = false,
      handleClickScreenshot = {},
      headScreenshot = analyticMessage.getHeadScreenshotUrl(screenshotToken);
    if (headScreenshot) {
      if (context.listeners["show-full-screenshot"]) {
        useFullScreenshot = true;
        handleClickScreenshot = {click: () => context.listeners["show-full-screenshot"](analyticMessage)};
      }
    } else {
      headScreenshot = defaultCover;
    }

    return h(
      "div", {class: ["analytic-message", context.data.class, context.data.staticClass], attrs: {title: text}}, [
        h("div", {class: {"analytic-message__screenshot": true, "analytic-message__overlay": useFullScreenshot}, on: handleClickScreenshot}, [
          h("img", {attrs: {src: headScreenshot}})
        ]),
        isVisibleOverlay
          ? h("router-link", {
            class: "analytic-message__description analytic-message__overlay",
            props: {
              to: {
                params: {isClickButtonToPlayEvent: true},
                query: {
                  [QUERY_KEY_ONE_SCREEN_CAMERA_NUMBER]: analyticMessage.cameraNumber,
                  [QUERY_KEY_ONE_SCREEN_TIME_SHIFT]: +analyticMessage.date
                }
              }
            }
          }, [
            h("div", {class: {"analytic-message__message-title": true, "analytic-message__message-title_warning": analyticMessage.access}}, text),
            h("div", {class: "analytic-message__message-date"}, date)
          ])
          : h("div", {class: "analytic-message__description"}, [
            ...(context.props.showCameraInfo ? [
              h("div", {class: "analytic-message__camera-title"}, analyticMessage.cameraInfo.title),
              h("div", {class: "analytic-message__camera-address"}, analyticMessage.cameraInfo.address),
            ] : []),
            h("div", {class: {"analytic-message__message-title": true, "analytic-message__message-title_warning": analyticMessage.access}}, text),
            h("div", {class: "analytic-message__message-date"}, date)
          ])
      ]);
  },
};

/**
 * Компонент отображения экземпляра сообщения по определению масок.
 * Обработка событий `@play-event-start` для воспроизведения видео события по клику на описание события.
 *
 * @see MaskMessage
 */
export const MaskMessageView = {
  ...analyticMessageView,
  render(h, context) {
    const analyticMessage = context.props.analyticMessage,
      text = analyticMessage.text,
      date = moment.tz(analyticMessage.date, moment.tz.guess()).format("DD.MM.YYYY HH:mm:ss"),
      // Признак видимости кнопки для перехода к определенному событию.
      isVisibleOverlay = context.listeners["play-event-start"],
      screenshotToken = analyticMessage.cameraInfo.tokenLive || analyticMessage.cameraInfo.tokenDVR;

    let useFullScreenshot = false,
      handleClickScreenshot = {},
      maskScreenshot = analyticMessage.getMaskScreenshotUrl(screenshotToken);
    if (maskScreenshot) {
      if (context.listeners["show-full-screenshot"]) {
        useFullScreenshot = true;
        handleClickScreenshot = {click: () => context.listeners["show-full-screenshot"](analyticMessage)};
      }
    } else {
      maskScreenshot = defaultCover;
    }

    return h(
      "div", {class: ["analytic-message", context.data.class, context.data.staticClass], attrs: {title: text}}, [
        h("div", {class: {"analytic-message__screenshot": true, "analytic-message__overlay": useFullScreenshot}, on: handleClickScreenshot}, [
          h("img", {attrs: {src: maskScreenshot}})
        ]),
        isVisibleOverlay
          ? h("router-link", {
            class: "analytic-message__description analytic-message__overlay",
            props: {
              to: {
                params: {isClickButtonToPlayEvent: true},
                query: {
                  [QUERY_KEY_ONE_SCREEN_CAMERA_NUMBER]: analyticMessage.cameraNumber,
                  [QUERY_KEY_ONE_SCREEN_TIME_SHIFT]: +analyticMessage.date
                }
              }
            }
          }, [
            h("div", {class: {"analytic-message__message-title": true, "analytic-message__message-title_warning": analyticMessage.access}}, text),
            h("div", {class: "analytic-message__message-date"}, date)
          ])
          : h("div", {class: "analytic-message__description"}, [
            ...(context.props.showCameraInfo ? [
              h("div", {class: "analytic-message__camera-title"}, analyticMessage.cameraInfo.title),
              h("div", {class: "analytic-message__camera-address"}, analyticMessage.cameraInfo.address),
            ] : []),
            h("div", {class: {"analytic-message__message-title": true, "analytic-message__message-title_warning": analyticMessage.access}}, text),
            h("div", {class: "analytic-message__message-date"}, date)
          ])
      ]);
  },
};

/**
 * Компонент отображения экземпляра сообщения по вторжению в зону.
 * Обработка событий `@play-event-start` для воспроизведения видео события по клику на описание события.
 *
 * @see PerimeterSecurityMessage
 */
export const PerimeterMessageView = {
  ...analyticMessageView,
  render(h, context) {
    const analyticMessage = context.props.analyticMessage,

      text = analyticMessage.text,
      date = moment.tz(analyticMessage.date, moment.tz.guess()).format("DD.MM.YYYY HH:mm:ss"),
      // Признак видимости кнопки для перехода к определенному событию.
      isVisibleOverlay = context.listeners["play-event-start"],
      screenshotToken = analyticMessage.cameraInfo.tokenLive || analyticMessage.cameraInfo.tokenDVR;
    let useFullScreenshot = false,
      handleClickScreenshot = {},
      perimeterScreenshot = analyticMessage.getPerimeterSecurityScreenshotUrl(screenshotToken);
    if (perimeterScreenshot) {
      if (context.listeners["show-full-screenshot"]) {
        useFullScreenshot = true;
        handleClickScreenshot = {click: () => context.listeners["show-full-screenshot"](analyticMessage)};
      }
    } else {
      perimeterScreenshot = defaultCover;
    }

    return h(
      "div", {class: ["analytic-message", context.data.class, context.data.staticClass], attrs: {title: text}}, [
        h("div", {class: {"analytic-message__screenshot": true, "analytic-message__overlay": useFullScreenshot}, on: handleClickScreenshot}, [
          h("img", {attrs: {src: perimeterScreenshot}})
        ]),
        isVisibleOverlay
          ? h("router-link", {
            class: "analytic-message__description analytic-message__overlay",
            props: {
              to: {
                params: {isClickButtonToPlayEvent: true},
                query: {
                  [QUERY_KEY_ONE_SCREEN_CAMERA_NUMBER]: analyticMessage.cameraNumber,
                  [QUERY_KEY_ONE_SCREEN_TIME_SHIFT]: +analyticMessage.date
                }
              }
            }
          }, [
            h("div", {class: {"analytic-message__message-title": true, "analytic-message__message-title_warning": analyticMessage.access}}, text),
            h("div", {class: "analytic-message__message-date"}, date)
          ])
          : h("div", {class: "analytic-message__description"}, [
            ...(context.props.showCameraInfo ? [
              h("div", {class: "analytic-message__camera-title"}, analyticMessage.cameraInfo.title),
              h("div", {class: "analytic-message__camera-address"}, analyticMessage.cameraInfo.address),
            ] : []),
            h("div", {class: {"analytic-message__message-title": true, "analytic-message__message-title_warning": analyticMessage.access}}, text),
            h("div", {class: "analytic-message__message-date"}, date)
          ])
      ]);
  },
};
/**
 * Компонент отображения экземпляра сообщения по определению толпы людей.
 * Обработка событий `@play-event-start` для воспроизведения видео события по клику на описание события.
 *
 * @see MaskMessage
 */
export const CrowdMessageView = {
  ...analyticMessageView,
  render(h, context) {
    const analyticMessage = context.props.analyticMessage,
      text = analyticMessage.text,
      date = moment.tz(analyticMessage.date, moment.tz.guess()).format("DD.MM.YYYY HH:mm:ss"),
      // Признак видимости кнопки для перехода к определенному событию.
      isVisibleOverlay = context.listeners["play-event-start"],
      screenshotToken = analyticMessage.cameraInfo.tokenLive || analyticMessage.cameraInfo.tokenDVR;

    let useFullScreenshot = false,
      handleClickScreenshot = {},
      crowdScreenshot = analyticMessage.getCrowdScreenshotUrl(screenshotToken);
    if (crowdScreenshot) {
      if (context.listeners["show-full-screenshot"]) {
        useFullScreenshot = true;
        handleClickScreenshot = {click: () => context.listeners["show-full-screenshot"](analyticMessage)};
      }
    } else {
      crowdScreenshot = defaultCover;
    }

    return h(
      "div", {class: ["analytic-message", context.data.class, context.data.staticClass], attrs: {title: text}}, [
        h("div", {class: {"analytic-message__screenshot": true, "analytic-message__overlay": useFullScreenshot}, on: handleClickScreenshot}, [
          h("img", {attrs: {src: crowdScreenshot}})
        ]),
        isVisibleOverlay
          ? h("router-link", {
            class: "analytic-message__description analytic-message__overlay",
            props: {
              to: {
                params: {isClickButtonToPlayEvent: true},
                query: {
                  [QUERY_KEY_ONE_SCREEN_CAMERA_NUMBER]: analyticMessage.cameraNumber,
                  [QUERY_KEY_ONE_SCREEN_TIME_SHIFT]: +analyticMessage.date
                }
              }
            }
          }, [
            h("div", {class: {"analytic-message__message-title": true, "analytic-message__message-title_warning": analyticMessage.access}}, text),
            h("div", {class: "analytic-message__message-date"}, date)
          ])
          : h("div", {class: "analytic-message__description"}, [
            ...(context.props.showCameraInfo ? [
              h("div", {class: "analytic-message__camera-title"}, analyticMessage.cameraInfo.title),
              h("div", {class: "analytic-message__camera-address"}, analyticMessage.cameraInfo.address),
            ] : []),
            h("div", {class: {"analytic-message__message-title": true, "analytic-message__message-title_warning": analyticMessage.access}}, text),
            h("div", {class: "analytic-message__message-date"}, date)
          ])
      ]);
  },
};
/**
 * Компонент отображения экземпляра сообщения по подсчету посетителей.
 * Обработка событий `@play-event-start` для воспроизведения видео события по клику на описание события.
 *
 * @see PeopleCountMessage
 */
export const PeopleMessageView = {
  ...analyticMessageView,
  render(h, context) {
    const analyticMessage = context.props.analyticMessage,
      date = moment.tz(analyticMessage.date, moment.tz.guess()).format("DD.MM.YYYY HH:mm:ss"),
      // Признак видимости кнопки для перехода к определенному событию.
      isVisibleOverlay = context.listeners["play-event-start"];
    let text = analyticMessage.text;
    text = text==="in"? text=i18n.t('entrance') : text=i18n.t('exit') ;
    return h(
      "div", {class: ["analytic-message", context.data.class, context.data.staticClass], attrs: {title: date}}, [
        isVisibleOverlay
          ? h("router-link", {
            class: "analytic-message__description analytic-message__overlay",
            props: {
              to: {
                params: {isClickButtonToPlayEvent: true},
                query: {
                  [QUERY_KEY_ONE_SCREEN_CAMERA_NUMBER]: analyticMessage.cameraNumber,
                  [QUERY_KEY_ONE_SCREEN_TIME_SHIFT]: +analyticMessage.date
                }
              }
            }
          }, [
            h("p", {class: "analytic-message__message-title"}, text),
            h("div", {class: "analytic-message__message-date"}, date)
          ])
          : h("div", {class: "analytic-message__description"}, [
            ...(context.props.showCameraInfo ? [
              h("div", {class: "analytic-message__camera-title"}, analyticMessage.cameraInfo.title),
              h("div", {class: "analytic-message__camera-address"}, analyticMessage.cameraInfo.address),
            ] : []),
            h("p", {class: "analytic-message__message-title"}, text),
            h("div", {class: "analytic-message__message-date"}, date)
          ])
      ]);
  },
};
/**
 * Компонент отображения экземпляра сообщения по распознаванию движения.
 * Обработка событий `@play-event-start` для воспроизведения видео события по клику на описание события.
 *
 * @see MotionAlarmMessage
 */
export const MotionAlarmMessageView = {
  ...analyticMessageView,
  render(h, context) {
    const analyticMessage = context.props.analyticMessage,
      text = `${i18n.t('Movement')} ${analyticMessage.continues ? i18n.t('continues') : ''}${analyticMessage.duration} ${i18n.t('sec')}`,
      date = moment.tz(analyticMessage.date, moment.tz.guess()).format("DD.MM.YYYY HH:mm:ss"),
      // Признак видимости кнопки для перехода к определенному событию.
      isVisibleOverlay = context.listeners["play-event-start"];

    return h(
      "div", {class: ["analytic-message", context.data.class, context.data.staticClass], attrs: {title: date}}, [
        isVisibleOverlay
          ? h("router-link", {
            class: "analytic-message__description analytic-message__overlay",
            props: {
              to: {
                params: {isClickButtonToPlayEvent: true},
                query: {
                  [QUERY_KEY_ONE_SCREEN_CAMERA_NUMBER]: analyticMessage.cameraNumber,
                  [QUERY_KEY_ONE_SCREEN_TIME_SHIFT]: +analyticMessage.date
                }
              }
            }
          }, [
            h("p", {class: "analytic-message__message-title"}, text),
            h("div", {class: "analytic-message__message-date"}, date)
          ])
          : h("div", {class: "analytic-message__description"}, [
            ...(context.props.showCameraInfo ? [
              h("div", {class: "analytic-message__camera-title"}, analyticMessage.cameraInfo.title),
              h("div", {class: "analytic-message__camera-address"}, analyticMessage.cameraInfo.address),
            ] : []),
            h("p", {class: "analytic-message__message-title"}, text),
            h("div", {class: "analytic-message__message-date"}, date)
          ])
      ]);
  },
};

export const DefaultMessageView = {
  functional: true,
  props: {
    /**
     * Экземпляр {@link CameraInfo} c любым токеном для просмотра скриншота события по этой камере.
     */
    cameraInfo: {
      type: CameraInfo,
      required: true
    },
    /**
     * Экземпляр {@link AnalyticMessage} по которому отображается информация в компоненте.
     */
    analyticMessage: {
      type: AnalyticMessage,
      required: true
    }
  },
  render(h) {
    return h("div");
  },
};

/**
 * Примесь со сборником всех функциональных компонентов по отображению событий.
 */
export const analyticMessageViewMixin = {
  computed: {
    /**
     * @return {Object} Объект со связями между классами сообщений и их компонентами для отображения.
     */
    analyticMessageViewComponents() {
      return {
        [ANALYTICS.thermal_vision]: ThermalVisionMessageView,
        [ANALYTICS.car_number]: CarNumberMessageView,
        [ANALYTICS.face_recognition]: FaceRecognitionMessageView,
        [ANALYTICS.helmet]: HelmetMessageView,
        [ANALYTICS.motion_alarm]: MotionAlarmMessageView,
        [ANALYTICS.mask]: MaskMessageView,
        [ANALYTICS.crowd]: CrowdMessageView,
        [ANALYTICS.perimeter_security]: PerimeterMessageView,
        [ANALYTICS.people_counter]: PeopleMessageView,
      };
    }
  }
};

/**
 * Примесь для работы со звуковыми оповещениями.
 */
export const messageSoundNotificationMixin = {
  computed: {
    /**
     * @return {HTMLAudioElement} Приватное свойство с объектом аудио файла для оповещения.
     */
    $_messageSoundNotificationMixin_audio() {
      return new Audio(bell);
    }
  },
  methods: {
    /**
     * Воспроизведение звука оповещения.
     */
    soundNotification() {
      this.$_messageSoundNotificationMixin_audio.play();
    }
  }
};

/**
 * Методы обработки кликов по событиям.
 */
export const eventHandlersMixin = {
  data() {
    return {
      downloadUrl: null,
    };
  },
  methods: {
    /**
     * Открытие диалогового окна с плеером в котором будет воспроизведен короткий участок видео с событием.
     *
     * @param {AnalyticMessage} analyticMessage
     * @param {CameraInfo} cameraInfo
     */
    playEventStart(analyticMessage, cameraInfo) {
      this.$camsdals.open(
        EventPlaybackDialog,
        {
          analyticMessage: analyticMessage,
          cameraInfo,
        },
        {dialogTitle: i18n.t('eventView')},
        {
          closeOnBackdrop: true,
          size: "vuedal-auto-width",
          name: "js-click-outside",
        }
      );
    },
    /**
     * Получит токен для скачивания нужного события и сформирует ссылку для его скачивания.
     * Ссылка поместится в {@see downloadUrl} которую надо задать для iframe.
     * Длительность видео с упреждением аналогична {@see EventPlaybackDialog}.
     *
     * @param {AnalyticMessage} analyticMessage
     * @param {CameraInfo} cameraInfo
     */
    async downloadEventVideo(analyticMessage, cameraInfo) {
      const unixDownloadFrom = Math.trunc(analyticMessage.startDate / 1000) - 15,
        unixDownloadTo = Math.trunc(analyticMessage.endDate / 1000) + 1,
        unixDownloadDelta = Math.min(Math.max((unixDownloadTo - unixDownloadFrom), MIN_UNIX_DOWNLOAD_DELTA_FOR_PLAYER), MAX_UNIX_DOWNLOAD_DELTA_FOR_PLAYER),
        downloadType = unixDownloadDelta <= 3600 ? DOWNLOADS_TYPES.MP4 : DOWNLOADS_TYPES.TS,
        tokenDownload = await this.$store.dispatch(`cameras/${ACTION_GET_DOWNLOAD_TOKEN}`, {
          number: cameraInfo.number,
          tokenDownloadDuration: unixDownloadDelta,
          tokenDownloadStart: unixDownloadFrom,
        });

      if (!tokenDownload) {
        this.$camsdals.alert(i18n.t('downloadError'));
        return;
      }

      this.downloadUrl = `${this.$store.getters.protocolVideoOverHTTP}://${cameraInfo.server.domain}/${cameraInfo.number}/archive-${unixDownloadFrom}-${unixDownloadDelta}.${downloadType.toLowerCase()}?token=${tokenDownload}`;
    },
    /**
     * Открытие диалогового окна со скриншотом события.
     *
     * @param {AnalyticMessage} analyticMessage
     * @param {CameraInfo} cameraInfo
     */
    showFullScreenshot(analyticMessage, cameraInfo) {
      this.$camsdals.open(
        EventScreenshotDialog,
        {
          analyticMessage: analyticMessage,
          cameraInfo,
        },
        {dialogTitle: i18n.t('screenshotView')},
        {
          closeOnBackdrop: true,
          size: "vuedal-auto-width",
          name: "js-click-outside",
        }
      );
    },
  }
};
