<template>
  <form @keyup.enter="saveDataAndRedirect()" @submit.prevent="saveDataAndRedirect()">
    <SmartInputText
      v-model="dataForm.title"
      :error="dataErrors.title"
      :caption="$t('groupName')"
      class="input-row"
      maxlength="128"
    />

    <SmartInputText
      v-model="dataForm.about"
      :error="dataErrors.about"
      :caption="$t('additionalDescription')"
      class="input-row"
      input-type="area"
      maxlength="200"
    />

    <div class="buttons-group buttons-group--align-right">
      <CamsButton type="button" @click="closeDialog()">
        {{ $t('cancel') }}
      </CamsButton>
      <CamsButton priority="primary" type="submit">
        {{ $t('save') }}
      </CamsButton>
    </div>

    <SpinnerLoadingModal v-if="isLoading" />
  </form>
</template>


<script>
import {createEntityMixin} from "@/components/pacs/mixins.js";
import {ACTION_CREATE_DEVICE_ACCESS_GROUP, DEVICE_ACCESS_GROUP_STRUCT} from "@/store/pacs/deviceAccessGroups/index.js";
import {ROUTE_PACS_EDIT_DEVICE_ACCESS_GROUP} from "@/router/names.js";

/**
 * Компонент диалога создания группы доступа.
 */
export default {
  mixins: [createEntityMixin],
  data() {
    return {
      actionForSave: `pacs/deviceAccessGroups/${ACTION_CREATE_DEVICE_ACCESS_GROUP}`,
      dataForm: {
        title: "",
        about: "",
      },
    };
  },
  methods: {
    /**
     * Перенаправление на страницу редактирования группы доступа.
     *
     * @link saveEntityMixin.methods.getRouteRedirectAfterSave
     */
    getRouteRedirectAfterSave(newEntity) {
      return {name: ROUTE_PACS_EDIT_DEVICE_ACCESS_GROUP, params: {deviceAccessGroupId: newEntity[DEVICE_ACCESS_GROUP_STRUCT.fields.id.name]}};
    },
  },
};
</script>
