<template>
  <div>
    <notifications
      classes="customized-notification-style"
      group="welcome"
      position="top center"
    />
  </div>
  <!--<div class="fav-notification fav-notification-opened" v-if="isVisible">-->
  <!--<div class="fav-notification__content">-->
  <!--Уважаемый клиент! Не забудьте добавить сайт в закладки.-->
  <!--</div>-->
  <!--<button @click="isVisible = false" type="button">-->
  <!--<svg height="32" version="1.1" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg">-->
  <!--<title>Закрыть уведомление</title>-->
  <!--<path-->
  <!--d="M1.509 0l-1.509 1.544 14.456 14.456-14.456 14.491 1.509 1.509 14.491-14.456 14.456 14.456 1.544-1.509-14.491-14.491 14.491-14.456-1.544-1.544-14.456 14.491-14.491-14.491z"-->
  <!--fill="#000"></path>-->
  <!--</svg>-->
  <!--</button>-->
  <!--</div>-->
</template>

<script>
  /**
   * Отображает банер поверх всех окон, с предложением сохранить в закладки сайт.
   * Отображается только в случае если в URL есть нужный параметр, который устанавливается сервером при перходе со старой версии сайта.
   *
   * Закоментирована версия которая по стилю залазит на контент.
   */
export default {
  name: "FavoriteNotification",
  // data() {
  //   return {
  //     isVisible: false,
  //   }
  // },
  /**
   * При создании компонента он просматривает содержимое URL на наличие ключевого параметра и отмечает его наличие в локальном состоянии компонента.
   */
  mounted() {
    if (this.$route.query["fromCams"]) {
      // this.isVisible = true;
      this.$notify({
        group: "welcome",
        title: "Уважаемый клиент!",
        text: "Не забудьте добавить сайт в закладки.",
        duration: -1,
      });
    }
  }
};
</script>

<!--<style lang="scss" scoped>-->
  <!--.fav-notification {-->
    <!--background: #D7EAFC;-->
    <!--padding: 12px 20px;-->
    <!--position: absolute;-->
    <!--left: 0;-->
    <!--z-index: 1000;-->
    <!--width: 100%;-->
    <!--justify-content: center;-->
    <!--display: none;-->

    <!--button {-->
      <!--position: absolute;-->
      <!--right: 20px;-->
      <!--top: 50%;-->
      <!--transform: translateY(-50%);-->
      <!--background-color: transparent;-->
    <!--}-->

    <!--svg {-->
      <!--width: 10px;-->
      <!--height: 10px;-->
    <!--}-->
  <!--}-->

  <!--.fav-notification__content {-->
    <!--width: 500px;-->
    <!--max-width: 100%;-->
    <!--text-align: center;-->
    <!--margin: 0 auto;-->
  <!--}-->

  <!--.fav-notification-opened {-->
    <!--display: flex;-->
  <!--}-->
<!--</style>-->
