import map_mixin from "@/store/map/mapMixin.js";
import {MAP_TARGET} from "@/utils/consts.js";

let mapFav = Object.assign({}, map_mixin);
mapFav.state = {
  cameras: {},
  target: MAP_TARGET.fav,
};
mapFav.mutations = {
  ...map_mixin.mutations,
  // удаление камер из избранных в компоненте
  deleteCamFromFav(state, number) {
    const {[number]: deletedValue, ...cameras} = state.cameras;
    state.cameras = cameras;
  },
};

export default mapFav;
