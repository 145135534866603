<template>
  <div class="common-container__body" style="border: none;">
    <SpinnerLoadingModal v-if="isLoading" />
    <p v-else-if="errorLoadEntity">
      Ошибка: невозможно отобразить информацию.
    </p>

    <form class="pacs-entity-form-primary" @keyup.enter="saveDataAnalytics()" @submit.prevent="saveDataAnalytics()">
      <br>
      <SmartInputText
        v-model="dataForm.minObjectHeight"
        caption="Минимальная высота объекта"
        maxlength="255"
        size="s"
      />
      <br>
      <br>
      <SmartInputText
        v-model="dataForm.minObjectWidth"
        caption="Минимальная ширина объекта"
        maxlength="255"
        size="s"
      />
      <br>
      <div class="buttons-group">
        <CamsButton priority="primary" type="submit">
          Сохранить
        </CamsButton>
        <CamsButton
          title="Открыть плеер для просмотра и редактирования зон аналитики"
          type="button"
          @click="openDialogEditZones()"
        >
          Редактировать зону распознавания
        </CamsButton>
      </div>
    </form>
  </div>
</template>

<script>
import {editEntityMixin} from "@/components/pacs/mixins.js";
import {
  ACTION_EDIT_PERIMETER_SECURITY_ANALYTICS,
  ACTION_LOAD_PERIMETER_SECURITY_ANALYTICS_FOR_EDIT,
  CAMERA_PERIMETER_ANALYTICS_STRUCT
} from "@/store/analytics/index.js";
import {ACTION_LOAD_CAMERA_FOR_EDIT_ANALYTICS, FIELDS_CAMERA} from "@/store/cameras/index.js";
import {TOKEN_TTL} from "@/utils/consts.js";
import {AnalyticZone, AnalyticZonesConfig} from "camsng-frontend-shared/components/smartPlayer/mixins.js";
import RequestTokenCameraDialog from "@/components/pacs/analytics/RequestTokenCameraDialog.vue";

export default {
  name: "EditPerimeterSecuritysAnalytics",
  mixins: [editEntityMixin],
  data() {
    const cameraNumber = this.$route.params.cameraNumber;

    return {
      entityId: cameraNumber,
      actionForLoadEntity: `analytics/${ACTION_LOAD_PERIMETER_SECURITY_ANALYTICS_FOR_EDIT}`,
      actionForSave: `analytics/${ACTION_EDIT_PERIMETER_SECURITY_ANALYTICS}`,
      numbers: [],
    };
  },
  methods: {
    /**
     * Загрузка информации по конкретной камере.
     */
    async afterLoadSourceData() {
      this.dataForm = {
        cameraNumber: this.sourceData.entityInfo[CAMERA_PERIMETER_ANALYTICS_STRUCT.fields.camera_number.name],
        zones: this.sourceData.entityInfo[CAMERA_PERIMETER_ANALYTICS_STRUCT.fields.zones.name],
        imageWidth: this.sourceData.entityInfo[CAMERA_PERIMETER_ANALYTICS_STRUCT.fields.image_width.name],
        imageHeight: this.sourceData.entityInfo[CAMERA_PERIMETER_ANALYTICS_STRUCT.fields.image_height.name],
        minObjectWidth: this.sourceData.entityInfo[CAMERA_PERIMETER_ANALYTICS_STRUCT.fields.min_object_width.name],
        minObjectHeight: this.sourceData.entityInfo[CAMERA_PERIMETER_ANALYTICS_STRUCT.fields.min_object_height.name],
      };
    },
    /**
     * Сохранение данных и продолжение редактирования.
     * После успешного сохранения данных происходит перезагрузка исходных данных.
     */
    async saveDataAnalytics() {
      try {
        await this.saveData();
        await this.loadSourceData();
        this.$notify({
          group: "main",
          text: this.$t('saveSuccess'),
          duration: 5000,
          type: "success"
        });
      } catch {
        this.$notify({
          group: "main",
          text: this.$t('errorOccurred'),
          duration: 5000,
          type: "error"
        });
      }
    },
    /**
     * Открытие формы для редактирования зон аналитики.
     */
    async openDialogEditZones() {
      const response = await this.$store.dispatch(`cameras/${ACTION_LOAD_CAMERA_FOR_EDIT_ANALYTICS}`, {
        fields: [
          FIELDS_CAMERA.number,
          FIELDS_CAMERA.address,
          FIELDS_CAMERA.title,
          FIELDS_CAMERA.is_embed,
          FIELDS_CAMERA.analytics,
          FIELDS_CAMERA.is_fav,
          FIELDS_CAMERA.is_public,
          FIELDS_CAMERA.server,
          FIELDS_CAMERA.streams_count,
          FIELDS_CAMERA.token_l,
          FIELDS_CAMERA.token_r,
          FIELDS_CAMERA.token_d,
          FIELDS_CAMERA.permission,
        ],
        tokenDVRTTL: TOKEN_TTL.MULTI,
        tokenDownloadStart: 1,
        numbers: [this.dataForm.cameraNumber],
      });
      const cameraInfo = response.data.results[0];
      const zonesInfoForEdit = this.dataForm.zones || [],
            analyticZonesConfigForEdit = new AnalyticZonesConfig(
              this.dataForm.imageWidth,
              this.dataForm.imageHeight,
              3,
              100,
              zonesInfoForEdit.map((rawAnalyticZone) => new AnalyticZone(rawAnalyticZone.coordinates, rawAnalyticZone.sensitivity)));
      this.$camsdals.open(
        RequestTokenCameraDialog,
        {
          archiveToken: cameraInfo.token_r,
          downloadToken: cameraInfo.token_d,
          liveToken: cameraInfo.token_l,
          cameraNumber: this.entityId,
          title: cameraInfo.title,
          domain: cameraInfo.server.domain,
          streamCount: cameraInfo.streams_count,
          hasSound: cameraInfo.is_sounding,
          analyticZonesConfig: analyticZonesConfigForEdit,
        },
        {dialogTitle: `Просмотр видео с камеры №${this.entityId} - ${cameraInfo.title}`},
        {
          name: "js-click-outside",
          size: "lg",
          onClose: async (analyticZonesConfigForSave) => {
            if (!analyticZonesConfigForSave) {
              return;
            }
            this.dataForm.imageWidth = analyticZonesConfigForSave.zonesWidth;
            this.dataForm.imageHeight = analyticZonesConfigForSave.zonesHeight;
            this.dataForm.zones = analyticZonesConfigForSave.exportZones().map((analyticZone) => {
              return {coordinates: analyticZone.exportPoints(), sensitivity: analyticZone.sensitivity};
            });
            await this.saveData();
            // this.errorsFormEdit[this.fieldsEntity.zones] = this.errorsFormEdit[this.fieldsEntity.zones].join(", ");
          }
        }
      );
    },
  }
};
</script>
