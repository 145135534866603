/**
 * Ключи для параметров в Query string.
 *
 * Есть необходимость систематизировать все ключи, т.к. по задачам они могут пересекаться в рамках одного маршрута.
 * Те маршруты, что пересекаются с остальными следует смешивать с существующими, например:
 * ```
 * this.$router.push({
 *     name: this.$route.name,
 *     query: {
 *       ...(this.$route.query || {}),
 *       [QUERY_KEY_ONE_SCREEN_CAMERA_NUMBER]: cameraInfo.number
 *     }
 *   });
 * ```
 * В свою очередь в компонентах со своими параметрами следует точечно реагировать на изменения URL, в случаях когда меняются глобальные параметры.
 */

// Глобальные. Пересекается со всеми везде.
// Для открытия диалогового окна с камерой.
export const QUERY_KEY_ONE_SCREEN_CAMERA_NUMBER = "n";
export const QUERY_KEY_ONE_SCREEN_TIME_SHIFT = "s";
export const QUERY_KEY_ONE_SCREEN_TAB = "t";

// Локальные для отдельных компонентов. Не пересекается в между разными компонентами.
// Для карты.
export const QUERY_KEY_MAP_CAMERA_NUMBER = "number";
// Для страницы списка камер.
export const QUERY_KEY_MESH_PAGE_TYPE_VIEW = "view";
export const QUERY_KEY_MESH_PAGE_SEARCH = "search";
export const QUERY_KEY_MESH_PAGE_SORT = "sort";
export const QUERY_KEY_MESH_PAGE_PAGE = "page";
export const QUERY_KEY_MESH_PAGE_FOLDER = "folder";
// СКУД.
export const QUERY_KEY_TABLE_PARAMS = "p";
