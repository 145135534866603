<template>
  <div>
    <div v-if="!isCorrectData" class="row">
      <p><strong>Произошла ошибка. На этой камере невозможно запросить видеопоток.</strong></p>
    </div>

    <template v-else>
      <div v-if="hasAnyToken && !isLoading" class="row smart-player-screen">
        <SmartPlayer
          :analytic-zones-config="analyticZonesConfig"
          :archive-token="archiveToken"
          :available-archive-fragments="availableArchiveFragments"
          :camera-number="cameraNumber"
          :domain="domain"
          :get-line-fragments="getLineFragments"
          :has-sound="hasSound"
          :http-protocol="$store.getters.protocolVideoOverHTTP"
          :initial-low-latency-mode="lowLatencyMode"
          :initial-time-shift="initialTimeShift"
          :live-token="liveToken"
          :max-unix-download-delta="maxUnixDownloadDelta"
          :min-unix-download-delta="minUnixDownloadDelta"
          :possible-low-latency-hls="false"
          :stream-count="streamCount"
          :ws-protocol="$store.getters.protocolVideoOverWS"
          @download-video="downloadVideo"
          @save-zones="saveZones"
        />

        <!--iframe для скачивания no-referrer -->
        <iframe
          :src="downloadUrl"
          frameborder="0"
          height="1"
          referrerpolicy="no-referrer"
          width="1"
        />
      </div>
    </template>
    <SpinnerLoading v-if="isLoading" size="s" />
  </div>
</template>

<script>
import {
  ACTION_LOAD_RECORDING_STATUSES,
  ACTION_GET_DOWNLOAD_TOKEN,
} from "@/store/cameras/index.js";
import {methodsForDialogMixin} from "@/components/pacs/mixins.js";
import {AnalyticZonesConfig} from "camsng-frontend-shared/components/smartPlayer/mixins.js";
import SmartPlayer from "camsng-frontend-shared/components/smartPlayer/SmartPlayer.vue";
import {MAX_UNIX_DOWNLOAD_DELTA_FOR_PLAYER, MIN_UNIX_DOWNLOAD_DELTA_FOR_PLAYER} from "@/utils/consts.js";

/**
 * Компонент диалога для запроса токена для получения видео с конкретной камеры и сохранения зоны.
 */
export default {
  components: {
    SmartPlayer
  },
  mixins: [methodsForDialogMixin],
  props: {
    /**
     * Номер камеры для получения токена.
     */
    cameraNumber: {
      type: String,
      default: null,
    },
    /**
     * Заголовок камеры.
     */
    title: {
      type: String,
      default: "",
    },
    /**
     * Токен доступа к архиву.
     */
    archiveToken: {
      type: String,
      default: "",
    },
    /**
     * Токен прямой трансляции.
     */
    liveToken: {
      type: String,
      default: "",
    },
    /**
     * Токен для скачивания.
     */
    downloadToken: {
      type: String,
      default: "",
    },
    /**
     * Домен сервера с которого можно получить поток с камеры.
     */
    domain: {
      type: String,
      default: null,
    },
    /**
     * Количество доступных потоков.
     */
    streamCount: {
      type: Number,
      default: null,
    },
    /**
     * Флаг, определяющий возможность настройки громкости.
     */
    hasSound: {
      type: Boolean,
      default: false,
    },
    /**
     * Набор настроек и прочей информации по зонам аналитики.
     */
    analyticZonesConfig: {
      type: AnalyticZonesConfig,
      default: null,
    },
  },
  data() {
    const isCorrectData = this.cameraNumber && this.domain && this.streamCount,
          initialTimeShift = new Date();
    initialTimeShift.setMinutes(initialTimeShift.getMinutes() - 10, 0, 0);

    return {
      isCorrectData: isCorrectData,
      isLoading: false,
      textError: "",
      lowLatencyMode: false, // todo имеет смысл запускать всегда hls т.к. MSE не может заработать хотя ошибок никаких не выдает
      timerIdForReceivingRecordingStatus: null,
      availableArchiveFragments: [],
      initialTimeShift: null,
      isLoadingDownloadToken: false,
      downloadUrl: "",
      minUnixDownloadDelta: MIN_UNIX_DOWNLOAD_DELTA_FOR_PLAYER,
      maxUnixDownloadDelta: MAX_UNIX_DOWNLOAD_DELTA_FOR_PLAYER,
    };
  },
  computed: {
    /**
     * @return {Boolean} Вернет true если есть хоть какой-то токен.
     */
    hasAnyToken() {
      return Boolean(this.archiveToken || this.liveToken);
    }
  },
  mounted() {
    this.requestTokens();
  },
  beforeDestroy() {
    clearInterval(this.timerIdForReceivingRecordingStatus);
  },
  methods: {
    /**
     * Отправка запроса.
     * В случае успешного получения токена открывается плеер для воспроизведения видео.
     */
    async requestTokens() {
      this.textError = "";
      await this.receiveRecordingStatus();
      this.timerIdForReceivingRecordingStatus = setInterval(this.receiveRecordingStatus, 5000);
    },
    /**
     * Получение доступных фрагментов видео от сервера.
     */
    async receiveRecordingStatus() {
      this.availableArchiveFragments = await this.$store.dispatch(`cameras/${ACTION_LOAD_RECORDING_STATUSES}`, {
        cameraNumber: this.cameraNumber,
        domain: this.domain,
        token: this.archiveToken,
      });
    },
    /**
     * Функция должна вернуть актуальный набор данных для отображения на временной шкале.
     * Для доступного архива фильтруются доступные участки для рисования, уточняется их цвет и низший приоритет.
     *
     * @param {Date} leftBoundary
     * @param {Date} rightBoundary
     * @return {Promise<Array<Array<Date, Date, String, Number>>>}
     */
    async getLineFragments(leftBoundary, rightBoundary) {
      return this.availableArchiveFragments.filter(([dateStart, dateEnd]) => {
        return +leftBoundary < +dateEnd && +rightBoundary > +dateStart;
      }).map(([dateStart, dateEnd]) => {
        return [dateStart, dateEnd, "#bbbbbb", 0];
      });
    },
    /**
     * Отправка запроса на получение токена для скачивания фрагмента видео.
     * При его получении формирование URL и автоматический запуск скачивания.
     *
     * @param {Function} getterDownloadUrl
     * @param {Number} unixDownloadFrom
     * @param {Number} unixDownloadDelta
     */
    async downloadVideo([getterDownloadUrl, unixDownloadFrom, unixDownloadDelta]) {
      // Конкретные ограничения по длительности запрашиваемого видео.
      if ((unixDownloadDelta < MIN_UNIX_DOWNLOAD_DELTA_FOR_PLAYER) || (unixDownloadDelta > MAX_UNIX_DOWNLOAD_DELTA_FOR_PLAYER)) {
        this.$camsdals.alert(this.$t('invalidDownloadRange'));
        return;
      }
      let tokenDownload = null;
      this.isLoadingDownloadToken = true;
      try {
        const token = await this.$store.dispatch(`cameras/${ACTION_GET_DOWNLOAD_TOKEN}`, {
          number: this.cameraNumber,
          tokenDownloadDuration: unixDownloadDelta,
          tokenDownloadStart: unixDownloadFrom,
        });
        tokenDownload = token;
      } catch (error) {
        devLog("[downloadVideo]", error);
      }
      if (tokenDownload) {
        this.downloadUrl = getterDownloadUrl(tokenDownload);
      } else {
        this.$camsdals.alert(this.$t('downloadError'));
      }
      this.isLoadingDownloadToken = false;
    },
    /**
     * Передаст параметры зон из плеера для сохранения в родительский компонент.
     *
     * @param {AnalyticZonesConfig} analyticZonesConfig
     */
    saveZones(analyticZonesConfig) {
      this.closeDialog(analyticZonesConfig);
      this.$notify({
        group: "main",
        text: this.$t('saveSuccess'),
        duration: 5000,
        type: "success"
      });
    }
  }
};
</script>

<style lang="scss">
// Коррекция размера экрана плеера. Без него камеры с большой высотой будут выходить на 100% высоты своего кадра и уходить за пределы окна.
.smart-player-screen {
  .smart-player__display {
    height: 460px;
  }
}
</style>
