/**
 * Отдельный vuex модуль для взаимодействия по employeePhotos-API
 */
import {DEFAULT_PAGE_SIZE_FOR_TABLE, getExtraApi, makeFilterApi, SORT_DIRECTIONS, SPECIAL_TYPES_DATA_IN_CELLS} from "@/store/pacs/helpers.js";

// actions для employeePhotos. Вызов действия начинать с "pacs/employeesPhoto/"
export const ACTION_LOAD_PHOTOS = "LOAD_PHOTOS";
export const ACTION_LOAD_PHOTOS_FOR_EMPLOYEE = "LOAD_PHOTOS_FOR_EMPLOYEE";
export const ACTION_CREATE_PHOTO = "CREATE_PHOTO";
export const ACTION_DELETE_PHOTO = "DELETE_PHOTO";

/**
 * Структура для общения с API по конкретной сущности.
 */
export const EMPLOYEE_PHOTO_STRUCT = Object.freeze({
  key: "id",
  fields: {
    id: {
      name: "id",
      title: "ID",
      type: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
    },
    image: {
      name: "image",
      title: "Изображение",
    },
    status: {
      name: "status",
      title: "Статус",
    },
    error_message: {
      name: "error_message",
      title: "Ошибка",
    },
    upload_date: {
      name: "upload_date",
      title: "Дата обновления",
    },
    width: {
      name: "width",
      title: "Ширина",
    },
    height: {
      name: "height",
      title: "Высота",
    },
    employee_id: {
      name: "employee_id",
      title: "Сотрудник",
      type: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
      extra: "employee",
    },
  },
  extras: {
    employee: "employee",
  },
  filters: {
    employee_id: "employee_id",
  },
});

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * Загрузка списка фото.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_PHOTOS](context, {page, pageSize, orderBy, filters, search}) {
      const fields = Object.keys(EMPLOYEE_PHOTO_STRUCT.fields),
        response = await this.getters.ajaxClient.post("/v0/employees/photo/", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          extra: getExtraApi(EMPLOYEE_PHOTO_STRUCT, fields),
          search,
        });
      return response.data;
    },
    /**
     * Загрузка фото по сотруднику.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} employeeId
     * @return {Promise}
     */
    async [ACTION_LOAD_PHOTOS_FOR_EMPLOYEE]({dispatch}, {page, employeeId}) {
      return dispatch(ACTION_LOAD_PHOTOS, {
        page: page,
        pageSize: DEFAULT_PAGE_SIZE_FOR_TABLE,
        orderBy: [{field: EMPLOYEE_PHOTO_STRUCT.key, direction: SORT_DIRECTIONS.DESC}],
        filters: [makeFilterApi(EMPLOYEE_PHOTO_STRUCT.filters.employee_id, "=", employeeId)],
      });
    },
    /**
     * Создание фото у конкретного сотрудника.
     *
     * @param {Object} context
     * @param {Number} employeeId
     * @param {String} photo
     * @return {Promise}
     */
    async [ACTION_CREATE_PHOTO](context, {employeeId, photo}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/employees/photo/create/", {
          image: photo,
          employee_id: employeeId
        });
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Удаление фото.
     *
     * @param {Object} context
     * @param {Array.<Number>} photosIds
     * @return {Promise}
     */
    async [ACTION_DELETE_PHOTO](context, photosIds) {
      return this.getters.ajaxClient.post("/v0/employees/photo/delete/", {ids: photosIds});
    },
  },
};
