/**
 * Здесь определены хелперы, используемые в нескольких местах вспомогательные функции
 */

/**
 * Формирование URL для получения скринов c камер.
 *
 * TODO: перевод на честные объекты {@link CameraInfo} в тех местах где используется эта функция
 */
export function getScreenshotUrl(camera, sizeScreenshot) {
  let frameUrl = `//${camera.server.domain}/${camera.number}/preview.mp4?token=${camera.token_l}`;
  if (camera.server.screenshot_domain) {
    frameUrl = `//${camera.server.screenshot_domain}/api/v0/screenshots/${camera.number}~${sizeScreenshot}.jpg?token=${camera.token_l}`;
  }
  return frameUrl;
}

/**
 * Вернет объект полученный из payload части JWT.
 *
 * @param {String} jwt
 * @return {Object}
 */
export function extractPayloadFromJWT(jwt) {
  return JSON.parse(atob(jwt.split(".", 3)[1]));
}

/**
 * Время обновления токена рассчитывается как заданное время минус его leadPercentage (=5%) для упреждения,
 * но чтобы результат не был меньше minUpdate (=60сек),
 * минус случайная величина в пределах maxJitter (=5 сек), для равномерной нагрузки.
 *
 * @param tokenTTL Кол-во секунд жизни токена, заявленное при запросе.
 * @param minUpdate Минимальное количество секунд, меньше которого расчетное время обновления быть не может.
 * @param leadPercentage Процент упреждения, зависит от tokenTTL и явно вычитается из него при расчете.
 * @param maxJitter Максимальное кол-во секунд для случайной величины, которое тоже будет прибавляться к итоговому результату.
 * @return {Number} Количество миллисекунд, через которое будет удобно запросить новый токен трансляции.
 */
export function whenToUpdateToken(tokenTTL, minUpdate = 60, leadPercentage = 10, maxJitter = 5) {
  const jitter = maxJitter * Math.random(),
    calcUpdate = Math.trunc(tokenTTL - (tokenTTL / leadPercentage));
  return (Math.max(calcUpdate, minUpdate) + jitter) * 1000;
}

/**
 * Вернет true если между старым и новым маршрутом нет изменений по локальным (местным для компонента) GET параметрам,
 * но есть другие изменения, скорее всего касающиеся глобальных параметров, которые отслеживаются в корневых компонентах.
 *
 * Использовать в случаях когда местные query параметры компонента могут пересекаться с другими,
 * в таких случаях следует точечно реагировать на изменения маршрута.
 *
 * @param {Object} from Объект предыдущего роута.
 * @param {Object} to Объект нового роута.
 * @param {Array.<String>} localQueryKeys Список ключей GET параметров которые надо отслеживать в роуте.
 * @return {Boolean}
 */
export function ifChangedOnlyGlobalQueryKeys(from, to, localQueryKeys) {
  return (to.name === from.name) && _.isEqual(_.pick(to.query, localQueryKeys), _.pick(from.query, localQueryKeys));
}

/**
 * Скачивание текста CSV в виде файла.
 *
 * @param {String} filename
 * @param {String} csvText
 */
export function downloadCSV(filename, csvText) {
  const blob = new Blob([`\uFEFF${csvText}`], {type: "text/csv;charset=utf-8;"});
  if (navigator.msSaveBlob) { // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    const downloadLink = document.createElement("a");
    if (downloadLink.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      downloadLink.setAttribute("href", URL.createObjectURL(blob));
      downloadLink.setAttribute("download", filename);
      downloadLink.style.visibility = "hidden";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  }
}
