/**
 * Отдельный vuex модуль для взаимодействия по analytics/perimeter_security-API
 */

import {ANALYTICS, AnalyticSettings} from "@/store/analytics/helpers.js";
import makeMessage from "@/store/analytics/analyticMessage.js";

// actions для perimeter_securitys. Вызов действия начинать с "analytics/perimeterSecuritys/"
export const ACTION_LOAD_PERIMETER_SECURITYS_SETTINGS = "LOAD_PERIMETER_SECURITYS_SETTINGS";
export const ACTION_LOAD_PERIMETER_SECURITY_SETTINGS = "LOAD_PERIMETER_SECURITY_SETTINGS";
export const ACTION_LOAD_PERIMETER_SECURITY_EVENTS = "LOAD_PERIMETER_SECURITY_EVENTS";
export const ACTION_LOAD_PERIMETER_SECURITY_EVENT = "LOAD_PERIMETER_SECURITY_EVENT";
export const ACTION_LOAD_PERIMETER_SECURITY_REPORT = "LOAD_PERIMETER_SECURITY_REPORT";

/**
 * Класс для представления настроек аналитики пересечения зоны.
 */
export class PerimeterSecuritySettings extends AnalyticSettings {
  get analyticType() {
    return ANALYTICS.perimeter_security;
  }
}

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * Загрузка списка настроек аналитик пересечения зоны.
     *
     * @param {Object} context
     * @param {Array.<String>} camerasNumbers
     * @return {Promise.<Array.<PerimeterSecuritySettings>>}
     */
    async [ACTION_LOAD_PERIMETER_SECURITYS_SETTINGS](context, {camerasNumbers}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/analytics/perimeter_security/settings/", {camera_numbers: camerasNumbers});
        return response.data.results.map(rawPerimeterSecuritySettings => new PerimeterSecuritySettings(rawPerimeterSecuritySettings));
      } catch {
        return [];
      }
    },
    /**
     * Загрузка настроек аналитики пересечения зоны с конкретной камеры.
     *
     * @param {Function} dispatch
     * @param {String} cameraNumber
     * @return {Promise.<PerimeterSecuritySettings>}
     */
    async [ACTION_LOAD_PERIMETER_SECURITY_SETTINGS]({dispatch}, {cameraNumber}) {
      const perimeterSecuritysSettings = await dispatch(ACTION_LOAD_PERIMETER_SECURITYS_SETTINGS, {camerasNumbers: [cameraNumber]});
      if (_.isEmpty(perimeterSecuritysSettings)) {
        return null;
      }
      return perimeterSecuritysSettings[0];
    },
    /**
     * Загрузка перечня событий по аналитике пересечения зоны с камеры для формирования отчета.
     *
     * @param {Object} context
     * @param {String} cameraNumber
     * @param {Array.<Number>} eventIds
     * @return {Promise.<Array.<PerimeterSecurityMessage>>}
     */
    async [ACTION_LOAD_PERIMETER_SECURITY_EVENTS](context, {cameraNumber, eventIds}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/analytics/perimeter_security/events/", {
          camera_number: cameraNumber,
          event_ids: eventIds,
        });
        return response.data.results.map(rawEventData => makeMessage(rawEventData, cameraNumber, ANALYTICS.perimeter_security));
      } catch {
        return [];
      }
    },
    /**
     * Загрузка перечня событий по аналитике пересечения зоны с камеры для формирования отчета.
     *
     * @param {Function} dispatch
     * @param {String} cameraNumber
     * @param {Number} eventId
     * @return {Promise.<PerimeterSecurityMessage>}
     */
    async [ACTION_LOAD_PERIMETER_SECURITY_EVENT]({dispatch}, {cameraNumber, eventId}) {
      const perimeterSecuritysSettings = await dispatch(ACTION_LOAD_PERIMETER_SECURITY_EVENTS, {cameraNumber, eventIds: [eventId]});
      if (_.isEmpty(perimeterSecuritysSettings)) {
        return null;
      }
      return perimeterSecuritysSettings[0];
    },
    /**
     * Загрузка перечня событий по определению пересечения зоны с камеры для формирования отчета.
     *
     * @param {Object} context
     * @param {String} cameraNumber
     * @param {Date} archiveFrom
     * @param {Date} archiveTo
     * @param {String} query
     * @return {Promise.<Array.<PerimeterSecurityMessage>>}
     */
    async [ACTION_LOAD_PERIMETER_SECURITY_REPORT](context, {cameraNumber, archiveFrom, archiveTo, query = null}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/analytics/perimeter_security/report/", {
          camera_number: cameraNumber,
          start: archiveFrom.toISOString(),
          end: archiveTo.toISOString(),
          query: query,
        });
        return response.data.results.map(rawEventData => makeMessage(rawEventData, cameraNumber, ANALYTICS.perimeter_security));
      } catch {
        return [];
      }
    },
  },
};
