/**
 * Отдельный vuex модуль для взаимодействия по analytics/thermal_vision-API
 */

import {ANALYTICS, AnalyticSettings, FIELDS_ANALYTIC_SETTINGS} from "@/store/analytics/helpers.js";
import makeMessage from "@/store/analytics/analyticMessage.js";

// actions для thermalVisions. Вызов действия начинать с "analytics/thermalVisions/"
export const ACTION_LOAD_THERMAL_VISIONS_SETTINGS = "LOAD_THERMAL_VISIONS_SETTINGS";
export const ACTION_LOAD_THERMAL_VISION_SETTINGS = "LOAD_THERMAL_VISION_SETTINGS";
export const ACTION_LOAD_THERMAL_VISION_EVENTS = "LOAD_THERMAL_VISION_EVENTS";
export const ACTION_LOAD_THERMAL_VISION_EVENT = "LOAD_THERMAL_VISION_EVENT";
export const ACTION_LOAD_THERMAL_VISION_REPORT = "LOAD_THERMAL_VISION_REPORT";

export const FIELDS_THERMAL_VISION_SETTINGS = Object.freeze({
  ...FIELDS_ANALYTIC_SETTINGS,
  face_recognition: "face_recognition",
});

/**
 * Класс для представления настроек аналитики по тепловизору.
 */
export class ThermalVisionSettings extends AnalyticSettings {
  constructor(rawAnalyticSettings) {
    super(rawAnalyticSettings);
    this.enabledFaceRecognition = rawAnalyticSettings[FIELDS_THERMAL_VISION_SETTINGS.face_recognition];
  }

  get analyticType() {
    return ANALYTICS.thermal_vision;
  }
}

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * Загрузка списка настроек аналитик тепловизоров.
     *
     * @param {Object} context
     * @param {Array.<String>} camerasNumbers
     * @return {Promise.<Array.<ThermalVisionSettings>>}
     */
    async [ACTION_LOAD_THERMAL_VISIONS_SETTINGS](context, {camerasNumbers}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/analytics/thermal_vision/settings/", {camera_numbers: camerasNumbers});
        return response.data.results.map(rawThermalVisionSettings => new ThermalVisionSettings(rawThermalVisionSettings));
      } catch {
        return [];
      }
    },
    /**
     * Загрузка настроек аналитики тепловизора с конкретной камеры.
     *
     * @param {Function} dispatch
     * @param {String} cameraNumber
     * @return {Promise.<ThermalVisionSettings>}
     */
    async [ACTION_LOAD_THERMAL_VISION_SETTINGS]({dispatch}, {cameraNumber}) {
      const thermalVisionsSettings = await dispatch(ACTION_LOAD_THERMAL_VISIONS_SETTINGS, {camerasNumbers: [cameraNumber]});
      if (_.isEmpty(thermalVisionsSettings)) {
        return null;
      }
      return thermalVisionsSettings[0];
    },
    /**
     * Загрузка перечня событий по тепловизору с камеры для формирования отчета.
     *
     * @param {Object} context
     * @param {String} cameraNumber
     * @param {Array.<Number>} eventIds
     * @return {Promise.<Array.<ThermalVisionMessage>>}
     */
    async [ACTION_LOAD_THERMAL_VISION_EVENTS](context, {cameraNumber, eventIds}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/analytics/thermal_vision/events/", {
          camera_number: cameraNumber,
          event_ids: eventIds,
        });
        return response.data.results.map(rawEventData => makeMessage(rawEventData, cameraNumber, ANALYTICS.thermal_vision));
      } catch {
        return [];
      }
    },
    /**
     * Загрузка перечня событий по тепловизору с камеры для формирования отчета.
     *
     * @param {Function} dispatch
     * @param {String} cameraNumber
     * @param {Number} eventId
     * @return {Promise.<ThermalVisionMessage>}
     */
    async [ACTION_LOAD_THERMAL_VISION_EVENT]({dispatch}, {cameraNumber, eventId}) {
      const thermalVisionMessages = await dispatch(ACTION_LOAD_THERMAL_VISION_EVENTS, {cameraNumber, eventIds: [eventId]});
      if (_.isEmpty(thermalVisionMessages)) {
        return null;
      }
      return thermalVisionMessages[0];
    },
    /**
     * Загрузка перечня событий по тепловизору с камеры для формирования отчета.
     *
     * @param {Object} context
     * @param {String} cameraNumber
     * @param {Date} archiveFrom
     * @param {Date} archiveTo
     * @param {Number} minTemperature
     * @param {Number} maxTemperature
     * @param {Boolean} alarm
     * @param {String} query
     * @return {Promise.<Array.<ThermalVisionMessage>>}
     */
    async [ACTION_LOAD_THERMAL_VISION_REPORT](context, {cameraNumber, archiveFrom, archiveTo, minTemperature = null, maxTemperature = null, alarm = null, query = null}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/analytics/thermal_vision/report/", {
          camera_number: cameraNumber,
          start: archiveFrom.toISOString(),
          end: archiveTo.toISOString(),
          temperature_min: minTemperature,
          temperature_max: maxTemperature,
          alarm: alarm,
          query: query,
        });
        return response.data.results.map(rawEventData => makeMessage(rawEventData, cameraNumber, ANALYTICS.thermal_vision));
      } catch {
        return [];
      }
    },
  },
};
