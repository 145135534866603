<template>
  <div class="one-screen-analytic-messages">
    <component
      :is="analyticMessageViewComponents[message.analyticType]"
      v-for="(message, messageIndex) in messages"
      :key="messageIndex"
      :analytic-message="message"
      class="one-screen-analytic-messages__analytic-message"
      @show-full-screenshot="$emit('show-full-screenshot', $event)"
    />
  </div>
</template>

<script>
import {CameraInfo} from "@/store/cameras/index.js";
import {analyticMessageViewMixin} from "@/components/events/mixins.js";
import SSEWorker from "@/store/analytics/sse.js";

/**
 * Компонент для отображения списка событий (сообщений), получаемых через Server Sent Events (SSE).
 * Применяется для отображения событий аналитики камер.
 */
export default {
  name: "LiveEvents",
  mixins: [
    // messageSoundNotificationMixin,
    analyticMessageViewMixin,
  ],
  props: {
    /**
     * Экземпляр {@link CameraInfo} по которому отображается информация в компоненте.
     */
    cameraInfo: {
      type: CameraInfo,
      required: true
    },
    /**
     * Экземпляры классов {@link AnalyticSettings} с настройками аналитики для выбора средств загрузки сопутствующей информации.
     */
    analyticsSettings: {
      type: Array,
      required: true
    },
    /**
     * Ограничение для количества отображаемых сообщений. Для избежания перегрузки DOM бесполезной информацией.
     *
     */
    messagesLimit: {
      type: Number,
      default: 1000,
    },
  },
  data() {
    return {
      registeredWorkers: [],
      messages: [],
    };
  },
  /**
   * Создание соединения с сервером и регистрация обработчиков событий.
   */
  mounted() {
    this.registeredWorkers = [];
    for (const analyticSettings of this.analyticsSettings) {
      const sseUrl = this.cameraInfo.getAnalyticSseUrl(analyticSettings.pushDomain, analyticSettings.analyticType);
      this.registeredWorkers.push(
        new SSEWorker(sseUrl, this.onNewMessage)
      );
    }
  },
  /**
   * Удаление обработчиков событий и закрытие соединений.
   */
  beforeDestroy() {
    for (const worker of this.registeredWorkers) {
      worker.close();
    }
  },
  methods: {
    /**
     * Срабатывает в момент получения нового сообщения аналитики.
     * Регистрирует новые сообщения, удаляет старые, вышедшее за рамки установленного лимита.
     *
     * @param {AnalyticMessage} analyticMessage
     */
    onNewMessage(analyticMessage) {
      this.messages.unshift(analyticMessage);
      analyticMessage.cameraInfo = this.cameraInfo;
      this.$emit("new-message", analyticMessage);

      // this.soundNotification();

      if (this.messages.length > this.messagesLimit) {
        this.messages.splice(this.messagesLimit + 1);
      }
    },
  }
};
</script>
