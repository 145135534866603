/**
 * Модуль vuex для объединения группы функций взаимодействующих с API СКУДа.
 * Повторяет аналогичное API из админки.
 */

import deviceAccessGroups from "@/store/pacs/deviceAccessGroups/index.js";
import devices from "@/store/pacs/devices/index.js";
import employees from "@/store/pacs/employees/index.js";
import employeesPhoto from "@/store/pacs/employeesPhoto/index.js";
import pacsPermissions from "@/store/pacs/pacsPermissions/index.js";
import cars from "@/store/pacs/cars/index.js";
import analytics from "@/store/pacs/analytics/index.js";
import {MAX_ENTITIES_FOR_MULTI_EDIT} from "@/utils/consts.js";
import {ACTION_LOAD_ENTITIES_FOR_MULTI_EDIT} from "@/store/pacs/actions.js";

export default {
  namespaced: true,
  modules: {
    deviceAccessGroups,
    devices,
    employees,
    employeesPhoto,
    pacsPermissions,
    cars,
    analytics,
  },
  actions: {
    /**
     * Действие для загрузки списка ключевых данных по конкретной сущности.
     * Необходим для дальнейшей передачи этих ключей в другие действия для массового редактирования.
     *
     * Поскольку API ограничивает максимальное количество сущностей 1000 то для получения полного списка данных необходимо
     * постранично получать и объединять все ключи.
     * Тем не менее установим некоторое искусственное ограничение чтобы не упасть в бесконечный цикл.
     *
     * @param {Object} context
     * @param {String} apiUrl
     * @param {String} keyField
     * @param {Object} dataForApi
     * @return {Promise<*[]>}
     */
    async [ACTION_LOAD_ENTITIES_FOR_MULTI_EDIT](context, {apiUrl, keyField, dataForApi}) {
      const entityKeys = [];
      let nextPage = 1;
      while (nextPage !== null) {
        const response = await this.getters.ajaxClient.post(apiUrl, {
          page: nextPage,
          page_size: 1000,
          fields: [keyField],
          ...dataForApi
        });

        entityKeys.push(..._.map(response.data.results, keyField));
        if (entityKeys.length >= MAX_ENTITIES_FOR_MULTI_EDIT) {
          throw null;
        }
        nextPage = response.data.page.next;
      }

      return entityKeys;
    },
  }
};
