<template>
  <MapTab key="mapTabMy" target="my" />
</template>

<script>
import MapTab from "@/components/map/MapTab.vue";

export default {
  name: "MapTabFav",
  components: {MapTab},
};
</script>
