import {ACTION_LOAD_CAMERA_FOR_PLAYER, CameraInfo} from "@/store/cameras/index.js";
import {ACTION_LOAD_THERMAL_VISION_SETTINGS} from "@/store/analytics/thermalVisions/index.js";
import {ANALYTICS} from "@/store/analytics/helpers.js";
import {ACTION_LOAD_CAR_NUMBER_SETTINGS} from "@/store/analytics/carNumbers/index.js";
import {ACTION_LOAD_FACE_RECOGNITION_SETTINGS} from "@/store/analytics/faceRecognitions/index.js";
import {ACTION_LOAD_HELMET_SETTINGS} from "@/store/analytics/helmets/index.js";
import {ACTION_LOAD_MOTION_ALARM_SETTINGS} from "@/store/analytics/motionAlarms/index.js";
import {ACTION_LOAD_MASK_SETTINGS} from "@/store/analytics/masks/index.js";
import {ACTION_LOAD_CROWD_SETTINGS} from "@/store/analytics/crowds/index.js";
import {TOKEN_TTL, VUEX_CACHE_TTL} from "@/utils/consts.js";
import {whenToUpdateToken} from "@/utils/helpers.js";
import {ACTION_LOAD_PERIMETER_SECURITY_SETTINGS} from "@/store/analytics/perimeterSecurity/index.js";
import {ACTION_LOAD_PEOPLE_COUNT_SETTINGS} from "@/store/analytics/peopleCount/index.js";

/**
 * Примесь для методов загрузки необходимой информации по камере для экранов.
 */
export const loadCameraInfoMixin = {
  props: {
    /**
     * Экземпляр {@link CameraInfo} по которому отображается информация в компоненте.
     */
    initialCameraInfo: {
      type: CameraInfo,
      required: true
    },
  },
  data() {
    return {
      isLoading: false,
      cameraInfo: null,
      analyticsSettings: [],
      tokenLiveTTL: TOKEN_TTL.PLAYER, // При запросе информации по камере еще запрашивать токен прямой трансляции.
      tokenArchiveTTL: TOKEN_TTL.PLAYER, // При запросе информации по камере еще запрашивать токен архива.
      $_loadCameraInfoMixin_refreshTimeoutId: null, // ID таймаута по истечении которого данные в cameraInfo будут автоматически обновлены.
    };
  },
  computed: {
    /**
     * @return {Boolean} Вернет true если в интерфейсе требуется использовать элементы аналитики по текущей камере.
     */
    analyticsMode() {
      return this.cameraInfo && this.cameraInfo.hasAnalytics && !_.isEmpty(this.analyticsSettings);
    },
  },
  /**
   * Загрузка актуальной информации по указанной камере при создании компонента, с нужным токеном и прочей недостающей информацией.
   */
  created() {
    this.setupCamera(this.initialCameraInfo.number);
  },
  /**
   * Очистка таймаута для обновления камеры.
   */
  beforeDestroy() {
    clearTimeout(this.$_loadCameraInfoMixin_refreshTimeoutId);
  },
  methods: {
    /**
     * Упорядоченная процедура получения всей информации по камере.
     *
     * @param {String} cameraNumber
     */
    async setupCamera(cameraNumber) {
      this.isLoading = true;
      await this.refreshCameraInfo(cameraNumber);
      await this.checkAnalytics();
      await this.afterLoadCameraInfo();
      this.isLoading = false;
    },
    /**
     * Обновление информации по переданной камере (номеру).
     *
     * Срабатывает при создании компонента и при обработке события о необходимости обновления камеры в дочерних компонентах.
     * Основанием для обновления камеры может служить просроченный токен трансляции, за которым здесь же происходит наблюдение
     * и рекурсивный вызов этой функции по таймауту.
     */
    async refreshCameraInfo(cameraNumber) {
      // Загрузка информации по указанной камере. Обрати внимание на наличие кеша и актуальный срок жизни токена - чтобы не пересекались!
      this.cameraInfo = await this.$store.cache.dispatch(`cameras/${ACTION_LOAD_CAMERA_FOR_PLAYER}`, {
        number: cameraNumber,
        tokenLiveTTL: this.tokenLiveTTL,
        tokenDVRTTL: this.tokenArchiveTTL,
      });

      clearTimeout(this.$_loadCameraInfoMixin_refreshTimeoutId);
      if (!this.cameraInfo) {
        return;
      }

      // Если в камере одновременно присутствует и токен прямой трансляции и архива то нет смысла следить за ними вместе,
      // т.к. оба приведут к перезагрузке камеры. Вместо этого следим за одним, а при обновлении камеры все равно оба токена обновятся.
      // Поскольку в методе используется кеш vuex, то при расчете надо учитывать ttl кеша в качестве минимального времени для обновления.
      if (this.cameraInfo.isReadyForLive) {
        this.$_loadCameraInfoMixin_refreshTimeoutId = setTimeout(() => {
          this.refreshCameraInfo(this.cameraInfo.number);
        }, whenToUpdateToken(this.tokenLiveTTL, VUEX_CACHE_TTL));
      } else if (this.cameraInfo.isReadyForArchive) {
        this.$_loadCameraInfoMixin_refreshTimeoutId = setTimeout(() => {
          this.refreshCameraInfo(this.cameraInfo.number);
        }, whenToUpdateToken(this.tokenArchiveTTL, VUEX_CACHE_TTL));
      }
    },
    /**
     * Функция проверяет возможности использования аналитики камеры на данном экране.
     */
    async checkAnalytics() {
      this.analyticsSettings = [];
      if (!this.cameraInfo || !this.cameraInfo.hasAnalytics) {
        return;
      }
      const actionsLoadAnalyticsSettings = {
        [ANALYTICS.thermal_vision]: `analytics/thermalVisions/${ACTION_LOAD_THERMAL_VISION_SETTINGS}`,
        [ANALYTICS.car_number]: `analytics/carNumbers/${ACTION_LOAD_CAR_NUMBER_SETTINGS}`,
        [ANALYTICS.face_recognition]: `analytics/faceRecognitions/${ACTION_LOAD_FACE_RECOGNITION_SETTINGS}`,
        [ANALYTICS.helmet]: `analytics/helmets/${ACTION_LOAD_HELMET_SETTINGS}`,
        [ANALYTICS.motion_alarm]: `analytics/motionAlarms/${ACTION_LOAD_MOTION_ALARM_SETTINGS}`,
        [ANALYTICS.mask]: `analytics/masks/${ACTION_LOAD_MASK_SETTINGS}`,
        [ANALYTICS.crowd]: `analytics/crowds/${ACTION_LOAD_CROWD_SETTINGS}`,
        [ANALYTICS.perimeter_security]: `analytics/perimeterSecuritys/${ACTION_LOAD_PERIMETER_SECURITY_SETTINGS}`,
        [ANALYTICS.people_counter]: `analytics/peopleCount/${ACTION_LOAD_PEOPLE_COUNT_SETTINGS}`,
      };

      for (const [analytic, action] of Object.entries(actionsLoadAnalyticsSettings)) {
        if (this.cameraInfo.analytics.includes(analytic)) {
          /**
           * @type {AnalyticSettings}
           */
          const analyticSettings = await this.$store.cache.dispatch(action, {
            cameraNumber: this.cameraInfo.number
          });
          if (analyticSettings && analyticSettings.isActive) {
            this.analyticsSettings.push(analyticSettings);
          }
        }
      }
    },
    /**
     * Функция для перекрытия и запуска операций, которые необходимо выполнить после загрузки
     * информации по камере.
     *
     * @return {Promise}
     */
    async afterLoadCameraInfo() {
    },
  }
};
