<template>
  <div :class="['loader', color]">
    Loading...
  </div>
</template>

<script>
/**
 * Компонент для отображения процесса загрузки в отдельном элементе не странице с возможностью установки цвета.
 */
export default {
  name: "SpinnerLoading",
  props: {
    color: {
      type: String,
      default: "blue",
      validator(value) {
        return ["blue", "white"].includes(value);
      },
    },
  },
};
</script>
