import my from "@/store/map/mapMy.js";
import fav from "@/store/map/mapFav.js";
import pub from "@/store/map/mapPub.js";

export default {
  namespaced: true,
  modules: {
    my,
    fav,
    pub,
  },
  state: {
    // центр карты по умолчанию
    defaultCenter: [54.734773, 55.957829],
    // минимальный зум (максимальный возможный размер карты)
    minZoom: 1.75,
    // зум по умолчанию
    zoom: 10,
  },
  mutations: {
  },
  actions: {},
};
