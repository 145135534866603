/**
 * Здесь собраны подключения библиотек их настроек, которые имеют отношение к опциональным возможностям конкретного
 * развернутого окружения (продукта), и не влияют на основной функционал системы.
 *
 * Необходимо придерживаться подхода чтобы билиотеки были полноценными (со стилями и прочим) и поменьше расходились по проекту.
 * Если щупальца расползаются то тут следует подписать в коментах куда надо еще идти и что искать.
 *
 * todo следует подумать о возможности управления этими подключениями на ходу а не во время билда приложения.
 * сейчас часть опций можно выносить в переменные окружения чтобы более опционально подходить к их подключению.
 *
 * todo как вариант - все вендорные вставки сопровождать коментом third party. Чтобы в будущем их было проще отследить и удалить.
 */
import Vue from "vue";
import router from "@/router/index.js";
// import ChatUfanet from "@/thirdParty/chatUfanet/index.js";
import VueYandexMetrika from "vue-yandex-metrika";
import FavoriteNotification from "./favoriteNotification/FavoriteNotification.vue";
import {VENDOR_IS_UFANET} from "@/utils/consts.js";

if (VENDOR_IS_UFANET) {
  /**
   * Чат для связи с СТП Уфанета.
   * @deprecated
   */
  // Vue.use(ChatUfanet);

  /**
   * Подключение яндекс.метрики.
   *
   * Реальные запросы в метрику уходят при NODE_ENV == production.
   * Если NODE_ENV != production && debug == true - все случаи когда запросы должны уходить - будут падать в консоль.
   *
   * см. public/index.html в теге noscript баннер.
   *
   * todo debug в переменные.
   */
  Vue.use(VueYandexMetrika, {
    id: 52381435,
    router: router,
    env: process.env.NODE_ENV,
    // debug: true,
    options: {
      accurateTrackBounce: false,
      clickmap: false,
      webvisor: false,
    },
  });

  /**
   * Прописан в App.vue и отображается ограниченному кругу лиц. Предполагается убрать в течении нескольких месяцев.
   */
  Vue.component("FavoriteNotification", FavoriteNotification);
}
