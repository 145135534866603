var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main__content content"},[_c('div',{staticClass:"pacs-settings-table"},[_c('div',{staticClass:"pacs-settings-table__group-setting"},[_c('SmartInputText',{staticClass:"pacs-settings-table__settings-control pacs-settings-table__settings-control_big",attrs:{"placeholder":_vm.$t('searchPlaceholder'),"size":"l"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.applyTableSettings()}},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}},[_c('CamsButton',{attrs:{"position":"r","size":"l","type":"button","icon-type":"only"},on:{"click":function($event){return _vm.applyTableSettings()}}},[_c('svg',{staticClass:"icon-search"},[_c('use',{attrs:{"xlink:href":"#icon-search"}})])])],1)],1)]),(_vm.isLoading)?_c('SpinnerLoadingModal'):_vm._e(),(_vm.isLoadingTable)?_c('SpinnerLoading',{staticClass:"loader_center",attrs:{"color":"blue"}}):_c('PacsTable',{attrs:{"column-captions":{
      id: 'tableHeaders.id',
      ident: 'tableHeaders.identifier',
      title: 'tableHeaders.name',
      emergency_vehicle_access: 'tableHeaders.emergencyVehicleAccess',
      camera_count: 'tableHeaders.cameraCount',
      is_active: 'tableHeaders.isActive',
    },"column-names":_vm.columnNames,"column-widths":_vm.columnWidths,"current-page":_vm.currentPage,"data-rows":_vm.dataRows,"key-field":_vm.keyField,"page-count":_vm.pageCount,"sort-info":_vm.sortInfo,"total-count":_vm.totalCount},on:{"new-page":_vm.selectPage,"new-sort":_vm.changeSort}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }