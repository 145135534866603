<template>
  <div class="video-popup__container" @mouseout="onPopupMouseOut" @mouseover="onPopupMouseOver">
    <div v-show="showContent" :title="favTitle">
      <svg v-if="camera.server" :class="icon" class="icon icon-heart video-popup__icon" @click="fav">
        <use xlink:href="#icon-heart" />
      </svg>
    </div>
    <p v-show="showContent" class="video-popup__desc">
      {{ camera.title }}
    </p>
    <video
      v-if="camera.server"
      :poster="screenshotUrl"
      autoplay
      class="video-popup__video"
      @click="play(number)"
    />
    <template v-if="camera.server">
      <img v-if="camera.server.screenshot_domain" :alt="camera.title" :src="screenshotUrl" class="video-popup__video">
      <video
        v-else
        :src="screenshotUrl"
        autoplay
        class="video-popup__video"
        @click="play(number)"
      />
    </template>
  </div>
</template>

<script>
import {MAP_TARGET} from "@/utils/consts.js";
import {getScreenshotUrl} from "@/utils/helpers.js";
import {CAMERA_SCREENSHOT_SIZES} from "@/store/cameras/index.js";
import {runPlayerMixin} from "@/components/map/mixins.js";

export default {
  name: "MapPopup",
  mixins: [runPlayerMixin],
  props: {
    number: {
      type: String,
      required: true,
    },
    target: {
      type: String,
      required: true,
      validator(value) {
        return value in MAP_TARGET;
      },
    },
  },
  data() {
    return {
      showContent: false,
    };
  },
  computed: {
    camera() {
      return this.$store.state.map[this.target].cameras[this.number];
    },
    screenshotUrl() {
      return getScreenshotUrl(this.camera, CAMERA_SCREENSHOT_SIZES.s400);
    },
    favTitle() {
      return this.camera.is_fav ? "Убрать из избранного" : "Добавить в избранное";
    },
    icon() {
      return this.camera.is_fav ? "video-popup__icon--fav" : "";
    },
  },
  methods: {
    onPopupMouseOver() {
      this.showContent = true;
      this.$emit("popupContentToggle", this.showContent);
    },
    onPopupMouseOut() {
      this.showContent = false;
      this.$emit("popupContentToggle", this.showContent);
    },
    fav() {
      this.$store.dispatch("apiFav", {
        cameraNumber: this.number,
        isFav: this.camera.is_fav,
      });
    },
  },
};
</script>
