<template>
  <div class="modal-container modal-container--show modal-container--init" style="display: flex; flex-direction: column">
    <div style=" color: white;  font-size: 20px; margin-bottom: 10px">
      {{ text }}
    </div>
    <div class="lds-css ng-scope">
      <div class="lds-double-ring" style="width:100%;height:100%">
        <div />
        <div />
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Компонент для отображения процесса загрузки в виде модального окна.
 */
export default {
  props: {
    /**
     * Вариант текста модального окна.
     *
     */
    text: {
      type: String,
      default: "",
    },
  }
};
</script>
