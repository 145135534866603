<template>
  <div class="analytic-report">
    <SpinnerLoading v-if="isLoading" class="loader_center" color="blue" />

    <template v-else-if="cameraInfo && cameraInfo.isAvailableArchive()">
      <div class="analytic-report__settings">
        <SmartInputDate
          v-model="archiveFrom"
          :config-flat-pickr="{minDate: minEventArchiveFrom, maxDate: maxEventArchiveTo}"
          :caption="$t('startDate')"
          class="analytic-report__settings-control analytic-report__settings-control_small"
        />
        <SmartInputDate
          v-model="archiveTo"
          :config-flat-pickr="{minDate: minEventArchiveFrom, maxDate: maxEventArchiveTo}"
          :caption="$t('endDate')"
          class="analytic-report__settings-control analytic-report__settings-control_small"
        />
        <CamsButton class="analytic-report__settings-control" type="button" @click="resetFilters()">
          {{ $t('reset') }}
        </CamsButton>
        <CamsButton class="analytic-report__settings-control" priority="primary" type="button" @click="downloadTableAsCSV()">
          {{ $t('downloadReport') }}
        </CamsButton>
      </div>

      <div class="analytic-report__table cams-table-wrapper">
        <SpinnerLoading v-if="isLoadingReport" class="loader_center" color="blue" />

        <table v-else-if="messages" class="cams-table">
          <tr>
            <th class="cams-table__cell cams-table__cell_fixed-width" />
            <th class="cams-table__cell">
              {{ $t('duration') }}
            </th>
            <th class="cams-table__cell">
              {{ $t('date') }}
            </th>
          </tr>
          <template v-for="message in messages">
            <tr :key="message.id" :class="{'warning warning-bg': message.alarm}">
              <td class="cams-table__cell cams-table__cell_fixed-width">
                <div class="analytic-report__event-actions">
                  <CamsButton
                    v-show="message.date >= minVideoArchiveFrom"
                    icon-type="only"
                    :title="$t('eventVideo')"
                    type="button"
                    @click="playEventStart(message, cameraInfo)"
                  >
                    <svg>
                      <use xlink:href="#icon-video-preview" />
                    </svg>
                  </CamsButton>
                  <CamsButton
                    v-show="message.date >= minVideoArchiveFrom"
                    icon-type="only"
                    :title="$t('downloadEventVideo')"
                    type="button"
                    @click="downloadEventVideo(message, cameraInfo)"
                  >
                    <svg>
                      <use xlink:href="../../../assets/img/icons.svg#cloud-archive" />
                    </svg>
                  </CamsButton>
                </div>
              </td>
              <td class="cams-table__cell">
                {{ message.duration }}
              </td>
              <td class="cams-table__cell">
                {{ message.date | localDateTime }}
              </td>
            </tr>
          </template>
        </table>

        <template v-else>
          <p>{{ $t('noEvents') }}</p>
        </template>
      </div>
    </template>

    <template v-else>
      <p>{{ $t('archiveError') }}</p>
    </template>

    <!--iframe для скачивания-->
    <iframe :src="downloadUrl" frameborder="0" height="1" width="1" />
  </div>
</template>

<script>
import {analyticReportMixin} from "@/components/oneScreen/reports/mixins.js";
import {ACTION_LOAD_MOTION_ALARM_REPORT} from "@/store/analytics/motionAlarms/index.js";
import {downloadCSV} from "@/utils/helpers.js";

/**
 * Отчет по движению.
 */
export default {
  name: "MotionAlarmReportScreen",
  mixins: [
    analyticReportMixin,
  ],
  data() {
    return {
      archiveFrom: null,
      archiveTo: null,
    };
  },
  computed: {
    /**
     * @return {Date} Корректное представление archiveFrom в сравнении с archiveTo.
     */
    clearArchiveFrom() {
      return this.archiveFrom && this.archiveTo ? new Date(Math.min(this.archiveFrom.getTime(), this.archiveTo.getTime())) : null;
    },
    /**
     * @return {Date} Корректное представление archiveTo в сравнении с archiveFrom.
     */
    clearArchiveTo() {
      return this.archiveFrom && this.archiveTo ? new Date(Math.max(this.archiveFrom.getTime(), this.archiveTo.getTime())) : null;
    },
  },
  watch: {
    // Отслеживание значений фильтров для автоматического обновления отчета.
    archiveFrom() {
      this.debouncedLoadReport();
    },
    archiveTo() {
      this.debouncedLoadReport();
    },
  },
  /**
   * Регистрируется отложенная загрузка отчета для случаев быстрого изменения фильтров.
   */
  created() {
    this.debouncedLoadReport = _.debounce(this.loadReport, 350);
  },
  methods: {
    /**
     * Сброс фильтров в начальное состояние.
     * Фильтры по умолчанию по датам за последний час.
     */
    resetFilters() {
      const archiveTo = new Date(),
            archiveFrom = new Date(archiveTo.getTime());
      archiveFrom.setDate(archiveTo.getDate() - 1);
      this.archiveFrom = archiveFrom;
      this.archiveTo = archiveTo;
    },
    /**
     * Загрузка отчета по заданным параметрам.
     */
    async loadReport() {
      if (!this.clearArchiveFrom || !this.clearArchiveTo) {
        return;
      }

      this.isLoadingReport = true;
      this.messages = [];
      this.messages = await this.$store.dispatch(`analytics/motionAlarms/${ACTION_LOAD_MOTION_ALARM_REPORT}`, {
        cameraNumber: this.cameraInfo.number,
        archiveFrom: this.clearArchiveFrom,
        archiveTo: this.clearArchiveTo,
      });
      this.isLoadingReport = false;
    },
    /**
     * Выгрузка данных таблицы в виде CSV.
     */
    downloadTableAsCSV() {
      let csvText = "";

      const filename = `${this.$t('report')} ${this.cameraInfo.title}.csv`,
            headers = [this.$t('duration'), this.$t('date')];
      csvText = `${headers.join(",")}\r\n`;
      this.messages.forEach((message) => {
        csvText += `${message.duration},${this.$options.filters.localDateTime(message.date)}\r\n`;
      });
      downloadCSV(filename, csvText);
    },
  },
};
</script>

<style lang="scss">
@import "./analytic-report.scss";
</style>
