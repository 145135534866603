/**
 * Перечень мутаций для хранилища состояния приложения.
 */

export const MUTATION_SET_CONTEXT = "SET_CONTEXT";
export const MUTATION_SET_USERNAME = "SET_USERNAME";
export const MUTATION_RESET_STATE = "RESET_STATE";
export const MUTATION_SET_IS_MOBILE = "SET_IS_MOBILE";
export const MUTATION_ACTUALIZE_ROUTE_SECTION = "ACTUALIZE_ROUTE_SECTION";
export const MUTATION_SET_ETAG = "SET_ETAG";
export const MUTATION_SET_NEED_UPDATE = "SET_NEED_UPDATE";
export const MUTATION_SET_SHARED_TIME_SHIFT = "SET_SHARED_TIME_SHIFT";
