import {SPECIAL_TYPES_DATA_IN_CELLS} from "@/store/pacs/helpers.js";
import {TableQueryParams} from "@/components/pacs/helpers.js";
import {QUERY_KEY_TABLE_PARAMS} from "@/router/queryKeys.js";

/**
 * Примесь для подготовки и отображения данных в виде таблицы по конкретной сущности.
 */
export const entityTableMixin = {
  data() {
    return {
      isLoading: false, // Флаг для общей блокировки страницы на время загрузки каких-то работ.
      isLoadingTable: false, // Флаг для загрузки таблицы.
      entityStruct: null, // Структура сущности по которой строится таблица.
      entityFields: [], // Список из раздела fields в структуре для отображения в таблице.
      entityFieldsForSort: [], // Список из раздела fields в структуре для работы сортировки.
      // Полное название action в vuex, которое предназначено для загрузки данных для мультиредактирования.
      // Применяется для случаев когда необходимо совершать выборку элементов за пределами текущей страницы.
      actionLoadDataForMultiEdit: null,
      // Вычисляемые данные для PacsTable.vue
      columnNames: [],
      columnCaptions: {},
      keyField: null,
      sortInfo: [], // Информация об используемых и доступных сортировках.
      filters: [], // Информация о применяемых фильтрах.
      // Настройка постраничной навигации.
      pageCount: 0,
      currentPage: 1,
      totalCount: 0,
      searchText: "", // Строка для поиска.
      dataRows: [], // Строки с данными для таблицы.
      selectedEntitiesForMultiEdit: [], // Список с ключами сущностей для групповой операции.
      // Приватные переменные.
      $_entityTableMixin_initDone: false, // Флаг начальной инициализации компонента.
    };
  },
  computed: {
    /**
     * Информация об используемых сортировках для передачи в API.
     *
     * @return {Array}
     */
    sortInfoForApi() {
      const sortInfoForApi = [];
      for (const sortInfoValue of this.sortInfo) {
        if (sortInfoValue[1]) {
          sortInfoForApi.push({
            field: sortInfoValue[0],
            direction: sortInfoValue[1],
          });
        }
      }
      return sortInfoForApi;
    },
    /**
     * @return {Boolean} Вернет true если требуется отобразить групповые операции.
     */
    showGroupOperations() {
      return this.actionLoadDataForMultiEdit && ((this.selectedEntitiesForMultiEdit.length > 0) || (this.selectedEntitiesForMultiEdit === true));
    }
  },
  watch: {
    /**
     * Перехват параметризованного URL - извлечение номера новой страницы и ее загрузка.
     * Хук beforeRouteUpdate работает только в компоненте, который непосредственно обслуживает маршрут,
     * но не его внутренние компоненты.
     *
     * @param {Object} to
     */
    $route(to) {
      this.parseQueryParams(to.query);
      this.$_entityTableMixin_initDone && this.loadPage();
    },
  },
  /**
   * Настройка таблицы.
   */
  created() {
    if (this.entityStruct) {
      this.keyField = this.entityStruct.key;
      this.columnNames = [];
      this.columnCaptions = {};
      this.sortInfo = [];
      for (const entityField of this.entityFields) {
        this.columnNames.push(entityField.name);
        this.columnCaptions[entityField.name] = entityField.title;
        if (entityField.sort) {
          this.sortInfo.push([entityField.name, null]);
        }
      }
    }
    // Все настройки переданные в URL накладываются поверх стандартных.
    this.parseQueryParams(this.$route.query);
    // После всех настроек ставится микрозадача на завершение настроек, после чего произойдет первая загрузка данных в таблицу.
    queueMicrotask(this.$_entityTableMixin_finishInit);
  },
  methods: {
    /**
     * Завершение инициализации и загрузка данных в таблицу.
     */
    $_entityTableMixin_finishInit() {
      this.$_entityTableMixin_initDone = true;
      this.loadPage();
    },
    /**
     * Приведет заданные настройки таблицы в строку для подстановки ее в адресную строку.
     *
     * Передача полученной строки в GET параметр и переход по готовому URL для применения настроек.
     * Переход по URL не осуществляется если новый и старый URL совпадают,
     * но при этом вызывается функция переданная в аргументе onAbort,
     * через нее принудительно вызывается разбор актуального URL для перезагрузки таблицы.
     */
    stringifyQueryParams({currentPage = null, order = null, filters = null, searchText = null,}) {
      const params = new TableQueryParams({
        currentPage: parseInt(currentPage) || this.currentPage,
        order: order || this.sortInfoForApi,
        filters: filters || this.filters,
        searchText: searchText || this.searchText,
      });

      this.$router.push(
        {name: this.$router.currentRoute.name, query: {[QUERY_KEY_TABLE_PARAMS]: params.stringify()}},
        null,
        () => {
          this.parseQueryParams(this.$route.query);
        }
      );
    },
    /**
     * Разбор параметров GET строки, парсинг и подстановка параметров в компонент.
     * Вызывать после осуществления навигации, для заполнения настроек переданными значениями или дефолтными.
     *
     * При присвоении настройкам таблицы значений из дефолтных, коими являются объекты - их необходимо присваивать
     * через полное, глубокое клонирование, чтобы избежать изменение оригинальных настроек, передающихся по ссылке.
     *
     * @param {Object} params
     */
    parseQueryParams(params) {
      const parsedParams = TableQueryParams.parse(params[QUERY_KEY_TABLE_PARAMS]);
      this.currentPage = parsedParams.currentPage || 1;
      const rawSortInfoForApi = parsedParams.order || [];
      for (const rawSortInfoForApiValue of rawSortInfoForApi) {
        this.$_entityTableMixin_changeSort([rawSortInfoForApiValue.field, rawSortInfoForApiValue.direction]);
      }
      // [] != false и parsedParams.filters != [] только если параметры пусты - значит это простой переход
      // к странице и в этом случае актуальны фильтры по умолчанию.
      this.filters = parsedParams.filters || [];
      this.searchText = parsedParams.searchText || "";
      // Обнуление значений для множественного редактирования.
      this.selectedEntitiesForMultiEdit = [];
    },
    /**
     * Переход к новой странице, который отражается в истории браузера.
     *
     * @param {Number} selectedPage
     */
    selectPage(selectedPage) {
      this.stringifyQueryParams({currentPage: selectedPage});
    },
    /**
     * Ручное применение настроек таблицы.
     */
    applyTableSettings() {
      this.stringifyQueryParams({currentPage: 1});
    },
    /**
     * Загрузка данных для таблицы по заданным настройкам.
     * Требует перекрытия в целевых компонентах.
     */
    async loadPage() {
    },
    /**
     * Для конкретной строки данных в таблице метод вернет объект, состоящий из данных для компонента PacsTableCell для каждой ячейки в строке.
     * {
     *   columnName: {
     *     type: null
     *     value: ""
     *     params: {}
     *   },
     *   ...
     * }
     *
     * @return {Object}
     */
    calcDefaultDataRow(rawEntityInfo) {
      const dataRow = {};
      if (this.keyField) {
        dataRow["_key_"] = rawEntityInfo[this.keyField];
      }
      for (const entityField of this.entityFields) {
        dataRow[entityField.name] = {
          type: entityField.type ?? SPECIAL_TYPES_DATA_IN_CELLS.TEXT,
          value: rawEntityInfo[entityField.name],
          params: {},
        };
      }
      return dataRow;
    },
    /**
     * Принимает пару значений с наименованием колонки и порядком сортировки
     * и сохраняет эти настройки сортировки.
     *
     * Новые настройки обычно получают через компонент таблиц, через соответствующее событие.
     *
     * @param {String} columnNameForNewSort
     * @param {String|null} newSortDirection
     */
    $_entityTableMixin_changeSort([columnNameForNewSort, newSortDirection]) {
      const indexForDelete = this.sortInfo.findIndex(([columnName,]) => columnName === columnNameForNewSort);
      if (indexForDelete >= 0) {
        this.sortInfo.splice(indexForDelete, 1);
        this.sortInfo.push([columnNameForNewSort, newSortDirection]);
      }
    },
    /**
     * Принимает новые настройки сортировки и применяет их к настройкам таблицы.
     *
     * @param {Array} newSortInfo
     */
    changeSort(newSortInfo) {
      this.$_entityTableMixin_changeSort(newSortInfo);
      this.stringifyQueryParams({});
    },
    /**
     * Загрузка информации из ключевого поля, для получения конечного набора сущностей, над которыми будет проводится редактирование.
     *
     * @return {Promise.<Array.<Number>>}
     */
    async getSelectedEntitiesForMultiEdit() {
      if (this.selectedEntitiesForMultiEdit === true) {
        this.isLoading = true;
        try {
          return await this.$store.dispatch(this.actionLoadDataForMultiEdit, {
            search: this.searchText,
            filters: this.filters,
          });
        } catch (error) {
          this.$camsdals.alert(
            error // В ACTION_LOAD_ENTITIES_FOR_MULTI_EDIT оговорена ошибка, которая выбрасывает null если есть проблемы с количеством.
              ? "При получении данных для групповой операции произошла непредвиденная ошибка."
              : "Выбрано слишком большое количество строк в таблице."
          );
          return [];
        } finally {
          this.isLoading = false;
        }
      }
      return this.selectedEntitiesForMultiEdit;
    },
  }
};

/**
 * Общие методы для применения на компонентах - диалоговых окон.
 */
export const methodsForDialogMixin = {
  /**
   * Фокус при показе диалогового окна, если нужно.
   */
  mounted() {
    if (this.$refs.elementForFocus) {
      this.$refs.elementForFocus.focus();
    }
  },
  methods: {
    /**
     * Закрытие диалогового окна.
     *
     * @param data
     */
    closeDialog(data) {
      this.$vuedals.close(data);
    },
  }
};

/**
 * Примесь для компонентов с функционалом сохранения данных по некоторой сущности.
 */
export const saveEntityMixin = {
  data() {
    return {
      /**
       * Маркер для отображения процесса загрузки.
       */
      isLoading: false,
      /**
       * Полное название action в vuex, которое предназначено для отправки новых данных.
       * Требует перекрытия.
       */
      actionForSave: null,
      /**
       * Перечень моделей для полей с редактируемыми данными.
       * Ключи могут быть самостоятельными и не привязаны к реальным полям в API.
       */
      dataForm: {},
      /**
       * Перечень ошибок валидации по полям, для показа на форме.
       * Для удобства ключи следует соотносить с теми, что указаны в {@see dataForm}.
       */
      dataErrors: {},
    };
  },
  methods: {
    /**
     * Вернет набор данных пригодных к сохранению.
     * В наследниках может быть перекрыт с целью преобразования содержимого некоторых полей.
     *
     * @return {Object}
     */
    getDataForSave() {
      return this.dataForm;
    },
    /**
     * Вернет маршрут по которому будет совершено перенаправление после успешного сохранения сущности.
     * Принимает информацию по новой созданной сущности.
     * Если ничего не вернет - редиректа не будет.
     *
     * @param {Object} entityInfo
     * @return {boolean|null|Object}
     */
    // eslint-disable-next-line no-unused-vars
    getRouteRedirectAfterSave(entityInfo) {
      return false;
    },
    /**
     * Переадресация на страницу просмотра и редактирования сохраненной сущности.
     *
     * @param {Object} entityInfo
     */
    redirectTo(entityInfo) {
      const routeRedirect = this.getRouteRedirectAfterSave(entityInfo);
      if (routeRedirect) {
        this.$router.push(routeRedirect);
      }
    },
    /**
     * Отправка данных на сервер для сохранения сущности.
     *
     * @return {Promise}
     */
    async saveData() {
      this.isLoading = true;
      this.dataErrors = {};
      try {
        return await this.$store.dispatch(this.actionForSave, this.getDataForSave());
      } catch ([dataErrors, apiError]) {
        this.dataErrors = dataErrors;
        throw apiError;
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * Отправка данных на сервер для сохранения сущности и в случае успеха редирект по определенному маршруту.
     *
     * @return {Promise}
     */
    async saveDataAndRedirect() {
      this.isLoading = true;
      try {
        const entityInfo = await this.saveData();
        this.redirectTo(entityInfo);
      } catch {
        // Перехват пробрасываемого исключения с ошибками валидации.
      }
      this.isLoading = false;
    }
  }
};

/**
 * Примесь для компонентов для создания некоторой сущности.
 * Как правило, создание сущности происходит в диалоговом окне.
 */
export const createEntityMixin = {
  mixins: [saveEntityMixin, methodsForDialogMixin],
  methods: {
    /**
     * Переадресация на страницу просмотра и редактирования сохраненной сущности.
     *
     * @param {Object} entityInfo
     */
    redirectTo(entityInfo) {
      const routeRedirect = this.getRouteRedirectAfterSave(entityInfo);
      if (routeRedirect) {
        this.$router.push(routeRedirect);
        this.$vuedals.close();
      }
    },
  }
};

/**
 * Примесь для компонентов редактирования данных некоторой сущности.
 */
export const editEntityMixin = {
  mixins: [saveEntityMixin], // , routeBackMixin
  data() {
    return {
      /**
       * Значение ключевого идентификатора сущности для его передачи в API для получения и сохранения данных.
       * Требует перекрытия.
       */
      entityId: null,
      /**
       * Полное название action в vuex, которое предназначено для загрузки данных по конкретной сущности.
       * Требует перекрытия.
       */
      actionForLoadEntity: null,
      /**
       * Исходные данные по редактируемой сущности и дополнительная информация по ней.
       */
      sourceData: {
        entityInfo: {},
        extraInfo: {}
      },
      /**
       * Флаг для определения что в процессе загрузки информации о сущности произошла ошибка и страница не может быть отображена.
       */
      errorLoadEntity: false,
    };
  },
  /**
   * Загрузка данных для редактирования.
   */
  created() {
    this.loadSourceData();
  },
  methods: {
    /**
     * Загрузит информацию для редактирования и подставит загружаемые данные "как есть" в поля формы.
     *
     * @return {Promise}
     */
    async loadSourceData() {
      this.isLoading = true;
      this.errorLoadEntity = false;
      try {
        const {entityInfo, extraInfo} = await this.$store.dispatch(this.actionForLoadEntity, this.entityId);
        if (!entityInfo) {
          throw 0;
        }
        this.sourceData.entityInfo = entityInfo;
        this.sourceData.extraInfo = extraInfo;
        await this.afterLoadSourceData();
      } catch {
        this.errorLoadEntity = true;
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * Метод по умолчанию вызовется при загрузке данных для редактирования.
     * Необходим для операций по уточнению загруженной информации.
     *
     * @return {Promise}
     */
    async afterLoadSourceData() {
      this.dataForm = _.cloneDeep(this.sourceData.entityInfo);
    },
    /**
     * Сохранение данных и продолжение редактирования.
     * После успешного сохранения данных происходит перезагрузка исходных данных.
     *
     * @return {Promise}
     */
    async saveDataAndReload() {
      try {
        await this.saveData();
        await this.loadSourceData();
      } catch {
        // Перехват пробрасываемого исключения с ошибками валидации.
      }
    },
    /**
     * Сохранение данных и редирект по маршруту routeBack.
     *
     * @return {Promise}
     */
    async saveDataAndRedirect() {
      try {
        await this.saveData();
        this.$router.push(this.routeBack);
      } catch {
        // Перехват пробрасываемого исключения с ошибками валидации.
      }
    },
  }
};
