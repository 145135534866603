/**
 * Отдельный vuex модуль для взаимодействия по pacs/analytics-API
 */
import makeMessage from "@/store/analytics/analyticMessage.js";
import {ANALYTICS} from "@/store/analytics/helpers.js";
import {cancelRequestOnReentry, handleErrorInRequest} from "camsng-frontend-shared/lib/requestAnnihilator.js";

// actions для analytics. Вызов действия начинать с "pacs/analytics/"
export const ACTION_LOAD_PACS_THERMAL_VISION_REPORT = "LOAD_PACS_THERMAL_VISION_REPORT";
export const ACTION_LOAD_PACS_MOTION_ALARM_REPORT = "LOAD_PACS_MOTION_ALARM_REPORT";
export const ACTION_LOAD_PACS_CAR_NUMBER_REPORT = "LOAD_PACS_CAR_NUMBER_REPORT";
export const ACTION_LOAD_PACS_HELMET_REPORT = "LOAD_PACS_HELMET_REPORT";
export const ACTION_LOAD_PACS_MASK_REPORT = "LOAD_PACS_MASK_REPORT";
export const ACTION_LOAD_PACS_PERIMETER_SECURITY_REPORT = "LOAD_PACS_PERIMETER_SECURITY_REPORT";
export const ACTION_LOAD_PACS_CROWD_REPORT = "LOAD_PACS_CROWD_REPORT";

export default {
  namespaced: true,
  actions: {
    /**
     * Загрузка перечня событий по тепловизору с камеры для формирования отчета.
     *
     * @param {Object} context
     * @param {Date} archiveFrom
     * @param {Date} archiveTo
     * @param {Number} minTemperature
     * @param {Number} maxTemperature
     * @param {Boolean} alarm
     * @param {String} query
     * @return {Promise.<Array.<ThermalVisionMessage>>}
     */
    async [ACTION_LOAD_PACS_THERMAL_VISION_REPORT](context, {
      archiveFrom,
      archiveTo,
      minTemperature = null,
      maxTemperature = null,
      alarm = null,
      query = null
    }) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/pacs/analytics/thermal_vision/report/", {
          start: archiveFrom.toISOString(),
          end: archiveTo.toISOString(),
          temperature_min: minTemperature,
          temperature_max: maxTemperature,
          alarm: alarm,
          query: query,
        }, {cancelToken: cancelRequestOnReentry(ACTION_LOAD_PACS_THERMAL_VISION_REPORT)});
        return response.data.results.map(rawEventData => makeMessage(rawEventData, null, ANALYTICS.thermal_vision));
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    /**
     * Загрузка перечня событий по распознаванию движения с камеры для формирования отчета.
     *
     * @param {Object} context
     * @param {Date} archiveFrom
     * @param {Date} archiveTo
     * @return {Promise.<Array.<MotionAlarmMessage>>}
     */
    async [ACTION_LOAD_PACS_MOTION_ALARM_REPORT](context, {archiveFrom, archiveTo}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/pacs/analytics/motion_alarm/report/", {
          start: archiveFrom.toISOString(),
          end: archiveTo.toISOString(),
        }, {cancelToken: cancelRequestOnReentry(ACTION_LOAD_PACS_MOTION_ALARM_REPORT)});
        return response.data.results.map(rawEventData => makeMessage(rawEventData, null, ANALYTICS.motion_alarm));
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    /**
     * Загрузка перечня событий по распознаванию касок для формирования отчета.
     *
     * @param {Object} context
     * @param {Date} archiveFrom
     * @param {Date} archiveTo
     * @return {Promise.<Array.<HelmetMessage>>}
     */
    async [ACTION_LOAD_PACS_HELMET_REPORT](context, {archiveFrom, archiveTo}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/gangs/analytics/helmet/report/", {
          start: archiveFrom.toISOString(),
          end: archiveTo.toISOString(),
        }, {cancelToken: cancelRequestOnReentry(ACTION_LOAD_PACS_HELMET_REPORT)});
        return response.data.results.map(rawEventData => makeMessage(rawEventData, null, ANALYTICS.helmet));
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    /**
     * Загрузка перечня событий по вторжению в зону для формирования отчета.
     *
     * @param {Object} context
     * @param {Date} archiveFrom
     * @param {Date} archiveTo
     * @return {Promise.<Array.<MaskMessage>>}
     */
    async [ACTION_LOAD_PACS_PERIMETER_SECURITY_REPORT](context, {archiveFrom, archiveTo}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/gangs/analytics/perimeter_security/report/", {
          start: archiveFrom.toISOString(),
          end: archiveTo.toISOString(),
        }, {cancelToken: cancelRequestOnReentry(ACTION_LOAD_PACS_PERIMETER_SECURITY_REPORT)});
        return response.data.results.map(rawEventData => makeMessage(rawEventData, null, ANALYTICS.perimeter_security));
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    /**
     * Загрузка перечня событий по распознаванию масок для формирования отчета.
     *
     * @param {Object} context
     * @param {Date} archiveFrom
     * @param {Date} archiveTo
     * @return {Promise.<Array.<MaskMessage>>}
     */
    async [ACTION_LOAD_PACS_MASK_REPORT](context, {archiveFrom, archiveTo}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/gangs/analytics/mask/report/", {
          start: archiveFrom.toISOString(),
          end: archiveTo.toISOString(),
        }, {cancelToken: cancelRequestOnReentry(ACTION_LOAD_PACS_MASK_REPORT)});
        return response.data.results.map(rawEventData => makeMessage(rawEventData, null, ANALYTICS.mask));
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    /**
     * Загрузка перечня событий по распознаванию скопления людей для формирования отчета.
     *
     * @param {Object} context
     * @param {Date} archiveFrom
     * @param {Date} archiveTo
     * @return {Promise.<Array.<MaskMessage>>}
     */
    async [ACTION_LOAD_PACS_CROWD_REPORT](context, {archiveFrom, archiveTo}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/gangs/analytics/crowd/report/", {
          start: archiveFrom.toISOString(),
          end: archiveTo.toISOString(),
        }, {cancelToken: cancelRequestOnReentry(ACTION_LOAD_PACS_CROWD_REPORT)});
        return response.data.results.map(rawEventData => makeMessage(rawEventData, null, ANALYTICS.crowd));
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
    /**
     * Загрузка перечня событий по автомобильным номерам с камеры для формирования отчета.
     *
     * @param {Object} context
     * @param {Date} archiveFrom
     * @param {Date} archiveTo
     * @param {String} number
     * @param {Boolean} access
     * @param {String} query
     * @return {Promise.<Array.<CarNumberMessage>>}
     */
    async [ACTION_LOAD_PACS_CAR_NUMBER_REPORT](context, {
      archiveFrom,
      archiveTo,
      number = null,
      access = null,
      query = null
    }) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/pacs/analytics/car_number/report/", {
          start: archiveFrom.toISOString(),
          end: archiveTo.toISOString(),
          number: number,
          access: access,
          query: query,
        }, {cancelToken: cancelRequestOnReentry(ACTION_LOAD_PACS_CAR_NUMBER_REPORT)});
        return response.data.results.map(rawEventData => makeMessage(rawEventData, null, ANALYTICS.car_number));
      } catch (error) {
        handleErrorInRequest(error);
      }
    },
  }
};