import {eventHandlersMixin} from "@/components/events/mixins.js";
import {parseQueryParams, stringifyQueryParams} from "@/components/pacs/helpers.js";
import {QUERY_KEY_ONE_SCREEN_CAMERA_NUMBER, QUERY_KEY_ONE_SCREEN_TIME_SHIFT, QUERY_KEY_TABLE_PARAMS} from "@/router/queryKeys.js";
import {ifChangedOnlyGlobalQueryKeys} from "@/utils/helpers.js";
import {ACTION_LOAD_CAMERA_FOR_PLAYER} from "@/store/cameras/index.js";
import {TOKEN_TTL} from "@/utils/consts.js";

/**
 * Примесь с общими функциями для представления событий в таблицах.
 * Урезанная версия {@see entityTableMixin}, в отличие от которой в таблицах представлены честные JS-объекты типа {@see AnalyticMessage},
 * а не абстрактные объекты из API.
 */
export const eventTableMixin = {
  mixins: [
    eventHandlersMixin,
  ],
  data() {
    return {
      isLoading: false,
      // Данные для PacsTable.vue
      columnNames: [],
      columnCaptions: {},
      filters: {}, // Информация о применяемых фильтрах.
      dataRows: [], // Строки с данными для таблицы.
      // Приватные переменные.
      $_entityTableMixin_initDone: false, // Флаг начальной инициализации компонента.
    };
  },
  watch: {
    /**
     * Перехват параметризованного URL - извлечение номера новой страницы и ее загрузка.
     * Хук beforeRouteUpdate работает только в компоненте, который непосредственно обслуживает маршрут,
     * но не его внутренние компоненты.
     *
     * @param {Object} to
     * @param {Object} from
     */
    $route(to, from) {
      if (ifChangedOnlyGlobalQueryKeys(from, to, [QUERY_KEY_TABLE_PARAMS])) {
        return;
      }
      this.assignQueryParams(parseQueryParams(to.query[QUERY_KEY_TABLE_PARAMS]));
      this.$_entityTableMixin_initDone && this.loadPage();
    },
  },
  /**
   * Настройка таблицы.
   */
  created() {
    // Все настройки переданные в URL накладываются поверх стандартных.
    this.assignQueryParams(parseQueryParams(this.$route.query[QUERY_KEY_TABLE_PARAMS]));
    // После всех настроек ставится микрозадача на завершение настроек, после чего произойдет первая загрузка данных в таблицу.
    queueMicrotask(this.$_entityTableMixin_finishInit);
  },
  methods: {
    /**
     * Завершение инициализации и загрузка данных в таблицу.
     */
    $_entityTableMixin_finishInit() {
      this.$_entityTableMixin_initDone = true;
      this.loadPage();
    },
    /**
     * Ручное применение настроек таблицы.
     * Может быть перекрыто с целью уточнения какие именно настройки следует применить.
     */
    applyTableSettings() {
      this.stringifyQueryParams({});
    },
    /**
     * Приведет заданные настройки таблицы в строку для подстановки ее в адресную строку.
     *
     * Передача полученной строки в GET параметр и переход по готовому URL для применения настроек.
     * Переход по URL не осуществляется если новый и старый URL совпадают,
     * но при этом вызывается функция переданная в аргументе onAbort,
     * через нее принудительно вызывается разбор актуального URL для перезагрузки таблицы.
     *
     * @param {Object} queryParams
     */
    stringifyQueryParams(queryParams = {}) {
      this.$router.push(
        {name: this.$router.currentRoute.name, query: {[QUERY_KEY_TABLE_PARAMS]: stringifyQueryParams(queryParams)}},
        null,
        () => {
          this.assignQueryParams(parseQueryParams(this.$route.query[QUERY_KEY_TABLE_PARAMS]));
        }
      );
    },
    /**
     * Принимает разобранные параметры  GET строки для дальнейшей подстановки в компонент.
     * Какие параметры в какие переменные нужно подставить решается в перекрытом в компоненте методе.
     * Вызывать после осуществления навигации, для заполнения настроек переданными значениями или дефолтными.
     *
     * При присвоении настройкам таблицы значений из дефолтных, коими являются объекты - их необходимо присваивать
     * через полное, глубокое клонирование, чтобы избежать изменение оригинальных настроек, передающихся по ссылке.
     *
     * @param {Object} parsedQueryParams
     */
    // eslint-disable-next-line no-unused-vars
    assignQueryParams(parsedQueryParams) {
    },
    /**
     * Загрузка данных для таблицы по заданным настройкам.
     * Требует перекрытия в целевых компонентах.
     */
    async loadPage() {
    },
    /**
     * Получение информации по камере и открытие диалогового окна со скриншотом события.
     *
     * @param {AnalyticMessage} analyticMessage
     */
    async showFullScreenshotForEventInTable(analyticMessage) {
      const cameraInfo = await this.$store.cache.dispatch(`cameras/${ACTION_LOAD_CAMERA_FOR_PLAYER}`, {
        number: analyticMessage.cameraNumber,
        tokenLiveTTL: TOKEN_TTL.PLAYER,
        tokenDVRTTL: TOKEN_TTL.PLAYER,
      });
      if (!cameraInfo) {
        this.$camsdals.alert("Недостаточно прав для просмотра скриншота с этой камеры.");
        return;
      }
      this.showFullScreenshot(analyticMessage, cameraInfo);
    },
    /**
     * Получение информации по камере и скачивание видео события.
     *
     * @param {AnalyticMessage} analyticMessage
     */
    async downloadEventVideoInTable(analyticMessage) {
      const cameraInfo = await this.$store.cache.dispatch(`cameras/${ACTION_LOAD_CAMERA_FOR_PLAYER}`, {
        number: analyticMessage.cameraNumber,
        tokenLiveTTL: TOKEN_TTL.PLAYER,
        tokenDVRTTL: TOKEN_TTL.PLAYER,
      });
      if (!cameraInfo) {
        this.$camsdals.alert("Недостаточно прав для скачивания видео с этой камеры.");
        return;
      }
      this.downloadEventVideo(analyticMessage, cameraInfo);
    },
    /**
     * Открытие плеера и воспроизведение архива начиная с момента события.
     *
     * @param {AnalyticMessage} analyticMessage
     */
    async playEventStartForEventInTable(analyticMessage) {
      this.$router.push({
        name: this.$route.name,
        query: {
          ...(this.$route.query || {}),
          [QUERY_KEY_ONE_SCREEN_CAMERA_NUMBER]: analyticMessage.cameraNumber,
          [QUERY_KEY_ONE_SCREEN_TIME_SHIFT]: +analyticMessage.date,
        }
      });
    },
  }
};
