<template>
  <div class="pacs-container-entity">
    <SpinnerLoadingModal v-if="isLoading" />
    <p v-else-if="errorLoadEntity">
      {{ $t('errorLoadEntity') }}
    </p>
    <form v-else class="pacs-entity-form-primary" @keyup.enter="saveDataAndReload()" @submit.prevent="saveDataAndReload()">
      <SmartInputText
        v-model="dataForm.carNumber"
        :error="dataErrors.carNumber"
        :caption="$t('carNumber')"
        class="input-row"
        maxlength="9"
      />

      <SmartVSelect
        v-model="dataForm.employeeId"
        :error="dataErrors.employeeId"
        :initial-options="initialOptionsEmployees"
        :settings-remote-search="settingsSelectEmployees"
        :caption="$t('carOwner')"
        class="input-row"
        :placeholder="$t('enterEmployeeName')"
      />

      <div class="buttons-group input-row">
        <CamsButton priority="primary" type="submit" @click="saveDataAndReload()">
          {{ $t('save') }}
        </CamsButton>
        <CamsButton type="button" @click="deleteCar()">
          {{ $t('delete') }}
        </CamsButton>
      </div>
    </form>
  </div>
</template>

<script>
import {editEntityMixin} from "@/components/pacs/mixins.js";
import {ACTION_DELETE_CARS, ACTION_EDIT_CAR, ACTION_LOAD_CAR_FOR_EDIT, CAR_STRUCT} from "@/store/pacs/cars/index.js";
import {ACTION_LOAD_EMPLOYEES_FOR_SELECT, EMPLOYEE_STRUCT} from "@/store/pacs/employees/index.js";
import {ROUTE_PACS_CARS} from "@/router/names.js";

/**
 * Компонент страницы редактирования автомобиля.
 */
export default {
  mixins: [editEntityMixin],
  data() {
    const carId = Number(this.$route.params.carId);

    return {
      entityId: carId,
      actionForSave: `pacs/cars/${ACTION_EDIT_CAR}`,
      actionForLoadEntity: `pacs/cars/${ACTION_LOAD_CAR_FOR_EDIT}`,
      dataForm: {
        carId,
        carNumber: "",
        employeeId: null,
      },
      initialOptionsEmployees: [],
      settingsSelectEmployees: {
        action: `pacs/employees/${ACTION_LOAD_EMPLOYEES_FOR_SELECT}`,
        valueField: EMPLOYEE_STRUCT.fields.id.name,
        labelField: EMPLOYEE_STRUCT.fields.title.name,
      },
    };
  },
  methods: {
    /**
     * @link editEntityMixin.methods.afterLoadSourceData
     */
    async afterLoadSourceData() {
      this.dataForm = {
        ...this.dataForm,
        carNumber: this.sourceData.entityInfo[CAR_STRUCT.fields.number.name],
        employeeId: this.sourceData.entityInfo[CAR_STRUCT.fields.employee_id.name]
      };
      this.initialOptionsEmployees = this.sourceData.extraInfo[CAR_STRUCT.extras.employee];
    },
    async saveDataAndReload() {
      try {
        await this.saveData();
        await this.loadSourceData();
        this.$notify({
          group: "main",
          text: this.$t('saveSuccess'),
          duration: 5000,
          type: "default"
        });
      } catch {
        // Перехват пробрасываемого исключения с ошибками валидации.
      }
    },
    /**
     * Отправка запроса на удаление текущего автомобиля.
     */
    deleteCar() {
      this.$camsdals.confirm(this.$t('confirmDeleteCar'), async () => {
        this.isLoading = true;
        try {
          await this.$store.dispatch(`pacs/cars/${ACTION_DELETE_CARS}`, [this.entityId]);
          await this.$router.push({name: ROUTE_PACS_CARS});
          this.$camsdals.alert(this.$t('carDeleted'));
        } catch {
          this.$camsdals.alert(this.$t('errorDeleteCar'));
        }
        this.isLoading = false;
      });
    },
  },
};
</script>
