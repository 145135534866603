/**
 * Этот файл - главная точка подключения всех своих и сторонних js библиотек и стилей.
 * В статическом public/index.html размещен базовый HTML шаблон, в котором подключается приложение.
 * Здесь не описывается поведение компонентов, а происходит только их загрузка.
 * Поведение главного компонента размещено в components/App.vue - начиная с него стартует вся логика.
 */

import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";

import "svgxuse";
// deprecated осталось захватить стандартные стили для ссылок, кнопок и шрифтов.
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import Vue from "vue";
import {default as Vuedals} from "vuedals";
import Paginate from "vuejs-paginate";
import App from "@/components/App.vue";
import router from "@/router/index.js";
import store from "@/store/index.js";
import VueObserveVisibility from "vue-observe-visibility";
import L from "leaflet";
import iconRetinaUrl from "@/assets/img/icons/map-pointer2х.png";
import iconUrl from "@/assets/img/icons/map-pointer1х.png";
import shadowUrl from "leaflet/dist/images/marker-shadow.png";
import Notifications from "vue-notification";
import "@/thirdParty/index.js";
import "camsng-frontend-shared";
// todo Компоненты с таким же именем есть в shared
import SpinnerLoadingModal from "@/components/spinnerLoading/SpinnerLoadingModal.vue";
import SpinnerLoading from "@/components/spinnerLoading/SpinnerLoading.vue";

import "@/assets/sass/style.scss";
import i18n from "/i18n/i18n.js";
import {CURRENT_LOCALE} from "@/utils/consts.js";

// немного магии а то возникают проблемы с иконками в leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl,
  iconUrl,
  iconSize: [37, 52],
  iconAnchor: [18, 51],
  popupAnchor: [0, -47],
  shadowAnchor: [12, 39],
  shadowUrl,
});

Vue.use(VueObserveVisibility);
Vue.use(Notifications);
Vue.config.productionTip = false;
// Компонент постраничной навигации.
Vue.component("Paginate", Paginate);
// Компонент создания модальных окон.
Vue.use(Vuedals);
// Анимация загрузки.
Vue.component("SpinnerLoadingModal", SpinnerLoadingModal);
Vue.component("SpinnerLoading", SpinnerLoading);
Vue.mixin(CURRENT_LOCALE);

new Vue({
  el: "#app",
  router,
  store,
  i18n,
  render: h => h(App),
});
