/**
 * Основное хранилище состояния клиентского приложения.
 *
 * Включает в себя основную работу с сервером по API в части авторизации и получения базовой информации.
 * Целевая работа с отдельными сущностями происходит в модулях vuex.
 */

import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import createCache from "vuex-cache";

import map from "@/store/map/map.js";
import cameras from "@/store/cameras/index.js";
import mosaics from "@/store/mosaics/index.js";
import meshCameras from "@/store/meshCameras/index.js";
import analytics from "@/store/analytics/index.js";
import pacs from "@/store/pacs/index.js";
import users from "@/store/users/index.js";
import setup from "@/store/setup/index.js";
import folders from "@/store/folders/index.js";

import {CONFIG_AJAX_PREFIX_HEADER_AUTH, CONFIG_AJAX_TIMEOUT, CONFIG_AUTH_TOKEN, CONFIG_BASE_URL, VUEX_CACHE_TTL,} from "@/utils/consts.js";
import {
  MUTATION_ACTUALIZE_ROUTE_SECTION,
  MUTATION_RESET_STATE,
  MUTATION_SET_CONTEXT,
  MUTATION_SET_ETAG,
  MUTATION_SET_IS_MOBILE,
  MUTATION_SET_NEED_UPDATE,
  MUTATION_SET_SHARED_TIME_SHIFT,
  MUTATION_SET_USERNAME,
} from "@/store/mutations.js";
import {ACTION_AUTO_UPDATE, ACTION_LOAD_CONTEXT, ACTION_SIGN_OUT} from "@/store/actions.js";
import {ROUTE_CAMS, ROUTE_MAP, ROUTE_MULTI_SCREEN, ROUTE_PACS, ROUTE_SETUP} from "@/router/names.js";
import {HTTP_MODE} from "camsng-frontend-shared/lib/consts.js";

Vue.use(Vuex);

/**
 * Конструктор клиента для общения с сервером, через настройку библиотеки axios.
 *
 * На axios навешивается перехватчик, который отслеживает 401 ошибку,
 * в этом случае состояние сбрасывается до начального вида.
 *
 * Если приложение сконфигурировано для авторизации через токен - он будет подмешиваться в заголовках,
 * без токена авторизация идет через сессии.
 *
 * @return {Object}
 */
export function constructorAjaxClient() {
  let config = {
    baseURL: CONFIG_BASE_URL,
    timeout: CONFIG_AJAX_TIMEOUT,
  };

  if (CONFIG_AUTH_TOKEN) {
    Object.assign(config, {
      headers: {
        Authorization: CONFIG_AJAX_PREFIX_HEADER_AUTH + CONFIG_AUTH_TOKEN,
      },
    });
  } else {
    Object.assign(config, {
      withCredentials: true,
      xsrfCookieName: "csrftoken",
      xsrfHeaderName: "X-CSRFToken",
    });
  }
  let instanceAxios = axios.create(config);
  instanceAxios.interceptors.request.use((request) => {
    const locale = localStorage.getItem('locale') || 'ru';
    if (request.url) {
      request.url += `?lang=${locale}`;
    }
    return request;
  });

  instanceAxios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        // todo может лучше диспатичить экшен. (настроить) проверить переброс при 401
        store.commit(MUTATION_RESET_STATE);
      }
      return Promise.reject(error);
    }
  );

  return instanceAxios;
}


/**
 * Функция вернет начальное состояние хранилища по ряду параметров, которые могут быть сохранены и после сброса состояния.
 * Как правило такие параметры не должны сбрасываться с основным состоянием, потому что не зависят от контекста и авторизаций.
 *
 * @return {Object}
 */
function zeroState() {
  return {
    etag: null, // Хеш ETag index.html по которому проверяются обновления.
    needUpdate: false, // Флаг если требуется обновление клиентского приложения.
  };
}

/**
 * Функция вернет начальное состояние хранилища.
 * Применяется на этапе инициализации приложения, если у пользователя не было ранее установленного состояния,
 * а так же при сбросе состояния в начальное положение при выходе пользователя из системы.
 *
 * @return {Object}
 */
function initialState() {
  return {
    username: "",
    context: {
      title: "Видеонаблюдение",
      // режим работы с HTTP
      httpMode: HTTP_MODE.https,
      favicon: "",
      logo: "",
      logoLink: "",
      version: "",
      gangAdmin: false,
      defaultPushDomain: "",
    },
    // Маршруты последних посещенных страниц по секциям.
    actualRoutesSections: {
      [ROUTE_CAMS]: {name: ROUTE_CAMS},
      [ROUTE_MAP]: {name: ROUTE_MAP},
      [ROUTE_MULTI_SCREEN]: {name: ROUTE_MULTI_SCREEN},
      [ROUTE_PACS]: {name: ROUTE_PACS},
      [ROUTE_SETUP]: {name: ROUTE_SETUP},
    },
    isMobile: false,
    sharedTimeShift: null,
    notificationQueue: [],
    notificationsMaxNumber: 5,
  };
}

const store = new Vuex.Store({
  plugins: [
    createCache({timeout: VUEX_CACHE_TTL * 1000})
  ],
  modules: {
    meshCameras,
    map,
    cameras,
    mosaics,
    analytics,
    pacs,
    users,
    setup,
    folders,
  },
  state: () => _.merge(zeroState(), initialState()),
  mutations: {
    /**
     * Сброс состояния к начальному виду.
     *
     * @param {Object} state
     */
    [MUTATION_RESET_STATE](state) {
      state = _.merge(state, initialState());
    },
    /**
     * Записывание имени пользователя.
     *
     * Отдельная мутация про изменении имени пользователя необходима для отслеживания
     * внутреннего состояния наличия авторизации {@link getters.isAuth}, на которое завязана логика отображения
     * компонента авторизации и основного контента.
     *
     * @param {Object} state
     * @param {String} username
     */
    [MUTATION_SET_USERNAME](state, username) {
      state.username = username;
    },
    /**
     * Установка состояния по данным из контекста в котором работает приложение.
     *
     * @param {Object} state
     * @param {Object} context
     */
    [MUTATION_SET_CONTEXT](state, context) {
      state.context.httpMode = context.http_mode;
      state.context.title = context.title;
      state.context.favicon = context.favicon;
      state.context.logo = context.logo;
      state.context.logoLink = context.logo_link;
      state.context.version = context.version;
      state.context.defaultPushDomain = context.default_push_domain;
      state.context.gangAdmin = context.gang_admin;
    },
    /**
     * Актуализация маршрута в секции для корректировки ссылки в главном меню.
     *
     * @param {Object} state
     * @param {String} nameRouteSection
     * @param {Object} routeTo
     */
    [MUTATION_ACTUALIZE_ROUTE_SECTION](state, {nameRouteSection, routeTo}) {
      state.actualRoutesSections[nameRouteSection] = routeTo;
    },
    /**
     * Установка маркера определяющего работу приложения на мобильном устройстве.
     *
     * @param {Object} state
     * @param {Boolean} isMobile
     */
    [MUTATION_SET_IS_MOBILE](state, isMobile) {
      state.isMobile = isMobile;
    },
    /**
     * Сохранение хеша ETag index.html по которому проверяются обновления.
     *
     * @param {Object} state
     * @param {String} etag
     */
    [MUTATION_SET_ETAG](state, etag) {
      state.etag = etag;
    },
    /**
     * Изменение флага необходимости обновления клиентского приложения.
     *
     * @param {Object} state
     * @param {Boolean} needUpdate
     */
    [MUTATION_SET_NEED_UPDATE](state, needUpdate) {
      state.needUpdate = needUpdate;
    },
    /**
     * В очередь сообщений вставляется объект сообщения, и убеждаемся что очередь не превышает максимального значения
     *
     * @param {Object} state
     * @param notification - объект сообщения
     */
    pushNotification(state, notification) {
      state.notificationQueue.push(notification);

      if (state.notificationQueue.length > state.notificationsMaxNumber) {
        state.notificationQueue.shift();
      }
    },
    /**
     * Сохранение даты и времени за которое просматривается архив.
     *
     * @param {Object} state
     * @param {Date} sharedTimeShift
     */
    [MUTATION_SET_SHARED_TIME_SHIFT](state, sharedTimeShift) {
      state.sharedTimeShift = sharedTimeShift;
    },
  },
  actions: {
    /**
     * Регулярный опрос сервера для того, чтобы узнать наличие обновление в клиентском приложении, чтобы обновить его.
     * Обновления проверяются сравнением значений ETag для index.html, поскольку в нем меняются ссылки на js скрипты, то и ETag будет отличаться.
     *
     * Период обновления = 15 минут + случайное количество секунд (до 10) чтобы обновления происходили не в одно мгновение у всех клиентов,
     * а нагрузка на сервер была более равномерной.
     *
     * @param {Object} state
     * @param {Function} commit
     * @return {Promise}
     */
    async [ACTION_AUTO_UPDATE]({state, commit}) {
      const response = await fetch("/index.html", {method: "HEAD"});

      commit(MUTATION_SET_ETAG, response.headers.get("ETag"));
      setInterval(async () => {
        try {
          const response = await fetch("/index.html", {method: "HEAD", headers: {"If-None-Match": state.etag}});
          // Для запроса с If-None-Match с тем же ETag будет не ok и статус 304, а при 200 есть изменения и можно обновить страницу.
          commit(MUTATION_SET_NEED_UPDATE, response.ok);
        } catch {
          // В случае ошибок на сети, обновления не происходит чтобы не сломать текущую картинку.
          // location.reload();
        }
      }, 900000 + (Math.random() * 10000));
    },
    /**
     * Загрузка контекстной информации о рабочем окружении.
     * Необходимо вызывать при перезагрузке страницы и (или) при старте главного компонента приложения.
     *
     * Загрузка контекста будет происходить успешно вне зависимости от наличия авторизации,
     * поэтому только явные ошибки на сети должны провоцировать catch.
     * Тем не менее, отсутствие имени пользователя в контексте говорит о слетевшей сессии,
     * а значит необходимо сбрасывать состояние до начального,
     * после чего обновить служебную информацию из полученного контекста.
     *
     * @param {Function} dispatch
     * @param {Function} commit
     * @return {Promise.<Boolean>} Вернет true если процесс обработки контекстной информации прошел штатно (не зависит от авторизации).
     */
    async [ACTION_LOAD_CONTEXT]({commit}) {
      try {
        const axiosResponse = await this.getters.ajaxClient.post("/v0/context/");
        if (axiosResponse.data.username === null) {
          // Если не авторизованы, то перенаправляем пользователя на страницу авторизации.
          commit(MUTATION_RESET_STATE);
          window.location = `//${window.location.host}/login/?next=${encodeURIComponent(window.location.pathname + window.location.search)}`;
          return true;
        }
        commit(MUTATION_SET_USERNAME, axiosResponse.data.username);
        commit(MUTATION_SET_CONTEXT, axiosResponse.data);
      } catch (error) {
        devLog("API не доступно произошла пичалька!", error);
        commit(MUTATION_RESET_STATE);
        return false;
      }
      return true;
    },
    /**
     * Выход из системы.
     *
     * @param {Function} commit
     */
    [ACTION_SIGN_OUT]({commit}) {
      commit(MUTATION_RESET_STATE);
    },
    // -------------------------------------------------------------------------------------------------------------
    // @deprecated
    /**
     * Вызов мутации mutationName, во всех модулях, если она там есть
     *
     * @param {Function} commit
     * @param {String} mutationName
     * @param {Object} mutationPayload
     */
    multipleCommit({commit}, {mutationName, mutationPayload}) {
      for (let mutation of Object.keys(this._mutations)) {
        if (mutation.endsWith(mutationName)) {
          commit(mutation, mutationPayload);
        }
      }
    },
    /**
     * Удаление / добавление камеры в избранное
     *
     * @param {Function} dispatch
     * @param {Function} commit
     * @param {String} cameraNumber
     * @param {String} isFav то что отображалось в том контроле, из которого был вызван apiFav
     */
    apiFav({dispatch, commit}, {cameraNumber, isFav}) {
      if (!isFav) {
        return this.getters.ajaxClient
          .post("/v0/cameras/fav/add/", {
            number: cameraNumber,
          })
          .then(() => {
            dispatch("multipleCommit", {mutationName: "setFav", mutationPayload: {is_fav: true, number: cameraNumber}});
            dispatch("map/fav/apiCameras");
            return true;
          })
          .catch(
            // TODO обработка ошибок
            error => {
              devLog(error);
              const notificationObj = {
                title: "Ошибка",
                text: error.response.data.error,
                type: "error",
              };
              commit("pushNotification", notificationObj);
              throw error;
            }
          );
      } else if (isFav) {
        return this.getters.ajaxClient
          .post("/v0/cameras/fav/delete/", {
            numbers: [cameraNumber],
          })
          .then(() => {
            dispatch("multipleCommit", {mutationName: "setFav", mutationPayload: {is_fav: false, number: cameraNumber}});
            dispatch("multipleCommit", {mutationName: "deleteCamFromFav", mutationPayload: cameraNumber});
            return false;
          })
          .catch(
            // TODO обработка ошибок
            error => {
              devLog(error);
              const notificationObj = {
                title: "Ошибка",
                text: error.response.data.error,
                type: "error",
              };
              commit("pushNotification", notificationObj);
            }
          );
      }
    },
    // -------------------------------------------------------------------------------------------------------------
  },
  getters: {
    /**
     * Вернет актуальный режим работы с http.
     *
     * @return {String}
     */
    httpMode: (state) => state.context.httpMode === HTTP_MODE.https ? HTTP_MODE.https : HTTP_MODE.http,
    /**
     * Клиент для общения с сервером.
     *
     * @return {Object}
     */
    ajaxClient: () => constructorAjaxClient(),
    /**
     * Вернет адрес для выхода из системы.
     *
     * @return {String}
     */
    urlSignOut: () => `${CONFIG_BASE_URL}internal/logout/`,
    /**
     * Проверка авторизации.
     *
     * @param {Object} state
     * @return {Boolean}
     */
    isAuth: (state) => !!state.username,
    /**
     * Вернет base64 представление favicon.
     *
     * @param state
     * @return {String}
     */
    favicon: (state) => state.context.favicon,
    /**
     * Вернет base64 представление картинки для логотипа.
     *
     * @param state
     * @return {String}
     */
    logo: (state) => state.context.logo,
    /**
     * Вернет URL для перехода по клику на логотип.
     *
     * @param state
     * @return {String}
     */
    logoLink: (state) => state.context.logoLink,
    /**
     * Вернет протокол для использования в запросах к серверам с видео по протоколу http.
     *
     * @param {Object} state
     * @param {Object} getters
     * @return {String}
     */
    protocolVideoOverHTTP: (state, getters) => getters.httpMode,
    /**
     * Вернет протокол для использования в запросах к серверам с видео по протоколу WebSocket.
     *
     * @param {Object} state
     * @param {Object} getters
     * @return {String}
     */
    protocolVideoOverWS: (state, getters) => getters.httpMode === HTTP_MODE.https ? "wss" : "ws",
    /**
     * Вернет true если доступен интерфейс управления СКУД.
     *
     * @param {Object} state
     * @return {Boolean}
     */
    availablePacs: (state) => state.context.gangAdmin,
  },
});

export default store;
