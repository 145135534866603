import {MAP_CAMERA_FIELDS, MAP_CAMERAS_API_URL} from "@/utils/consts.js";
import {FIELDS_CAMERA} from "@/store/cameras/index.js";

export default {
  namespaced: true,
  state: {
    cameras: {},
    target: null,
  },
  mutations: {
    /**
     * Устанавливаем информацию по камерам
     * И устанавливаем сразу несколько полей ради реактивности, которых нет на текущий момент и
     * они будут получены при клике на маркер камеры.
     * @param state
     * @param cameras
     */
    setCameras(state, cameras) {
      state.cameras = cameras.reduce((acc, x) => {
        // у камеры запросто может не оказаться координат
        // либо они равны дефолтным 0,0
        if (!x.latitude || !x.longitude) return acc;
        acc[x.number] = x;
        x.server = "";
        x.token_l = "";
        x.is_fav = "";
        return acc;
      }, {});
    },
    // установка данных для отображение в попапе на карте
    setPopupData(state, {result, number}) {
      let camera = state.cameras[number];
      if (!camera) return;
      if (result.length !== 1 || !result[0].token_l) {
        camera.server = "";
        camera.token_l = "";
        camera.is_fav = "";
      } else {
        camera.server = result[0].server;
        camera.token_l = result[0].token_l;
        camera.is_fav = result[0].is_fav;
        camera.title = result[0].title;
      }
    },
    // добавить в избранные камеру
    setFav(state, {number, is_fav}) {
      let camera = state.cameras[number];
      if (!camera) return;
      camera.is_fav = is_fav;
    },
    setRefreshDataTimerFlag(state) {
      state.refreshDataTimerFlag = true;
    },
    resetRefreshDataTimerFlag(state) {
      state.refreshDataTimerFlag = false;
    },
  },
  actions: {
    /**
     * Получение списка всех камер для карты в соответствующей вкладке карты
     * @param store
     */
    apiCameras(store, setBoundCallback) {
      this.getters.ajaxClient
        .post(MAP_CAMERAS_API_URL[store.state.target], {
          fields: [
            MAP_CAMERA_FIELDS.number,
            MAP_CAMERA_FIELDS.address,
            MAP_CAMERA_FIELDS.title,
            MAP_CAMERA_FIELDS.latitude,
            MAP_CAMERA_FIELDS.longitude,
            MAP_CAMERA_FIELDS.marker_id,
          ],
        })
        .then(response => {
          store.commit("setCameras", response.data.results);
          store.commit("resetRefreshDataTimerFlag");
          // устанавливается 15 минутное ожидание, затем устанавливается признак обновления данных,
          // сделанно для того чтобы клиент не работал со старой информацией
          setTimeout(() => {
            store.commit("setRefreshDataTimerFlag");
          }, 900000);
          if (setBoundCallback) {
            setBoundCallback(response.data.results);
          }
        })
        .catch(
          // TODO обработка ошибок
          error => devLog(error)
        );
    },
    /**
     * Получение информации для Popup окна - появляется при клике по маркеру камеры
     * @param store
     * @param number
     */
    apiPopupData(store, number) {
      this.getters.ajaxClient
        .post("/v0/cameras/this/", {
          fields: [
            FIELDS_CAMERA.number,
            FIELDS_CAMERA.server,
            FIELDS_CAMERA.token_l,
            FIELDS_CAMERA.is_fav,
            FIELDS_CAMERA.title,
          ],
          numbers: [number],
        })
        .then(response => {
          store.commit("setPopupData", {result: response.data.results, number});
        })
        .catch(
          // TODO обработка ошибок
          error => devLog(error)
        );
    },
  },
  getters: {
    getCameras(state) {
      return state.cameras;
    },
  },
};
