import {makeFilterApi, reformatErrorsForm} from "@/store/pacs/helpers.js";

/**
 * Отдельный vuex модуль для взаимодействия по folders-API
 */


// actions для folders. Вызов действия начинать с "folders/"
export const ACTION_LOAD_FOLDERS = "LOAD_FOLDERS";
export const ACTION_LOAD_FOLDER = "LOAD_FOLDER";
export const ACTION_CREATE_FOLDER = "CREATE_FOLDER";
export const ACTION_CREATE_ROOT_FOLDER = "CREATE_ROOT_FOLDER";
export const ACTION_DELETE_FOLDER = "DELETE_FOLDER";
export const ACTION_ADD_CAMERAS_TO_FOLDER = "ADD_CAMERAS_TO_FOLDER";
export const ACTION_EDIT_FOLDER = "EDIT_FOLDER";
export const ACTION_REMOVE_CAMERAS_FROM_FOLDER = "REMOVE_CAMERAS_FROM_FOLDER";
export const ACTION_LOAD_FOLDERS_ROOT = "LOAD_FOLDERS_ROOT";

/**
 * Перечисление констант с названиями полей, разрешенных к использованию через API.
 */
export const FIELDS_FOLDER = Object.freeze({
  key: "id",
  fields: {
    id: {
      name: "id",
      title: "ID",
      sort: true,
    },
    name: {
      name: "name",
      title: "Имя",
      sort: true,
    },
    parent_id: {
      name: "parent_id",
      title: "Количество групп доступа к устройствам",
    },
    cameras: {
      name: "cameras",
      title: "Учетные записи",
    },
    children: {
      name: "children",
      title: "Группы доступа",
    },
    children_count: {
      name: "children_count",
      title: "Автомобили",
    },
  },
  extras: {
  },
  filters: {
    id: "id",
    parent_id: "parent_id",
  },
});
export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * Загрузка списка папок.
     *
     * @param {Object} context
     * @param {Array} folderIds
     */
    async [ACTION_LOAD_FOLDERS](context, {page, pageSize, orderBy, filters, search}) {
      const fields = Object.keys(FIELDS_FOLDER.fields);
      const response = await this.getters.ajaxClient.post("/v0/folders/", {
        page,
        page_size: pageSize,
        order_by: orderBy,
        fields,
        filters,
        search,
      });
      return response.data.results;
    },
    async [ACTION_LOAD_FOLDERS_ROOT](context, {page, pageSize, orderBy, search}) {
      const fields = Object.keys(FIELDS_FOLDER.fields);
      const response = await this.getters.ajaxClient.post("/v0/folders/", {
        page,
        page_size: pageSize,
        order_by: orderBy,
        fields,
        filters: [makeFilterApi(FIELDS_FOLDER.filters.parent_id, "=", null)],
        search,
      });
      return response.data.results;
    },
    /**
     * Загрузка одной папки.
     *
     * @param {Function} dispatch
     */
    async [ACTION_LOAD_FOLDER]({dispatch}, folderId) {
      const fields = Object.keys(FIELDS_FOLDER.fields);
      const responseData = await dispatch(ACTION_LOAD_FOLDERS, {
        page: 1,
        pageSize: 1,
        fields,
        filters: [makeFilterApi(FIELDS_FOLDER.filters.id, "=", folderId)]});
      return responseData;
    },
    /**
     * Отправка данных для создания новой папки.
     *
     * @param {Object} context
     * @param {String} folderTitle
     * @param {String} folderGrid
     * @param {Array.<String>} viewCameraNumbers
     * @param {Boolean} lowLatencyMode
     * @return {Promise}
     */
    async [ACTION_CREATE_FOLDER](context, {parent_id, name}) {
      try{
        const response = await this.getters.ajaxClient.post(  "/v0/folders/create/", {
          parent_id,
          name
        });
        return response.data;
      } catch (error)  {
        const apiDataErrors = reformatErrorsForm(error.response.data.fields),
          dataErrors = {
            name: apiDataErrors["name"],
          };
        throw [dataErrors, error.response.data];
      }
    },
    async [ACTION_CREATE_ROOT_FOLDER](context, {folderTitle,cameraNumbers,parentId}) {
      try{
        const response = await this.getters.ajaxClient.post(  "/v0/folders/create/",
          parentId ? {
            camera_numbers: cameraNumbers,
            name: folderTitle,
            parent_id: parentId
          } : {
            camera_numbers: cameraNumbers,
            name: folderTitle,
          });
        return response.data;
      } catch (error)  {
        const apiDataErrors = reformatErrorsForm(error.response.data.fields),
          dataErrors = {
            folderTitle: apiDataErrors["name"],
          };
        throw [dataErrors, error.response.data];
      }
    },
    async [ACTION_EDIT_FOLDER](context, {folderId,folderTitle}) {
      try{
        const response = await this.getters.ajaxClient.post(  "/v0/folders/edit/", {
          id:folderId,
          name: folderTitle,
        });
        return response.data;
      } catch (error)  {
        const apiDataErrors = reformatErrorsForm(error.response.data.fields),
          dataErrors = {
            folderTitle: apiDataErrors["name"],
          };
        throw [dataErrors, error.response.data];
      }
    },
    async [ACTION_ADD_CAMERAS_TO_FOLDER](context, {folderId,cameraNumbers}) {
      try{
        const response = await this.getters.ajaxClient.post(  "/v0/folders/cameras/set/", {
          folder_id:folderId,
          camera_numbers:cameraNumbers,
        });
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    async [ACTION_REMOVE_CAMERAS_FROM_FOLDER](context, {folderId,cameraNumbers}) {
      try{
        const response = await this.getters.ajaxClient.post(  "/v0/folders/cameras/remove/", {
          folder_id:folderId,
          camera_numbers:[cameraNumbers],
        });
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    /**
     * Удаление существующей мозаики.
     *
     * @param {Object} context
     * @param {Number} folderId
     * @return {Promise.<Boolean>}
     */
    async [ACTION_DELETE_FOLDER](context, {folderId}) {
      try {
        return this.getters.ajaxClient.post("/v0/folders/delete/", {id: folderId});
      } catch {
        throw false;
      }
    },
  },
};
