<template>
  <div class="user-link-container">
    <ParentLinksForClients v-if="!showInput" />
    <ChildLinksForClients v-if="!showInput" />
    <p v-if="!showInput">
      {{ $t('addLinkPrompt') }}
    </p>
    <div class="buttons-group buttons-group--align-right">
      <CamsButton
        v-if="!showInput"
        priority="primary"
        type="button"
        @click="showInput = !showInput"
      >
        {{ $t('add') }}
      </CamsButton>
    </div>
    <div v-if="showInput" class="add-link-form">
      <p>
        {{ $t('inputUsernamePassword') }}
      </p>
      <SmartInputText
        v-model="username"
        default-if-empty=""
        :placeholder="$t('enterUsername')"
      />
      <SmartInputText
        v-model="password"
        default-if-empty=""
        :placeholder="$t('password')"
        :input-type="inputType"
      >
        <CamsButton
          position="r"
          size="s"
          type="button"
          icon-type="only"
          @mousedown="inputType = 'text'"
          @mouseout="inputType = 'password'"
          @mouseup="inputType = 'password'"
        >
          <svg class="icon-search">
            <use xlink:href="#icon-search" />
          </svg>
        </CamsButton>
      </SmartInputText>
      <div class="buttons-group buttons-group--align-right">
        <CamsButton
          priority="primary"
          type="button"
          @click="addChildLink()"
        >
          {{ $t('add') }}
        </CamsButton>
        <CamsButton
          type="button"
          @click="switchOffModeAddLink()"
        >
          {{ $t('cancel') }}
        </CamsButton>
      </div>
    </div>
    <SpinnerLoadingModal v-if="isLoading" />
  </div>
</template>

<script>

import {ACTION_ADD_CHILD_LINKS} from "@/store/users/index.js";
import ChildLinksForClients from "@/components/users/ChildLinksForClients.vue";
import ParentLinksForClients from "@/components/users/ParentLinksForClients.vue";

/**
 * Компонент диалога для настройки связей между учетными записями клиентов.
 */
export default {
  components: {
    ParentLinksForClients,
    ChildLinksForClients
  },

  data() {
    return {
      showInput: false,
      isLoading: false,
      username: "",
      password: "",
      inputType: "password",
    };
  },
  methods: {
    /**
     * Отправка запроса на добавление связи.
     */
    async addChildLink() {
      this.isLoading = true;
      const username = this.username,
            password = this.password;
      try {
        await this.$store.dispatch(`users/${ACTION_ADD_CHILD_LINKS}`, {username, password});
        this.switchOffModeAddLink();
        this.$notify({
          type: "success",
          group: "main",
          text: this.$t('accountLinkedSuccess'),
        });
      } catch (error) {
        this.$camsdals.alert(error);
      }
      this.$root.$emit('load-cameras');
      this.isLoading = false;
    },
    /**
     * Произойдет очищение полей и скрытие формы ввода при нажатии отмена и после добавления связи.
     */
    switchOffModeAddLink() {
      this.username = "";
      this.password = "";
      this.showInput = false;
    },
  }
};


</script>

<style lang="scss">
.user-link-container, .add-link-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>
