/**
 * Отдельный vuex модуль для взаимодействия по analytics/car_number-API
 */

import {ANALYTICS, AnalyticSettings} from "@/store/analytics/helpers.js";
import makeMessage from "@/store/analytics/analyticMessage.js";

// actions для carNumbers. Вызов действия начинать с "analytics/carNumbers/"
export const ACTION_LOAD_CAR_NUMBERS_SETTINGS = "LOAD_CAR_NUMBERS_SETTINGS";
export const ACTION_LOAD_CAR_NUMBER_SETTINGS = "LOAD_CAR_NUMBER_SETTINGS";
export const ACTION_LOAD_CAR_NUMBER_EVENTS = "LOAD_CAR_NUMBER_EVENTS";
export const ACTION_LOAD_CAR_NUMBER_EVENT = "LOAD_CAR_NUMBER_EVENT";
export const ACTION_LOAD_CAR_NUMBER_REPORT = "LOAD_CAR_NUMBER_REPORT";

/**
 * Класс для представления настроек аналитики по автомобильным номерам.
 */
export class CarNumberSettings extends AnalyticSettings {
  get analyticType() {
    return ANALYTICS.car_number;
  }
}

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * Загрузка списка настроек аналитик автомобильных номеров.
     *
     * @param {Object} context
     * @param {Array.<String>} camerasNumbers
     * @return {Promise.<Array.<CarNumberSettings>>}
     */
    async [ACTION_LOAD_CAR_NUMBERS_SETTINGS](context, {camerasNumbers}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/analytics/car_number/settings/", {camera_numbers: camerasNumbers});
        return response.data.results.map(rawCarNumberSettings => new CarNumberSettings(rawCarNumberSettings));
      } catch {
        return [];
      }
    },
    /**
     * Загрузка настроек аналитики автомобильных номеров с конкретной камеры.
     *
     * @param {Function} dispatch
     * @param {String} cameraNumber
     * @return {Promise.<CarNumberSettings>}
     */
    async [ACTION_LOAD_CAR_NUMBER_SETTINGS]({dispatch}, {cameraNumber}) {
      const carNumbersSettings = await dispatch(ACTION_LOAD_CAR_NUMBERS_SETTINGS, {camerasNumbers: [cameraNumber]});
      if (_.isEmpty(carNumbersSettings)) {
        return null;
      }
      return carNumbersSettings[0];
    },
    /**
     * Загрузка перечня событий по аналитики автомобильных номеров с камеры для формирования отчета.
     *
     * @param {Object} context
     * @param {String} cameraNumber
     * @param {Array.<Number>} eventIds
     * @return {Promise.<Array.<CarNumberMessage>>}
     */
    async [ACTION_LOAD_CAR_NUMBER_EVENTS](context, {cameraNumber, eventIds}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/analytics/car_number/events/", {
          camera_number: cameraNumber,
          event_ids: eventIds,
        });
        return response.data.results.map(rawEventData => makeMessage(rawEventData, cameraNumber, ANALYTICS.car_number));
      } catch {
        return [];
      }
    },
    /**
     * Загрузка перечня событий по аналитики автомобильных номеров с камеры для формирования отчета.
     *
     * @param {Function} dispatch
     * @param {String} cameraNumber
     * @param {Number} eventId
     * @return {Promise.<CarNumberMessage>}
     */
    async [ACTION_LOAD_CAR_NUMBER_EVENT]({dispatch}, {cameraNumber, eventId}) {
      const carNumbersSettings = await dispatch(ACTION_LOAD_CAR_NUMBER_EVENTS, {cameraNumber, eventIds: [eventId]});
      if (_.isEmpty(carNumbersSettings)) {
        return null;
      }
      return carNumbersSettings[0];
    },
    /**
     * Загрузка перечня событий по автомобильным номерам с камеры для формирования отчета.
     *
     * @param {Object} context
     * @param {String} cameraNumber
     * @param {Date} archiveFrom
     * @param {Date} archiveTo
     * @param {String} number
     * @param {Boolean} access
     * @param {String} query
     * @return {Promise.<Array.<CarNumberMessage>>}
     */
    async [ACTION_LOAD_CAR_NUMBER_REPORT](context, {cameraNumber, archiveFrom, archiveTo, number = null, access = null, query = null, page, page_size}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/analytics/car_number/report/", {
          camera_number: cameraNumber,
          start: archiveFrom.toISOString(),
          end: archiveTo.toISOString(),
          number: number,
          access: access,
          query: query,
          page: page,
          page_size: page_size
        });
        const messages = response.data.results.map(rawEventData => makeMessage(rawEventData, cameraNumber, ANALYTICS.car_number));
        // Возвращаем и `messages`, и полный `response` для использования в компоненте
        return {
          messages,
          response: response.data
        };
      } catch (error) {
        return {
          messages: [],
          response: null
        };
      }
    },
  },
};
