/**
 * Отдельный vuex модуль для взаимодействия по device_access_groups-API
 */
import {
  DEFAULT_PAGE_SIZE_FOR_SELECT,
  DEFAULT_PAGE_SIZE_FOR_TABLE,
  getExtraApi,
  makeFilterApi,
  reformatErrorsForm,
  SPECIAL_TYPES_DATA_IN_CELLS
} from "@/store/pacs/helpers.js";
import {ACTION_LOAD_ENTITIES_FOR_MULTI_EDIT} from "@/store/pacs/actions.js";

// actions для device_access_groups. Вызов действия начинать с "pacs/deviceAccessGroups/"
export const ACTION_LOAD_DEVICE_ACCESS_GROUPS = "LOAD_DEVICE_ACCESS_GROUPS";
export const ACTION_LOAD_DEVICE_ACCESS_GROUPS_FOR_TABLE = "LOAD_DEVICE_ACCESS_GROUPS_FOR_TABLE";
export const ACTION_LOAD_DEVICE_ACCESS_GROUPS_FOR_SELECT = "LOAD_DEVICE_ACCESS_GROUPS_FOR_SELECT";
export const ACTION_LOAD_DEVICE_ACCESS_GROUP_FOR_EDIT = "LOAD_DEVICE_ACCESS_GROUP_FOR_EDIT";
export const ACTION_LOAD_DEVICE_ACCESS_GROUPS_FOR_MULTI_EDIT = "LOAD_DEVICE_ACCESS_GROUPS_FOR_MULTI_EDIT";
export const ACTION_CREATE_DEVICE_ACCESS_GROUP = "CREATE_DEVICE_ACCESS_GROUP";
export const ACTION_EDIT_DEVICE_ACCESS_GROUP = "EDIT_DEVICE_ACCESS_GROUP";
export const ACTION_DELETE_DEVICE_ACCESS_GROUPS = "DELETE_DEVICE_ACCESS_GROUPS";

/**
 * Структура для общения с API по конкретной сущности.
 */
export const DEVICE_ACCESS_GROUP_STRUCT = Object.freeze({
  key: "id",
  fields: {
    id: {
      name: "id",
      title: "ID",
      type: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
      sort: true,
    },
    title: {
      name: "title",
      title: "Название",
      type: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
      sort: true,
    },
    about: {
      name: "about",
      title: "Доп. описание",
    },
    employee_count: {
      name: "employee_count",
      title: "Количество сотрудников",
    },
    employee_ids: {
      name: "employee_ids",
      title: "Сотрудники группы",
      type: SPECIAL_TYPES_DATA_IN_CELLS.ARRAY,
    },
  },
  extras: {
    employee: "employee",
  },
});

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * Загрузка списка групп доступа к устройствам.
     *
     * @param {Object} context
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_DEVICE_ACCESS_GROUPS](context, {page, pageSize, orderBy, filters, search}) {
      const fields = Object.keys(DEVICE_ACCESS_GROUP_STRUCT.fields),
        response = await this.getters.ajaxClient.post("/v0/device_access_groups/", {
          page,
          page_size: pageSize,
          order_by: orderBy,
          fields,
          filters,
          search,
          extra: getExtraApi(DEVICE_ACCESS_GROUP_STRUCT, fields),
        });
      return response.data;
    },
    /**
     * Загрузка списка групп доступа к устройствам для отображения в таблице.
     *
     * @param {Function} dispatch
     * @param {Number} page
     * @param {Number} pageSize
     * @param {Array} orderBy
     * @param {Array} filters
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_DEVICE_ACCESS_GROUPS_FOR_TABLE]({dispatch}, {page = 1, pageSize = DEFAULT_PAGE_SIZE_FOR_TABLE, orderBy = [], filters = [], search = ""}) {
      return dispatch(ACTION_LOAD_DEVICE_ACCESS_GROUPS, {page, pageSize, orderBy, filters, search});
    },
    /**
     * Загрузка списка групп доступа к устройствам для отображения в селекте.
     *
     * @param {Function} dispatch
     * @param {Number} pageSize
     * @param {String} search
     * @return {Promise}
     */
    async [ACTION_LOAD_DEVICE_ACCESS_GROUPS_FOR_SELECT]({dispatch}, {pageSize = DEFAULT_PAGE_SIZE_FOR_SELECT, search}) {
      const responseData = await dispatch(ACTION_LOAD_DEVICE_ACCESS_GROUPS, {pageSize, search});
      return responseData.results;
    },
    /**
     * Загрузка одной группы доступа к устройствам для редактирования.
     *
     * @param {Function} dispatch
     * @param {Number} deviceAccessGroupId
     * @return {Promise}
     */
    async [ACTION_LOAD_DEVICE_ACCESS_GROUP_FOR_EDIT]({dispatch}, deviceAccessGroupId) {
      const responseData = await dispatch(ACTION_LOAD_DEVICE_ACCESS_GROUPS, {page: 1, pageSize: 1, filters: [makeFilterApi(DEVICE_ACCESS_GROUP_STRUCT.key, "=", deviceAccessGroupId)]});
      return {entityInfo: responseData.results[0], extraInfo: responseData.extra};
    },
    /**
     * Загрузка ключевых данных группы доступа для множественного редактирования.
     *
     * @param {Function} dispatch
     * @param {Array} filters
     * @param {String} search
     * @return {Promise.<Array.<Number>>}
     */
    async [ACTION_LOAD_DEVICE_ACCESS_GROUPS_FOR_MULTI_EDIT]({dispatch}, {filters, search}) {
      return dispatch(`pacs/${ACTION_LOAD_ENTITIES_FOR_MULTI_EDIT}`, {
        apiUrl: "/v0/device_access_groups/",
        keyField: DEVICE_ACCESS_GROUP_STRUCT.key,
        dataForApi: {filters, search},
      }, {root: true});
    },
    /**
     * Создание группы доступа к устройствам.
     *
     * @param {Object} context
     * @param {String} title
     * @param {String} about
     * @return {Promise}
     */
    async [ACTION_CREATE_DEVICE_ACCESS_GROUP](context, {title, about}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/device_access_groups/create/", {title, about});
        return response.data;
      } catch (error) {
        throw [reformatErrorsForm(error.response.data.fields), error.response.data];
      }
    },
    /**
     * Редактирование групп доступа к устройствам.
     *
     * @param {Object} context
     * @param {Number} groupId
     * @param {String} title
     * @param {String} about
     * @return {Promise}
     */
    async [ACTION_EDIT_DEVICE_ACCESS_GROUP](context, {groupId, title, about}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/device_access_groups/edit/", {
          id: groupId,
          title,
          about,
        });
        return response.data;
      } catch (error) {
        throw [reformatErrorsForm(error.response.data.fields), error.response.data];
      }
    },
    /**
     * Удаление группы доступа к устройствам.
     *
     * @param {Object} context
     * @param {Array.<Number>} deviceAccessGroupIds
     * @return {Promise}
     */
    async [ACTION_DELETE_DEVICE_ACCESS_GROUPS](context, deviceAccessGroupIds) {
      return this.getters.ajaxClient.post("/v0/device_access_groups/delete/", {ids: deviceAccessGroupIds});
    },
  },
};
