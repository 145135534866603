export const enMessagesCams = {
  linkedAccounts: "Linked accounts",
  accountAccess: "Account access",
  exit: "Exit",
  accountRelationsSettings: "Account relations settings",
  sortBy: "Sort by:",
  language: "Language",
  languageSelection: "Language selection",
  view: "View ",
  byAddress: "By address",
  byHeading: "By heading",
  byDateAdded: "By date added",
  user: "User",
  tile: "Tile",
  list: "List",
  cancel: "Cancel",
  save: "Submit",
  myCameras: "My cameras",
  favorites: "Favorites",
  search: "Search",
  showAll: "Show all",
  found: "Found",
  addresses: "Addresses",
  titles: "Titles",
  noResults: "No search results",
  createFolder: "Create Folder",
  editFolder: "Edit Folder",
  noCamerasFound: "No cameras found",
  saveSelectedCameras: "Save Selected Cameras",
  enterFolderName: "Enter folder name",
  folderCreate: "Folder Create Menu",
  saveChanges: "Save changes",
  createSubfolder: "Create subfolder",
  deleteFolder: "Delete folder",
  deleteFolderConfirmation: "Warning! Deleting the folder will also delete all subfolders and cameras.",
  folderDeletedSuccess: "Folder {folderName} was successfully deleted",
  changesSaved: "Changes saved",
  errorAddingCameras: "Error adding cameras",
  errorDeletingFolder: "Error deleting folder",
  createSubfolderFor: "Create subfolder for {folderName}",
  cameraDisabled: "Camera disabled",
  cameraNotFound: "Camera not found",
  embedCamera: "Embed on website",
  cameraSelectedForMonitor: "Camera selected for alarm monitor",
  cameraSelected: "Camera selected",
  viewOnline: "View online",
  viewArchive: "View archive",
  infoOfCamera: "Camera information",
  thermalImager: "Thermal imager",
  carNumberRecognition: "Car number recognition",
  faceRecognition: "Face recognition",
  helmetRecognition: "Helmet recognition",
  maskDetector: "Mask detector",
  perimeterDetector: "Perimeter detector",
  crowdDetector: "Crowd detector",
  peopleCounter: "People counter",
  motionAlarm: "Motion alarm",
  player: "Player",
  embed: "Embed",
  thermalVisionReport: "Thermal Vision Report",
  carNumberReport: "Car Number Report",
  faceRecognitionReport: "Face Recognition Report",
  helmetReport: "Helmet Report",
  maskReport: "Mask Report",
  perimeterReport: "Perimeter Intrusion Report",
  motionAlarmReport: "Motion Alarm Report",
  crowdReport: "Crowd Report",
  peopleCountReport: "People Count Report",
  onMap: "On Map",
  width: "Width",
  height: "Height",
  fullscreen: "Fullscreen",
  autoplay: "Autoplay",
  https: "HTTPS",
  lowLatency: "Low Latency Mode",
  standardPlayback: "Standard playback. Video is delivered with a slight delay.",
  lowLatencyPlayback: "Video is streamed without delay. Not supported in some browsers.",
  sound: "Sound",
  hoursWithoutReload: "Hours of streaming without reload:",
  copyCode: "Copy Code",
  streamError: "Error: Failed to start the stream",
  copiedToClipboard: "Copied to clipboard",
  events: "Events",
  info: "Info",
  cameraNumber: "Camera №",
  cameraAddress: "Camera Address",
  timezoneOffset: "Timezone Offset",
  invalidDownloadRange: "Unable to download. The selected time range is incorrect, try changing the download boundaries.",
  downloadFailed: "Unable to download. Please try again later.",
  addCamera: "Add Camera",
  alarmCell: "Alarm Cell",
  streamUnavailable: "Stream unavailable. You need to change the camera.",
  alarm: "Alarm",
  deleteCamera: "Delete camera from cell",
  changeCamera: "Change camera in cell",
  moveToAnotherCell: "Move to another cell",
  selectCameraForCell: "Select a camera for the cell",
  newMosaic: "New Mosaic",
  enterTitle: "Enter title",
  addMosaic: "Add Mosaic",
  hideArchiveTools: "Hide archive tools",
  showArchiveTools: "Show archive tools",
  archive: "Archive",
  cancelChanges: "Cancel all changes and hide settings",
  showSettings: "Show current mosaic settings",
  setLowLatency: "Set low latency mode for all cameras",
  selectAlarmCameras: "Select alarm cameras for this mosaic",
  oneCamera: "1 camera",
  fourCamerasGrid: "4 cameras grid",
  grid5Title: "Grid 5",
  grid6Title: "Grid 6",
  grid8Title: "Grid 8",
  grid9Title: "Grid 9",
  grid10Title: "Grid 10",
  grid16Title: "Grid 16",
  deleteMosaic: "Delete current mosaic",
  delete: "Delete",
  alarmCameras: "Alarm Cameras",
  public: "Public Cameras",
  confirmDeleteMosaic: "Do you want to delete this mosaic?",
  deleteMosaicError: "Error while deleting the mosaic",
  deleteMosaicSuccess: "Mosaic deleted",
  saveChangesError: "Error occurred while saving. Please refresh the page and try again.",
  saveMosaicError: "Error occurred while saving the mosaic. Please create a new mosaic.",
  saveChangesSuccess: "Mosaic saved",
  newMosaicAdded: "New mosaic added",
  defaultMosaicRedirect: "Mosaic saved successfully",
  chooseCamerasForAlarm: "Choose cameras for alarm events on the mosaic",
  setAlarmCameras: "Press 'Save' to activate selected alarm cameras",
  setFullscreen: "Set fullscreen",
  archiveSettings: "Archive Settings",
  hideEventsCount: "Hidden events: ",
  hiddenThermalVisionEvents: "Hidden events for thermal vision: ",
  hiddenCarNumberEvents: "Hidden events for car numbers: ",
  hiddenFaceRecognitionEvents: "Hidden events for face recognition: ",
  hiddenHelmetEvents: "Hidden events for helmets: ",
  hiddenMotionAlarmEvents: "Hidden events for motion alarms: ",
  hiddenMaskEvents: "Hidden events for masks: ",
  hiddenCrowdEvents: "Hidden events for crowding: ",
  hiddenPerimeterEvents: "Hidden events for perimeter security: ",
  hiddenPeopleCounterEvents: "Hidden events for people counter: ",
  editTitleTooltip: "Edit",
  titleSaveSuccess: "Camera title successfully changed",
  titleSaveError: "Error: ",
  titleWarning: "Title length cannot exceed 64 characters and cannot be empty",
  noEvents: "No events during this time interval.",
  deleteConfirmation: "Delete the link with the account?",
  accessHeader: "Account Access",
  nothingHere: "There's nothing here",
  createTokenMessage: "Create a new token for integrating third-party systems with our service via API",
  createToken: "Create Token",
  username: "Username",
  link: "Link",
  token: "Token",
  copy: "Copy",
  deleteToken: "Delete Token",
  copySuccess: "Copied to clipboard",
  addLinkPrompt: "To link cameras from another account, click add",
  add: "Add",
  enterUsername: "Enter username",
  password: "Enter password",
  accountLinkedSuccess: "Account linked successfully",
  inputUsernamePassword: "Enter username and password:",
  employees: "Employees",
  accessDevices: "Access Devices",
  accessGroups: "Access Groups",
  cars: "Cars",
  searchPlaceholder: "Search",
  filterByGroupsPlaceholder: "Filter by Access Groups",
  addToGroup: "Add to Group",
  removeFromGroup: "Remove from Group",
  deleteEmployees: "Delete Employees",
  addEmployee: "Add",
  addEmployeeFromFile: "Add from File",
  downloadReport: "Download Report",
  deleteEmployeeConfirm: "Do you want to delete this employee?",
  deleteEmployeesConfirm: "Do you want to delete the selected employees?",
  employeesDeleted: "Employees deleted",
  employeeDeleted: "Employee deleted",
  errorDeletingEmployee: "Error deleting employee",
  viewAll: "View All",
  deleteSelectedEmployeesConfirm: "Do you want to delete the selected employees? Selected",
  removeEmployeesFromGroup: "Remove employees from the group",
  addEmployeesToGroup: "Add employees to the group",
  csvFileName: "Employee report.csv",
  totalEmployees: "Total employees:",
  tableHeaders: {
    id: "ID",
    name: "Name",
    cars: "Cars",
    deviceAccessGroups: "Device Access Groups",
    isActive: "Active",
    identifier: "Identifier",
    emergencyVehicleAccess: "Emergency Vehicle Access",
    cameraCount: "Camera Count",
    cameras: "Cameras",
    title: "Title",
    about: "Additional Description",
    employee_count: "Number of Employees",
    employee_ids: "Group Employees",
  },
  entry: "entry",
  employeeName: "Employee Name",
  enterEmployeeName: "Enter the employee's full name",
  selectAccessGroups: "Select access groups, and the employee will get access to devices",
  carNumbers: "Car Numbers",
  accountNumber: "Account (contract number)",
  isActive: "Active?",
  saveAndAddNew: "Save and add another",
  successEmployeeCreated: "Employee successfully created",
  uploadFile: "Upload File",
  getTemplate: "Get Template",
  successFileUploaded: "File successfully uploaded",
  errorFileUpload: "Error: ",
  errorLoadEntity: "Error: Unable to display information.",
  deviceAccessGroups: "Device Access Groups",
  addPhoto: "Add Photo",
  deletePhoto: "Delete Photo",
  noPhotos: "No Photos",
  attachedUsernames: "Account for Barrier Access (Contract Number)",
  employeeCarsCount: "Number of cars for employee: ",
  accessSettings: "Access Settings",
  accessGroupList: "List of Access Groups:",
  viewGroup: "View Group: ",
  confirmDeletePhoto: "Do you want to delete this photo?",
  errorDeletePhoto: "Error while deleting photo",
  successSave: "Saved successfully",
  confirmDeleteEmployee: "Do you want to delete this employee?",
  errorDeleteEmployee: "Error while deleting employee",
  addDevice: "Add Device",
  chooseDevice: "Select Device",
  enterEmployee: "Enter Employee",
  more: "More",
  deviceFilter: "Filter by Devices",
  employeeFilter: "Filter by Employees",
  device: "Device",
  employee: "Employee",
  accessLevel: "Access Level",
  notFound: "No permissions found.",
  foundCount: "Found: ",
  addPermissionError: "Error adding permissions",
  setPermissionError: "Error setting permissions",
  deletePermissionConfirm: "Do you want to delete these permissions?",
  devicePermissionDeleted: "Device permissions have been deleted",
  deletePermissionError: "Error deleting permissions",
  fullAccess: "Full Access",
  entrance: "Entrance",
  selectAccessGroupForDelete: "Select the access group to remove employees from ",
  selectAccessGroupForAdd: "Select the access group to add employees to ",
  enterAccessGroup: "Enter the access group",
  deleteFromGroup: "Remove from this group",
  errorDeleteEmployeesFromGroup: "Error while removing employees from the group. ",
  errorAddEmployeesToGroup: "Error while adding employees to the group. ",
  noData: "No data",
  totalCount: "Total",
  selectDevicePlaceholder: "Select device",
  selectGroupPlaceholder: "Enter the name of the access group",
  addGroup: "Add employee group",
  groupFilter: "Filter by access groups",
  deviceHeader: "Device",
  groupHeader: "Access Group",
  noPermissions: "No permissions found",
  errorAddingPermissions: "Error adding permissions",
  errorUpdatingPermissions: "Error updating permissions",
  confirmDeletePermission: "Do you want to delete these permissions?",
  permissionDeleted: "Permission deleted",
  errorDeletingPermissions: "Error deleting permissions",
  allRowsSelected: "All rows selected",
  selectedRows: "Selected rows",
  selectAll: "Select all",
  deviceName: "Device Name",
  deviceState: "Device State",
  active: "Active",
  inactive: "Inactive",
  emergencyVehicleAccess: "Emergency Vehicle Access",
  available: "Available",
  notAvailable: "Not Available",
  cameraCountForDevice: "Number of cameras attached to the device",
  availableCamerasForUser: "Number of available cameras for the current user",
  noAvailableCamerasForUser: "No cameras available for the current user.",
  noCamerasAttachedToDevice: "No cameras are attached to the device.",
  editPermissionsForEmployees: "Edit Permissions for Employees",
  editPermissionsForDeviceAccessGroups: "Edit Permissions for Device Access Groups",
  editAccessSettings: "Access Settings",
  deleteGroups: "Delete Groups",
  createAccessGroup: "Create Access Group",
  confirmDeleteGroup: "Do you want to delete this access group?",
  groupDeleted: "Access group deleted",
  errorDeletingGroup: "Error deleting access group",
  confirmDeleteGroups: "Do you want to delete the selected access groups? Selected: {count}",
  groupsDeleted: "Access groups deleted",
  errorDeletingGroups: "Error deleting access groups",
  groupTitle: "Group Title",
  groupAbout: "Additional Description",
  employeesCount: "Number of employees in the group: ",
  viewAllEmployees: "View all employees",
  addEmployeeToGroup: "Add employee to the group",
  saveSuccess: "Saved successfully",
  deleteGroupConfirm: "Do you want to delete this access group?",
  employeeAddedToGroup: "Employee added to the group",
  errorAddingEmployees: "Error adding employees to the group.",
  groupName: "Group Name",
  additionalDescription: "Additional Description",
  filterByEmployee: "Filter by employee",
  deleteCars: "Delete cars",
  addCar: "Add car",
  createCarDialogTitle: "Create a car",
  confirmDeleteCar: "Do you want to delete this car?",
  carDeleted: "Car deleted",
  errorDeleteCar: "Error deleting the car",
  confirmDeleteSelectedCars: "Do you want to delete the selected cars? Selected: {count}",
  carsDeleted: "Cars deleted",
  errorDeleteCars: "Error deleting the cars",
  carNumber: "Car number",
  carOwner: "Car owner",
  existingEmployeeCars: "Existing employee cars",
  shownCars: "Shown cars of the employee",
  totalCars: "Total cars",
  accountLinkedTo: "Account linked to",
  deleteAccountLinkConfirmation: "Delete link with the account?",
  accessGranted: "Access granted",
  name: "Name",
  number: "Number",
  access: "Access",
  date: "Date",
  yes: "Yes",
  no: "No",
  unknown: "Unknown",
  startDate: "Start Date",
  endDate: "End Date",
  reset: "Reset",
  itemsPerPage: "Items per page",
  screenshot: "Screenshot",
  video: "Video",
  downloadVideo: "Download Video",
  text: "Text",
  archiveError: "Error: unable to access archive",
  crowdDetection: "Crowd Detected",
  screenshotEvent: "Screenshot Event",
  eventVideo: "Event Video",
  downloadEventVideo: "Download Event Video",
  noHelmet: "No Helmet",
  report: "Report",
  noMask: "No Mask",
  duration: "Duration (in sec.)",
  reportType: "Report Type",
  inCount: "People Entered",
  outCount: "People Exited",
  defaultReport: "General Report",
  hourly: "Hourly",
  daily: "Daily",
  weekly: "Weekly",
  monthly: "Monthly",
  perimeterCrossing: "Perimeter Crossing",
  minTemperature: "Min Temperature",
  maxTemperature: "Max Temperature",
  temperature: "Temperature",
  exceededTemperature: "Exceeded Temperature?",
  maxRowsWarning:
    "Displaying the maximum number of 1000 rows, additional records may be hidden. Refine filters to view them.",
  thermalCameras: "Thermal Cameras",
  motions: "Motions",
  helmets: "Helmets",
  masks: "Masks",
  crowdGathering: "Crowd Gathering",
  zoneCrossing: "Zone Crossing",
  tooManyRows: "The maximum number of rows displayed is 1000, other records may have been hidden. Refine the filters to see them.",
  eventText: "Event",
  eventDate: "Date",
  crowdReportFilename: "Crowd Report",
  defaultCrowdEvent: "Crowd Gathering",
  helmetReportFilename: "Helmet Report",
  helmetMissing: "Helmet Missing",
  maskReportFilename: "Mask Report",
  perimeterSecurityReportFilename: "Perimeter Security Report",
  unknownEvent: "Unknown",
  loadingError: "An error occurred while loading data. Please refine the filters.",
  motionReportFilename: "Motion Detection Report",
  thermalReportFilename: "Thermal Report",
  title: "Name",
  tariff: "Tariff",
  address: "Address",
  firmwareUpdateMessage: "Firmware is updating… do not close or refresh the page.",
  cameraModel: "Camera model",
  macAddress: "MAC address",
  firmwareVersion: "Firmware version",
  firmwareUpdateAvailable: "Update available",
  enableVideoStream: "Enable video stream",
  enableAudio: "Audio",
  ntpServer: "NTP server",
  videoResolution: "Resolution",
  videoBitrate: "Bitrate",
  nightModeEnable: "Night mode",
  timeZone: "Time zone",
  rebootCamera: "Reboot camera",
  confirmReboot: "Do you want to reboot the camera?",
  cameraRebootIn15Sec: "The camera will reboot in 15 seconds",
  configurationError: "Error during configuration",
  confirmUpgradeFirmware: "Firmware version {version} will be installed. Do not disconnect the camera from power or the internet. This may cause damage.",
  upgradeSuccess: "Firmware will be updated within 45 seconds",
  upgradeError: "An error occurred, please try again",
  screenshotAlt: "Event screenshot",
  screenshotProcessing: "Screenshot is being processed.",
  Movement: "Movement",
  continues: "continues",
  sec: "sec.",
  employeeId: "Employee",
  deviceId: "Device",
  specialTransport: "Special Transport",
  eventView: 'Event View',
  downloadError: 'Unable to download. Please try later.',
  screenshotView: 'Screenshot View',
  participants: '&copy; Contributors to {link}',
  insufficientRights: "Insufficient rights.",
  liveStreamTokenError: "Failed to obtain a token for live stream access.",
  archiveTokenError: "Failed to obtain a token for archive access.",
  multiscreen: "Multiscreen",
  map: "Map",
  scud: "ACS",
  setupCameras: "Setting up cameras",
  reloadToUpdate: "Do you want to reload the page to update?",
  reloadNow: "Reload now",
  postpone: "Postpone",
  newVersion: "A new version of the app is available",
  errorWhileLoading: "Oops, something went wrong while loading the page. Let's try reloading the page.",
  reload: "Reload",
  errorOccurred: "An error occurred",
  on: "On",
  off: "Off",
  auto: "Automatic mode",
};
