/**console.log(this.messages);
 * Перечень доступных аналитик по камерам.
 */
export const ANALYTICS = Object.freeze({
  thermal_vision: "thermal_vision",
  car_number: "car_number",
  face_recognition: "face_recognition",
  helmet: "helmet",
  motion_alarm: "motion_alarm",
  mask: "mask",
  crowd: "crowd",
  perimeter_security: "perimeter_security",
  people_counter: "people_counter",
});

/**
 * Перечисление констант с названиями полей для настроек разных аналитик.
 */
export const FIELDS_ANALYTIC_SETTINGS = Object.freeze({
  camera_number: "camera_number",
  push_domain: "push_domain",
  is_active: "is_active",
});

/**
 * Общий класс для представления настроек аналитики.
 * Для настроек каждой аналитики имеются общие поля.
 * В наследниках можно уточнить тип аналитики и определить конструктор для сообщений,
 * который создаст сообщения по аналитике определенного типа.
 */
export class AnalyticSettings {
  constructor(rawAnalyticSettings) {
    this.cameraNumber = rawAnalyticSettings[FIELDS_ANALYTIC_SETTINGS.camera_number];
    this.pushDomain = rawAnalyticSettings[FIELDS_ANALYTIC_SETTINGS.push_domain];
    this.isActive = rawAnalyticSettings[FIELDS_ANALYTIC_SETTINGS.is_active];
  }

  /**
   * Вернет тип используемой аналитики в настройках.
   *
   * @see ANALYTICS
   * @return {String}
   */
  get analyticType() {
    throw new Error("Analytic type is undefined");
  }
}

