/**
 * Отдельный vuex модуль для взаимодействия по analytics/face_recognition-API
 */

import {ANALYTICS, AnalyticSettings} from "@/store/analytics/helpers.js";
import makeMessage from "@/store/analytics/analyticMessage.js";

// actions для faceRecognitions. Вызов действия начинать с "analytics/faceRecognitions/"
export const ACTION_LOAD_FACE_RECOGNITIONS_SETTINGS = "LOAD_FACE_RECOGNITIONS_SETTINGS";
export const ACTION_LOAD_FACE_RECOGNITION_SETTINGS = "LOAD_FACE_RECOGNITION_SETTINGS";
export const ACTION_LOAD_FACE_RECOGNITION_EVENTS = "LOAD_FACE_RECOGNITION_EVENTS";
export const ACTION_LOAD_FACE_RECOGNITION_EVENT = "LOAD_FACE_RECOGNITION_EVENT";
export const ACTION_LOAD_FACE_RECOGNITION_REPORT = "LOAD_FACE_RECOGNITION_REPORT";

/**
 * Класс для представления настроек аналитики по распознаванию лиц.
 */
export class FaceRecognitionSettings extends AnalyticSettings {
  get analyticType() {
    return ANALYTICS.face_recognition;
  }
}

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * Загрузка списка настроек аналитик по распознаванию лиц.
     *
     * @param {Object} context
     * @param {Array.<String>} camerasNumbers
     * @return {Promise.<Array.<FaceRecognitionSettings>>}
     */
    async [ACTION_LOAD_FACE_RECOGNITIONS_SETTINGS](context, {camerasNumbers}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/analytics/face_recognition/settings/", {camera_numbers: camerasNumbers});
        return response.data.results.map(rawFaceRecognitionSettings => new FaceRecognitionSettings(rawFaceRecognitionSettings));
      } catch {
        return [];
      }
    },
    /**
     * Загрузка настроек аналитики по распознаванию лиц с конкретной камеры.
     *
     * @param {Function} dispatch
     * @param {String} cameraNumber
     * @return {Promise.<FaceRecognitionSettings>}
     */
    async [ACTION_LOAD_FACE_RECOGNITION_SETTINGS]({dispatch}, {cameraNumber}) {
      const faceRecognitionsSettings = await dispatch(ACTION_LOAD_FACE_RECOGNITIONS_SETTINGS, {camerasNumbers: [cameraNumber]});
      if (_.isEmpty(faceRecognitionsSettings)) {
        return null;
      }
      return faceRecognitionsSettings[0];
    },
    /**
     * Загрузка перечня событий по распознаванию лиц с камеры для формирования отчета.
     *
     * @param {Object} context
     * @param {String} cameraNumber
     * @param {Array.<Number>} eventIds
     * @return {Promise.<Array.<FaceRecognitionMessage>>}
     */
    async [ACTION_LOAD_FACE_RECOGNITION_EVENTS](context, {cameraNumber, eventIds}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/analytics/face_recognition/events/", {
          camera_number: cameraNumber,
          event_ids: eventIds,
        });
        return response.data.results.map(rawEventData => makeMessage(rawEventData, cameraNumber, ANALYTICS.face_recognition));
      } catch {
        return [];
      }
    },
    /**
     * Загрузка перечня событий по распознаванию лиц с камеры для формирования отчета.
     *
     * @param {Function} dispatch
     * @param {String} cameraNumber
     * @param {Number} eventId
     * @return {Promise.<FaceRecognitionMessage>}
     */
    async [ACTION_LOAD_FACE_RECOGNITION_EVENT]({dispatch}, {cameraNumber, eventId}) {
      const faceRecognitionMessages = await dispatch(ACTION_LOAD_FACE_RECOGNITION_EVENTS, {cameraNumber, eventIds: [eventId]});
      if (_.isEmpty(faceRecognitionMessages)) {
        return null;
      }
      return faceRecognitionMessages[0];
    },
    /**
     * Загрузка перечня событий по распознаванию лиц с камеры для формирования отчета.
     *
     * @param {Object} context
     * @param {String} cameraNumber
     * @param {Date} archiveFrom
     * @param {Date} archiveTo
     * @param {Boolean} access
     * @param {String} query
     * @return {Promise.<Array.<FaceRecognitionMessage>>}
     */
    async [ACTION_LOAD_FACE_RECOGNITION_REPORT](context, {cameraNumber, archiveFrom, archiveTo, access = null, query = null}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/analytics/face_recognition/report/", {
          camera_number: cameraNumber,
          start: archiveFrom.toISOString(),
          end: archiveTo.toISOString(),
          access: access,
          query: query,
        });
        return response.data.results.map(rawEventData => makeMessage(rawEventData, cameraNumber, ANALYTICS.face_recognition));
      } catch {
        return [];
      }
    },
  },
};
