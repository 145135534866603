/**
 * Общие данные и методы для компонентов отображения информации по камере.
 */

import EditorCameraTitle from "@/components/meshCameras/EditorCameraTitle.vue";
import {
  ACTION_LOAD_CAMERAS_FAV,
  ACTION_LOAD_CAMERAS_MY,
  ACTION_LOAD_CAMERAS_MY_FOLDERS,
  ACTION_SEARCH_CAMERAS,
  CameraInfo,
  FIELDS_CAMERA
} from "@/store/cameras/index.js";
import {ONE_SCREEN_TABS, PARAMS_SORT_CAMERAS, TOKEN_TTL, TYPES_VIEWS, VENDOR_IS_UFANET} from "@/utils/consts.js";
import {MUTATION_SET_STATE_FRAME, TABS} from "@/store/meshCameras/index.js";
import {YM_GOAL_PLAY_ARCHIVE, YM_GOAL_PLAY_LIVE} from "@/utils/tracking.js";
import {QUERY_KEY_ONE_SCREEN_CAMERA_NUMBER, QUERY_KEY_ONE_SCREEN_TAB, QUERY_KEY_ONE_SCREEN_TIME_SHIFT} from "@/router/queryKeys.js";
import {whenToUpdateToken} from "@/utils/helpers.js";

/**
 * @type {Number} Время жизни скриншота (в секундах), после чего должно произойти его обновление.
 */
const DEFAULT_SCREENSHOT_TTL = 300;

/**
 * Общие свойства и методы для компонентов отображения экземпляра камеры.
 */
export const viewTypeMixin = {
  components: {
    EditorCameraTitle,
  },
  props: {
    /**
     * Экземпляр {@link CameraInfo} по которому отображается информация в компоненте.
     */
    cameraInfo: {
      type: CameraInfo,
      required: true
    },
    /**
     * Указание, что с элементом будет выполняться Drag'n'Drop
     * используется в избранных камерах в пользовательской сортировке.
     * Изменение данного параметра влияет только на визуальную составляющую,
     * при указании будет навешен класс изменяющий курсор.
     * Управление самим функционалом Drag'n'Drop происходит в вышестоящем компоненте.
     *
     * TODO: В случае с таблицей должен появиться отдельный элемент за который будут перетаскиваться строки
     * на текущий момент можно перетаскивать за всю строку, что делает невозможным выделение текста.
     */
    grabbableMode: {
      type: Boolean,
      default: false,
    },
    /**
     * Указывается режим в котором отключены интерактивные элементы управления,
     * вместо них компонент используется для выбора камеры через отправку события `select-camera`.
     */
    selectableOnlyMode: {
      type: Boolean,
      default: false,
    },
    /**
     * Уникальная метка, которая добавляется в URL для избежания кеширования при регулярном обновлении скриншотов.
     * Но метка у скриншотов с разных камер общая, чтобы не плодить на каждом компоненте свой таймер с обновлением.
     */
    screenshotSign: {
      type: [String, Number],
      default: null,
    },
    /**
     * Флаг для визуального отображения сообщения о том что камера была выбрана (по логике родительского компонента).
     */
    isSelectedCamera: {
      type: Boolean,
      default: false,
    },
    /**
     * Флаг для визуального отображения сообщения о том что камера была выбрана (по логике родительского компонента).
     */
    isSelectedCameraForFolder: {
      type: Boolean,
      default: false,
    },
    /**
     * Режим работы с папками.
     */
    folderMode: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    /**
     * Клик по текущей камере с целью выбора. Инициирует событие `select-camera` - передачи номера камеры в родительский компонент.
     */
    selectCamera() {
      this.$emit("select-camera", this.cameraInfo.number);
    },
    /**
     * Воспроизведение прямой трансляции.
     *
     * @param {String} ymGoal Цель для я.метрики
     */
    play(ymGoal = YM_GOAL_PLAY_LIVE) {
      if (this.selectableOnlyMode) {
        return;
      }
      VENDOR_IS_UFANET && this.$metrika?.reachGoal(ymGoal);
      this.$router.push({
        name: this.$route.name,
        query: {
          ...(this.$route.query || {}),
          [QUERY_KEY_ONE_SCREEN_CAMERA_NUMBER]: this.cameraInfo.number
        }
      });
    },
    /**
     * Воспроизведение архива.
     */
    playArchive() {
      if (this.selectableOnlyMode || !this.cameraInfo.isAvailableArchive()) {
        return;
      }
      VENDOR_IS_UFANET && this.$metrika?.reachGoal(YM_GOAL_PLAY_ARCHIVE);
      const queryParams = {
        ...(this.$route.query || {}),
        [QUERY_KEY_ONE_SCREEN_CAMERA_NUMBER]: this.cameraInfo.number
      };
      if (this.$store.state.sharedTimeShift) {
        queryParams[QUERY_KEY_ONE_SCREEN_TIME_SHIFT] = +this.$store.state.sharedTimeShift;
      }
      this.$router.push({
        name: this.$route.name,
        query: queryParams
      });
    },
    /**
     * Окошко встраивания видео.
     */
    showEmbed() {
      if (this.selectableOnlyMode) {
        return;
      }
      this.$router.push({
        name: this.$route.name,
        query: {
          ...(this.$route.query || {}),
          [QUERY_KEY_ONE_SCREEN_CAMERA_NUMBER]: this.cameraInfo.number,
          [QUERY_KEY_ONE_SCREEN_TAB]: ONE_SCREEN_TABS.EMBED,
        }
      });
    },
  },
};

/**
 * Общие свойства и методы для компонентов отображения набор камер и механизмов управления отображением.
 * Некоторые общие вещи как компоненты не были вынесены в эту примесь по причине возникновения проблем с циклическим
 * импортом, т.к. миксин выше {@link viewTypeMixin} используется в этих самых компонентах в которых опять импортируется данный файл.
 *
 * В параметре currentFrame задается текущий компонент-фрейм в рамках которого будут сохранятся опции.
 * В конкретных компонентах должен быть перекрыт и задан конкретный фрейм из {@link FRAMES}.
 *
 * В примесь заложены методы сохранения основных параметров состояния во vuex. В компонентах может возникнуть необходимость
 * сохранения состояния (например в URL), для этого там надо реализовать свои методы сохранения и перекрыть
 * {@link meshFrameMixin.saveState} и {@link meshFrameMixin.saveStateAndLoadCameras},
 * так как эти методы расчитаны на публичное использование в шаблоне конкретного компонента (в зависимости от ситуации использования).
 *
 * Для загрузки камер в ряде случаев используется кеширование, поэтому необходимо отслеживать кеш и инвалидиировать по необходимости.
 */
export const meshFrameMixin = {
  data() {
    return {
      currentFrame: null,
      tabs: TABS,
      isLoadingCommon: false,
      isLoadingPage: false,
      selectedSort: PARAMS_SORT_CAMERAS.addr_asc,
      selectedView: TYPES_VIEWS.TILE,
      selectedTab: TABS.MY,
      querySearchCameras: "",
      queryTabSearch: "",
      folderIds: "",
      selectedPage: 1,
      pageCount: 0,
      listCamerasInfo: [],
      timeoutIdForReloadCameras: null,
      screenshotSign: null,
      intervalIdForNewScreenshotSign: null,
    };
  },
  computed: {
    /**
     * Вернет список
     *
     * @return {Array.<PARAMS_SORT_CAMERAS>}
     */
    availableParamsSort() {
      const commonSettingsSortCameras = [
        PARAMS_SORT_CAMERAS.addr_asc,
        PARAMS_SORT_CAMERAS.addr_desc,
        PARAMS_SORT_CAMERAS.title_asc,
        PARAMS_SORT_CAMERAS.title_desc,
        PARAMS_SORT_CAMERAS.create_asc,
        PARAMS_SORT_CAMERAS.create_desc,
      ];
      if (this.selectedTab === TABS.FAV) {
        return [PARAMS_SORT_CAMERAS.user, ...commonSettingsSortCameras];
      }
      return commonSettingsSortCameras;
    },
    /**
     * Вернет объект с параметрами для передачи в action vuex который отвечает за загрузку камер с определенными фильтрами.
     *
     * Набор запрашиваемой информации (поля по камере) должен в конечном позволять выяснить все доступные опции по камере,
     * чтобы отрисовать их все и на списке камер и (возможно) сразу на зависимых компонентах.
     *
     * @return {Object}
     */
    dataForLoadCameras() {
      return {
        fields: [
          FIELDS_CAMERA.number,
          FIELDS_CAMERA.address,
          FIELDS_CAMERA.title,
          FIELDS_CAMERA.longitude,
          FIELDS_CAMERA.latitude,
          FIELDS_CAMERA.is_embed,
          FIELDS_CAMERA.analytics,
          FIELDS_CAMERA.is_fav,
          FIELDS_CAMERA.is_public,
          FIELDS_CAMERA.inactivity_period,
          FIELDS_CAMERA.server,
          FIELDS_CAMERA.tariff,
          FIELDS_CAMERA.token_l,
          FIELDS_CAMERA.permission,
          FIELDS_CAMERA.record_disable_period,
        ],
        orderBy: this.selectedSort,
        tokenLiveTTL: TOKEN_TTL.DEFAULT,
        page: this.selectedPage,
        pageSize: this.$store.state.meshCameras.pageSize,
        query: this.queryTabSearch,
        folderIds: this.folderIds
      };
    },
  },
  /**
   * При загрузке компонента востанавливается его предыдущее состояние и в соответствии с ним загружается актуальный список камер.
   * Состояние восстанавливается исходя из работы конкретного метода {@link recoverState} который можно перекрыть
   * в компонентах и задать свой механизм восстановления.
   *
   * Регистрация периодического обновления подписи для обновления скриншотов, чтобы через нее происходила загрузка свежих скриншотов,
   * если пользователь долго находится на странице.
   */
  mounted() {
    this.recoverState();
    this.isLoadingCommon = true;
    this.loadCameras().then(() => {
      this.isLoadingCommon = false;
    });

    this.intervalIdForNewScreenshotSign = setInterval(() => {
      this.screenshotSign = Math.random().toString(36).substr(2, 5);
    }, DEFAULT_SCREENSHOT_TTL * 1000);
  },
  /**
   * Очистка таймаутов для обновления камер и скриншотов.
   */
  beforeDestroy() {
    clearTimeout(this.timeoutIdForReloadCameras);
    clearInterval(this.intervalIdForNewScreenshotSign);
  },
  methods: {
    /**
     * Метод для перекрытия, в котором вызываются функции для сохранения состояния компонента надлежащим образом.
     * Этот метод должен передаваться как функция в компоненты и вызываться по наступлению изменения привязанных моделей.
     *
     * Случаи когда нужно в основном только сохранить состояние, но не нужно обновлять список камер:
     *  - Изменение варианта отображения списка камер при непосредственном выборе в компоненте {@link ViewTypeSwitcher}.
     *  - Изменение текста в поисковом запросе в компоненте {@link CamerasFinder}.
     */
    saveState() {
      this.storeState();
    },
    /**
     * То же что и {@link saveState}, но после сохранения запускает процесс обновления камер в компоненте.
     *
     * Случаи когда нужно и сохранить состояние и обеспечить обновление списка камер:
     *  - Изменение существующей сортировки при непосредственном выборе в компоненте {@link SelectSort}.
     *  - Переход к новой странице.
     *  - Необходимость отражать изменения состояния в URL чтобы сохранить переходы между ними при использовании кнопок "вперед/назад"
     *  и в таком случае компонент должен самостоятельно уметь распозновать необходимость загрузки камер.
     */
    saveStateAndLoadCameras() {
      this.saveState();
      this.loadCameras();

    },
    saveStateAndFolder() {
      this.loadCamerasFolders();
    },
    /**
     * Метод для перекрытия, в котором вызываются функции для восстановления состояния компонента из сохраненного места.
     *
     * Как правило, нет необходимости восстанавливать состояние вручную (при клике на кнопку в компоненте).
     * Но это нужно производить при создании компонента или при его восстановлении, потому пусть сам компонент перекроет метод
     * и решит как надо восстанавливать себя, а тут по умолчанию это произойдет в хуках.
     */
    recoverState() {
      this.restoreStateCurrentFrame();
    },
    /**
     * Полное восстановление ключевых параметров из предыдущего состояния компонента, сохраненных во vuex.
     */
    restoreStateCurrentFrame() {
      this.selectedTab = this.$store.state.meshCameras.frames[this.currentFrame].activeTab;
      this.selectedView = this.$store.state.meshCameras.frames[this.currentFrame].typeView;
      this.querySearchCameras = this.$store.state.meshCameras.frames[this.currentFrame].querySearchCameras;
      this.queryTabSearch = this.$store.state.meshCameras.frames[this.currentFrame].queryTabSearch;
      this.restoreStateForSelectedTab();
    },
    /**
     * Восстановление параметров зависимых от выбранного таба из предыдущего состояния компонента, сохраненных во vuex.
     */
    restoreStateForSelectedTab() {
      this.selectedSort = this.$store.state.meshCameras.frames[this.currentFrame].tabs[this.selectedTab].sort;
      this.selectedPage = this.$store.state.meshCameras.frames[this.currentFrame].tabs[this.selectedTab].page;
    },
    /**
     * Полное сохранение во vuex ключевых параметров компонента для последующего восстановления.
     */
    storeState() {
      this.$store.commit("meshCameras/" + MUTATION_SET_STATE_FRAME, {
        frame: this.currentFrame,
        tab: this.selectedTab,
        typeView: this.selectedView,
        querySearchCameras: this.querySearchCameras,
        queryTabSearch: this.queryTabSearch,
        sort: this.selectedSort,
        page: this.selectedPage,
        folders: this.folderIds,
      });
    },

    /**
     * Общий метод загрузки списка камер, исходя из выбранных опций на компоненте.
     *
     * @return {Promise}
     */
    async loadCameras() {
      clearTimeout(this.timeoutIdForReloadCameras);
      this.isLoadingPage = true;
      const folderIds = this.$route.params.folderId;
      this.folderIds = [folderIds];
      const actionForLoadCameras = {
        [TABS.MY]: `cameras/${ACTION_LOAD_CAMERAS_MY}`,
        [TABS.FAV]: `cameras/${ACTION_LOAD_CAMERAS_FAV}`,
        [TABS.SEARCH]: `cameras/${ACTION_SEARCH_CAMERAS}`,
        [TABS.FOLDERS]: `cameras/${ACTION_LOAD_CAMERAS_MY_FOLDERS}`,
      }[this.selectedTab];
      const [listCamerasInfo, pageCount] = await this.$store.dispatch(actionForLoadCameras, this.dataForLoadCameras);
      this.pageCount = pageCount;
      this.listCamerasInfo = listCamerasInfo;
      this.isLoadingPage = false;

      this.timeoutIdForReloadCameras = setTimeout(() => {
        this.loadCameras();
      }, whenToUpdateToken(TOKEN_TTL.DEFAULT));
    },
    async loadCamerasFolders() {
      clearTimeout(this.timeoutIdForReloadCameras);
      this.isLoadingPage = true;
      const [listCamerasInfo, pageCount] = await this.$store.dispatch(`cameras/${ACTION_LOAD_CAMERAS_MY_FOLDERS}`, this.dataForLoadCameras);
      this.pageCount = pageCount;
      this.listCamerasInfo = listCamerasInfo;
      this.isLoadingPage = false;

      this.timeoutIdForReloadCameras = setTimeout(() => {
        this.loadCamerasFolders();
      }, whenToUpdateToken(TOKEN_TTL.DEFAULT));
    },
  },
};
