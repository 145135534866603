import map_mixin from "@/store/map/mapMixin.js";
import {MAP_TARGET} from "@/utils/consts.js";

let mapMy = Object.assign({}, map_mixin);
mapMy.state = {
  cameras: {},
  refreshDataTimerFlag: false,
  target: MAP_TARGET.my,
};

export default mapMy;
