<template>
  <div class="main__content content">
    <div class="pacs-settings-table">
      <div class="pacs-settings-table__group-setting">
        <SmartInputText
          v-model="searchText"
          class="pacs-settings-table__settings-control pacs-settings-table__settings-control_big"
          :placeholder="$t('searchPlaceholder')"
          size="l"
          @keyup.native.enter="applyTableSettings()"
        >
          <CamsButton
            position="r"
            size="l"
            type="button"
            icon-type="only"
            @click="applyTableSettings()"
          >
            <svg class="icon-search">
              <use xlink:href="#icon-search" />
            </svg>
          </CamsButton>
        </SmartInputText>
      </div>
    </div>

    <SpinnerLoadingModal v-if="isLoading" />
    <SpinnerLoading v-if="isLoadingTable" class="loader_center" color="blue" />

    <PacsTable
      v-else
      :column-captions="{
        id: 'tableHeaders.id',
        ident: 'tableHeaders.identifier',
        title: 'tableHeaders.name',
        emergency_vehicle_access: 'tableHeaders.emergencyVehicleAccess',
        camera_count: 'tableHeaders.cameraCount',
        is_active: 'tableHeaders.isActive',
      }"
      :column-names="columnNames"
      :column-widths="columnWidths"
      :current-page="currentPage"
      :data-rows="dataRows"
      :key-field="keyField"
      :page-count="pageCount"
      :sort-info="sortInfo"
      :total-count="totalCount"
      @new-page="selectPage"
      @new-sort="changeSort"
    />
  </div>
</template>

<script>
import {ACTION_LOAD_DEVICES_FOR_TABLE, DEVICE_STRUCT} from "@/store/pacs/devices/index.js";
import PacsTable from "@/components/pacs/PacsTable.vue";
import {entityTableMixin} from "@/components/pacs/mixins.js";
import {ROUTE_PACS_EDIT_DEVICE} from "@/router/names.js";

/**
 * Компонент отображения таблицы и фильтров с информацией по устройствам.
 */
export default {
  mixins: [
    entityTableMixin
  ],
  components: {
    PacsTable,
  },
  data() {
    return {
      entityStruct: DEVICE_STRUCT,
      entityFields: [
        DEVICE_STRUCT.fields.id,
        DEVICE_STRUCT.fields.title,
        DEVICE_STRUCT.fields.ident,
        DEVICE_STRUCT.fields.emergency_vehicle_access,
        DEVICE_STRUCT.fields.camera_count,
        DEVICE_STRUCT.fields.is_active,
      ],
      // Настройки для фиксации ширины определенных колонок.
      columnWidths: {
        [DEVICE_STRUCT.fields.id.name]: "100px",
        [DEVICE_STRUCT.fields.camera_count.name]: "140px",
        [DEVICE_STRUCT.fields.is_active.name]: "100px",
      },
    };
  },
  methods: {
    /**
     * @link entityTableMixin.methods.loadPage
     */
    async loadPage() {
      this.isLoadingTable = true;
      try {
        const responseData = await this.$store.dispatch(`pacs/devices/${ACTION_LOAD_DEVICES_FOR_TABLE}`, {
          page: this.currentPage,
          search: this.searchText,
          orderBy: this.sortInfoForApi,
        });

        this.pageCount = responseData.page.all;
        this.totalCount = responseData.count;

        this.dataRows = responseData.results.map((rawDeviceInfo) => {
          const paramsRouteEditDevice = {name: ROUTE_PACS_EDIT_DEVICE, params: {deviceId: rawDeviceInfo[DEVICE_STRUCT.key]}};
          return {
            ...this.calcDefaultDataRow(rawDeviceInfo),
            [DEVICE_STRUCT.fields.id.name]: {
              type: DEVICE_STRUCT.fields.id.type,
              value: rawDeviceInfo[DEVICE_STRUCT.fields.id.name],
              params: paramsRouteEditDevice,
            },
            [DEVICE_STRUCT.fields.title.name]: {
              type: DEVICE_STRUCT.fields.title.type,
              value: rawDeviceInfo[DEVICE_STRUCT.fields.title.name],
              params: paramsRouteEditDevice,
            },
          };
        });
      } finally {
        this.isLoadingTable = false;
      }
    },
  },
};
</script>
